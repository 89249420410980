import { Box, Center, useColorModeValue, Stack, SimpleGrid, useBreakpointValue, Grid } from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import { useMemo } from "react";
import { useCallback } from "react";
import React, { useContext, useEffect, useRef } from "react";
import { CollectionsFilterContext } from "../collections/CollectionsFilterContext";
import { ItemLoadingIndicator } from "../collections/ItemLoadingIndicator";
import { NotesPanel } from "screens/content/common/notes/NotesPanel";
import { ContentViewPanel } from "screens/content/contentView/ContentViewPanel";
import { SharePanel } from "screens/panels/share/SharePanel";
import { VerifiedAIPanel } from "screens/content/contentView/VerifiedAIPanel";
import { useDispatch } from "react-redux";
import { deleteUserPreference } from "state/userPreference/operations";
import {
  useCollectionKey,
  useEquityPortfolioId,
  useGetConversationCompanyTickerValue,
  useGroupCollectionsIds,
  usePortfolioCollections,
  useProjectParams,
  useSortedCollectionsIds,
  useUserProfile,
  useUserPreference,
  useAllDueDiligenceProjectMetaData,
} from "hooks";
import { placeholderCompanies } from "../dashboard/PlaceholderCompanies";
import { HomePlaceholderTile } from "./projectLandingTileLayouts/HomePlaceholderTile";
import { useScheduledPlaceholderProjectsByPortfolio } from "hooks/useWorkflowSchedules";
import { ScheduledProjectPlaceholderTile } from "./projectLandingTileLayouts/ScheduledProjectPlaceholderTile";
import ProjectLandingSmallTile from "./projectLandingTileLayouts/ProjectLandingSmallTile";
import { ProjectLandingSmallPreviousVersionItem } from "./projectLandingTileLayouts/ProjectLandingSmallPreviousVersionItem";

const LOAD_MORE_LIMIT = 8;

const ProjectGroup = ({ collectionId }: { collectionId: string }) => {
  const maybeGroupId = useCollectionKey(collectionId, "projectGroupId");
  const maybeCollectionsIds = useGroupCollectionsIds(maybeGroupId);
  const maybeSortedCollectionsIds = useSortedCollectionsIds(maybeCollectionsIds);

  const { firstCollectionId, restCollections } = useMemo(() => {
    if (!maybeSortedCollectionsIds || maybeSortedCollectionsIds.length === 0)
      return { firstCollectionId: collectionId, restCollections: [] };
    else {
      return {
        firstCollectionId: collectionId,
        restCollections: maybeSortedCollectionsIds.filter((id) => id !== collectionId),
      };
    }
  }, [maybeSortedCollectionsIds, collectionId]);

  const renderRestCollections = useCallback(() => {
    return restCollections.length > 0 ? (
      <Stack spacing=".75rem" overflowY="auto" maxHeight="10rem">
        {restCollections.map((id) => (
          <ProjectLandingSmallPreviousVersionItem key={`project-${id}`} collectionId={id} />
        ))}
      </Stack>
    ) : null;
  }, [restCollections]);

  return (
    <ProjectLandingSmallTile
      collectionId={firstCollectionId}
      previousVersions={renderRestCollections()}
      previousVersionCount={restCollections.length}
    />
  );
};

export const ProjectLandingTiles: FunctionComponent = () => {
  const { projectFilter, isPortfolios, projectId } = useProjectParams();
  const {
    collectionCount,
    setCollectionCount,
    filteredCollections,
    selectedQuadrant,
    searchText,
    setSelectedQuadrant,
    setSearchText,
    selectedRating,
    setSelectedRating,
    setSelectedProjectId,
  } = useContext(CollectionsFilterContext);
  const sectionRef = useRef<HTMLDivElement>(null);
  const bgColor = useColorModeValue("white", "gray.800");
  const textColor = useColorModeValue("primary.darkGray", "gray.400");
  const dispatch = useDispatch();
  const { id: currentUserId } = useUserProfile();
  const userPreferenceSourceWeight = useUserPreference("source_weights_index") as 0 | 1;
  const observer = useRef<IntersectionObserver | null>(null);
  const portfolioCollections = usePortfolioCollections();
  const portfolioId = useEquityPortfolioId(portfolioCollections, "Magnificent 7");
  const loadTilesCount = 6;
  const observerRef = useRef<HTMLDivElement>(null);
  const isDueDiligence = useMemo(() => projectFilter === "due_diligence" || isPortfolios, [isPortfolios, projectFilter]);
  const scheduledNewProjects = useScheduledPlaceholderProjectsByPortfolio(projectFilter ?? "");
  const isDueDiligenceLanding = projectFilter === "due_diligence" && !projectId;
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const isTablet = useBreakpointValue({ base: false, md: true, lg: false }, { fallback: "md", ssr: false });
  const dueDiligenceProjectMetaData = useAllDueDiligenceProjectMetaData();

  useEffect(() => {
    if (loadTilesCount > 0) {
      setCollectionCount(loadTilesCount);
    }
  }, [loadTilesCount, setCollectionCount]);

  useEffect(() => {
    if (!userPreferenceSourceWeight) return;

    dispatch(
      deleteUserPreference({
        preferenceKey: "source_weights_index",
      })
    );
  }, [dispatch, userPreferenceSourceWeight]);

  const filteredActiveOrUniqueCollections = useMemo(() => {
    const collections = filteredCollections.filter((collection) => {
      if (isDueDiligenceLanding && collection.shareDetails) {
        return collection.shareDetails.ownerId === currentUserId;
      }
      return true;
    });

    // const [activeCollections, uniqueCollections] = collections.reduce(
    //   (acc, collection) => {
    //     if (collection.isActiveProjectInGroup || !collection.projectGroupId) {
    //       acc[0].push(collection);
    //     } else {
    //       acc[1].push(collection);
    //     }
    //     return acc;
    //   },
    //   [[], []] as [typeof filteredCollections, typeof filteredCollections]
    // );
    // console.log(`Active collections: ${activeCollections.length}, Unique collections: ${uniqueCollections.length}`);

    return collections.flatMap((collection) => {
      if (collection.isActiveProjectInGroup || !collection.projectGroupId) {
        return [collection.id];
      } else {
        return [];
      }
    });
  }, [filteredCollections, isDueDiligenceLanding, currentUserId]);

  const totalItemCount = useMemo(() => {
    // For due_diligence projects with totalCount available
    if (projectFilter === "due_diligence" && dueDiligenceProjectMetaData.totalCount) {
      return dueDiligenceProjectMetaData.totalCount;
    }

    // For other cases, use the first available count
    if (filteredActiveOrUniqueCollections && filteredActiveOrUniqueCollections.length > 0) {
      return filteredActiveOrUniqueCollections.length;
    }

    return filteredActiveOrUniqueCollections.length || 0;
  }, [projectFilter, dueDiligenceProjectMetaData.totalCount, filteredActiveOrUniqueCollections]);

  const collectionsToRender = useMemo(() => {
    return filteredActiveOrUniqueCollections.slice(0, collectionCount);
  }, [collectionCount, filteredActiveOrUniqueCollections]);

  const allProjectConversationIds = useMemo(
    () => filteredCollections.map((collection) => collection.conversationId).filter((id): id is string => id !== undefined),
    [filteredCollections]
  );

  const getConversationTicker = useGetConversationCompanyTickerValue();
  const filteredPlaceholderCompanies = useMemo(() => {
    const stockTickers = new Set(filteredCollections.map((collection) => collection.ticker && collection.ticker.toLowerCase()));
    const conversationTickerValues = allProjectConversationIds.flatMap((conversationId) => {
      const maybeValue = getConversationTicker(conversationId);

      if (maybeValue) {
        return [maybeValue];
      } else {
        return [];
      }
    });
    const allTickers = new Set([...stockTickers, ...conversationTickerValues.map((ticker) => ticker.toLowerCase())]);

    return placeholderCompanies.filter((company) => !allTickers.has(company.ticker.toLowerCase()));
  }, [filteredCollections, allProjectConversationIds, getConversationTicker]);

  useEffect(() => {
    const currentObserver = observer.current;
    const observerElement = observerRef.current;

    if (!observerElement) return;

    const options = {
      root: null,
      rootMargin: "20px",
      threshold: 0.1,
    };

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setCollectionCount((prev) => prev + LOAD_MORE_LIMIT);
      }
    }, options);

    observer.current.observe(observerElement);

    const checkInitialVisibility = () => {
      if (observerElement) {
        const rect = observerElement.getBoundingClientRect();
        const isVisible = rect.top <= window.innerHeight && rect.bottom >= 0;
        if (isVisible) {
          setCollectionCount((prev) => prev + LOAD_MORE_LIMIT);
        }
      }
    };

    setTimeout(checkInitialVisibility, 100);

    return () => {
      if (currentObserver) {
        currentObserver.disconnect();
      }
    };
  }, [setCollectionCount]);

  const handleClearFilters = () => {
    setSearchText("");
    setSelectedQuadrant(undefined);
    setSelectedRating(undefined);
  };

  return (
    <Box ref={sectionRef} height="100%" onMouseLeave={() => setSelectedProjectId(undefined)}>
      {collectionsToRender.length > 0 ? (
        <Stack pb={isMobile || isTablet ? "1rem" : "2rem"}>
          {scheduledNewProjects.map((schedule) => (
            <ScheduledProjectPlaceholderTile
              key={schedule.scheduleId}
              scheduleId={schedule.scheduleId}
              companyName={schedule.companyName}
              exchange={schedule.companyStockExchange}
              ticker={schedule.companyTicker}
            />
          ))}
          <Grid
            templateColumns={["1fr", "repeat(2, 1fr)", "repeat(2, 1fr)", "repeat(2, 1fr)", "repeat(2, 1fr)"]}
            gap={isMobile || isTablet ? "1rem" : "2rem"}
            alignItems="flex-start"
            width="100%">
            {collectionsToRender.map((collectionId) => {
              return (
                <Box key={`group-${collectionId}`} height="auto">
                  <ProjectGroup collectionId={collectionId} />
                </Box>
              );
            })}
          </Grid>
        </Stack>
      ) : (
        <>
          {selectedQuadrant || searchText || selectedRating ? (
            <Stack textAlign="center" mt="3rem" mb="4rem" color={textColor}>
              <Box fontSize="lg">
                No projects found {searchText.length > 0 || selectedRating ? "using the filter" : ""} <b>{searchText || selectedRating}</b>{" "}
                {selectedQuadrant ? "in quadrant" : ""} <b>{selectedQuadrant}</b>
              </Box>
              <Box fontSize="md" onClick={handleClearFilters} color="charli.primaryBlue" cursor="pointer">
                Click here to clear filter
              </Box>
            </Stack>
          ) : (
            isDueDiligence &&
            portfolioId !== projectFilter && (
              <Grid
                templateColumns={["1fr", "repeat(2, 1fr)", "repeat(2, 1fr)", "repeat(2, 1fr)", "repeat(2, 1fr)"]}
                gap={isMobile || isTablet ? "1rem" : "2rem"}
                alignItems="flex-start"
                width="100%">
                <Box height="auto">
                  <ProjectLandingSmallTile collectionId="placeholder" />
                </Box>
              </Grid>
            )
          )}
        </>
      )}
      <Box
        height={collectionsToRender.length === 0 && portfolioId === projectFilter ? "0" : "20px"}
        width="100%"
        className="last-content-item"
        ref={observerRef}
      />
      {collectionsToRender.length > 0 && (
        <Box position={"fixed"} bottom={"0"} backgroundColor={bgColor} borderRadius="full" border="none" zIndex={2} mb="5px" right="8px">
          <Center height="100%">
            <ItemLoadingIndicator
              tooltip={`There are ${totalItemCount} project groups ${
                filteredCollections.length - totalItemCount > 0
                  ? `containing ${filteredCollections.length - totalItemCount} additional versions`
                  : ""
              }`}
              onClick={() => null}
              currentItemCount={collectionsToRender.length}
              totalItemCount={totalItemCount}
            />
          </Center>
        </Box>
      )}
      {!selectedQuadrant && !searchText && !selectedRating && portfolioId === projectFilter && (
        <SimpleGrid spacing="1rem" columns={[1, 1, 3, 3, 4]}>
          {filteredPlaceholderCompanies.map((company) => (
            <HomePlaceholderTile
              key={company.ticker}
              logo={company.logo}
              companyName={company.companyName}
              ticker={company.ticker}
              exchange={company.exchange}
              securityType={company.securityType}
              overview={company.overview}
            />
          ))}
        </SimpleGrid>
      )}
      <ContentViewPanel />
      <SharePanel /> <NotesPanel /> <VerifiedAIPanel />
    </Box>
  );
};
