import type { Middleware } from "redux";
import { actions as workflowActions } from "state/workflow/reducer";
import { downloadWorkflows } from "state/workflow/operations";
import { requestToDownloadWorkflows } from "state/workflow/utils";
import isEmpty from "lodash/isEmpty";
import type { RootState } from "state/rootReducer";
import type { Workflow } from "types/workflows/workflow";
import { WorkflowTaskStatus } from "types/workflows/workflow";
import { actions as checkpointsActions } from "state/checkpoints/reducer";

export const workflowFetchingMiddleware: Middleware = (store) => (next) => (action) => {
  next(action);

  if (action.type === downloadWorkflows.fulfilled.type) {
    const origin = action.meta.arg.origin;
    const workflows = action.payload as Workflow[];
    const rootState = store.getState() as RootState;
    const queuedWorkflowIds = rootState.workflow.queuedWorkflowIds;

    if (!isEmpty(queuedWorkflowIds)) {
      store.dispatch(workflowActions.removeFromQueuedWorkflowIds(queuedWorkflowIds));
      requestToDownloadWorkflows(queuedWorkflowIds, rootState.workflow.isLoadingWorkflowMap, store.dispatch, { origin });
    }

    workflows.forEach((workflow) => {
      // Set checkpoints only for those workflows that are in progress. Clean them otherwise
      if (workflow.status === "in_progress" || workflow.status === "clarification_needed" || workflow.status === "queued") {
        if (workflow.progress) {
          store.dispatch(checkpointsActions.setCheckpointsWorkflows([{ id: workflow.id, progress: workflow.progress }]));
          if (workflow.progress.task) {
            store.dispatch(
              checkpointsActions.setWorkflowRunningTask({
                workflowId: workflow.id,
                name: workflow.progress.task,
              })
            );
          }
        }
        if (workflow.progressByChildWorkflow) {
          // clear the child workflows checkpoints before setting the new values
          store.dispatch(checkpointsActions.clearChildWorkflowCheckpoints(workflow.id));
          store.dispatch(
            checkpointsActions.setCheckpointsChildWorkflows({
              workflowId: workflow.id,
              progressByChildWorkflow: Object.entries(workflow.progressByChildWorkflow).reduce(
                (
                  acc,
                  [
                    childWorkflowId,
                    {
                      intent,
                      progress: { task, taskId, ...progress },
                    },
                  ]
                ) => {
                  store.dispatch(checkpointsActions.setStatusByChildWorkflow({ childWorkflowId, status: WorkflowTaskStatus.inProgress }));

                  if (task && taskId) {
                    store.dispatch(
                      checkpointsActions.setChildWorkflowTask({
                        taskId,
                        childWorkflowId,
                        workflowId: workflow.id,
                        name: task,
                        status: WorkflowTaskStatus.inProgress,
                      })
                    );
                  }

                  return {
                    ...acc,
                    [childWorkflowId]: { ...progress, id: childWorkflowId, intent },
                  };
                },
                {}
              ),
            })
          );
        }
      } else if (workflow.status === "complete") {
        store.dispatch(checkpointsActions.clearWorkflowCheckpoints(workflow.id));
        store.dispatch(checkpointsActions.clearChildWorkflowCheckpoints(workflow.id));
      }
    });
  }
};
