import { Stack, Text, useBreakpointValue, useColorModeValue } from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import React from "react";
import ECHighlighter from "react-ec-highlighter";
import type { AssessmentResultsRiskAssessmentResult } from "types/collection";
import { BlockSectionHeader } from "screens/content/contentView/previewSection/BlockSectionHeader";

export interface Props {
  assessmentResults: AssessmentResultsRiskAssessmentResult;
  searchText?: string;
  subSection?: boolean;
}

export const AssessmentResultsRiskAssessment: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  assessmentResults,
  searchText,
  subSection,
}) => {
  const textColor = useColorModeValue("primary.darkGray", "gray.400");
  const bgColor = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });

  return (
    <Stack p={isMobile ? ".5rem" : "1rem"} width="100%" bg={bgColor} border="1px solid" borderColor={borderColor} borderRadius="md">
      <BlockSectionHeader title="Risk Assessment" subSection={subSection} />
      {assessmentResults.risk_assessment_report.length && (
        <Stack>
          {assessmentResults.risk_assessment_report.split("\n").map(
            (sentence, index) =>
              sentence.length > 0 && (
                <Text
                  color={textColor}
                  fontSize={"sm"}
                  className="ch-assessment-result-risk-assessment"
                  key={index}
                  width="100%"
                  pb=".5rem">
                  {searchText && searchText.length > 0 && sentence ? <ECHighlighter searchPhrase={searchText} text={sentence} /> : sentence}
                </Text>
              )
          )}
        </Stack>
      )}
    </Stack>
  );
};
