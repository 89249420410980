import type { FunctionComponent } from "react";
import React, { useMemo, memo, useState, useRef, useCallback } from "react";
import { Stack, Text, Badge, Box, useColorModeValue } from "@chakra-ui/react";
import { ResponsiveContainer, ComposedChart, Tooltip, XAxis, YAxis, Bar, CartesianGrid, Area } from "recharts";
import type { StockEquityData } from "types/stock";
import { useProjectParams } from "hooks";
import { motion } from "framer-motion";

interface Props {
  height?: string;
  hideAxis?: boolean;
  hideBorder?: boolean;
  stockEquityData: StockEquityData | null;
  usingMockData?: boolean;
  width?: number | string;
}

interface ChartDataPoint {
  label: string;
  date: string;
  close: number;
  low: number;
  high: number;
  volume: number;
}

const formatPrice = (price: number | string) => Number(price).toFixed(2);
const formatNumber = (num: number | string): string => {
  return Number(num).toLocaleString("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
};

interface CustomTooltipProps {
  active?: boolean;
  payload?: Array<{
    value: number;
    payload: ChartDataPoint;
    dataKey?: string;
  }>;
  position?: { x: number; y: number };
}

const CustomTooltip = memo(({ active = false, payload = [] }: CustomTooltipProps) => {
  const bgColor = useColorModeValue("#fbfbfb", "gray.800");
  const bgHeaderColor = useColorModeValue("gray.50", "gray.800");

  if (active && payload.length) {
    const data = payload[0].payload;
    return (
      <Stack spacing="0" bgColor={bgColor} fontSize="xs" boxShadow="md" width="100%" borderRadius="md">
        <Box bgColor={bgHeaderColor} p="10px" borderTopRadius="md">
          <Text isTruncated width="100%" fontWeight="semibold">
            {data.date}
          </Text>
        </Box>
        <Stack spacing="5px" p="10px" borderBottomRadius="md">
          {data.high && (
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Badge size="xs" isTruncated color="white" bgColor="#007560">
                High
              </Badge>
              <Text width="5rem" textAlign="right">
                ${formatPrice(data.high)}
              </Text>
            </Stack>
          )}
          {data.low && (
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Badge size="xs" isTruncated color="white" bgColor="#f56565">
                Low
              </Badge>
              <Text width="5rem" textAlign="right">
                ${formatPrice(data.low)}
              </Text>
            </Stack>
          )}
          {data.close && (
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Badge size="xs" isTruncated color="white" bgColor="#3d9484">
                Close
              </Badge>
              <Text width="5rem" textAlign="right">
                ${formatPrice(data.close)}
              </Text>
            </Stack>
          )}
          {data.volume && (
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Badge size="xs" isTruncated color="white" bgColor="#93abcc">
                Volume
              </Badge>
              <Text width="5rem" textAlign="right">
                {formatNumber(data.volume)}
              </Text>
            </Stack>
          )}
        </Stack>
      </Stack>
    );
  }
  return null;
});

CustomTooltip.displayName = "CustomTooltip";

const sampleData = (data: ChartDataPoint[], sampleSize: number): ChartDataPoint[] => {
  if (data.length <= sampleSize) return data;

  const sampledData: ChartDataPoint[] = [];
  const step = Math.floor(data.length / sampleSize);

  for (let i = 0; i < data.length; i += step) {
    sampledData.push(data[i]);
  }

  if (sampledData[sampledData.length - 1] !== data[data.length - 1]) {
    sampledData.push(data[data.length - 1]);
  }

  return sampledData;
};

const MotionBox = motion(Box);

export const StockEquityChart: FunctionComponent<React.PropsWithChildren<Props>> = memo(
  ({ height = "20rem", hideAxis = false, hideBorder = false, stockEquityData, usingMockData = false, width = "100%" }) => {
    const [tooltipData, setTooltipData] = useState<{
      active: boolean;
      payload?: Array<{
        value: number;
        payload: ChartDataPoint;
        dataKey?: string;
      }>;
      position?: { x: number; y: number };
      index?: number;
    }>({ active: false });
    const chartRef = useRef<HTMLDivElement>(null);
    const reformattedStockEquityData = useMemo(() => {
      if (!stockEquityData?.data.chart?.result?.[0]) {
        return [];
      }

      const result = stockEquityData.data.chart.result[0];
      const { timestamp, indicators } = result;
      const quote = indicators.quote[0];

      if (!timestamp) return [];

      const formattedData: ChartDataPoint[] = timestamp.map((ts, index) => ({
        label: new Date(ts * 1000).toLocaleDateString("en-US", {
          day: "2-digit",
          month: "short",
          year: "numeric",
        }),
        date: new Date(ts * 1000).toLocaleDateString("en-US", {
          day: "2-digit",
          month: "short",
          year: "numeric",
        }),
        close: Number(quote.close?.[index]?.toFixed(2) ?? 0),
        low: Number(quote.low?.[index]?.toFixed(2) ?? 0),
        high: Number(quote.high?.[index]?.toFixed(2) ?? 0),
        volume: Math.round(quote.volume?.[index] ?? 0),
      }));

      return sampleData(formattedData, 100);
    }, [stockEquityData]);

    const { minClose, maxClose, minVolume, maxVolume } = useMemo(() => {
      if (!reformattedStockEquityData.length) {
        return { minClose: 0, maxClose: 0, minVolume: 0, maxVolume: 0 };
      }

      return reformattedStockEquityData.reduce(
        (acc, item) => ({
          minClose: Math.min(acc.minClose, item.close),
          maxClose: Math.max(acc.maxClose, item.close),
          minVolume: Math.min(acc.minVolume, item.volume),
          maxVolume: Math.max(acc.maxVolume, item.volume),
        }),
        {
          minClose: Number.MAX_VALUE,
          maxClose: Number.MIN_VALUE,
          minVolume: Number.MAX_VALUE,
          maxVolume: Number.MIN_VALUE,
        }
      );
    }, [reformattedStockEquityData]);

    const tileBorderColor = useColorModeValue("gray.300", "gray.700");
    const { projectId, parentRoute } = useProjectParams();
    const barColor = useColorModeValue("#93abcc", "#4A5568");
    const cartesianGridColor = useColorModeValue("#f5f5f5", "#4A5568");

    const handleMouseOver = useCallback((data: any) => {
      if (data && data.activePayload && data.activePayload.length) {
        setTooltipData({
          active: true,
          payload: data.activePayload,
          position: {
            x: data.activeCoordinate?.x ?? 0,
            y: data.activeCoordinate?.y ?? 0,
          },
          index: data.activeTooltipIndex,
        });
      }
    }, []);

    const handleMouseLeave = useCallback(() => {
      setTooltipData({ active: false });
    }, []);

    const tooltipX = tooltipData.position?.x ?? 0;
    const chartWidth = chartRef.current?.clientWidth ?? 0;
    const tooltipWidth = 200;
    const tooltipOffset = 10;

    const isRightHalf = tooltipX > chartWidth / 2;

    let tooltipLeft = 0;
    if (isRightHalf) {
      tooltipLeft = tooltipX - tooltipWidth - tooltipOffset;
    } else {
      tooltipLeft = tooltipX + tooltipOffset;
    }

    if (tooltipLeft < 10) tooltipLeft = 10;
    if (tooltipLeft + tooltipWidth > chartWidth - 10) tooltipLeft = chartWidth - tooltipWidth - 10;

    return (
      <Box
        ref={chartRef}
        height={height}
        width="100%"
        className="ch-project-tile-stock-chart"
        opacity={usingMockData ? 0.2 : 1}
        border={hideBorder || projectId || parentRoute === "home" ? "none" : "1px solid"}
        borderColor={tileBorderColor}
        borderRadius=".375rem"
        position="relative">
        {tooltipData.active && tooltipData.payload && tooltipData.payload.length > 0 && (
          <MotionBox
            position="absolute"
            top="30%"
            style={{
              y: "-50%",
            }}
            initial={{
              x: tooltipLeft,
            }}
            animate={{
              x: tooltipLeft,
            }}
            transition={{
              type: "spring",
              stiffness: 500,
              damping: 30,
            }}
            zIndex={1000}
            width={`${tooltipWidth}px`}>
            <CustomTooltip active={tooltipData.active} payload={tooltipData.payload} />
          </MotionBox>
        )}
        <ResponsiveContainer width={width} height="100%">
          <ComposedChart
            width={500}
            height={400}
            data={reformattedStockEquityData}
            margin={{
              top: 0,
              right: hideAxis ? 0 : 0,
              bottom: 0,
              left: hideAxis ? 0 : -20,
            }}
            onMouseMove={handleMouseOver}
            onMouseLeave={handleMouseLeave}
            style={{ position: "relative" }}>
            <defs>
              <linearGradient id="colorClose" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#007560" stopOpacity={0.4} />
                <stop offset="95%" stopColor="#007560" stopOpacity={0} />
              </linearGradient>
            </defs>
            {!hideAxis && <CartesianGrid stroke={cartesianGridColor} />}
            <XAxis hide={hideAxis} dataKey="date" fontSize="10px" interval="preserveStartEnd" />
            <YAxis
              hide={hideAxis}
              yAxisId="left"
              domain={[Math.floor(minClose * 0.95), Math.ceil(maxClose * 1.02)]}
              allowDataOverflow
              fontSize="10px"
            />
            <YAxis
              hide
              yAxisId="right"
              orientation="right"
              domain={[minVolume, maxVolume * 2]}
              allowDataOverflow
              fontSize="10px"
              tickLine={false}
            />
            {!hideAxis && <Tooltip isAnimationActive={false} content={<div />} />}
            {tooltipData.active && tooltipData.position && (
              <g style={{ zIndex: 8 }}>
                <rect
                  x={tooltipData.position.x - 4}
                  y={0}
                  width={8}
                  height="90%"
                  fill="#007560"
                  fillOpacity={0.1}
                  style={{ pointerEvents: "none" }}
                />
              </g>
            )}
            <Area
              yAxisId="left"
              fill="url(#colorClose)"
              dataKey="close"
              stroke="#007560"
              strokeWidth={1}
              isAnimationActive={false}
              style={{ zIndex: 10 }}
            />
            <Bar yAxisId="right" dataKey="volume" fill={barColor} isAnimationActive={false} />
            <rect
              x={0}
              y={0}
              width="100%"
              height="100%"
              fill="transparent"
              onMouseMove={useCallback(
                (e: React.MouseEvent<SVGRectElement>) => {
                  if (hideAxis) return;
                  const chartElement = (e.target as SVGElement).ownerSVGElement;
                  if (!chartElement) return;

                  const rect = chartElement.getBoundingClientRect();
                  const x = e.clientX - rect.left;
                  const dataLength = reformattedStockEquityData.length;
                  if (dataLength === 0) return;

                  const chartPadding = { left: 40, right: 0 };
                  const effectiveWidth = rect.width - chartPadding.left;

                  const normalizedX = (x - chartPadding.left) / effectiveWidth;
                  if (normalizedX < 0 || normalizedX > 1) return;
                  const index = Math.min(Math.max(0, Math.floor(normalizedX * dataLength)), dataLength - 1);

                  if (index >= 0 && index < dataLength) {
                    const item = reformattedStockEquityData[index];
                    const xCoord = chartPadding.left + ((index + 0.5) / dataLength) * effectiveWidth;

                    const yRange = rect.height;
                    const yTop = rect.height * 0.1;
                    const valueRange = maxClose - minClose;
                    const normalizedValue = valueRange > 0 ? (item.close - minClose) / valueRange : 0.5;
                    const yCoord = yTop + (1 - normalizedValue) * yRange;

                    const chartEvent = {
                      activeCoordinate: { x: xCoord, y: yCoord },
                      activePayload: [
                        {
                          value: item.close,
                          payload: item,
                          dataKey: "close",
                        },
                      ],
                      activeTooltipIndex: index,
                      chartX: xCoord,
                      chartY: yCoord,
                      activeLabel: item.date,
                    };
                    handleMouseOver(chartEvent);
                  }
                },
                [hideAxis, reformattedStockEquityData, maxClose, minClose, handleMouseOver]
              )}
              style={{ pointerEvents: "all", zIndex: 5 }}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </Box>
    );
  }
);

StockEquityChart.displayName = "StockEquityChart";
