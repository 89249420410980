import type { Static } from "runtypes";
import { Record, String, Number, Array } from "runtypes";
import { TickerStatus } from "./TickerStatus";

export const DealFinderTicker = Record({
  exchange: String,
  id: String,
  name: String,
  symbol: String.optional(),
  companyLogoUrl: String.nullable().optional(),
  companyBannerUrl: String.nullable().optional(),
  companyUrl: String.nullable().optional(),
  companyTaxId: String.nullable().optional(),
  companyLegalName: String.nullable().optional(),
  companyOverview: String.nullable().optional(),
  status: TickerStatus,
  type: String.nullable(),
  symbols: Array(String).optional(),
  projectId: String.nullable(),
  projectCreationDate: String.nullable(), // Date as string
  investmentScoreAnalytical: Number.nullable(),
  investmentScoreSentiment: Number.nullable(),
  country: String.nullable().optional(),
  stateOrProvince: String.nullable().optional(),
  city: String.nullable().optional(),
  yearFounded: Number.nullable().optional(),
  industries: Array(String).optional(),
});

export type DealFinderTicker = Static<typeof DealFinderTicker>;
