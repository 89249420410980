import React, { useCallback, useContext, useMemo, useRef } from "react";
import {
  Box,
  Heading,
  useColorModeValue,
  Stack,
  Tooltip,
  useBreakpointValue,
  useDisclosure,
  SlideFade,
  IconButton,
  Tag,
  Icon,
  Center,
  Button,
} from "@chakra-ui/react";
import {
  useButtonProps,
  useCollectionCreatedDate,
  useCollectionKey,
  useCollectionMetadataIds,
  useCollectionQuestions,
  useCompanyStockFromCollectionContentsOrConversation,
  useConfigMap,
  useContents,
  useDownloadCollectionContents,
  useFilterAndGroupReports,
  useGetViewConfig,
  useLatestCollectionWorkflowId,
  useManualTags,
  useProjectInPortfolios,
  useProjectParams,
  useProjectStockEquityDataFromCollectionMetadata,
  useTileProps,
  useUserProfile,
} from "hooks";
import { BiLike, BiShareAlt } from "react-icons/bi";
import { BsPinAngle } from "react-icons/bs";
import { SmallActionButton } from "screens/content/contentCanvas/cell/SmallActionButton";
import { MarkdownItemView } from "screens/markdown/MarkdownItemView";
import { StockEquityChart } from "screens/collection/components/StockEquityChart";
import { formatDateWithOutputFormat } from "screens/common/modal/formatters";
import { useCustomScrollbar } from "hooks/useCustomScrollbar";
import { ProjectHeaderImage } from "./components/ProjectHeaderImage";
import { CollectionsFilterContext } from "../../collections";
import { useNavigate } from "react-router-dom";
import { useConversationContext } from "screens/thread/ConversationContext";
import { WorkflowProgressBar } from "screens/landing/components/WorkflowProgressBar";
import { useCopyValue } from "hooks/useCopies";
import { useWorkflowCompletionDateOrNow, useWorkflowKey } from "hooks/useWorkflows";
import { formatDistanceToNow } from "date-fns";
import { ProjectDeleteButton } from "screens/collection/views/ProjectActions/ProjectDeleteButton";
import { ProjectRerunCancelButton } from "screens/collection/views/ProjectActions/ProjectRerunCancelButton";
import { MoveProjectModal } from "screens/collection/views/ProjectActions/MoveProjectModal";
import { v4 as uuid } from "uuid";
import { ProjectMoveButton } from "screens/collection/views/ProjectActions/ProjectMoveButton";
import { AddIcon, CloseIcon } from "@chakra-ui/icons";
import { TbVersions } from "react-icons/tb";
import { WorkflowProgressStepperContainer } from "screens/landing/components/WorkflowProgressStepper";
import { useWorkflowScheduleByProject } from "hooks/useWorkflowSchedules";
import { TextOverflowTooltip } from "screens/landing/components/TextOverflowTooltip";
import { FiShare2 } from "react-icons/fi";
import { GrLineChart } from "react-icons/gr";
import { ProjectReportPanelButton } from "screens/collection/components/ProjectReportPanelButton";
import { ContentPreview } from "screens/content/contentView/previewSection/ContentPreview";
import type { ContentDetails } from "types/content/ContentDetails";
import { ProjectAnalysisPopover } from "../../collections/chartComponents/ProjectAnalysisPopover";
import { generateMockStockEquityData } from "types/stock";
import { ShowMoreContainer } from "screens/common/components/ShowMoreContainer";

interface ProjectLandingSmallTileProps {
  collectionId: string;
  previousVersions?: React.ReactNode;
  previousVersionCount?: number;
}

const ProjectLandingSmallTile: React.FC<ProjectLandingSmallTileProps> = ({ collectionId, previousVersions, previousVersionCount }) => {
  const { projectFilter, parentRoute, isPortfolios } = useProjectParams();
  const tileBgColor = useColorModeValue("white", "#161B25");
  const tileBorderColor = useColorModeValue("gray.300", "gray.700");
  const tagBgColor = useColorModeValue("white", "gray.800");
  const tagBorderColor = useColorModeValue("gray.300", "gray.700");
  const tagColor = useColorModeValue("gray.600", "gray.500");
  const borderColor = useColorModeValue("gray.200", "gray.700");
  const textColor = useColorModeValue("primary.darkGray", "gray.300");

  const ctaButtonProps = useButtonProps("md", "cta");
  const commonTileProps = useTileProps();
  const isMobile = useBreakpointValue({ base: true, md: false, lg: false }, { fallback: "md", ssr: false });
  const navigate = useNavigate();

  const collectionName = useCollectionKey(collectionId, "name");
  const collectionMetadataIds = useCollectionKey(collectionId, "metadataIds");
  const collectionType = useCollectionKey(collectionId, "collectionType");
  const projectGroupId = useCollectionKey(collectionId, "projectGroupId");
  const shareDetails = useCollectionKey(collectionId, "shareDetails");
  const collectionConversationId = useCollectionKey(collectionId, "conversationId");

  const currentWorkflowId = useLatestCollectionWorkflowId(collectionId);
  const currentWorkflowCompletionDate = useWorkflowCompletionDateOrNow(currentWorkflowId);
  useDownloadCollectionContents(collectionMetadataIds, collectionId);
  const portfoliosProjectIsIn = useProjectInPortfolios(collectionId);
  const companyStockData = useCompanyStockFromCollectionContentsOrConversation(collectionId);

  const configMap = useConfigMap();
  const projectRoute = useGetViewConfig("route", collectionType, configMap);

  // TODO: update this to use new tags filter
  const questionsFilters = useMemo(() => {
    return {
      byQuestions: ["in a concise one paragraph, describe the investment potential with the company"],
    };
  }, []);
  const filteredContentIdsWithoutProjectOutput = useCollectionMetadataIds(collectionId);
  const collectionQuestions = useCollectionQuestions(collectionId, questionsFilters);
  const projectCreationDate = useCollectionCreatedDate(collectionId);
  const stockEquityData = useProjectStockEquityDataFromCollectionMetadata(collectionMetadataIds || []);
  const placeholderStockEquityData = useMemo(() => generateMockStockEquityData(), []);
  const isPlaceholderTile = collectionId === "placeholder";
  const isSharedRead = shareDetails && shareDetails.accessMode === "read";
  const { setSelectedProjectId } = useContext(CollectionsFilterContext);
  const { onConversationOpen, onConversationClose, onDeleteProjectModalOpen } = useConversationContext();
  const { isOpen: isPanelOpen, onOpen: onPanelOpen, onClose: onPanelClose } = useDisclosure();
  const { onOpen: onOpenMoveProject, onClose: onCloseMoveProject, isOpen: isOpenMoveProject } = useDisclosure();
  const placeholderTileText = useCopyValue("copy_project_placeholder_message");
  const loadingText = useCopyValue("copy_project_starting_message");
  const latestWorkflowId = useLatestCollectionWorkflowId(collectionId);
  const workflowStatus = useWorkflowKey(latestWorkflowId, "status");

  const tagsFilter = useManualTags();
  const contentData = useContents(collectionMetadataIds ?? [], { refreshFromNetwork: true, filterByTags: tagsFilter });
  const contentDataFiltered = useFilterAndGroupReports(contentData);
  const firstContentDetailFromFilter = useMemo(() => {
    const firstGroup = Object.values(contentDataFiltered)[0];
    return firstGroup?.[0] as ContentDetails | undefined;
  }, [contentDataFiltered]);

  const oneTimeSchedule = useWorkflowScheduleByProject(collectionId);
  const hasOneTimeSchedule = useMemo(() => !!oneTimeSchedule, [oneTimeSchedule]);

  const businessOverview = useMemo(() => {
    const question = collectionQuestions?.find((q) => q.status !== "unpinned" && q.answers && q.answers.length > 0);

    return question?.answers[0].answer;
  }, [collectionQuestions]);

  const tileRef = useRef<HTMLDivElement>(null);
  const { scrollbarStyle } = useCustomScrollbar(tileRef, { width: "1px" });

  const handleActionClick = useCallback((actionType: string, collectionId: string) => {
    // Don't close the modal on action clicks
    return false;
  }, []);

  const handleAddTicker = useCallback(() => {
    const inputElement = document.getElementById("view-input") as HTMLInputElement;
    if (inputElement) {
      inputElement.click();
    }
  }, []);

  const handlePanelToggle = useCallback(() => {
    isPanelOpen ? onPanelClose() : onPanelOpen();
    return false;
  }, [isPanelOpen, onPanelOpen, onPanelClose]);

  const getPath = () => {
    if (projectRoute) {
      onConversationClose();
      return navigate(`/${parentRoute}/${projectFilter}/${collectionId}`);
    } else {
      onConversationOpen();
    }
  };

  const { id: currentUserId } = useUserProfile();
  const isSharedOwner = useMemo(() => {
    if (!shareDetails) {
      return true;
    }
    return shareDetails?.ownerId === currentUserId;
  }, [currentUserId, shareDetails]);

  return (
    <>
      <Box
        {...commonTileProps}
        bgColor={tileBgColor}
        p="0"
        onClick={() => {
          if (!isPlaceholderTile) {
            getPath();
          }
        }}
        _hover={{
          boxShadow: "0 0 6px rgba(0, 0, 0, 0.1)",
        }}
        onMouseEnter={() => setSelectedProjectId(collectionId)}
        overflow="visible"
        textAlign="left"
        className={`${
          isPlaceholderTile ? "ch-project-landing-placeholder-tile" : `ch-project-landing-default-${isSharedRead ? "shared" : "tile"}`
        }`}>
        <Stack spacing={"0"} direction="column" h="full">
          <Stack spacing={"1rem"} direction="column" h="full" p="1rem">
            <Stack direction="row" justifyContent={"space-between"} width="100%" alignItems="center">
              <Stack direction={"row"} spacing={"0.5rem"} alignItems="center">
                {currentWorkflowId && (
                  <Stack direction={"row"} spacing={"0.5rem"} alignItems="center">
                    <Center>
                      <WorkflowProgressBar
                        workflowId={currentWorkflowId}
                        size="compact"
                        lastUpdated={formatDistanceToNow(new Date(currentWorkflowCompletionDate), {
                          addSuffix: true,
                          includeSeconds: false,
                        })}
                        progressIndicatorStyle="circle"
                        conversationId={collectionConversationId}
                      />
                    </Center>
                  </Stack>
                )}
                <TextOverflowTooltip
                  label={
                    companyStockData?.name
                      ? companyStockData?.name
                      : collectionName?.toLowerCase() === "new project"
                      ? ""
                      : collectionName || ""
                  }
                  color={textColor}
                  noOfLines={1}
                  className="ch-project-landing-title"
                />
              </Stack>
              {!isPortfolios &&
                portfoliosProjectIsIn.projects.map((portfolio, index) => {
                  return (
                    <Tooltip
                      key={index}
                      label={`Open  ${shareDetails?.accessMode === "read" ? "shared" : ""} portfolio ${portfolio.name}`}
                      aria-label="Portfolio Name"
                      placement="top">
                      <Tag
                        className="ch-project-landing-portfolio-tag"
                        zIndex={2}
                        variant="subtle"
                        borderWidth="1px"
                        borderColor={tagBorderColor}
                        bgColor={tagBgColor}
                        color={tagColor}
                        fontSize="0.65rem"
                        cursor={"pointer"}
                        onClick={(evt) => {
                          evt.stopPropagation();
                          navigate(`/portfolios/${portfolio.id}`);
                        }}>
                        <Icon as={shareDetails?.accessMode === "read" ? FiShare2 : GrLineChart} boxSize=".8rem" color={tagColor} mr="5px" />
                        {portfolio.name}
                      </Tag>
                    </Tooltip>
                  );
                })}
            </Stack>
            <Stack direction={"row"} spacing={"1rem"} alignItems="top" justifyContent={"space-between"}>
              <Stack minWidth={isMobile ? "5rem" : "10rem"} overflow={"hidden"}>
                {isPlaceholderTile && (
                  <Button
                    onClick={(event) => {
                      event.preventDefault();
                      handleAddTicker();
                    }}
                    {...ctaButtonProps}
                    className="ch-project-landing-add-ticker-button"
                    leftIcon={<AddIcon />}>
                    Add a New Ticker
                  </Button>
                )}
                <ProjectHeaderImage
                  maxHeight={isMobile ? "2rem" : "3.5rem"}
                  collectionId={collectionId}
                  projectName={
                    companyStockData?.name
                      ? companyStockData?.name
                      : collectionName?.toLowerCase() === "new project"
                      ? ""
                      : collectionName || ""
                  }
                  metadataIds={filteredContentIdsWithoutProjectOutput}
                  projectType={collectionType}
                  projectGroupId={projectGroupId}
                  logoOnly={isPlaceholderTile}
                />
              </Stack>
              <Box color={textColor}>
                <ShowMoreContainer minHeight="6.5rem" height="4.5rem">
                  <MarkdownItemView markdown={isPlaceholderTile ? placeholderTileText : businessOverview || loadingText} />
                </ShowMoreContainer>
              </Box>
            </Stack>
            <Stack direction="row" justify="space-between" align="center" spacing="4.4rem">
              {isSharedRead && firstContentDetailFromFilter && (
                <Box
                  position="relative"
                  width={isSharedRead ? (isMobile ? "5rem" : "10rem") : "unset"}
                  className="ch-project-landing-report-preview-container">
                  <ProjectAnalysisPopover projectId={collectionId}>
                    <Box borderColor={tileBorderColor} borderWidth="1px" borderRadius="sm" width="4rem">
                      <ContentPreview
                        thumbnailFit="fill"
                        maxHeight="4rem"
                        backgroundColor={"transparent"}
                        itemContent={firstContentDetailFromFilter}
                        thumbnailHeight={"100px"}
                        noOnClickAction
                      />
                    </Box>
                  </ProjectAnalysisPopover>
                </Box>
              )}
              <Tooltip
                zIndex={2}
                aria-label="stock-equity-tooltip"
                label={`As of ${projectCreationDate && formatDateWithOutputFormat(new Date(projectCreationDate), "do MMM yyyy hh:mm")}`}>
                <Box zIndex={1} width="100%">
                  <StockEquityChart
                    height="4rem"
                    hideAxis
                    hideBorder
                    stockEquityData={stockEquityData || placeholderStockEquityData}
                    usingMockData={!stockEquityData}
                  />
                </Box>
              </Tooltip>
            </Stack>
            {(currentWorkflowId && isPlaceholderTile) ||
              (workflowStatus === "in_progress" && (
                <Box
                  zIndex={1}
                  position={"absolute"}
                  width="100%"
                  left="0"
                  bottom="2.7rem"
                  borderColor={tileBorderColor}
                  borderTopWidth="1px"
                  p="10px"
                  bgColor={tileBgColor}>
                  <WorkflowProgressStepperContainer
                    hasOneTimeSchedule={hasOneTimeSchedule}
                    oneTimeScheduleDesiredExecutionDate={oneTimeSchedule?.desiredExecutionDate}
                    collectionId={collectionId}
                    hideProgressBar={false}
                  />
                </Box>
              ))}
          </Stack>
          <Stack
            direction="row"
            width="100%"
            justify="space-between"
            spacing={isMobile ? ".5rem" : "1rem"}
            px={isMobile ? ".5rem" : "1rem"}
            py={isMobile ? ".2rem" : ".5rem"}
            borderTop="1px solid"
            borderColor={borderColor}>
            <Stack direction="row" justify="space-between" spacing={"1rem"}>
              <SmallActionButton
                classNames={previousVersionCount && previousVersionCount > 0 ? "ch-rerun-version-tag" : ""}
                tooltip={previousVersionCount && previousVersionCount > 0 ? `${previousVersionCount} Previous Versions` : ""}
                isDisabled={!isSharedOwner || isPlaceholderTile || workflowStatus === "in_progress" || previousVersionCount === 0}
                iconSize="1.2rem"
                iconTypeName={TbVersions}
                onClick={() => handlePanelToggle()}
              />
              <SmallActionButton
                isDisabled={!isSharedOwner || isPlaceholderTile || workflowStatus === "in_progress"}
                iconSize="1rem"
                tooltip="Like"
                iconTypeName={BiLike}
                onClick={() => handleActionClick("like", collectionId)}
              />
              <SmallActionButton
                isDisabled={!isSharedOwner || isPlaceholderTile || workflowStatus === "in_progress"}
                iconSize="1rem"
                tooltip="Share"
                iconTypeName={BiShareAlt}
                onClick={() => handleActionClick("share", collectionId)}
              />
              <SmallActionButton
                isDisabled={!isSharedOwner || isPlaceholderTile || workflowStatus === "in_progress"}
                iconSize="1rem"
                tooltip="Pin"
                iconTypeName={BsPinAngle}
                onClick={() => handleActionClick("pin", collectionId)}
              />
            </Stack>
            <Stack direction="row" justify="space-between" spacing={"1rem"}>
              <ProjectDeleteButton
                isDisabled={!isSharedOwner || isPlaceholderTile}
                label={"Delete"}
                onOpenConfirmation={onDeleteProjectModalOpen}
                projectId={collectionId}
                isIcon
                projectType={"project"}
              />
              <ProjectMoveButton
                isDisabled={!isSharedOwner || isPlaceholderTile || workflowStatus === "in_progress"}
                isIcon
                onClick={onOpenMoveProject}
              />
              <ProjectRerunCancelButton isDisabled={!isSharedOwner || isPlaceholderTile} reRunProjectId={collectionId} isIcon />
              <ProjectReportPanelButton
                isDisabled={!isSharedOwner || isPlaceholderTile || workflowStatus === "in_progress"}
                collectionId={collectionId}
              />
              {/* <SmallActionButton
                isDisabled={isPlaceholderTile || workflowStatus === "in_progress"}
                classNames="ch-project-landing-tile-options-menu"
                iconSize="1rem"
                tooltip="More options"
                iconTypeName={BiDotsVertical}
                onClick={() => handleActionClick("more", collectionId)}
              /> */}
            </Stack>
          </Stack>
        </Stack>
        <Box
          position="absolute"
          bottom="2px"
          left="2px"
          right="2px"
          height={isPanelOpen ? "87%" : "0"}
          bgColor={useColorModeValue("white", "gray.800")}
          borderTopRadius="lg"
          boxShadow="0 -4px 6px -1px rgba(0, 0, 0, 0.1)"
          transition="height 0.3s ease-in-out"
          overflow="hidden"
          zIndex={10}>
          <SlideFade in={isPanelOpen} offsetY="20px">
            <Box p="1rem" overflowY="hidden">
              <Stack direction="row" justify="space-between" align="center" pb="1rem">
                <Heading size="sm">{`${previousVersionCount} Previous Versions`}</Heading>
                <IconButton
                  icon={<CloseIcon />}
                  _hover={{}}
                  _active={{}}
                  aria-label="Close panel"
                  size="sm"
                  variant="ghost"
                  onClick={(e) => {
                    onPanelClose();
                    e.stopPropagation();
                  }}
                />
              </Stack>
              <Box height="15rem" overflowX="hidden" overflowY={"auto"} mr="2px" css={scrollbarStyle}>
                {previousVersions}
              </Box>
            </Box>
          </SlideFade>
        </Box>
      </Box>
      <MoveProjectModal
        isOpen={isOpenMoveProject}
        onClose={() => {
          onCloseMoveProject();
        }}
        collectionId={collectionId}
        conversationId={collectionConversationId || uuid()}
      />
    </>
  );
};

export default ProjectLandingSmallTile;
