import React from "react";
import { useColorMode, useColorModeValue } from "@chakra-ui/react";

import CodeMirror from "@uiw/react-codemirror";

import { EditorState } from "@codemirror/state";
import { EditorView, lineNumbers, tooltips } from "@codemirror/view";
import { oneDark } from "@codemirror/theme-one-dark";
import { jsonParseLinter, json } from "@codemirror/lang-json";
import { linter, lintGutter } from "@codemirror/lint";
import { bracketMatching } from "@codemirror/language";
import { autocompletion, closeBrackets } from "@codemirror/autocomplete";
import { history } from "@codemirror/commands";
import type { JSONSchema7 } from "json-schema";

interface Props {
  value: JSONSchema7;
  height?: string;
  simpleView?: boolean;
  backgroundColor?: string;
}

// Create a custom theme extension
const createCustomTheme = (backgroundColor: string) => {
  return EditorView.theme({
    "&": {
      backgroundColor: backgroundColor,
    },
    ".cm-scroller": {
      backgroundColor: backgroundColor,
    },
    ".cm-gutters": {
      display: "none",
    },
    ".cm-activeLine": {
      backgroundColor: backgroundColor,
    },
  });
};

const baseExtensions = [
  bracketMatching(),
  closeBrackets(),
  history(),
  autocompletion(),
  EditorView.lineWrapping,
  EditorState.tabSize.of(2),
  autocompletion(),
  tooltips(),
];

// Add line numbers and gutter only when not in simple view
const commonExtensions = [...baseExtensions, lineNumbers(), lintGutter()];

export const JSONSchemaViewer = ({ value, height = "calc(20vh)", simpleView, backgroundColor }: Props) => {
  const { colorMode } = useColorMode();
  // Use theme-appropriate background colors
  const defaultBackgroundColor = useColorModeValue("transparent", "#1e1e2e");
  const customTheme = createCustomTheme(backgroundColor || defaultBackgroundColor);

  return (
    <CodeMirror
      theme={colorMode === "dark" ? oneDark : "light"}
      basicSetup
      extensions={[...(simpleView ? baseExtensions : commonExtensions), json(), linter(jsonParseLinter()), customTheme]}
      value={JSON.stringify(value, null, 2)}
      height={height}
      width="100%"
      editable={false}
    />
  );
};
