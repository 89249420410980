export const EntitlementIds = [
  "ui_enable_deep_analysis",
  "summarization",
  "highlights_generation",
  "report_generation",
  "ai_meetings_sync",
  "manage_configured_workflows_read",
  "manage_configured_workflows_write",
  "manage_system_preferences_read",
  "manage_system_preferences_write",
  "show_detailed_workflow_summary",
  "manage_entitlements",
  "view_users",
  "manage_billing_plans_read",
  "manage_billing_plans_write",
  "read_workflows_admin",
  "manage_users_write",
  "read_workflows_payloads_admin",
  "manage_organizations_read",
  "manage_organizations_write",
  "manage_organizations_delete",
  "manage_entity_configurations_read",
  "manage_entity_configurations_write",
  "manage_memorized_clarification_config_read",
  "manage_memorized_clarification_config_write",
  "manage_project_config_read",
  "manage_project_config_write",
  "zendesk_chat",
  "manage_report_template_write",
  "access_all_report_templates",
  "manage_information_extraction_config_service_read",
  "manage_information_extraction_config_service_write",
  "ui_themes",
  "ui_allow_env_badge",
  "ui_enable_command_suggestions",
  "ui_enable_organization_admin_page",
  "ui_share_highlights",
  "manage_operations_read",
  "manage_delivery_read",
  "increased_max_upload_size",
  "manage_charli_activities_read",
  "allow_debug_mode",
  "ui_project_metrics",
  "ui_project_metrics_home",
  "manage_task_log_read",
  "bypass_usage_limits",
  "ui_enable_search",
  "ui_show_menu_new_button",
  "manage_ticker_read",
  "manage_ticker_admin",
  "ui_enable_custom_reports",
  "ui_bypass_maintenance_page",
  "ui_enable_portfolio_questions",
  "manage_questions_read",
  "manage_questions_write",
  "ui_enable_integrations_menu",
  "manage_copies_read",
  "manage_copies_write",
  "ui_workflow_gantt_chart",
  "ui_enable_add_source_content",
  "ui_show_additional_security_types",
  "ui_show_private_tickers",
  "ui_show_private_tickers_restricted",
  "ui_show_additional_exchanges",
  "enable_rerun_projects",
  "enable_share_portfolio",
  "ui_enable_dealfinder",
] as const;

export type EntitlementId = typeof EntitlementIds[number];

export type Entitlement = {
  entitlementId: EntitlementId | string;
  available: boolean;
  name?: string;
  description?: string;
};

export type EntitlementMap = Entitlement[];
