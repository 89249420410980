import { Box, Center, Stack, Text, useBreakpointValue, Image, Tooltip, useColorModeValue } from "@chakra-ui/react";
import {
  useCollectionCreatedDate,
  useCollectionKey,
  useConfigMap,
  useContents,
  useFilterAndGroupReports,
  useGetViewConfig,
  useItemSelector,
  useLatestCollectionWorkflowId,
  useProjectStockEquityDataFromCollectionMetadata,
  useTileProps,
} from "hooks";
import type { FunctionComponent } from "react";
import React, { useCallback, useContext, useEffect, useMemo, useState, startTransition } from "react";
import { ContentFilterContext } from "screens/content";
import { useAddToCharliContext } from "screens/panels/addToCharli/AddToCharliWizard/AddToCharliProvider";
import { getItemTypeForContent } from "screens/content/common/utils";
import type { SupportedItemKeys } from "state/selection/reducer";
import { AnswerStatus } from "types/question";
import { QuestionAnswerResults } from "./QuestionAnswerResults";
import { HighlightsSimpleList } from "screens/panels/highlights/HighlightsSimpleList";
import { GapAnalysisResults } from "./GapAnalysisResults";
import { AssessmentResultsQuestion } from "./AssessmentResultsQuestion";
import orderBy from "lodash/orderBy";
import { ContentViewExtractedAISection } from "screens/content/contentView/ContentViewExtractedAISection";
import { SectionContentLayout } from "../components/utils";
import { StockEquityChart } from "../components/StockEquityChart";
import { BlockSectionHeader } from "screens/content/contentView/previewSection/BlockSectionHeader";
import { ProjectDetailViewSkeleton } from "../ProjectDetailViewSkeleton";
import { formatDateWithOutputFormat } from "screens/common/modal/formatters";
import type { AssessmentResultsData } from "types/collection";
import DataAnalysisGif from "screens/common/static/images/data-analysis.gif";
import { ScaleBar } from "screens/landing/tabs/collections/chartComponents/ScaleBar";
import { BiInfoCircle } from "react-icons/bi";
import { useWorkflowKey } from "hooks/useWorkflows";
import { useProjectQuestionsContext } from "../ProjectQuestionsContext";
import type { ContentDetails } from "types/content/ContentDetails";

interface Props {
  collectionId: string;
  isLoading: boolean;
}

export const ProjectDetailDefaultAIResults: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  collectionId,
  isLoading,
}) => {
  const { setSelectedItems, resetSelectedItems } = useItemSelector();
  const { searchText, setResultsDataCount } = useContext(ContentFilterContext);
  const { isHighlightsPanelOpen, isAddToCollectionModalOpen } = useAddToCharliContext();
  const configMap = useConfigMap();
  const collectionMetadataIds = useCollectionKey(collectionId, "metadataIds");
  const memoizedMetadataIds = useMemo(() => collectionMetadataIds || [], [collectionMetadataIds]);
  const collectionType = useCollectionKey(collectionId, "collectionType");
  const collectionExtractedHighlights = useCollectionKey(collectionId, "extractedHighlights");
  const { questions: collectionQuestions } = useProjectQuestionsContext();
  const collectionUnstructuredData = useCollectionKey(collectionId, "unstructuredData");
  const contentData = useContents(memoizedMetadataIds || []);
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const aiResultResourceSections = useGetViewConfig("aiResultResourceSections", collectionType, configMap);
  const stockEquityData = useProjectStockEquityDataFromCollectionMetadata(collectionMetadataIds || []);
  const projectCreationDate = useCollectionCreatedDate(collectionId);
  const commonTileProps = useTileProps();
  const latestWorkflowId = useLatestCollectionWorkflowId(collectionId);
  const workflowStatus = useWorkflowKey(latestWorkflowId, "status");
  const textColor = useColorModeValue("primary.darkGray", "gray.400");
  const contentDataFiltered = useFilterAndGroupReports(contentData);
  const firstContentDetailFromFilter = useMemo(() => {
    const firstGroup = Object.values(contentDataFiltered)[0];
    return firstGroup?.[0] as ContentDetails | undefined;
  }, [contentDataFiltered]);
  const bgColor = useColorModeValue("white", "#161B25");
  const borderColor = useColorModeValue("gray.200", "gray.600");

  const extractNumericValue = (value: string | undefined | null): number => {
    if (!value) return 0;
    const match = value.match(/\d+(\.\d+)?/g);
    return match ? Number(match[0]) : 0;
  };
  const investmentScoreAnalytical = extractNumericValue(useCollectionKey(collectionId, "investmentScoreAnalytical")?.value);
  const investmentScoreSentiment = extractNumericValue(useCollectionKey(collectionId, "investmentScoreSentiment")?.value);

  const assessmentResultsData = useMemo(() => {
    if (!collectionUnstructuredData?.data.assessment_results) {
      return [];
    }
    return collectionUnstructuredData?.data.assessment_results;
  }, [collectionUnstructuredData]);

  const [filteredQuestions, setFilteredQuestions] = useState<AssessmentResultsData[]>(assessmentResultsData);

  useEffect(() => {
    if (contentData?.length) {
      const items =
        contentData &&
        contentData.reduce((cells, cell) => {
          const itemType = getItemTypeForContent(cell.type);
          cells[cell.mediaId] = { type: itemType };
          return cells;
        }, {} as { [id: string]: { type: SupportedItemKeys } });
      if (items) setSelectedItems(items);
    } else {
      resetSelectedItems();
    }
  }, [contentData?.length, isHighlightsPanelOpen, isAddToCollectionModalOpen, contentData, setSelectedItems, resetSelectedItems]);

  const extractedHighlightsData = useMemo(() => {
    if (!collectionExtractedHighlights) {
      return [];
    }
    return collectionExtractedHighlights;
  }, [collectionExtractedHighlights]);

  const allQuestionAnswers = useMemo(() => {
    return (collectionQuestions ?? []).filter((question) => ![AnswerStatus.unpinned, AnswerStatus.removed].includes(question.status));
  }, [collectionQuestions]);

  const filteredQuestionAnswers = useMemo(() => {
    if (!searchText) return allQuestionAnswers;
    return allQuestionAnswers.filter(
      (question) =>
        question.question.toLowerCase().includes(searchText.toLowerCase()) ||
        question.answers.some((answer) => answer.answer.toLowerCase().includes(searchText.toLowerCase()))
    );
  }, [allQuestionAnswers, searchText]);

  const loadMoreQuestions = useCallback(
    async (token: string | null) => {
      const PAGE_SIZE = 5;
      const offset = token ? parseInt(token) : 0;

      // Simulate network delay with a random duration to make loading feel more natural
      await new Promise((resolve) => setTimeout(resolve, Math.random() * 300 + 200));

      const nextBatch = filteredQuestionAnswers.slice(offset, offset + PAGE_SIZE);
      const hasMore = offset + PAGE_SIZE < filteredQuestionAnswers.length;
      const nextToken = hasMore ? (offset + PAGE_SIZE).toString() : null;

      return {
        data: nextBatch,
        nextToken,
        totalCount: filteredQuestionAnswers.length,
      };
    },
    [filteredQuestionAnswers]
  );

  const gapAnalysisData = useMemo(() => {
    if (!collectionUnstructuredData?.data.gap_analysis_results) {
      return [];
    }
    return collectionUnstructuredData?.data.gap_analysis_results;
  }, [collectionUnstructuredData]);

  const aiResultResourceSectionContent = useMemo(() => {
    if (!aiResultResourceSections) {
      return [];
    }

    return orderBy(
      contentData?.filter((content) =>
        content.detailSections.some(
          (detailSection) => aiResultResourceSections.includes(detailSection.sectionName) && detailSection.data.length > 0
        )
      ),
      "name"
    );
  }, [aiResultResourceSections, contentData]);

  const orderByRiskLevel = useCallback(() => {
    const riskLevelEnum = {
      "Low Risk": 1,
      "Medium Risk": 2,
      "High Risk": 3,
    };

    return orderBy(filteredQuestions, [(result) => riskLevelEnum[result.risk_assessment_result.risk_level.replace(".", "")]], ["desc"]);
  }, [filteredQuestions]);

  const hasGeneratedContentQA = useMemo(() => {
    return allQuestionAnswers.length > 0;
  }, [allQuestionAnswers.length]);

  const hasGeneratedContentGap = useMemo(() => {
    return gapAnalysisData.length > 0;
  }, [gapAnalysisData.length]);

  const hasGeneratedContentRisk = useMemo(() => {
    return orderByRiskLevel().length > 0;
  }, [orderByRiskLevel]);

  const hasExtractedContentSourceSections = useMemo(() => {
    return aiResultResourceSectionContent && aiResultResourceSectionContent.length > 0;
  }, [aiResultResourceSectionContent]);

  const hasExtractedContentHighlights = useMemo(() => {
    return extractedHighlightsData.length > 0;
  }, [extractedHighlightsData.length]);

  const hasGeneratedContentSectionCount =
    Number(hasGeneratedContentQA) + Number(hasGeneratedContentGap) + Number(hasGeneratedContentRisk) || 0;
  const hasExtractedContentSectionCount = Number(hasExtractedContentSourceSections) + Number(hasExtractedContentHighlights) || 0;

  useEffect(() => {
    setResultsDataCount(Math.max(0, hasGeneratedContentSectionCount + hasExtractedContentSectionCount));
  }, [hasExtractedContentSectionCount, hasGeneratedContentSectionCount, setResultsDataCount]);

  useEffect(() => {
    if (searchText && searchText.length > 0) {
      startTransition(() => {
        setFilteredQuestions(
          assessmentResultsData.filter(
            (result) =>
              result.section.question.includes(searchText) ||
              result.section.answer.includes(searchText) ||
              result.followup_questions.some((question) => question.question.includes(searchText))
          )
        );
      });
    } else {
      setFilteredQuestions(assessmentResultsData);
    }
  }, [assessmentResultsData, searchText]);

  const DataAnalysisMessage = () => (
    <Center>
      <Stack direction="row" spacing="1rem" justifyContent={"space-between"} {...commonTileProps} borderRadius={"none"}>
        <Center>
          <Image src={DataAnalysisGif} alt="AI Results Empty" width={isMobile ? "4rem" : "6rem"} />
        </Center>
        <Stack width="100%" color={textColor}>
          <Text textAlign={"left"} fontSize="sm">
            {`I am collecting the data and running a full analysis${
              stockEquityData ? ` on ${stockEquityData?.title}` : ""
            }. I will send you an email with the summary report shortly.`}
          </Text>
          <Text textAlign={"left"} fontSize="sm">
            {`Once complete you can ask detailed questions and dive deeper. You can kick-off multiple projects at the same time and I will work on all of them in parallel.`}
          </Text>
        </Stack>
      </Stack>
    </Center>
  );

  return (
    <ProjectDetailViewSkeleton isLoading={isLoading} height="100%">
      <SectionContentLayout useGrid className="ch-ai-result-sections">
        <SectionContentLayout useGrid={false} className="layout-sections-extracted">
          {hasGeneratedContentQA ? (
            <QuestionAnswerResults
              collectionId={collectionId}
              questionAnswers={filteredQuestionAnswers}
              loadMoreQuestions={loadMoreQuestions}
            />
          ) : (
            collectionType === "due_diligence" && workflowStatus === "in_progress" && <DataAnalysisMessage />
          )}
          {hasGeneratedContentGap && <GapAnalysisResults gapAnalysisResults={gapAnalysisData} />}
          {hasGeneratedContentRisk && <AssessmentResultsQuestion assessmentResults={orderByRiskLevel()} searchText={searchText} />}
        </SectionContentLayout>
        {(investmentScoreAnalytical || investmentScoreSentiment || hasExtractedContentSourceSections || hasExtractedContentHighlights) && (
          <SectionContentLayout useGrid={false} className="ch-sections-generated">
            {(investmentScoreAnalytical || investmentScoreSentiment) && (
              <Box p={isMobile ? ".5rem" : "1rem"} width="100%" bg={bgColor} border="1px solid" borderColor={borderColor} borderRadius="md">
                <BlockSectionHeader title={`Sentiment and Fundamentals Benchmarks`} align="flex-start">
                  <Tooltip
                    placement="left"
                    maxWidth={"20rem"}
                    label="The Sentiment and Fundamentals Benchmark are important indicators for assessing the indicator relative to financial fundamentals versus the market sentiment.  Hover over the indicators to see the reasoning behind the rating."
                    aria-label="Sentiment and Fundamentals Benchmarks">
                    <Box pt="2px" color={textColor}>
                      <BiInfoCircle />
                    </Box>
                  </Tooltip>
                </BlockSectionHeader>
                <Stack
                  bgColor={bgColor}
                  direction={isMobile ? "column" : "row"}
                  px={isMobile ? "0.5rem" : firstContentDetailFromFilter ? "2rem" : "4rem"}
                  spacing="2rem"
                  pt="2rem"
                  pb="1rem"
                  justifyContent="space-between">
                  <Center width="100%">
                    <ScaleBar
                      projectId={collectionId}
                      analyticalValue={Number(investmentScoreAnalytical) || 0}
                      sentimentValue={Number(investmentScoreSentiment) || 0}
                    />
                  </Center>
                </Stack>
              </Box>
            )}

            {stockEquityData && (
              <Box p={isMobile ? ".5rem" : "1rem"} width="100%" bg={bgColor} border="1px solid" borderColor={borderColor} borderRadius="md">
                <BlockSectionHeader
                  title={`Stock Equity Chart ${
                    projectCreationDate && "as of " + formatDateWithOutputFormat(new Date(projectCreationDate), "do MMM yyyy hh:mm")
                  }`}
                />
                <StockEquityChart stockEquityData={stockEquityData} />
              </Box>
            )}
            {aiResultResourceSectionContent &&
              aiResultResourceSectionContent.map((contentDetail, index) => (
                <SectionContentLayout useGrid={false} className={`section-${index}`} key={`section-${index}`}>
                  <ContentViewExtractedAISection
                    showEmptyValues={false}
                    contentDetails={contentDetail}
                    sectionTitle={contentDetail.name}
                    includedSections={aiResultResourceSections}
                    isEditModeEnabled={false}
                    valueColumnAlignRight
                    isTextTruncated
                  />
                </SectionContentLayout>
              ))}
            {hasExtractedContentHighlights && (
              <HighlightsSimpleList extractedHighlights={extractedHighlightsData} searchPhrase={searchText} />
            )}
          </SectionContentLayout>
        )}
      </SectionContentLayout>
    </ProjectDetailViewSkeleton>
  );
};
