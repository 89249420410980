import {
  Box,
  Stack,
  useBreakpointValue,
  Icon,
  Tooltip,
  useDisclosure,
  Button,
  IconButton,
  useToast,
  useColorModeValue,
  keyframes,
  usePrefersReducedMotion,
  Text,
  Center,
  Flex,
  useColorMode,
  Skeleton,
  Avatar,
  SimpleGrid,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState, useContext, useMemo } from "react";
import { CollectionsFilterMenu } from "../collections/CollectionsFilterMenu";
import { ProjectLandingTiles } from "../project/ProjectLandingTiles";
import {
  useButtonProps,
  useCollection,
  useConfigMap,
  useGetViewConfig,
  useProjectParams,
  useSidebarNavigation,
  useEntitlementKey,
  useCollectionKey,
  useTileProps,
  usePortfolioCollectionsLoaded,
  useSkeletonProps,
  useUserProfile,
  useDownloadPortfolioCollections,
} from "hooks";
import { getViewConfig } from "configs/configMap";
import { LandingTitle } from "screens/landing/components";
import { ProjectMetrics } from "../collections/ProjectMetrics";
import { AddIcon } from "@chakra-ui/icons";
import { useAddToCharliContext } from "screens/panels/addToCharli/AddToCharliWizard/AddToCharliProvider";
import { useConversationContext } from "screens/thread/ConversationContext";
import { PausedWorkflowModalProvider } from "screens/collection/views/ProjectActions/PausedWorkflowModal";
import { LuSettings2 } from "react-icons/lu";
import { HiOutlineRefresh } from "react-icons/hi";
import { useDispatch } from "react-redux";
import { sendMessage } from "state/websocket/operations";
import { v4 as uuid } from "uuid";
import type { RequestEntities } from "types/charliui";
import { ToastMessageContent } from "screens/common/components/ToastMessageContent";
import { FloatingDialogModal } from "components/FloatingDialogModal";
import { CollectionsFilterContext } from "../collections";
import { useProjectsHaveWorkflowSchedules } from "hooks/useWorkflowSchedules";
import { getStatusColor, WorkflowStatusIcon } from "screens/common/components/WorkflowStatusIcon";
import { useWorkflowSchedulesByPortfolio } from "hooks/useWorkflowSchedules";
import type { TimeOfDay } from "./projectLandingTileLayouts/components/PortfolioSettingsPanel";
import { timeRangeByTimeOfDay } from "./projectLandingTileLayouts/components/PortfolioSettingsPanel";
import { FiShare2 } from "react-icons/fi";
import { TextOverflowTooltip } from "screens/landing/components/TextOverflowTooltip";
import { APP_CONTAINER_WIDTH } from "screens/landing/Landing";

const spin = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

interface Props {
  collectionType: string;
}

const DOWNLOAD_PROJECTS_LIMIT = 1000; // set the max number of projects that will be downloaded and added to redux store

export const ProjectLanding: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({ collectionType }) => {
  const { projectFilter, projectId } = useProjectParams();
  const portfolioProject = useCollection(projectFilter);
  const configMap = useConfigMap();
  const { filteredCollections, groupedCollections, selectedQuadrant, searchText, selectedRating } = useContext(CollectionsFilterContext);
  const portfolioCollectionType = getViewConfig("portfolioProjectType", portfolioProject?.collectionType || "", configMap);
  const isMobile = useBreakpointValue({ base: true, md: false, lg: false }, { fallback: "md", ssr: false });
  const isTablet = useBreakpointValue({ base: false, md: true, lg: false }, { fallback: "md", ssr: false });
  const projectTitle = useGetViewConfig("title", portfolioCollectionType || projectFilter, configMap);
  const commonButtonProps = useButtonProps("sm", "primary");
  const ctaButtonProps = useButtonProps("sm", "cta");
  const { isOpen: isFilterOpen, onToggle: onFilterToggle, onClose: onFilterClose } = useDisclosure();
  const { isDialogOpen, onDialogOpen, onDialogClose } = useConversationContext();
  const { onPortfolioSettingsOpen } = useAddToCharliContext();
  const isDueDiligence = portfolioCollectionType === "due_diligence" || collectionType === "due_diligence";
  const isDueDiligenceLanding = projectFilter === "due_diligence" && !projectId;
  const { currentSidebarType } = useSidebarNavigation();
  const dispatch = useDispatch();
  const hasRerunAllProjects = useEntitlementKey("enable_rerun_projects");
  const toast = useToast();
  const addTickerButtonRef = useRef<HTMLButtonElement>(null);
  const bgColor = useColorModeValue("#fbfbfb", "gray.800");
  const hasPrefersReducedMotion = usePrefersReducedMotion();
  const [isRerunning, setIsRerunning] = useState(false);
  const iconColor = useColorModeValue("200", "800");
  const textColor = useColorModeValue("primary.darkGray", "gray.400");
  const textColorOverlay = useColorModeValue("gray.600", "gray.500");
  const bgColorOverlayText = useColorModeValue("white", "gray.700");
  const { colorMode } = useColorMode();
  const showProjectMetrics = useEntitlementKey("ui_project_metrics");
  const shareDetails = useCollectionKey(projectFilter, "shareDetails");
  const bgColorReadOnly = useColorModeValue("orange.100", "gray.700");
  const bgColorShared = useColorModeValue("green.100", "green.700");
  const tileBgColor = useColorModeValue("white", "#161B25");
  const commonTileProps = useTileProps();
  const arePortfolioProjectsLoaded = usePortfolioCollectionsLoaded(projectFilter);
  const skeletonStyle = useSkeletonProps();

  const { refreshCollections } = useDownloadPortfolioCollections({ portfolioId: projectFilter, limit: DOWNLOAD_PROJECTS_LIMIT });

  useEffect(() => {
    refreshCollections();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectFilter]);

  const workflowSchedule = useWorkflowSchedulesByPortfolio(projectFilter || "");
  const workflowScheduleFrequency = useMemo(() => workflowSchedule[workflowSchedule.length - 1]?.frequency, [workflowSchedule]);
  const workflowScheduleDesiredTime = useMemo(() => {
    const period = workflowSchedule[workflowSchedule.length - 1]?.desiredTime as TimeOfDay;
    return `between ${timeRangeByTimeOfDay[period]}`;
  }, [workflowSchedule]);
  const workflowScheduleDesiredDay = useMemo(() => {
    const dayMap = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const day = workflowSchedule[workflowSchedule.length - 1]?.day;
    if (!day) return "";
    if (workflowScheduleFrequency === "weekly") {
      return day >= 1 && day <= 7 ? `on ${dayMap[day]}` : "";
    } else {
      if (day < 1 || day > 31) return "";
      const suffix = ["th", "st", "nd", "rd"];
      const v = day % 100;
      const formattedDay = day + (suffix[(v - 20) % 10] || suffix[v] || suffix[0]);
      return `on the ${formattedDay}`;
    }
  }, [workflowSchedule, workflowScheduleFrequency]);

  const activeProjectsIds = useMemo(
    () => filteredCollections.flatMap((collection) => (collection.isActiveProjectInGroup ? [collection.id] : [])),
    [filteredCollections]
  );

  const containerWidth = useMemo(() => {
    return `${currentSidebarType === "hidden" && !isTablet && !isMobile ? APP_CONTAINER_WIDTH : "100%"}`;
  }, [currentSidebarType, isMobile, isTablet]);

  const doesProjectsHaveScheduledWorkflows = useProjectsHaveWorkflowSchedules(activeProjectsIds);
  const animation = hasPrefersReducedMotion ? undefined : `${spin} 1s linear infinite`;
  const showOverlay = filteredCollections.length === 0 && !selectedQuadrant && searchText === "" && !selectedRating;
  const { id: currentUserId } = useUserProfile();

  const handleRerunAllProjects = () => {
    if (!portfolioProject || isRerunning) {
      return;
    }

    const newConversationId = uuid();

    const entities: RequestEntities = [
      {
        entity: "portfolio_id",
        value: portfolioProject.id,
      },
    ];

    dispatch(
      sendMessage({
        conversationId: newConversationId,
        intent: "/rerun_portfolio",
        entities,
      })
    );

    setIsRerunning(true);

    toast.closeAll();

    toast({
      render: ({ onClose }) => (
        <ToastMessageContent
          message="All projects have been scheduled to rerun."
          onClick={() => {
            onClose();
          }}
          onClose={onClose}
        />
      ),
      duration: 5000,
      isClosable: true,
      position: "top-right",
    });
  };

  const hasRerunAllProjectsButton = !!portfolioProject && hasRerunAllProjects;

  useEffect(() => {
    onFilterClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portfolioProject]);

  useEffect(() => {
    setIsRerunning(doesProjectsHaveScheduledWorkflows);
  }, [doesProjectsHaveScheduledWorkflows]);

  return (
    <>
      <Box height="100%" width="100%" maxWidth={containerWidth} overflow="hidden" bgColor={bgColor} pt={isMobile ? "0.5rem" : "1rem"}>
        {isDueDiligence && (
          <Box width="100%" bgColor={bgColor} zIndex={3} position="relative" height="auto">
            <LandingTitle
              mt="0"
              maxWidth={containerWidth}
              pl={isMobile || isTablet ? ".5rem" : currentSidebarType === "hidden" ? "0" : "1rem"}
              pr={isMobile || isTablet ? ".5rem" : currentSidebarType === "hidden" ? "0" : "1rem"}
              underline={!isMobile}
              text={
                isMobile
                  ? ""
                  : portfolioProject
                  ? `${portfolioProject.name}`
                  : `${projectTitle ? (projectTitle.toLowerCase().includes("projects") ? projectTitle : `${projectTitle} Projects`) : ""}`
              }
              titleMaxWidth="15rem"
              color="primary.default">
              <Stack justifyContent={isMobile ? "space-between" : "flex-end"} direction="row" spacing="0.5rem" pt="0" width="100%">
                <Box width="100%" maxWidth="22rem">
                  <CollectionsFilterMenu onFilterToggle={onFilterToggle} isOpen={isFilterOpen} hideStateFilter hideTagFilter />
                </Box>
                {isMobile && isDueDiligence && (
                  <Box cursor="pointer">
                    <Button
                      ref={addTickerButtonRef}
                      {...ctaButtonProps}
                      onClick={onDialogOpen}
                      className="ch-project-landing-add-ticker-button"
                      leftIcon={<AddIcon />}>
                      Add Ticker
                    </Button>
                  </Box>
                )}
                {hasRerunAllProjectsButton && (
                  <Tooltip label={isRerunning ? "Scheduling Projects..." : "Rerun All Projects"} aria-label="Rerun Projects">
                    <Box>
                      <IconButton
                        onClick={() => handleRerunAllProjects()}
                        className="ch-rerun-all-projects-button"
                        aria-label="Rerun Projects"
                        {...commonButtonProps}
                        isDisabled={groupedCollections.length === 0 || isRerunning || (shareDetails && shareDetails.accessMode === "read")}
                        icon={<Icon as={HiOutlineRefresh} boxSize="1.2rem" animation={isRerunning ? animation : undefined} />}
                      />
                    </Box>
                  </Tooltip>
                )}
                {portfolioProject && (
                  <Tooltip label="Portfolio Settings" aria-label="Show/Hide Settings">
                    <Box cursor="pointer" onClick={() => onPortfolioSettingsOpen()}>
                      <IconButton
                        isDisabled={shareDetails && shareDetails.ownerId !== currentUserId}
                        className="ch-open-portfolio-settings-button"
                        aria-label="Show/Hide Settings"
                        {...commonButtonProps}
                        icon={<Icon as={LuSettings2} boxSize="1.2rem" />}
                        onClick={() => {
                          onPortfolioSettingsOpen();
                        }}
                      />
                    </Box>
                  </Tooltip>
                )}
              </Stack>
            </LandingTitle>
            {workflowScheduleFrequency && workflowScheduleFrequency !== "one-time" && (
              <Box
                pt={isMobile ? "0.5rem" : "1rem"}
                pl={isMobile || isTablet ? ".5rem" : currentSidebarType === "hidden" ? "0" : "1rem"}
                pr={isMobile || isTablet ? ".5rem" : currentSidebarType === "hidden" ? "0" : "1rem"}>
                <Stack
                  {...commonTileProps}
                  bgColor={tileBgColor}
                  _hover={{}}
                  cursor={"default"}
                  spacing=".75rem"
                  py=".75rem"
                  direction="row"
                  justifyContent={"space-between"}
                  width="100%">
                  <Stack direction="row" spacing="1rem">
                    <Center>
                      <Box
                        onClick={() => {
                          onPortfolioSettingsOpen();
                        }}
                        p="3px"
                        borderRadius={"full"}
                        backgroundColor={`${getStatusColor("scheduled")}.${iconColor}`}
                        border={"none"}
                        className="ch-workflow-step"
                        textAlign="left"
                        cursor="pointer">
                        <Center>
                          <WorkflowStatusIcon boxSize="1.6rem" status={"scheduled"} />
                        </Center>
                      </Box>
                    </Center>
                    {!isMobile && (
                      <Center>
                        <TextOverflowTooltip
                          color={textColor}
                          fontSize={"md"}
                          label={`Scheduled to refresh ${workflowScheduleFrequency} ${workflowScheduleDesiredDay} ${workflowScheduleDesiredTime} EST`}
                        />
                      </Center>
                    )}
                  </Stack>
                  {!isMobile && (
                    <Center>
                      <Box
                        bgColor={`${getStatusColor("scheduled")}.${iconColor}`}
                        color={textColor}
                        fontSize="11px"
                        px="6px"
                        py="3px"
                        fontWeight={"semibold"}
                        borderRadius={"md"}>
                        SCHEDULED
                      </Box>
                    </Center>
                  )}
                </Stack>
              </Box>
            )}
            {shareDetails && (
              <Box
                pt={isMobile ? "0.5rem" : "1rem"}
                pl={isMobile || isTablet ? ".5rem" : currentSidebarType === "hidden" ? "0" : "1rem"}
                pr={isMobile || isTablet ? ".5rem" : currentSidebarType === "hidden" ? "0" : "1rem"}>
                {shareDetails && shareDetails.ownerId !== currentUserId && (
                  <Stack
                    {...commonTileProps}
                    bgColor={tileBgColor}
                    _hover={{}}
                    cursor={"default"}
                    spacing=".75rem"
                    py=".75rem"
                    direction="row"
                    justifyContent={"space-between"}
                    width="100%">
                    <Stack direction="row" spacing="1rem">
                      <Avatar
                        borderWidth={"2px"}
                        borderColor={"gray.400"}
                        name={shareDetails.ownerName}
                        src={shareDetails.ownerAvatarUrl}
                        boxSize={isMobile ? "1.5rem" : "2rem"}
                      />
                      <Center>
                        <TextOverflowTooltip
                          color={textColor}
                          fontSize={"md"}
                          label={`Portfolio was shared by ${shareDetails.ownerName}.`}
                        />
                      </Center>
                    </Stack>
                    {!isMobile && (
                      <Center>
                        <Box
                          bgColor={bgColorReadOnly}
                          color={textColor}
                          fontSize="11px"
                          px="6px"
                          py="3px"
                          fontWeight={"semibold"}
                          borderRadius={"md"}>
                          READ ONLY
                        </Box>
                      </Center>
                    )}
                  </Stack>
                )}
                {shareDetails && shareDetails.ownerId === currentUserId && (
                  <Stack
                    {...commonTileProps}
                    bgColor={tileBgColor}
                    _hover={{}}
                    cursor={"default"}
                    spacing=".75rem"
                    py=".75rem"
                    direction="row"
                    justifyContent={"space-between"}
                    width="100%">
                    <Stack direction="row" spacing="1rem">
                      <Center width="2rem">
                        <Icon as={FiShare2} color={textColor} boxSize={"1.6rem"} />
                      </Center>
                      <Center>
                        <TextOverflowTooltip
                          color={textColor}
                          fontSize={"md"}
                          label={`Portfolio is shared in the ${shareDetails.group} group`}
                        />
                      </Center>
                    </Stack>
                    <Center>
                      <Box
                        bgColor={bgColorShared}
                        color={textColor}
                        fontSize="11px"
                        px="6px"
                        py="3px"
                        fontWeight={"semibold"}
                        borderRadius={"md"}>
                        SHARED
                      </Box>
                    </Center>
                  </Stack>
                )}
              </Box>
            )}
            {!isDueDiligenceLanding && (
              <Box position="relative" maxWidth={containerWidth} pt={isMobile ? "0.5rem" : "0"}>
                <ProjectMetrics />
                {showProjectMetrics && showOverlay && (
                  <>
                    <Center
                      position="absolute"
                      top="50%"
                      left="0"
                      right="0"
                      transform="translateY(-50%)"
                      zIndex={4}
                      maxWidth={containerWidth}>
                      <Text
                        textAlign={"center"}
                        bgColor={bgColorOverlayText}
                        px="1rem"
                        py=".5rem"
                        borderRadius={"md"}
                        fontSize="lg"
                        color={textColorOverlay}
                        fontWeight="medium">
                        Charts will populate as projects are added to the portfolio.
                      </Text>
                    </Center>
                    <Flex
                      className="ch-project-landing-overlay"
                      zIndex={3}
                      position="absolute"
                      top={0}
                      left={0}
                      right={0}
                      bottom={0}
                      bgColor={bgColor}
                      opacity={colorMode === "light" ? 0.5 : 0.7}
                      height={"100%"}
                      width={containerWidth}
                    />
                  </>
                )}
              </Box>
            )}
          </Box>
        )}
        <Stack
          bgColor={bgColor}
          spacing="1rem"
          justifyContent="space-between"
          mt={isDueDiligenceLanding ? (isMobile ? "1rem" : "0") : isDueDiligence && isMobile ? "0" : "0"}>
          <Stack
            spacing="1rem"
            pl={isMobile || isTablet ? ".5rem" : currentSidebarType === "hidden" ? "0" : "1rem"}
            pr={isMobile || isTablet ? ".5rem" : currentSidebarType === "hidden" ? "0" : "1rem"}
            pt={isMobile ? "1rem" : "1rem"}>
            <PausedWorkflowModalProvider>
              <Box mb={"5rem!important"}>
                {arePortfolioProjectsLoaded === false ? (
                  <SimpleGrid spacing={isMobile ? "1rem" : "2rem"} columns={[1, 2, 2, 2, 2]}>
                    <Skeleton {...skeletonStyle} borderRadius={"sm"} height="24rem" width="100%" />
                    <Skeleton {...skeletonStyle} borderRadius={"sm"} height="24rem" width="100%" />
                    <Skeleton {...skeletonStyle} borderRadius={"sm"} height="24rem" width="100%" />
                    <Skeleton {...skeletonStyle} borderRadius={"sm"} height="24rem" width="100%" />
                  </SimpleGrid>
                ) : (
                  <ProjectLandingTiles />
                )}
              </Box>
            </PausedWorkflowModalProvider>
          </Stack>
        </Stack>
      </Box>
      <FloatingDialogModal isOpen={isDialogOpen} onClose={onDialogClose} buttonRef={addTickerButtonRef} />
    </>
  );
};
