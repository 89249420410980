import { Array as RuntimeArray } from "runtypes";
import { PaginatedTickersResponse } from "./models/PaginatedTickersResponse";
import { prependProxyBaseUrl, request } from "api/request";
import type { CreateTickerRequest } from "./models/CreateTickerRequest";
import type { TickerStatus } from "api/tickers/models/TickerStatus";
import type { UpdateTickerRequest } from "./models/UpdateTickerRequest";
import type { TickerType } from "./models/TickerType";
import { SimplifiedTicker } from "./models/SimplifiedTicker";
import { DealFinderTicker } from "./models/DealFinderTicker";

const TickersQueryResponse = RuntimeArray(SimplifiedTicker);
const DealFinderTickersResponse = RuntimeArray(DealFinderTicker);

const TICKERS_V2_ENDPOINT = prependProxyBaseUrl("/api/tickers/v2");

export async function getPaginatedTickers({
  limit = 20,
  query,
  token,
  status,
  exchange,
  symbol,
  type,
}: {
  limit?: number;
  query?: string;
  token?: string;
  status?: TickerStatus;
  exchange?: string;
  symbol?: string;
  type?: string;
}): Promise<PaginatedTickersResponse> {
  try {
    const response = await request()
      .url(`${TICKERS_V2_ENDPOINT}`)
      .query({
        limit,
        ...(query && { filterByQuery: query }),
        ...(token && { token }),
        ...(status && { filterByStatus: status }),
        ...(exchange && { filterByExchange: exchange }),
        ...(symbol && { filterBySymbol: symbol }),
        ...(type && { filterByType: type }),
      })
      .get()
      .json();

    const validate = PaginatedTickersResponse.validate(response);

    if (validate.success) {
      return validate.value;
    } else {
      const message = "Invalid response from server: " + validate.message;
      console.error(message);

      throw new Error(message);
    }
  } catch (error) {
    throw new Error(error);
  }
}

export async function getTickersQuery({
  limit = 10,
  query,
  type,
  exchange,
  excludedType,
  excludedExchanges,
}: {
  limit?: number;
  query?: string;
  type?: TickerType | TickerType[];
  exchange?: string | string[];
  excludedType?: TickerType | TickerType[];
  excludedExchanges?: string | string[];
}): Promise<SimplifiedTicker[]> {
  try {
    const exchangeValue = exchange ? (Array.isArray(exchange) ? exchange : [exchange]) : undefined;

    const response = await request()
      .url(`${TICKERS_V2_ENDPOINT}/query`)
      .query({
        limit,
        ...(query && { query }),
        ...(type && { type }),
        ...(exchangeValue && { exchange: exchangeValue }),
        ...(excludedType && { excludedType }),
        ...(excludedExchanges && { excludedExchanges }),
      })
      .get()
      .json();

    const validate = TickersQueryResponse.validate(response);

    if (validate.success) {
      return validate.value;
    } else {
      const message = "Invalid response from server: " + validate.message;
      console.error(message);

      throw new Error(message);
    }
  } catch (error) {
    throw new Error(error);
  }
}

export async function importTickers({ exchange, file }: { exchange: string; file: File }): Promise<void> {
  try {
    const formData = new FormData();
    formData.append("csvFile", file);
    formData.append("exchange", exchange);

    await request().url(`${TICKERS_V2_ENDPOINT}/import`).body(formData).post().json();
  } catch (error) {
    throw new Error(error);
  }
}

export async function updateTicker({ id, ticker }: { id: string; ticker: UpdateTickerRequest }): Promise<void> {
  try {
    await request().url(`${TICKERS_V2_ENDPOINT}/${id}`).put(ticker).json();
  } catch (error) {
    throw new Error(error);
  }
}

export async function createTicker({ ticker }: { ticker: CreateTickerRequest }): Promise<void> {
  try {
    await request().url(`${TICKERS_V2_ENDPOINT}`).post(ticker).json();
  } catch (error) {
    throw new Error(error);
  }
}

const DEAL_FINDER_ENDPOINT = prependProxyBaseUrl("/api/deal-finder/tickers");

export async function getDealFinderTickersQuery({
  limit = 50,
  symbols,
  ids,
  type,
  exchange,
  excludedType,
  excludedExchanges,
}: {
  limit?: number;
  symbols?: string[];
  ids?: string[];
  type?: TickerType | TickerType[];
  exchange?: string | string[];
  excludedType?: TickerType | TickerType[];
  excludedExchanges?: string | string[];
}): Promise<DealFinderTicker[]> {
  try {
    const exchangeValue = exchange ? (Array.isArray(exchange) ? exchange : [exchange]) : undefined;

    const response = await request()
      .url(DEAL_FINDER_ENDPOINT)
      .query({
        limit,
        ...(symbols && { symbols }),
        ...(ids && { ids }),
        ...(type && { type }),
        ...(exchangeValue && { exchange: exchangeValue }),
        ...(excludedType && { excludedType }),
        ...(excludedExchanges && { excludedExchanges }),
      })
      .get()
      .json();

    const validate = DealFinderTickersResponse.validate(response);

    if (validate.success) {
      return validate.value;
    } else {
      const message = "Invalid response from server: " + validate.message;
      console.error(message);

      throw new Error(message);
    }
  } catch (error) {
    throw new Error(error);
  }
}

export async function exportTickersToCSV({ exchange }: { exchange: string }): Promise<Blob> {
  const response = await (await request().url(`${TICKERS_V2_ENDPOINT}/export`).query({ exchange }).get().res()).blob();

  return response;
}
