import { useDispatch } from "react-redux";
import type { AppDispatch } from "state/store";

export const useAppDispatch = () => useDispatch<AppDispatch>();

export * from "./useBilling";
export * from "./useCollections";
export * from "./useCommandsSuggestions";
export * from "./useConversation";
export * from "./useCurrentWidth";
export * from "./useCustomTheme";
export * from "./useEntitlements";
export * from "./useFeatureFlags";
export * from "./useGoogleApi";
export * from "./useIntegrations";
export * from "./useIsMainAppLoading";
export * from "./useItemSelector";
export * from "./useJWT";
export * from "./useModalView";
export * from "./useNotifications";
export * from "./useShareHistory";
export * from "./useSidebarNavigation";
export * from "./useTags";
export * from "./useThumbnail";
export * from "./useUppy";
export * from "./useUserPreferences";
export * from "./useUserProfile";
export * from "./useContentService";
export * from "./useCommonProps";
export * from "./useMeetings";
export * from "./useOrganizations";
export * from "./useViewConfig";
export * from "./useInfiniteLoading";
export * from "./useDeleteCollection";
export * from "./useCollectionMetadata";
export * from "./useDealFinderTickers";
export * from "./useInvestmentDetailModal";
