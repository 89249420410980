import { Box, Button, Center, Stack, Switch, Text, useBreakpointValue, useColorModeValue } from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import React, { useContext, useState, useMemo, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import type { TableRowValue } from "screens/content/common/TwoColumnTable";
import { TwoColumnTable } from "screens/content/common/TwoColumnTable";
import { sendMessage } from "state/websocket/operations";
import { ConversationContext } from "screens/thread/ConversationContext";
import { formatValue, getMetatDataForContent } from "../common/utils";
import type { RequestEntities } from "types/charliui";
import { MultiColumnTableEditable } from "../common/TwoColumnTable/MultiColumnTableEditable";
import type { ContentDetails } from "types/content/ContentDetails";
import type { CollectionWithAdditionalProps } from "types/collection";
import { JSONEditor, ModalView } from "screens/common/components";
import type { DetailSectionData } from "types/content/DetailSectionData";
import type { DetailSection } from "types/content/DetailSection";
import { useButtonProps, useLoadingContents } from "hooks";
import { BlockSectionHeader } from "./previewSection/BlockSectionHeader";
import { TableContent } from "api/content/models/TableContent";
import moment from "moment";
import { BiCopy } from "react-icons/bi";
import { SmallActionButton } from "../contentCanvas/cell/SmallActionButton";

interface Props {
  contentDetails: ContentDetails;
  sectionTitle?: string;
  showEmptyValues?: boolean;
  includedSections?: string[];
  excludeSections?: string[];
  hideSectionTitles?: boolean;
  metadataId?: string;
  isEditModeEnabled?: boolean;
  collection?: CollectionWithAdditionalProps;
  isModalOpen?: boolean;
  valueColumnAlignRight?: boolean;
  isTextTruncated?: boolean;
  onModalClose?: () => void;
}

const isValidJSON = (text: string) => {
  try {
    JSON.parse(text);
    return true;
  } catch (error) {
    return false;
  }
};

const getGroupedData = (data: DetailSectionData[]) => {
  return data.reduce(
    (acc, record) => {
      if (record.type === "table") {
        try {
          const tableContent = typeof record.value === "string" ? JSON.parse(record.value) : record.value;

          if (!tableContent || typeof tableContent !== "object") {
            acc.sectionsWithTableNoData.push(record);
            return acc;
          }

          if (!Array.isArray(tableContent.data) || tableContent.data.length === 0) {
            acc.sectionsWithTableNoData.push(record);
            return acc;
          }

          const transformedContent = {
            title: tableContent.title,
            schema: {
              fields: Object.keys(tableContent.data[0]).map((key) => ({
                name: key,
                type: typeof tableContent.data[0][key] === "number" ? "number" : "string",
              })),
            },
            data: tableContent.data.map((item) => {
              if (typeof item === "object" && item !== null) {
                return Object.entries(item).reduce(
                  (acc, [key, value]) => ({
                    ...acc,
                    [key]: typeof value === "number" ? value : String(value),
                  }),
                  {}
                );
              }
              return {};
            }),
            label: tableContent.label,
            recordId: tableContent.recordId,
            recordEntity: tableContent.recordEntity,
            confidence: tableContent.confidence,
          };

          try {
            const isValid = TableContent.guard(transformedContent);
            if (isValid) {
              const recordWithTransformedValue = {
                ...record,
                value: transformedContent,
              };
              acc.sectionsWithTableData.push(recordWithTransformedValue);
            } else {
              acc.sectionsWithTableNoData.push(record);
            }
          } catch (error) {
            acc.sectionsWithTableNoData.push(record);
          }
        } catch (error) {
          acc.sectionsWithTableNoData.push(record);
        }
      } else if (record.type === "json") {
        acc.sectionsWithJSONType.push(record);
      } else {
        acc.sectionsWithNoTableType.push(record);
      }
      return acc;
    },
    {
      sectionsWithTableData: [] as DetailSectionData[],
      sectionsWithNoTableType: [] as DetailSectionData[],
      sectionsWithJSONType: [] as DetailSectionData[],
      sectionsWithTableNoData: [] as DetailSectionData[],
    }
  );
};

const getJSONTypesMap = (
  detailSections: DetailSection[],
  currentMap?: Record<string, { value: unknown; changed: boolean }>
): Record<string, { value: unknown; changed: boolean }> => {
  return detailSections.reduce(
    (acc, section) => ({
      ...acc,
      ...section.data.reduce(
        (acc2, record) => ({
          ...acc2,
          ...(record.id && record.type === "json"
            ? {
                [record.id]: { value: record.value, changed: currentMap && currentMap[record.id] ? currentMap[record.id].changed : false },
              }
            : {}),
        }),
        {}
      ),
    }),
    {}
  );
};

export const ContentViewExtractedAISection: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  contentDetails,
  sectionTitle,
  showEmptyValues = true,
  includedSections = [],
  excludeSections = [],
  hideSectionTitles = false,
  metadataId,
  isEditModeEnabled = false,
  collection,
  valueColumnAlignRight,
  isModalOpen,
  onModalClose,
  isTextTruncated = false,
}) => {
  const dispatch = useDispatch();
  const { contentId } = useContext(ConversationContext);
  const [showSummary, setShowSummary] = useState(true);
  const conversationId = contentId ? contentId : collection?.conversationId;
  const bgColor = useColorModeValue("white", "#161B25");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });

  const detailsSections = useMemo(() => {
    let filteredSections = contentDetails?.detailSections ?? [];

    if (includedSections.length > 0) {
      filteredSections = filteredSections.filter((section) => includedSections.includes(section.sectionName));
    }

    if (excludeSections.length > 0) {
      filteredSections = filteredSections.filter((section) => !excludeSections.includes(section.sectionName));
    }

    return filteredSections;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentDetails]);

  const contentDetailSource = contentDetails.sourceIntegrationUrn;

  // this is for storing current values for json type fields. Then, if user modifies the value, we can send the new value to the server
  // hitting "save" button
  const [jsonTypesMap, setJsonTypesMap] = useState<Record<string, { value: unknown; changed: boolean }>>(getJSONTypesMap(detailsSections));
  const commonButtonProps = useButtonProps("xs", "secondary");
  const isLoading = useLoadingContents();

  const groupedData = useCallback((data: DetailSectionData[]) => getGroupedData(data), []);

  const sendAction = useCallback(
    (intent?: string, entities?: { [entity: string]: string }) => {
      if (!conversationId || !intent) return;

      const requestEntities: RequestEntities = [];
      if (entities) Object.keys(entities).forEach((entity) => requestEntities.push({ entity: entity, value: entities[entity] }));

      dispatch(
        sendMessage({
          conversationId: conversationId,
          intent: `/${intent}`,
          entities: requestEntities,
        })
      );
    },
    [conversationId, dispatch]
  );

  const sendFieldEdit = useCallback(
    (sectionName: string, fieldName: string, value: string, id?: string) => {
      if (!conversationId) return;
      const metadata = getMetatDataForContent(contentDetails);
      if (!metadata) return;
      const entities: RequestEntities = [];
      if (id && metadata.intent === "edit_file_metadata") entities.push({ entity: "extracted_info_id", value: id });
      if (metadataId) entities.push({ entity: "metadata_id", value: metadataId });
      entities.push({ entity: "section_name", value: sectionName });
      entities.push({ entity: "property_name", value: fieldName });
      entities.push({ entity: "property_value", value: value });

      dispatch(
        sendMessage({
          conversationId: conversationId,
          intent: `/${metadata.intent}`,
          entities: entities,
        })
      );
    },
    [conversationId, dispatch, contentDetails, metadataId]
  );

  const formatRowValue = useCallback(
    (row: DetailSectionData) => {
      const recordValue = String(row.value);
      const isDate = moment(recordValue, "YYYY-MM-DD", true).isValid();
      const isNumber =
        !isNaN(parseInt(recordValue)) &&
        !isDate &&
        row.type !== "date" &&
        contentDetailSource === "activityhandler:v2:external:charli.ai:factset" &&
        row.type !== "byte";
      const valueType = isNumber ? "number" : isDate ? "date" : row.type;
      const value =
        showSummary && row.summary && row.summary?.length > 0
          ? row.summary
          : formatValue(row.value as string | number | Date | null, valueType, row.context ?? undefined, row.entity ?? undefined);

      return value;
    },
    [contentDetailSource, showSummary]
  );

  const copySectionData = useCallback(
    (sectionData: DetailSectionData[]): string => {
      if (!sectionData) return "";

      const header = `${decodeURIComponent(sectionTitle || "")}`;
      const dataRows = sectionData.map((row) => {
        return `${row.label},${formatRowValue(row)},${row.confidence ? Math.round(row.confidence * 100) : "-"}`;
      });
      const tableString = [header, ...dataRows].join("\n");

      return tableString;
    },
    [formatRowValue, sectionTitle]
  );

  const renderGroupedSections = useCallback(
    (section: DetailSection) => {
      const { sectionsWithNoTableType, sectionsWithTableData, sectionsWithJSONType, sectionsWithTableNoData } = groupedData(section.data);

      if (
        sectionsWithNoTableType.length === 0 &&
        sectionsWithTableData.length === 0 &&
        sectionsWithJSONType.length === 0 &&
        sectionsWithTableNoData.length === 0
      ) {
        return null;
      }

      return (
        <React.Fragment key={`section-${section.sectionId}`}>
          {sectionsWithNoTableType.length > 0 && (
            <Box
              className={`two-column${section.sectionId}`}
              p={isMobile ? ".5rem" : "1rem"}
              width="100%"
              bg={bgColor}
              border="1px solid"
              borderColor={borderColor}
              borderRadius="md">
              {!hideSectionTitles && (
                <BlockSectionHeader title={sectionTitle ? decodeURIComponent(sectionTitle || "") : section.sectionTitle} direction="row">
                  {section.data && section.data.some((record) => record.summary) && (
                    <Center>
                      <Stack direction="row" mt="0!important">
                        <Text fontSize="xs">Summary</Text>
                        <Switch
                          size="sm"
                          className="summary-toggle"
                          onChange={(event) => setShowSummary(event.target.checked)}
                          isChecked={showSummary}
                          colorScheme="teal"
                        />
                      </Stack>
                    </Center>
                  )}
                  <SmallActionButton
                    classNames="ch-copy-section"
                    iconTypeName={BiCopy}
                    onClick={() => navigator.clipboard.writeText(copySectionData(sectionsWithNoTableType))}
                    tooltip={"Copy Section"}
                  />
                </BlockSectionHeader>
              )}
              <TwoColumnTable
                valueColumnAlignRight={valueColumnAlignRight}
                showEmptyValues={showEmptyValues}
                sectionHeader={section.sectionTitle}
                isEditEnabled={isEditModeEnabled}
                isTextTruncated={isTextTruncated}
                onEdit={
                  !sectionsWithNoTableType.some((record) => record.summary) || !showSummary
                    ? (entity, value, id) => {
                        sendFieldEdit(section.sectionName, entity, value, id);
                      }
                    : undefined
                }
                rows={sectionsWithNoTableType.map((record) => {
                  const className = `ch-${showSummary ? "summary-value" : "raw-value"} ch-label-${record.label
                    ?.toLowerCase()
                    .replace(/\s+/g, "-")}`;
                  return {
                    id: record.id,
                    className,
                    title: record.label,
                    value: formatRowValue(record),
                    type: record.type,
                    entityName: record.entity,
                    url: contentDetails.urls?.parentUrl,
                    rowMaxHeight: "1.4rem",
                    action: record.action,
                    onSendAction: (intent, entities) => sendAction(intent, entities),
                  } as TableRowValue;
                })}
              />
            </Box>
          )}
          {sectionsWithTableData.length > 0 && (
            <Box className={`table-${section.sectionId}`}>
              <MultiColumnTableEditable
                isTabs={false}
                isLoading={isLoading}
                isEditEnabled={isEditModeEnabled}
                onEditTableData={(_, editedContent) => {
                  if (!editedContent) {
                    return;
                  }

                  const entity = editedContent.recordEntity;
                  const id = editedContent.recordId;

                  if (!entity || !id) return;

                  sendFieldEdit(
                    section.sectionName,
                    entity,
                    JSON.stringify({ data: editedContent.data, schema: editedContent.schema, title: editedContent.title }).replace(
                      /\//g,
                      "-"
                    ),
                    id
                  );
                }}
                tablesData={sectionsWithTableData.flatMap((record) => {
                  if (!TableContent.guard(record.value)) {
                    return [];
                  }

                  return [
                    {
                      ...record.value,
                      label: record.label !== null ? record.label : undefined,
                      recordId: record.id !== null ? record.id : undefined,
                      recordEntity: record.entity !== null ? record.entity : undefined,
                      confidence: record.confidence !== null ? record.confidence : undefined,
                    },
                  ];
                })}
              />
            </Box>
          )}
          {sectionsWithTableNoData.map((record, index) => {
            return (
              <Box key={`nodata-${index}`} className={`nodata-${index}`}>
                <BlockSectionHeader title={record.label ?? "n/a"} />
                <Text fontSize={"sm"}>No data available</Text>
              </Box>
            );
          })}
          {sectionsWithJSONType.length > 0 && (
            <>
              {sectionsWithJSONType.map((record, index) => {
                const id = record.id ?? "";

                return (
                  <Box key={`json-${index}`} className="ch-json-section">
                    <BlockSectionHeader title={record.label ?? "n/a"} direction="row">
                      <Button
                        {...commonButtonProps}
                        mt="0!important"
                        onClick={() => {
                          if (record.entity && id.length > 0) {
                            setJsonTypesMap((prev) => ({ ...prev, [id]: { ...prev[id], changed: false } }));
                            sendFieldEdit(section.sectionName, record.entity, JSON.stringify(jsonTypesMap[id]?.value), id);
                          }
                        }}
                        disabled={!jsonTypesMap[id]?.changed}>
                        Save
                      </Button>
                    </BlockSectionHeader>
                    <JSONEditor
                      value={JSON.stringify(jsonTypesMap[id]?.value ?? "", null, 2)}
                      editable={isEditModeEnabled}
                      onChange={(data) => {
                        if (isValidJSON(data) && id.length > 0) {
                          setJsonTypesMap((prev) => ({ ...prev, [id]: { value: JSON.parse(data), changed: true } }));
                        }
                      }}
                    />
                  </Box>
                );
              })}
            </>
          )}
        </React.Fragment>
      );
    },
    [
      groupedData,
      isMobile,
      bgColor,
      borderColor,
      hideSectionTitles,
      sectionTitle,
      showSummary,
      valueColumnAlignRight,
      showEmptyValues,
      isEditModeEnabled,
      isTextTruncated,
      isLoading,
      copySectionData,
      sendFieldEdit,
      formatRowValue,
      contentDetails.urls?.parentUrl,
      sendAction,
      commonButtonProps,
      jsonTypesMap,
    ]
  );

  const renderDetailsSections = useCallback(
    () => <>{detailsSections.map((section) => renderGroupedSections(section))}</>,
    [detailsSections, renderGroupedSections]
  );

  useEffect(() => {
    setJsonTypesMap((prev) => getJSONTypesMap(detailsSections, prev));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {renderDetailsSections()}
      {isModalOpen && onModalClose && (
        <ModalView onClose={onModalClose} isOpen={isModalOpen} title="Extracted AI Information">
          <Stack spacing="1rem">{renderDetailsSections()}</Stack>
        </ModalView>
      )}
    </>
  );
};
