import {
  Button,
  Center,
  Highlight,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useCallback, useContext } from "react";
import { useDispatch } from "react-redux";

import { useCollectionKey, useGroupCollectionsIds, useMainCollectionWorkflowId, useProjectParams } from "./useCollections";
import type { RequestEntities } from "types/charliui";
import { sendMessage } from "state/websocket/operations";
import { useNavigate } from "react-router-dom";
import { useButtonProps } from "./useCommonProps";
import { v4 as uuid } from "uuid";
import { ConversationContext } from "screens/thread/ConversationContext";
import { CloseIcon } from "@chakra-ui/icons";
import { useCompanyStockFromCollectionContentsOrConversation } from "hooks";
import { CollectionsFilterContext } from "screens/landing/tabs/collections/CollectionsFilterContext";

export const DeleteCollectionModal = ({
  onDeleteCollection,
}: {
  onDeleteCollection: (projectType?: "portfolio" | "project" | "version") => void;
}) => {
  const { onDeleteProjectModalClose, isDeleteProjectModalOpen, isDeleteProjectId, isDeleteProjectType } = useContext(ConversationContext);
  const collectionName = useCollectionKey(isDeleteProjectId, "name");
  const isActiveProjectInGroup = useCollectionKey(isDeleteProjectId, "isActiveProjectInGroup");
  const primaryButtonProps = useButtonProps("sm", "primary");
  const secondaryButtonProps = useButtonProps("sm", "secondary");
  const companyStockData = useCompanyStockFromCollectionContentsOrConversation(isDeleteProjectId);
  const textColor = useColorModeValue("primary.darkGray", "gray.400");
  const bgColor = useColorModeValue("white", "gray.700");
  const maybeGroupId = useCollectionKey(isDeleteProjectId, "projectGroupId");
  const maybeCollectionsIds = useGroupCollectionsIds(maybeGroupId);

  return (
    <Modal onClose={onDeleteProjectModalClose} isOpen={isDeleteProjectModalOpen} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          bgColor={bgColor}
          color={textColor}
          maxHeight="10rem"
          display="flex"
          flexDirection="column"
          padding={0}
          borderTopRadius="lg">
          <Stack direction={"row"} fontSize="md" textAlign="center" pt="1rem" width="100%" justifyContent={"space-between"} px="1.5rem">
            <Text fontWeight={"semibold"} fontSize="lg">
              {`Confirm Delete ${isDeleteProjectType === "portfolio" ? "Portfolio" : "Project"}`}
            </Text>
            <Center>
              <CloseIcon cursor={"pointer"} boxSize={".8rem"} onClick={onDeleteProjectModalClose} />
            </Center>
          </Stack>
        </ModalHeader>
        <ModalBody fontSize={"sm"} pt="1rem">
          <Text fontWeight="normal" color={textColor}>
            <Highlight
              query={collectionName?.toUpperCase() || ""}
              styles={{ py: "1", whiteSpace: "normal", fontWeight: "bold", color: textColor }}>
              {isActiveProjectInGroup
                ? `Please confirm that you would like to delete the ${
                    companyStockData?.name?.toUpperCase() || collectionName?.toUpperCase() || "this"
                  } ${isDeleteProjectType ? "project" : "portfolio"}. This can not be undone.`
                : `Please confirm that you would like to delete this version from the ${
                    companyStockData?.name?.toUpperCase() || collectionName?.toUpperCase()
                  } project. This can not be undone.`}
            </Highlight>
          </Text>
        </ModalBody>
        <ModalFooter>
          <Stack direction="row" spacing="1rem" justifyContent={"space-between"} width="100%">
            <Button {...secondaryButtonProps} onClick={onDeleteProjectModalClose}>
              Cancel
            </Button>
            <Stack direction="row" spacing="1rem">
              {isDeleteProjectType !== "version" && (
                <Button
                  className="ch-delete-project-confirm"
                  {...secondaryButtonProps}
                  onClick={() => {
                    onDeleteCollection(isDeleteProjectType);
                  }}>
                  Delete {isDeleteProjectType === "portfolio" ? "Portfolio" : "Project"}
                </Button>
              )}
              {isDeleteProjectType !== "portfolio" && maybeCollectionsIds && maybeCollectionsIds?.length > 0 && (
                <Button
                  className="ch-delete-project-version-confirm"
                  {...primaryButtonProps}
                  onClick={() => {
                    onDeleteCollection("version");
                  }}>
                  Delete Version
                </Button>
              )}
            </Stack>
          </Stack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export const useDeleteCollection = () => {
  const { projectId } = useProjectParams();
  const { onDeleteProjectModalClose, isDeleteProjectId, navigatePath, setNavigatePath, isDeleteProjectType, setIsDeleteProjectId } =
    useContext(ConversationContext);
  const { addDeletedCollectionId } = useContext(CollectionsFilterContext);
  const workflowId = useMainCollectionWorkflowId(isDeleteProjectId);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onDeleteCollection = useCallback(
    (projectType?: "portfolio" | "project" | "version") => {
      if (!isDeleteProjectId) {
        return;
      }

      const deleteType = projectType || isDeleteProjectType;
      const entities: RequestEntities = [];

      entities.push({ entity: "collection_id", value: isDeleteProjectId });
      deleteType !== "portfolio" && workflowId && entities.push({ entity: "workflow_id", value: workflowId });

      // Immediately update the UI to remove the deleted project
      // Add the deleted ID to the list of deleted collections
      addDeletedCollectionId(isDeleteProjectId);

      dispatch(
        sendMessage({
          conversationId: uuid(),
          intent: `${
            deleteType === "version" ? "/delete_project" : deleteType === "project" ? "/delete_all_project_versions" : "/delete_portfolio"
          }`,
          entities: entities,
        })
      );

      setIsDeleteProjectId(undefined);

      if (navigatePath) {
        navigate(navigatePath);
        setNavigatePath(undefined);
      }
      if (!navigatePath && projectId) {
        navigate(-1);
      }

      onDeleteProjectModalClose();
    },
    [
      isDeleteProjectId,
      workflowId,
      dispatch,
      isDeleteProjectType,
      setIsDeleteProjectId,
      navigatePath,
      projectId,
      onDeleteProjectModalClose,
      navigate,
      setNavigatePath,
      addDeletedCollectionId,
    ]
  );

  return {
    onDeleteCollection,
  };
};
