import { Box, Center, Image, useColorModeValue } from "@chakra-ui/react";
import type { FunctionComponent, HTMLAttributes } from "react";
import React from "react";
import CharliLogoSmall from "screens/common/static/logos/charli-logo-small.png";
import { useConversationContext } from "./ConversationContext";

interface Props extends HTMLAttributes<HTMLDivElement> {
  onClick?: () => void;
  className?: string;
  isSquareIcon?: boolean;
}

export const CharliLogoCircle: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  onClick,
  className = "ch-quick-actions-command-line-button",
  isSquareIcon,
  ...props
}) => {
  const chatButtonBackground = useColorModeValue("chatButton.background", "gray.900");
  const { isConversationOpen, onConversationClose, onConversationOpen } = useConversationContext();
  const boxShadowColor = useColorModeValue("204,204,204", "74,85,104");
  const inputBgColor = useColorModeValue("white", "gray.700");

  const onHandleConversation = () => {
    if (isConversationOpen) {
      onConversationClose();
    } else {
      onConversationOpen();
    }
  };

  return (
    <Box
      className={className}
      onClick={onClick || onHandleConversation}
      p={isSquareIcon ? "0" : "0.5rem"}
      background={isSquareIcon ? inputBgColor : chatButtonBackground}
      _hover={
        !isConversationOpen
          ? {
              boxShadow: isSquareIcon ? "none" : `0 0 0 6px rgba(${boxShadowColor}, 0.8)`,
              transition: isSquareIcon ? "none" : "box-shadow 0.3s ease-in-out",
            }
          : {}
      }
      cursor="pointer"
      height={isSquareIcon ? "calc(100% - 2px)" : "3rem"}
      width={isSquareIcon ? "100%" : "3rem"}
      borderRadius="full"
      boxShadow={isSquareIcon ? "none" : `0 0 0 4px rgba(${boxShadowColor}, 1)`}
      transition={isSquareIcon ? "none" : "box-shadow 0.3s ease-in-out"}
      {...props}>
      <Center width="100%" height="100%">
        <Image src={CharliLogoSmall} height={isSquareIcon ? "1rem" : "auto"} alt="Charli Logo" />
      </Center>
    </Box>
  );
};
