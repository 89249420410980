import { Box } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { TabTitle } from "screens/common/components/TabTitle";
import { useSettingContext } from "screens/panels/settings/SettingsProvider";
import { useConversationContext } from "screens/thread/ConversationContext";
import { getViewConfig } from "configs/configMap";
import { useConfigMap, useCollection, useProjectParams, useGetViewConfig } from "hooks";
import { ProjectLanding } from "./ProjectLanding";

export const Projects = () => {
  const { projectFilter = "", isPortfolios } = useProjectParams();
  const collection = useCollection(projectFilter, { refreshFromNetwork: !!isPortfolios });
  const { setHeaderText } = useSettingContext();
  const { onConversationClose } = useConversationContext();
  const configMap = useConfigMap();
  const collectionType = useGetViewConfig("collectionType", projectFilter, configMap);
  const portfolioCollectionType = useGetViewConfig("portfolioProjectType", collection?.collectionType || "", configMap);

  useEffect(() => {
    onConversationClose();
    setHeaderText(collection ? collection.name : `${getViewConfig("title", projectFilter, configMap)}` || "Project");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectFilter, configMap]);

  return (
    <Box width="100%" height="100%">
      <Box>
        <TabTitle
          title={collection ? `Charli > ${collection.name}` : `Charli > ${getViewConfig("title", projectFilter, configMap) || "Project"}`}
        />
        <ProjectLanding collectionType={collectionType || portfolioCollectionType || "due_diligence"} />
      </Box>
    </Box>
  );
};
