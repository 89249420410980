import { useCallback } from "react";
import { getDealFinderTickersQuery } from "api/tickers";
import type { TickerType } from "api/tickers/models/TickerType";
import type { DealFinderTicker } from "api/tickers/models/DealFinderTicker";

interface QueryParams {
  limit?: number;
  symbols?: string[];
  ids?: string[];
  type?: TickerType | TickerType[];
  exchange?: string | string[];
  excludedType?: TickerType | TickerType[];
  excludedExchanges?: string | string[];
}

export const useDealFinderTickers = () => {
  const getDealFinderTickers = useCallback(async (params: QueryParams): Promise<DealFinderTicker[]> => {
    try {
      const { limit, symbols, ids, type, exchange, excludedType, excludedExchanges } = params;

      const data = await getDealFinderTickersQuery({
        limit,
        symbols,
        ids,
        type,
        exchange,
        excludedType,
        excludedExchanges,
      });

      return data;
    } catch (error) {
      console.error("Error fetching deal finder tickers:", error);
      return [];
    }
  }, []);

  return { getDealFinderTickers };
};
