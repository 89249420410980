// Polyfills
import "core-js/features/object/from-entries";

// All other imports
import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "./state/store";
import { ColorModeScript } from "@chakra-ui/react";
import "./index.css";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import "./mobileViewport.css";
import { createRoot } from "react-dom/client";

import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { Worker } from "@react-pdf-viewer/core";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ColorModeScript initialColorMode={"dark"} />
        <App />
      </PersistGate>
    </Provider>
  </Worker>
);

document.querySelector("body")!.style.overflow = "hidden";

serviceWorker.unregister();
