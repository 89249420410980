import {
  Box,
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  Input,
  Stack,
  Text,
  useToast,
  Image,
  useBreakpointValue,
  HStack,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Tooltip,
  Container,
  Heading,
  List,
  ListItem,
  useColorModeValue,
} from "@chakra-ui/react";
import { sendPasswordResetEmail } from "api/user";
import { useCopyValue } from "hooks/useCopies";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { ToastMessageContent } from "screens/common/components";
import { TabTitle } from "screens/common/components/TabTitle";
import { GoogleSignInButton } from ".";
import verifiedShield from "screens/common/static/misc/verified_shield.svg";
import soc2logo from "screens/common/static/logos/soc2_logo.png";
import { useButtonProps } from "hooks";
import { CheckIcon } from "@chakra-ui/icons";
import { RegistrationBanner } from "components/RegistrationBanner";
import { useCustomScrollbar } from "hooks/useCustomScrollbar";
import targetImage from "screens/common/static/misc/target_image.png";
import edgarLogo from "screens/common/static/logos/edgar_color.png";
import nasdaqLogo from "screens/common/static/logos/nasdaq_color.png";
import tsxLogo from "screens/common/static/logos/tsx_color.png";
import sedarLogo from "screens/common/static/logos/sedar_color.png";
import { updateTypedUserPreference } from "state/userPreference/operations";
import { useDispatch, useSelector } from "react-redux";
import type { RootState } from "state/rootReducer";

interface Props {
  onSubmit: (fields: any) => void;
  showEmailForm: boolean;
}

export const LoginForm = (props: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setError,
    clearErrors,
  } = useForm<{
    username: string;
    password: string;
  }>();
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const toast = useToast();
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const commonButtonProps = useButtonProps("md", "cta");
  const [hasWebsocketSupport, setHasWebsocketSupport] = useState(true);
  const loginHeading = useCopyValue("copy_login_heading");
  const listItems = useCopyValue("copy_login_list_items");
  const containerRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const lastError = useSelector((state: RootState) => state.session.lastError);

  // Color mode values
  const bgColor = useColorModeValue("#fbfbfb", "gray.900");
  const textColor = useColorModeValue("gray.800", "gray.100");
  const boxBgColor = useColorModeValue("white", "gray.800");
  const inputBgColor = useColorModeValue("white", "gray.700");
  const inputBorderColor = useColorModeValue("gray.700", "gray.500");
  const headingColor = useColorModeValue("gray.700", "gray.200");
  const descriptionColor = useColorModeValue("gray.600", "gray.400");
  const borderColor = useColorModeValue("rgba(61, 61, 61, 0.25)", "rgba(255, 255, 255, 0.25)");
  const checkIconColor = useColorModeValue("gray.700", "gray.900");
  const headerBgColor = useColorModeValue("white", "gray.900");

  const colors = ["blue.300", "teal.300", "green.300", "purple.300", "pink.300", "orange.300"];
  const getRandomColor = (index: number) => colors[index % colors.length];
  const { scrollbarStyle } = useCustomScrollbar(containerRef, { width: "2px", barTransparency: 0.2 });

  const checkWebSocketSupport = () => {
    if ("WebSocket" in window) {
      setHasWebsocketSupport(true);
    } else {
      setHasWebsocketSupport(false);
    }
  };

  useEffect(() => {
    checkWebSocketSupport();
  }, []);

  useEffect(() => {
    if (lastError?.includes("The username or password is incorrect.")) {
      setIsSubmitting(false);
    }
  }, [lastError]);

  const handleResetPassword = async () => {
    const email = getValues("username");
    if (email === "") {
      setError("username", {}, { shouldFocus: true });
    } else {
      clearErrors("username");
      await sendPasswordResetEmail(email);

      toast({
        render: ({ onClose }) => (
          <ToastMessageContent
            message={`An email has been sent to your ${email} account. Please use the link in it to reset your password then try logging in again.`}
            onClick={() => {
              onClose();
            }}
            onClose={onClose}
          />
        ),
        duration: 10000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  return (
    <Box bg={bgColor} fontFamily="Montserrat" overflow="auto" height={"100vh"} pb="2rem" css={scrollbarStyle}>
      <TabTitle title={`Charli > ${showResetPassword ? "Reset Password" : "Login"}`} />
      <Box width="100%" bgColor={headerBgColor} borderBottom={`1px solid ${borderColor}`}>
        <Box mx={isMobile ? "1rem" : "auto"}>
          <Center>
            <RegistrationBanner isLogin viewType="register" />
          </Center>
        </Box>
      </Box>
      <Box pt={isMobile ? "0" : "2.2rem"} pb={0}>
        <Container maxW="1130px" fontFamily="Montserrat" px={isMobile ? 4 : 6}>
          <Stack direction={isMobile ? "column-reverse" : "row"} spacing={isMobile ? "0" : "6rem"} justify="space-between">
            <Box flex="7">
              <Stack spacing={"1rem"}>
                <Box pt={isMobile ? "0" : "4.6em"}>
                  <Text fontSize={isMobile ? "2rem" : "2.4rem"} color={textColor} fontWeight="600" lineHeight="1.2" mb={6}>
                    {loginHeading}
                  </Text>
                  <Box pt="1rem">
                    <List spacing={"2rem"}>
                      {listItems.map((item, index) => {
                        const [title, description] = item.split("|");
                        return (
                          <ListItem key={index} display="flex" alignItems="flex-start">
                            <Box
                              bg={getRandomColor(index)}
                              color={checkIconColor}
                              borderRadius="full"
                              w="35px"
                              h="35px"
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                              mr={4}
                              flexShrink={0}>
                              <CheckIcon boxSize={"20px"} fontWeight={"semibold"} />
                            </Box>
                            <Box>
                              {title && (
                                <Text fontSize="1rem" color={textColor} fontWeight="600" mb={2}>
                                  {title}
                                </Text>
                              )}
                              {description && <Text color={descriptionColor}>{description}</Text>}
                            </Box>
                          </ListItem>
                        );
                      })}
                    </List>
                  </Box>
                </Box>
              </Stack>
            </Box>
            <Box flex="5" mt={isMobile ? "6rem" : "8rem"} position={"relative"}>
              <Box position={"absolute"} top="-3.9rem" left="2.2rem" transform={"translateX(-50%)"} width="13rem">
                <Image src={targetImage} width="100%" opacity={0.6} />
              </Box>
              <Box
                bg={boxBgColor}
                borderRadius="xl"
                p={6}
                boxShadow={isMobile ? "none" : "xl"}
                width={isMobile ? "100%" : "420px"}
                mx="auto">
                <Stack spacing={6}>
                  <Heading zIndex={2} as="h3" size="lg" textAlign="left" color={headingColor}>
                    Welcome Back!
                  </Heading>
                  <Box>
                    {!hasWebsocketSupport && (
                      <Center>
                        <Alert status="error" width="100%" justifyContent={"center"}>
                          <AlertIcon />
                          <AlertTitle>WebSockets Required:</AlertTitle>
                          <AlertDescription>
                            Your browser does not support WebSockets. Please try using a different browser.
                          </AlertDescription>
                        </Alert>
                      </Center>
                    )}
                    <Center>
                      <Stack width="100%" justifyContent={"space-between"} spacing="1rem" px="2rem" maxWidth={["100%", "25rem", "20rem"]}>
                        <form onSubmit={handleSubmit(props.onSubmit)}>
                          <Stack spacing="1rem" width="100%">
                            <FormControl isInvalid={!!errors.username} pb=".5rem">
                              <Input
                                _hover={{}}
                                size="sm"
                                {...register("username", { required: true })}
                                id="login-username"
                                borderColor={errors.username ? "red.500" : inputBorderColor}
                                bg={inputBgColor}
                                name="username"
                                type="username"
                                placeholder={showResetPassword ? "Email Address to Reset" : "Email Address"}
                                autoComplete="username"
                              />
                              {showResetPassword && (
                                <Text
                                  color="gray.400"
                                  textAlign={"end"}
                                  cursor={"pointer"}
                                  width="100%"
                                  id="link-button"
                                  fontWeight="light"
                                  fontSize="xs"
                                  onClick={() => {
                                    setShowResetPassword((value) => !value);
                                  }}>
                                  Back to login
                                </Text>
                              )}
                              {errors.username && <FormErrorMessage>Email is required.</FormErrorMessage>}
                            </FormControl>
                            <FormControl isInvalid={!!errors.password} hidden={showResetPassword} pb=".5rem">
                              <Input
                                _hover={{}}
                                size="sm"
                                {...register("password", { required: true })}
                                id="login-password"
                                type="password"
                                placeholder="Password"
                                autoComplete="current-password"
                                bg={inputBgColor}
                                borderColor={errors.password ? "red.500" : inputBorderColor}
                                fontSize="sm"
                                boxShadow="none"
                              />
                              {!showResetPassword && (
                                <Text
                                  color="gray.400"
                                  textAlign={"end"}
                                  cursor={"pointer"}
                                  width="100%"
                                  id="link-button"
                                  fontWeight="light"
                                  fontSize="xs"
                                  onClick={() => {
                                    setShowResetPassword((value) => !value);
                                  }}>
                                  Forgot password?
                                </Text>
                              )}
                              {errors.password && <FormErrorMessage>Password is required.</FormErrorMessage>}
                            </FormControl>

                            {!showResetPassword && (
                              <Center>
                                <Button
                                  {...commonButtonProps}
                                  borderColor={isSubmitting ? "#81c34c" : "primary.default"}
                                  bgColor={isSubmitting ? "#81c34c" : "primary.default"}
                                  opacity={isSubmitting ? 0.6 : 1}
                                  cursor={isSubmitting ? "default" : "pointer"}
                                  onClick={() => {
                                    setIsSubmitting(true);
                                    dispatch(updateTypedUserPreference({ preferenceKey: "ui_onboarding_completed", value: true }));
                                  }}
                                  width="100%"
                                  borderRadius={"full"}
                                  id={"login-submit"}
                                  type="submit"
                                  isDisabled={!hasWebsocketSupport}>
                                  {isSubmitting ? "SIGNING IN..." : "SIGN IN"}
                                </Button>
                              </Center>
                            )}
                            {showResetPassword && (
                              <Center>
                                <Button
                                  {...commonButtonProps}
                                  width="100%"
                                  borderRadius={"full"}
                                  id="reset-password"
                                  type="submit"
                                  onClick={handleResetPassword}>
                                  Reset Password
                                </Button>
                              </Center>
                            )}
                          </Stack>
                        </form>
                        <Stack spacing={"1rem"} mt="4rem">
                          <GoogleSignInButton variant={"signin"} />
                        </Stack>
                      </Stack>
                    </Center>
                  </Box>
                </Stack>
              </Box>
            </Box>
          </Stack>
          <Stack direction={isMobile ? "column" : "row"} justifyContent={"space-between"} mt="5rem" width="100%">
            <HStack align="center" justifyContent={"space-between"} width="100%">
              <Image src={nasdaqLogo} height={isMobile ? "1.5rem" : "2rem"} alt="NASDAQ Logo" />
              <Image src={tsxLogo} height={isMobile ? "3rem" : "4rem"} alt="TSX Logo" />
              <Image src={sedarLogo} height={isMobile ? ".9rem" : "1.1rem"} alt="Sedar Logo" />
              <Image src={edgarLogo} height={isMobile ? "2.5rem" : "4rem"} alt="Edgar Logo" />
            </HStack>
            <HStack justifyContent={"flex-end"} spacing="1rem" width="100%">
              <Tooltip
                maxWidth={"21rem"}
                label="Charli is SOC Type 2 compliant and designed to protect the security and privacy of data used by the system including protecting the privacy of equity research and analysis questions and answers."
                aria-label="SOC2 Certified">
                <Image src={soc2logo} height={isMobile ? "3rem" : "85px"} alt="SOC2 Logo" />
              </Tooltip>
              <Tooltip
                maxWidth={"21rem"}
                label="Charli leverages a highly accurate and trusted AI that is designed specifically for the Financial Services market and incorporates advanced Fact Check Analysis features to verify the results that are produced."
                aria-label="Verified by AI">
                <Image src={verifiedShield} height={isMobile ? "3rem" : "85px"} alt="SOC2 Logo" />
              </Tooltip>
            </HStack>
          </Stack>
        </Container>
      </Box>
    </Box>
  );
};
