import { Box, Flex, Icon, useColorModeValue, useToast, Text } from "@chakra-ui/react";
import { format, isValid } from "date-fns";
import React, { useMemo } from "react";
import { BiCopy } from "react-icons/bi";
import { TextOverflowTooltip } from "screens/landing/components/TextOverflowTooltip";
import type { ChangeLogEntry } from "api/tickers/models/ChangeLogEntry";

interface ColumnConfig {
  id: keyof ChangeLogEntry | string;
  header: string;
  flex: number;
  centered?: boolean;
  copyButton?: boolean;
  render: (item: ChangeLogEntry) => React.ReactNode;
}

interface TickerChangeLogProps {
  changeLog: ChangeLogEntry[];
  maxHeight?: string | number;
}

const TableHeader: React.FC<{
  columns: ColumnConfig[];
  titleColor: string;
  bgColor: string;
}> = React.memo(({ columns, titleColor, bgColor }) => (
  <Box fontSize="small" borderBottomColor={bgColor} borderBottom="2px solid" mb={1} color={titleColor} display="flex" width="100%">
    {columns.map((col) => (
      <Box
        key={col.id}
        flex={col.flex}
        {...(col.centered && {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        })}>
        {col.header}
      </Box>
    ))}
  </Box>
));

// Helper function to format values for display
const formatValue = (value: unknown): string => {
  if (value === null || value === undefined) {
    return "—";
  }

  if (typeof value === "object") {
    return JSON.stringify(value);
  }

  return String(value);
};

// Helper function to safely format dates
const formatDate = (dateString: string): string => {
  if (!dateString) return "";

  try {
    const date = new Date(dateString);
    if (!isValid(date)) return "Invalid date";

    return format(date, "dd-MMM-yyyy HH:mm");
  } catch (error) {
    return dateString; // Return the original string if parsing fails
  }
};

// Separate content to allow hook usage within React.memo
const TableRowContent: React.FC<{
  item: ChangeLogEntry;
  columns: ColumnConfig[];
  bgColor: string;
}> = ({ item, columns, bgColor }) => {
  const toast = useToast();
  const hoverBgColor = useColorModeValue("white", "gray.400");
  const hoverBorderColor = useColorModeValue("gray.200", "gray.400");

  const handleCopy = async (value: string, fieldName: string) => {
    try {
      // Ensure value is a string before copying
      const stringValue = typeof value === "string" ? value : String(value);
      await navigator.clipboard.writeText(stringValue);
      toast({
        title: "Copied to clipboard",
        description: `${fieldName}: ${stringValue}`,
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    } catch (err) {
      console.error("Failed to copy text: ", err);
      toast({
        title: "Copy failed",
        description: "Could not copy value to clipboard.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Box fontSize="sm" _hover={{}} display="flex" width="100%" p={1}>
      {columns.map((col) => {
        // Determine the value to copy. Use formatValue for consistency.
        const valueToCopy = formatValue(item[col.id as keyof ChangeLogEntry]);
        return (
          <Box
            key={col.id}
            flex={col.flex}
            minWidth="0"
            {...(col.centered && {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            })}>
            <Box
              onClick={() => handleCopy(valueToCopy, col.header)}
              position="relative"
              width="100%"
              cursor="pointer"
              role="group"
              borderRadius="sm"
              transition="all 0.2s"
              _hover={{}}>
              {col.render(item)}
              {col.copyButton && (
                <Box
                  px="5px"
                  bgColor={hoverBgColor}
                  boxShadow={"md"}
                  borderRadius="sm"
                  borderWidth="1px"
                  borderColor={hoverBorderColor}
                  position="absolute"
                  right="2"
                  top="50%"
                  transform="translateY(-50%)"
                  opacity="0"
                  transition="opacity 0.2s"
                  _groupHover={{ opacity: 1 }}
                  display="inline-flex"
                  alignItems="center"
                  justifyContent="center">
                  <Text pr="4px">Copy</Text>
                  <Icon as={BiCopy} />
                </Box>
              )}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

const TableRow: React.FC<{
  item: ChangeLogEntry;
  columns: ColumnConfig[];
  bgColor: string;
}> = React.memo(({ item, columns, bgColor }) => <TableRowContent item={item} columns={columns} bgColor={bgColor} />);

export const TickerChangeLog: React.FC<TickerChangeLogProps> = ({ changeLog, maxHeight }) => {
  const bgColor = useColorModeValue("gray.200", "gray.700");
  const titleColor = useColorModeValue("charli.lightGray", "gray.500");

  const columns = useMemo<ColumnConfig[]>(
    () => [
      {
        id: "field",
        header: "Field",
        flex: 2,
        render: (item) => <TextOverflowTooltip noOfLines={1} label={item.field} highlightBackground={false} />,
      },
      {
        id: "oldValue",
        header: "Old Value",
        flex: 2,
        copyButton: true,
        render: (item) => <TextOverflowTooltip noOfLines={1} label={formatValue(item.oldValue)} highlightBackground={false} />,
      },
      {
        id: "newValue",
        header: "New Value",
        flex: 2,
        render: (item) => <TextOverflowTooltip noOfLines={1} label={formatValue(item.newValue)} highlightBackground={false} />,
      },
      {
        id: "userName",
        header: "User Name",
        flex: 1.5,
        render: (item) => (
          <TextOverflowTooltip
            noOfLines={1}
            label={item.userName || `${item.userId === "system" ? "" : "Unknown : "}${item.userId}` || ""}
            highlightBackground={false}
          />
        ),
      },
      {
        id: "date",
        header: "Date",
        flex: 1.5,
        render: (item) => <TextOverflowTooltip noOfLines={1} label={formatDate(item.date)} highlightBackground={false} />,
      },
      {
        id: "additionalContext",
        header: "Additional Context",
        flex: 2,
        render: (item) => <TextOverflowTooltip noOfLines={1} label={item.additionalContext || ""} highlightBackground={false} />,
      },
    ],
    []
  );

  return (
    <Box overflowX="auto">
      <Flex direction="column" width="100%">
        <TableHeader columns={columns} titleColor={titleColor} bgColor={bgColor} />

        <Box overflowY="auto" maxHeight={maxHeight}>
          {changeLog.length > 0 ? (
            changeLog.map((item, index) => (
              <TableRow key={`${item.field}-${item.date}-${index}`} item={item} columns={columns} bgColor={bgColor} />
            ))
          ) : (
            <Box fontSize={"sm"} p={4} textAlign="left" color={titleColor}>
              No change log entries available
            </Box>
          )}
        </Box>
      </Flex>
    </Box>
  );
};
