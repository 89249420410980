import React, { useRef, useState, useCallback, useEffect } from "react";
import { useCopyValue } from "hooks/useCopies";
import {
  Box,
  Container,
  Heading,
  HStack,
  Image,
  List,
  ListItem,
  Stack,
  Text,
  useBreakpointValue,
  Spinner,
  Icon,
  Tooltip,
  useColorModeValue,
  Center,
} from "@chakra-ui/react";
import { CheckIcon } from "@chakra-ui/icons";
import { RegistrationBanner } from "components/RegistrationBanner";
import verifiedShield from "screens/common/static/misc/verified_by_ai.png";
import soc2logo from "screens/common/static/logos/soc2_logo.png";
import { RegisterForm } from "./components/RegisterForm";
import targetImage from "screens/common/static/misc/target_image.png";
import edgarLogo from "screens/common/static/logos/edgar_color.png";
import nasdaqLogo from "screens/common/static/logos/nasdaq_color.png";
import tsxLogo from "screens/common/static/logos/tsx_color.png";
import sedarLogo from "screens/common/static/logos/sedar_color.png";
import { useCustomScrollbar } from "hooks/useCustomScrollbar";
import { ConversationDialogInput } from "screens/thread/ConversationDialogInput";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { sendMessage } from "state/websocket/operations";
import { v4 as uuid } from "uuid";
import { useNavigate } from "react-router-dom";
import { ConversationContextProvider } from "screens/thread/ConversationContext";
import { HiOutlineRefresh } from "react-icons/hi";
import type { RootState } from "state/rootReducer";
import { TabTitle } from "screens/common/components/TabTitle";
import { useEquityPortfolioId, usePortfolioCollections, useUserProfile } from "hooks";
import { track } from "api/analytics";
import {
  REGISTRATION_EMAIL_EXISTS,
  REGISTRATION_POST_SETUP,
  REGISTRATION_ACCOUNT_SUBMITTED,
  REGISTRATION_TICKER_SKIPPED,
  REGISTRATION_TICKER_SUBMITTED,
} from "api/analytics/events";
import { updateTypedUserPreference } from "state/userPreference/operations";
import { APP_CONTAINER_WIDTH } from "screens/landing";

interface RegistrationProps {
  onSubmit: (fields: { firstName: string; lastName: string; username: string; investmentInterest: string; password: string }) => void;
  isPreview?: boolean;
}

interface RegistrationState {
  isCheckingAccount: boolean;
  isRegistered: boolean;
  isCreatingProject: boolean;
  loadingText: string;
  hasReceivedResponse: boolean;
}

export const Registration: React.FC<RegistrationProps> = ({ onSubmit, isPreview }) => {
  const [state, setState] = useState<RegistrationState>({
    isCheckingAccount: false,
    isRegistered: false,
    isCreatingProject: false,
    loadingText: "Preparing your account...",
    hasReceivedResponse: false,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const conversationId = useRef(uuid());
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const containerRef = useRef<HTMLDivElement>(null);
  const { scrollbarStyle } = useCustomScrollbar(containerRef, { width: "2px", barTransparency: 0.2 });
  const registrationHeading = useCopyValue("copy_registration_heading");
  const registrationSubheading = useCopyValue("copy_registration_subheading");
  const registrationListItems = useCopyValue("copy_registration_list_items");
  const registrationFooterText = useCopyValue("copy_registration_footer_text");
  const registrationCta = useCopyValue("copy_registration_cta");
  const { email, fullName } = useUserProfile();

  // Color mode values
  const bgColor = useColorModeValue("#fbfbfb", "gray.900");
  const textColor = useColorModeValue("gray.800", "gray.100");
  const boxBgColor = useColorModeValue("white", "gray.800");
  const headingColor = useColorModeValue("gray.700", "gray.200");
  const descriptionColor = useColorModeValue("gray.600", "gray.400");
  const borderColor = useColorModeValue("rgba(61, 61, 61, 0.25)", "rgba(255, 255, 255, 0.25)");
  const checkIconColor = useColorModeValue("gray.700", "gray.900");
  const headerBgColor = useColorModeValue("white", "gray.900");

  const colors = ["blue.300", "teal.300", "green.300", "orange.300", "purple.300"];
  const getItemColor = (index: number) => colors[index % colors.length];
  const { lastError } = useSelector((state: RootState) => state.session, shallowEqual);
  const inputRef = useRef<HTMLInputElement>(null);
  const portfolioCollections = usePortfolioCollections({ filterShared: true });
  const myEquitiesPortfolioId = useEquityPortfolioId(portfolioCollections, "My Equities");

  useEffect(() => {
    if (state.isRegistered && inputRef.current) {
      inputRef.current.focus();
    }
  }, [state.isRegistered]);

  // Track when we receive an API response
  useEffect(() => {
    if (state.isCheckingAccount && !state.hasReceivedResponse) {
      const timeoutId = setTimeout(() => {
        setState((prev) => ({ ...prev, hasReceivedResponse: true }));
      }, 5000); // Wait to ensure API has time to respond

      return () => clearTimeout(timeoutId);
    }
  }, [state.isCheckingAccount, state.hasReceivedResponse, lastError]);

  // Handle registration state changes
  useEffect(() => {
    if (!state.isCheckingAccount) return;

    if (state.hasReceivedResponse) {
      if (lastError?.includes("An account with email")) {
        track(REGISTRATION_EMAIL_EXISTS, { userName: fullName, email });
        // Error case - account exists
        setState((prev) => ({
          ...prev,
          isCheckingAccount: false,
          isRegistered: false,
        }));
      } else if (lastError === undefined) {
        // Send post registration setup message
        track(REGISTRATION_POST_SETUP, { userName: fullName, email });
        dispatch(
          sendMessage({
            conversationId: conversationId.current,
            intent: "/post_registration_setup",
          })
        );
      }
    }
  }, [lastError, dispatch, conversationId, state.isCheckingAccount, state.hasReceivedResponse, fullName, email]);

  useEffect(() => {
    if (isPreview) {
      setState((prev) => ({
        ...prev,
        isRegistered: false,
        isCheckingAccount: false,
      }));
      return;
    }
    const timeoutId = setTimeout(() => {
      // If portfolioCollections is still empty after 15 seconds, proceed anyway
      if (portfolioCollections.length === 0 && state.isCheckingAccount) {
        setState((prev) => ({
          ...prev,
          isRegistered: true,
          isCheckingAccount: false,
        }));
      }
    }, 15000);

    // If portfolioCollections has items, clear timeout and proceed immediately
    if (portfolioCollections.length > 0) {
      clearTimeout(timeoutId);
      setState((prev) => ({
        ...prev,
        isRegistered: true,
        isCheckingAccount: false,
      }));
    }

    return () => clearTimeout(timeoutId);
  }, [isPreview, portfolioCollections.length, state.isCheckingAccount]);

  // Reset hasReceivedResponse when starting a new registration attempt
  const handleRegistrationSubmit = useCallback(
    (fields: { firstName: string; lastName: string; username: string; investmentInterest: string; password: string }) => {
      track(REGISTRATION_ACCOUNT_SUBMITTED, { userName: fullName, email });
      setState((prev) => ({
        ...prev,
        isCheckingAccount: true,
        hasReceivedResponse: false,
      }));
      onSubmit(fields);
    },
    [email, fullName, onSubmit]
  );

  const DELAY_PERIOD = 10000; // delay before redirecting to home page

  const handleTickerSubmit = useCallback(() => {
    setState((prev) => ({ ...prev, isCreatingProject: true, loadingText: "Preparing your account..." }));

    const timer1 = setTimeout(() => {
      setState((prev) => ({ ...prev, loadingText: "Starting your first project..." }));
    }, DELAY_PERIOD * 0.22);
    const timer2 = setTimeout(() => {
      setState((prev) => ({ ...prev, loadingText: "Analyzing Initial Data Sources..." }));
    }, DELAY_PERIOD * 0.44);
    const timer3 = setTimeout(() => {
      setState((prev) => ({ ...prev, loadingText: "Loading your home page..." }));
    }, DELAY_PERIOD * 0.89);
    const timer4 = setTimeout(() => {
      track(REGISTRATION_TICKER_SUBMITTED, { userName: fullName, email });
      isMobile && dispatch(updateTypedUserPreference({ preferenceKey: "ui_onboarding_completed", value: true }));
      navigate("/");
    }, DELAY_PERIOD);

    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
      clearTimeout(timer3);
      clearTimeout(timer4);
    };
  }, [dispatch, email, fullName, isMobile, navigate]);

  const handleTickerSkip = useCallback(() => {
    track(REGISTRATION_TICKER_SKIPPED, {
      userName: fullName,
      email,
    });
    navigate("/");
  }, [email, fullName, navigate]);

  return (
    <Box bg={bgColor} fontFamily="Montserrat" overflow="auto" height={"100vh"} pb="2rem" css={scrollbarStyle}>
      <TabTitle title={`Charli > Register`} />
      <Box width="100%" bgColor={headerBgColor} borderBottom={`1px solid ${borderColor}`}>
        <Box mx={isMobile ? "1rem" : "auto"}>
          <Center>
            <RegistrationBanner disableLogin={state.isRegistered} viewType="login" />
          </Center>
        </Box>
      </Box>
      <Box pt={isMobile ? "2rem" : "2.2rem"} pb={0}>
        <Container maxW={APP_CONTAINER_WIDTH} fontFamily="Montserrat" px={isMobile ? 4 : 6}>
          <Stack direction={isMobile ? "column" : "row"} spacing={isMobile ? "0" : "6rem"} justify="space-between">
            <Box flex="7">
              <Stack spacing={"1rem"}>
                <Box pt={isMobile ? "0" : "4.6em"}>
                  <Text fontSize={isMobile ? "2rem" : "2.4rem"} color={textColor} fontWeight="600" lineHeight="1.2" mb={6}>
                    {registrationHeading}
                  </Text>
                  <Box pt="1rem">
                    <Text fontSize={isMobile ? "1.5rem" : "1.8rem"} color={textColor} fontWeight="700" lineHeight="1.2" mb={6}>
                      {registrationSubheading}
                    </Text>
                    <List spacing={"2rem"}>
                      {registrationListItems.map((item, index) => {
                        const [title, description] = item.split("|");
                        const isFirstItem = index === 0;
                        const isSecondItem = index === 1;
                        const isLastItem = index === registrationListItems.length - 1;

                        return (
                          <ListItem key={index} display="flex" alignItems="flex-start">
                            <Box
                              bg={getItemColor(index)}
                              color={checkIconColor}
                              borderRadius="full"
                              w="35px"
                              h="35px"
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                              mr={4}
                              flexShrink={0}>
                              {isLastItem ? (
                                <Icon as={HiOutlineRefresh} boxSize={"20px"} fontWeight={"semibold"} />
                              ) : state.isCreatingProject ? (
                                <CheckIcon boxSize={"20px"} fontWeight={"semibold"} />
                              ) : state.isRegistered && isFirstItem ? (
                                <CheckIcon boxSize={"20px"} fontWeight={"semibold"} />
                              ) : state.isRegistered && isSecondItem ? (
                                <Text fontWeight={"semibold"}>{index + 1}</Text>
                              ) : (
                                <Text fontWeight={"semibold"}>{index + 1}</Text>
                              )}
                            </Box>
                            <Box>
                              {title && (
                                <Text fontSize="1rem" color={textColor} fontWeight="600" mb={2}>
                                  {title}
                                </Text>
                              )}
                              {description && <Text color={descriptionColor}>{description}</Text>}
                            </Box>
                          </ListItem>
                        );
                      })}
                    </List>
                    <Text fontSize={isMobile ? "1.5rem" : "1.8rem"} color={textColor} fontWeight="700" lineHeight="1.2" mb={6} mt="3rem">
                      {registrationCta}
                    </Text>
                    {registrationFooterText.map((text, index) => (
                      <Text key={index} color={descriptionColor} pt={index > 0 ? "1rem" : undefined}>
                        {text}
                      </Text>
                    ))}
                  </Box>
                </Box>
              </Stack>
            </Box>
            <Box flex="5" mt={isMobile ? "6rem" : "8rem"} position={"relative"}>
              <Box position={"absolute"} top="-3.9rem" left="2.2rem" transform={"translateX(-50%)"} width="13rem">
                <Image src={targetImage} width="100%" opacity={0.6} />
              </Box>
              <Box bg={boxBgColor} borderRadius="xl" p={6} boxShadow="xl" width={isMobile ? "100%" : "420px"} mx="auto">
                <Stack spacing={6}>
                  <Heading zIndex={2} as="h3" size="lg" textAlign="left" color={headingColor}>
                    {state.isCreatingProject
                      ? "Creating your First Equity Project"
                      : state.isRegistered
                      ? "Step 2: Enter a stock to analyze"
                      : "Start your Automated Analysis:"}
                  </Heading>
                  {state.isCreatingProject ? (
                    <Stack spacing={4} align="center" p={6}>
                      <Spinner size="xl" />
                      <Text color={descriptionColor}>{state.loadingText}</Text>
                    </Stack>
                  ) : state.isRegistered ? (
                    <ConversationContextProvider>
                      <Box fontFamily="body">
                        <ConversationDialogInput
                          portfolioId={myEquitiesPortfolioId}
                          showToast={false}
                          minWidth="23rem"
                          minWidthList="25.3rem"
                          marginLeftList="-1rem"
                          defaultIsOpen
                          inputId="view-input"
                          afterSubmit={handleTickerSubmit}
                          initialText="Enter a ticker symbol to research"
                          renderRole="embedded"
                        />
                      </Box>
                    </ConversationContextProvider>
                  ) : (
                    <RegisterForm
                      onSubmit={handleRegistrationSubmit}
                      isCheckingAccount={state.isCheckingAccount}
                      usernameError={
                        lastError?.includes("An account with email") ? "An account with this email already exists." : undefined
                      }
                    />
                  )}
                </Stack>
              </Box>
              {state.isRegistered && (
                <Box position={"absolute"} textAlign="right" width="100%" top="32rem" pr="1rem">
                  <Text
                    as="button"
                    color="blue.500"
                    fontSize="sm"
                    fontWeight="medium"
                    _hover={{ textDecoration: "underline" }}
                    onClick={handleTickerSkip}>
                    Skip for now
                  </Text>
                </Box>
              )}
            </Box>
          </Stack>
          <Stack direction={isMobile ? "column" : "row"} justifyContent={"space-between"} mt="2rem" width="100%">
            <HStack align="center" justifyContent={"space-between"} width="100%">
              <Image src={nasdaqLogo} height={isMobile ? "1.5rem" : "2rem"} alt="NASDAQ Logo" />
              <Image src={tsxLogo} height={isMobile ? "3rem" : "4rem"} alt="TSX Logo" />
              <Image src={sedarLogo} height={isMobile ? ".9rem" : "1.1rem"} alt="Sedar Logo" />
              <Image src={edgarLogo} height={isMobile ? "2.5rem" : "4rem"} alt="Edgar Logo" />
            </HStack>
            <HStack justifyContent={"flex-end"} spacing="1rem" width="100%">
              <Tooltip
                maxWidth={"21rem"}
                label="Charli is SOC Type 2 compliant and designed to protect the security and privacy of data used by the system including protecting the privacy of equity research and analysis questions and answers."
                aria-label="SOC2 Certified">
                <Image src={soc2logo} height={isMobile ? "3rem" : "85px"} alt="SOC2 Logo" />
              </Tooltip>
              <Tooltip
                maxWidth={"21rem"}
                label="Charli leverages a highly accurate and trusted AI that is designed specifically for the Financial Services market and incorporates advanced Fact Check Analysis features to verify the results that are produced."
                aria-label="Verified by AI">
                <Image src={verifiedShield} height={isMobile ? "3rem" : "85px"} alt="SOC2 Logo" />
              </Tooltip>
            </HStack>
          </Stack>
        </Container>
      </Box>
    </Box>
  );
};
