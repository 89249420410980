import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  useColorMode,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { deleteAdminQuestion, getAdminQuestions, upsertAdminQuestion } from "api/adminQuestions";
import type { Question } from "api/adminQuestions/models/Question";
import { useButtonProps } from "hooks";
import reverse from "lodash/reverse";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { AdminTiles } from "screens/common/components";
import { UpsertQuestionModal } from "./components/UpsertQuestion";
import { useAnswerFocusOptions } from "hooks/useAnswerFocusOptions";

export const QuestionsTab = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [questions, setQuestions] = useState<Question[]>([]);
  const [selectedQuestion, setSelectedQuestion] = useState<Question | null>(null);
  const { isOpen: isOpenUpsert, onClose: onCloseUpsert, onOpen: onOpenUpsert } = useDisclosure();
  const { isOpen: isOpenDelete, onClose: onCloseDelete, onOpen: onOpenDelete } = useDisclosure();
  const toast = useToast();
  const cancelRef = useRef<HTMLButtonElement>(null);
  const commonButtonProps = useButtonProps("sm", "primary");
  const textColor = useColorModeValue("primary.darkGray", "gray.200");
  const { answerFocusOptions } = useAnswerFocusOptions("due_diligence");
  const { colorMode } = useColorMode();

  const fetchAdminQuestions = useCallback(() => {
    setIsLoading(true);
    getAdminQuestions()
      .then((quest) => setQuestions(reverse(quest)))
      .catch(console.error)
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    fetchAdminQuestions();
  }, [fetchAdminQuestions]);

  const handleUpsert = (values: Question) => {
    setIsLoading(true);
    upsertAdminQuestion(values)
      .then(() => {
        toast({
          title: "Question saved successfully",
          status: "success",
        });

        onCloseUpsert();
        fetchAdminQuestions();
      })
      .catch(console.error)
      .finally(() => setIsLoading(false));
  };

  const handleDelete = () => {
    if (!selectedQuestion) return;

    setIsLoading(true);
    deleteAdminQuestion(selectedQuestion.id)
      .then(() => {
        toast({
          title: "Question deleted successfully",
          status: "success",
        });

        onCloseDelete();
        setSelectedQuestion(null);

        fetchAdminQuestions();
      })
      .catch(console.error)
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <UpsertQuestionModal
        isLoading={isLoading}
        initialValue={selectedQuestion ?? undefined}
        isOpen={isOpenUpsert}
        onClose={() => {
          setSelectedQuestion(null);
          onCloseUpsert();
        }}
        onSubmit={handleUpsert}
      />

      <AlertDialog
        leastDestructiveRef={cancelRef}
        isOpen={isOpenDelete}
        onClose={() => {
          setSelectedQuestion(null);
          onCloseDelete();
        }}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="sm" fontWeight="bold">
              Delete Question
            </AlertDialogHeader>

            <AlertDialogBody fontSize="sm">Are you sure? You can't undo this action afterwards.</AlertDialogBody>

            <AlertDialogFooter>
              <Button isLoading={isLoading} {...commonButtonProps} ref={cancelRef} onClick={onCloseDelete}>
                Cancel
              </Button>
              <Button isLoading={isLoading} {...commonButtonProps} onClick={handleDelete} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <AdminTiles<Question & { lastUpdatedDate?: Date; lastUpdatedByUserName?: string }>
        items={questions}
        tileTitle="question"
        keyName="id"
        fieldsToRender={[]}
        filterByFields={["question"]}
        inputFilterPlaceholder="Filter by question"
        customFields={[
          {
            field: "Focus",
            value: ({ focus }) => {
              const focusOption = answerFocusOptions?.find((option) => option.key === focus);
              return (
                <Box
                  maxWidth={"5rem"}
                  textAlign={"center"}
                  borderRadius={"4px"}
                  height="1.2rem"
                  lineHeight={"1.2rem"}
                  px="5px"
                  fontSize={"10px"}
                  color={textColor}
                  bgColor={`${focusOption?.color}.${colorMode === "light" ? "200" : "800"}` || "gray.100"}>
                  {focusOption?.label || focus.toUpperCase()}
                </Box>
              );
            },
          },
        ]}
        hasRead
        hasWrite
        tileTitleKey="question"
        showViewType={false}
        viewType="table"
        isLoading={isLoading}
        showUpdatedInfo={false}
        onClickCreate={() => {
          setSelectedQuestion(null);
          onOpenUpsert();
        }}
        onClickDelete={(_, index) => {
          const question = questions[index];
          setSelectedQuestion(question);
          onOpenDelete();
        }}
        onClickEdit={(_, index) => {
          const question = questions[index];
          setSelectedQuestion(question);
          onOpenUpsert();
        }}
      />
    </>
  );
};
