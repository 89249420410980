import React, { useCallback } from "react";
import { Box, Text, Link, Stack, useBreakpointValue, useColorModeValue, Image } from "@chakra-ui/react";
import logo from "screens/common/static/logos/charli-logo-full.svg";
import { ReactComponent as CharliLogoFull } from "screens/common/static/logos/charli-logo-full.svg";
import { useNavigate } from "react-router-dom";
import { HEADER_HEIGHT, HEADER_ICON_HEIGHT } from "screens/landing";

interface RegistrationBannerProps {
  isLogin?: boolean;
  viewType?: "login" | "register" | "dealfinder";
  disableLogin?: boolean;
}

export const RegistrationBanner: React.FC<RegistrationBannerProps> = ({ isLogin = false, viewType, disableLogin }) => {
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const navigate = useNavigate();

  // Color mode values
  const bgColor = useColorModeValue("white", "gray.900");
  const textColor = useColorModeValue("gray.700", "gray.200");
  const disabledColor = useColorModeValue("gray.400", "gray.600");
  const linkColor = useColorModeValue("primary.600", "primary.300");
  const linkHoverColor = useColorModeValue("primary.default", "primary.100");

  const getViewType = useCallback(() => {
    switch (viewType) {
      case "dealfinder":
        return "Main App";
      case "login":
        return "Log In";
      case "register":
      default:
        return "Register";
    }
  }, [viewType]);

  return (
    <Box
      width="100vw"
      height={`${HEADER_HEIGHT}px`}
      background={bgColor}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      fontSize="sm"
      fontWeight="medium">
      <Stack width="100%" direction="row" justifyContent="space-between" paddingX={isMobile ? "0" : "2.2rem"}>
        <Box>
          {isMobile ? (
            <Image src={logo} alt="Charli AI" w="150px" cursor="pointer" onClick={() => window.open("https://charliai.com", "_blank")} />
          ) : (
            <CharliLogoFull
              className="sidebar-logo-button"
              style={{
                width: "100%",
                height: `${HEADER_ICON_HEIGHT - 4}px`,
                paddingLeft: "0.5rem",
              }}
              onClick={() => window.open("https://charliai.com", "_blank")}
              cursor="pointer"
            />
          )}
        </Box>
        <Stack
          fontSize="1rem"
          fontWeight="500"
          color={textColor}
          direction="row"
          spacing={isMobile ? "1rem" : "2rem"}
          fontFamily="Montserrat">
          <Text>
            <Link
              href="https://support.charli.ai/"
              target="_blank"
              rel="noopener noreferrer"
              color={linkColor}
              _hover={{ color: linkHoverColor }}>
              Support
            </Link>
          </Text>
          <Text>
            <Text
              color={disableLogin ? disabledColor : linkColor}
              _hover={{ color: linkHoverColor }}
              as="span"
              cursor={disableLogin ? "not-allowed" : "pointer"}
              onClick={() => {
                !disableLogin && navigate(viewType === "register" ? "/register" : viewType === "login" ? "/login" : "/home");
              }}>
              {getViewType()}
            </Text>
          </Text>
        </Stack>
      </Stack>
    </Box>
  );
};
