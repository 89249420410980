import React, { useEffect } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import type { Control } from "react-hook-form";
import type { FormValues } from "./ConfiguredWorkflowUpsertModal";
import { Flex, FormControl, Radio, RadioGroup, Stack } from "@chakra-ui/react";
import { CheckpointTypes } from "types/configuredWorkflows";
import startCase from "lodash/startCase";

interface IProps {
  checkpointIndex: number;
  control: Control<FormValues>;
  onRemove: (index: number) => void;
}

export const CheckpointType = ({ control, checkpointIndex, onRemove }: IProps) => {
  const { setValue } = useFormContext<FormValues>();
  const checkpointType = useWatch({ control, name: `config.checkpoints.${checkpointIndex}.type` });
  const checkpointLabel = useWatch({ control, name: `config.checkpoints.${checkpointIndex}.label` });

  useEffect(() => {
    if (checkpointType === "create_entities") {
      setValue(`config.checkpoints.${checkpointIndex}.showEntityToSplit`, false);
    }
  }, [checkpointType, checkpointIndex, setValue]);

  useEffect(() => {
    if (checkpointType === "milestone" && checkpointLabel === undefined) {
      setValue(`config.checkpoints.${checkpointIndex}.label`, "");
    }
  }, [checkpointType, checkpointIndex, checkpointLabel, setValue]);

  return (
    <Flex mb={"1rem"} alignItems={"center"} justifyContent={"space-between"}>
      <FormControl>
        <Controller
          render={({ field }) => (
            <RadioGroup {...field}>
              <Stack direction="row">
                {CheckpointTypes.map((type) => (
                  <Radio size="sm" key={type} value={type}>
                    {startCase(type)}
                  </Radio>
                ))}
              </Stack>
            </RadioGroup>
          )}
          name={`config.checkpoints.${checkpointIndex}.type`}
          control={control}
        />
      </FormControl>
    </Flex>
  );
};
