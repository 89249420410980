import { Box, Stack, Popover, PopoverTrigger, PopoverContent, PopoverBody, Text, useToast, useColorModeValue } from "@chakra-ui/react";
import React, { useCallback, useMemo } from "react";
import { getStatusColor } from "screens/common/components/WorkflowSummary";
import { WorkflowStatusIcon } from "screens/common/components/WorkflowStatusIcon";
import { useWorkflowMilestoneById, useWorkflowMilestonesIds } from "hooks/useWorkflowMilestones";
import { formatDate } from "screens/common/modal/formatters";
import { useWorkflowKey } from "hooks/useWorkflows";
import { WorkflowTaskStatus } from "types/workflows/workflow";
import { usePausedWorkflowModal } from "screens/collection/views/ProjectActions/PausedWorkflowModal";
import { useCollectionKey, useConfigMap, useGetViewConfig } from "hooks";
import { useDispatch } from "react-redux";
import { sendMessage } from "state/websocket/operations";
import { useNavigate } from "react-router-dom";
import { useConversationContext } from "screens/thread/ConversationContext";
import { ToastMessageContent } from "screens/common/components";
import capitalize from "lodash/capitalize";

const Milestone = ({
  milestoneId,
  previousMilestoneId,
  isProjectView,
  collectionId,
  workflowStatus,
  hasOneTimeSchedule,
}: {
  milestoneId: string;
  previousMilestoneId?: string;
  isProjectView: boolean;
  collectionId: string;
  workflowStatus?: WorkflowTaskStatus;
  hasOneTimeSchedule?: boolean;
}) => {
  const configMap = useConfigMap();
  const { onConversationOpen, setConversationId } = useConversationContext();
  const collectionConversationId = useCollectionKey(collectionId, "conversationId");
  const collectionType = useCollectionKey(collectionId, "collectionType");
  const projectRoute = useGetViewConfig("route", collectionType, configMap);
  const milestone = useWorkflowMilestoneById(milestoneId);
  const previousMilestone = useWorkflowMilestoneById(previousMilestoneId);
  const { onOpen: onOpenPausedWorkflowModal } = usePausedWorkflowModal();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const iconColor = useColorModeValue("200", "800");

  const status = milestone?.completionDate
    ? WorkflowTaskStatus.complete
    : !previousMilestone || previousMilestone.completionDate
    ? workflowStatus
    : undefined;

  const workflowStatusWithNotStarted = useMemo(() => {
    if (hasOneTimeSchedule) {
      return "not_started";
    } else if (!status) {
      return "not_started";
    } else {
      return status;
    }
  }, [hasOneTimeSchedule, status]);

  const onClickStepAction = useCallback(() => {
    if (!collectionConversationId) return;

    const processAction = (conversationId: string, intentAction?: string, entities?: { entity: string; value: unknown }[]) => {
      dispatch(
        sendMessage({
          conversationId,
          intent: intentAction,
          entities: entities,
          disableDebugging: true,
          disableTesting: true,
        })
      );

      setConversationId(conversationId);
      toast({
        render: ({ onClose }) => (
          <ToastMessageContent
            message={`Rerunning ${intentAction?.replace(/_+/g, " ")} step now. You can open the conversation by clicking this message`}
            onClick={() => {
              onConversationOpen();
              onClose();
            }}
            onClose={onClose}
          />
        ),
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    };

    const getPath = () => {
      if (projectRoute) {
        return navigate(`/${projectRoute}/${collectionId}`);
      } else {
        onConversationOpen();
      }
    };

    switch (status) {
      case "clarification_needed":
        setConversationId(collectionConversationId);
        onConversationOpen();
        break;
      case "failed":
      case "error":
        processAction(collectionConversationId, "/retry", [{ entity: "collection_id", value: collectionId }]);
        break;
      default:
        getPath();
    }
  }, [collectionConversationId, collectionId, dispatch, navigate, onConversationOpen, projectRoute, setConversationId, status, toast]);

  if (!milestone) {
    return null;
  } else {
    return (
      <Popover isLazy trigger="hover">
        <PopoverTrigger>
          <Box
            p="3px"
            borderRadius={"full"}
            backgroundColor={`${getStatusColor(workflowStatusWithNotStarted)}.${iconColor}`}
            border="none"
            height={isProjectView ? "2rem" : "unset"}
            className="ch-workflow-step"
            textAlign="left"
            cursor="pointer"
            onClick={() => {
              if (workflowStatus === "failed_checkstop") {
                onOpenPausedWorkflowModal(collectionId);
              } else {
                onClickStepAction();
              }
            }}>
            <Stack direction="row" justifyContent="left" alignItems="left">
              <WorkflowStatusIcon status={workflowStatusWithNotStarted} boxSize="15px" />
            </Stack>
          </Box>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverBody>
            <Stack>
              <Box pl="1rem" borderLeft="1px solid #718096">
                <Stack direction="row" align="center">
                  <Text fontSize="xs" as="em">{`${milestone.label}`}</Text>
                </Stack>
                <Stack direction="row" align="center">
                  <Text fontSize="xs">
                    {workflowStatusWithNotStarted === "in_progress"
                      ? "In Progress..."
                      : workflowStatusWithNotStarted === "not_started"
                      ? "Not Started"
                      : workflowStatusWithNotStarted === "complete"
                      ? "Completed: "
                      : capitalize(workflowStatusWithNotStarted).replaceAll("_", " ")}
                  </Text>
                  <Text fontSize="xs" as="em">{`${
                    milestone.completionDate ? formatDate(new Date(milestone.completionDate), "dd MM yyyy hh:mm") : ""
                  }`}</Text>
                </Stack>
              </Box>
            </Stack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    );
  }
};

export const MilestonesStepper = ({
  collectionId,
  workflowId,
  isProjectView,
  hasOneTimeSchedule,
}: {
  collectionId: string;
  workflowId: string;
  isProjectView: boolean;
  hasOneTimeSchedule?: boolean;
}) => {
  const milestonesIds = useWorkflowMilestonesIds(workflowId);
  const workflowStatus = useWorkflowKey(workflowId, "status");

  return (
    <>
      {milestonesIds.map((milestone, index) => (
        <Milestone
          {...(index > 0 && { previousMilestoneId: milestonesIds[index - 1] })}
          key={milestone}
          collectionId={collectionId}
          milestoneId={milestone}
          isProjectView={isProjectView}
          workflowStatus={workflowStatus}
          hasOneTimeSchedule={hasOneTimeSchedule}
        />
      ))}
    </>
  );
};
