import React from "react";
import { Box, Stack, useBreakpointValue, useColorModeValue, Text, Center, useColorMode } from "@chakra-ui/react";
import { TextOverflowTooltip } from "screens/landing/components/TextOverflowTooltip";
import { UpgradePlanButton } from "screens/panels/settings/tabs/subscription/SubscriptionUpgrade";
import { useFocusBadgeColors } from "./hooks";
import type { SuggestedQuestion } from "api/suggestions";
import { useAnswerFocusOptions } from "hooks/useAnswerFocusOptions";
import type { SuggestionTypes } from "./types";

interface SuggestionItemProps {
  suggestion: SuggestedQuestion & { id: string };
  value?: string;
  isDisabled: boolean;
  textColor: string;
  betaBgColor: string;
  suggestionType?: SuggestionTypes;
}

export const SuggestionItem: React.FC<SuggestionItemProps> = ({
  suggestion,
  value,
  isDisabled,
  textColor,
  betaBgColor,
  suggestionType,
}) => {
  const { question, label, focus, type, status } = suggestion;
  const { answerFocusOptions } = useAnswerFocusOptions("due_diligence");
  const filteredFocusData = answerFocusOptions?.filter((option) => option.key.toLowerCase() === focus?.toLowerCase())[0];
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const colors = useFocusBadgeColors();
  const typeBgColor = useColorModeValue("green.100", "green.800");
  const focusBgColor = useColorModeValue("gray.200", "gray.600");
  const privateBgColor = useColorModeValue("purple.100", "pink.600");
  const isPrivateListing = focus?.toUpperCase().includes("PRIVATE");
  const upgradeBgColor = useColorModeValue("yellow.300", "yellow.800");
  const { colorMode } = useColorMode();

  return (
    <>
      {suggestionType === "tickers" ? (
        <Stack
          direction="row"
          justifyContent="space-between"
          spacing="0"
          alignItems="left"
          width="100%"
          cursor={isDisabled ? "not-allowed" : "pointer"}>
          <Stack spacing={0}>
            <TextOverflowTooltip
              style={{
                opacity: isDisabled ? 0.6 : 1,
              }}
              className="ch-autocomplete-suggestion-text"
              noOfLines={1}
              label={(label || question.replace("|", "")).split("(")[0]}
              searchText={value}
              highlightBackground={false}
            />
            <Stack direction="row" spacing={"5px"} alignItems="center">
              {suggestion.matchFilter && (
                <Box
                  opacity={isDisabled ? 0.6 : 1}
                  className="ch-autocomplete-suggestion-ticker"
                  borderRadius="4px"
                  py="2px"
                  fontSize="10px"
                  fontWeight={"semibold"}
                  color={textColor}>
                  {suggestion.matchFilter[0].toUpperCase().replace(/_/g, " ")}
                </Box>
              )}
              <Text fontSize="10px" color={textColor}>
                :
              </Text>
              {focus && (
                <Box
                  opacity={isDisabled ? 0.6 : 1}
                  className="ch-autocomplete-suggestion-tag"
                  borderRadius="4px"
                  py="2px"
                  fontSize="10px"
                  color={textColor}>
                  {focus.toUpperCase().replace(/_/g, " ")}
                </Box>
              )}
            </Stack>
          </Stack>
          <Center>
            {type && (
              <Box
                opacity={isDisabled ? 0.6 : 1}
                className="ch-autocomplete-suggestion-type"
                borderRadius="4px"
                py="2px"
                px="5px"
                fontSize="10px"
                color={textColor}
                whiteSpace="nowrap"
                background={isPrivateListing ? privateBgColor : colors[type.toUpperCase()] || typeBgColor}>
                {isPrivateListing
                  ? focus?.toUpperCase() || "PRIVATE"
                  : isMobile
                  ? type.toUpperCase().slice(0, 1)
                  : type.toUpperCase().replace(/_/g, " ")}
              </Box>
            )}
            {status === "beta" && (
              <Box
                opacity={isDisabled ? 0.6 : 1}
                className="ch-autocomplete-suggestion-type"
                borderRadius="4px"
                ml="0.5rem"
                py="2px"
                px="5px"
                fontSize="10px"
                color={textColor}
                whiteSpace="nowrap"
                background={betaBgColor}>
                BETA
              </Box>
            )}
            {isDisabled && (
              <UpgradePlanButton
                tooltip="Upgrade to use this ticker"
                placement="left"
                buttonSize="xs"
                style={{
                  fontSize: "10px",
                  fontWeight: "normal",
                  color: textColor,
                  backgroundColor: upgradeBgColor,
                  ml: "0.5rem",
                  py: "2px",
                  px: "5px",
                  height: "unset",
                }}
              />
            )}
          </Center>
        </Stack>
      ) : (
        <Stack
          direction="row"
          justifyContent="space-between"
          spacing="2rem"
          alignItems="center"
          width="100%"
          cursor={isDisabled ? "not-allowed" : "pointer"}>
          <TextOverflowTooltip
            style={{
              opacity: isDisabled ? 0.6 : 1,
            }}
            className="ch-autocomplete-suggestion-text"
            noOfLines={isMobile ? 1 : 2}
            label={label || question.replace("|", "")}
            searchText={value}
            highlightBackground={false}
          />
          {focus && (
            <Box
              opacity={isDisabled ? 0.6 : 1}
              className="ch-autocomplete-suggestion-tag"
              borderRadius="4px"
              py="2px"
              px="5px"
              fontSize="10px"
              color={textColor}
              textAlign={"center"}
              bgColor={filteredFocusData ? `${filteredFocusData.color}.${colorMode === "light" ? "200" : "800"}` : focusBgColor}>
              <Text fontSize="10px" color={textColor}>
                {filteredFocusData ? filteredFocusData.label.toUpperCase() : focus.toUpperCase().replace(/_/g, " ")}
              </Text>
            </Box>
          )}
        </Stack>
      )}
    </>
  );
};
