import type { ChakraProps } from "@chakra-ui/react";
import { Box, Stack, Text, useColorModeValue, useDisclosure, useToast } from "@chakra-ui/react";
import type { Copy } from "api/copies/models/Copy";
import { formatDistanceToNow } from "date-fns";
import { useAppDispatch, useEntitlementKey } from "hooks";
import { useCopies, useCopiesDictionary, useIsLoadingCopies } from "hooks/useCopies";
import React, { useMemo, useState } from "react";
import { PiBroomLight } from "react-icons/pi";
import { AdminTiles } from "screens/common/components";
import { ConfirmDeleteModal } from "screens/common/components/ConfirmDeleteModal";
import { JSONSchemaViewer } from "screens/common/components/JsonSchemaViewer";
import { removeCopy, upsertCopy } from "state/copies/operations";
import { CopiesKeys, defaultCopiesValues, isDefinedCopyKey } from "types/copy";
import type { UpsertCopyForm } from "./components/UpsertCopy";
import { UpsertCopyModal } from "./components/UpsertCopy";
import { ShowMoreContainer } from "screens/common/components/ShowMoreContainer";
import { MarkdownViewer } from "screens/markdown/MarkdownViewer";

export type CopyWithAdditionalProps = Copy & { isMissing?: boolean };

export const CopiesTab = () => {
  const dispatch = useAppDispatch();
  const isLoading = useIsLoadingCopies();
  const copies = useCopies();
  const copiesDictionary = useCopiesDictionary();
  const [selectedCopy, setSelectedCopy] = useState<CopyWithAdditionalProps | null>(null);
  const { isOpen: isOpenUpsert, onClose: onCloseUpsert, onOpen: onOpenUpsert } = useDisclosure();
  const { isOpen: isOpenDelete, onClose: onCloseDelete, onOpen: onOpenDelete } = useDisclosure();
  const toast = useToast();
  const hasCopiesWrite = useEntitlementKey("manage_copies_write");
  const bgColor = useColorModeValue("#fbfbfb", "gray.800");
  const textColor = useColorModeValue("primary.darkGray", "gray.400");

  const handleUpsert = async (values: UpsertCopyForm) => {
    const response = await dispatch(
      upsertCopy({
        key: `${values.key}`,
        value: values.type === "string" ? values.stringValue ?? "" : (values.listValue || []).map((v) => v.value),
      })
    );

    if (response.type === upsertCopy.rejected.type) {
      toast({
        title: "Failed to save preference",
        status: "error",
      });
    } else {
      toast({
        title: "Preference saved successfully",
        status: "success",
      });
    }
  };

  const handleDelete = async (copyKey: string) => {
    const response = await dispatch(removeCopy(copyKey));

    if (response.type === removeCopy.rejected.type) {
      toast({
        title: "Failed to delete copy",
        status: "error",
      });
    } else {
      toast({
        title: "Preference deleted successfully",
        status: "success",
      });
    }
  };

  const handleRestoreDefault = async (copyKey: string) => {
    const response = await dispatch(removeCopy(copyKey));

    if (response.type === removeCopy.rejected.type) {
      toast({
        title: "Failed to restore preference to default",
        status: "error",
      });
    } else {
      toast({
        title: "Preference restored to default successfully",
        status: "success",
      });
    }
  };

  const localAndExistingCopies = useMemo(() => {
    const copiesToCreate = CopiesKeys.flatMap((key) => {
      if (!copiesDictionary[key]) {
        return [
          {
            isMissing: true,
            key,
            value: defaultCopiesValues[key],
            createdAt: new Date().toISOString(),
            createdByUser: "N/A",
          },
        ];
      } else {
        return [];
      }
    });

    return [...copiesToCreate, ...copies];
  }, [copies, copiesDictionary]);

  return (
    <>
      <UpsertCopyModal
        isLoading={isLoading}
        initialValue={selectedCopy ?? undefined}
        isOpen={isOpenUpsert}
        onClose={() => {
          setSelectedCopy(null);
          onCloseUpsert();
        }}
        onSubmit={handleUpsert}
      />

      <ConfirmDeleteModal
        isLoading={isLoading}
        isOpen={isOpenDelete}
        title="Delete Preference"
        body={`Are you sure you want to delete the preference "${selectedCopy?.key}"?`}
        onClose={() => {
          setSelectedCopy(null);
          onCloseDelete();
        }}
        onConfirm={() => {
          if (!selectedCopy) {
            return;
          }

          handleDelete(selectedCopy?.key).then(() => {
            setSelectedCopy(null);
            onCloseDelete();
          });
        }}
      />

      <AdminTiles<CopyWithAdditionalProps & { lastUpdatedDate?: Date; lastUpdatedByUserName?: string }>
        items={localAndExistingCopies}
        onClickHelp={() => {
          const url = "https://charliai.atlassian.net/wiki/spaces/CHARLIAI/pages/2146533377/Delivery+Copy+Definitions";
          window.open(url, "_blank");
        }}
        tileTitle="Delivery Preferences"
        tileTitleKey="key"
        showKeyInList={false}
        keyName="key"
        fieldsToRender={[]}
        filterByFields={["key"]}
        inputFilterPlaceholder="Filter by key"
        defaultSortByKey={"key"}
        defaultOrderBy={"asc"}
        customOptions={[
          {
            label: "Clear value to default",
            icon: PiBroomLight,
            hasPermission: true,
            onClick: (row) => {
              handleRestoreDefault(row.key);
            },
          },
        ]}
        customFields={[
          {
            field: "Key",
            width: "18rem",
            value(row) {
              const labelAndColor: {
                label: string;
                color: ChakraProps["bg"];
              } = row.isMissing
                ? {
                    label: "DEFAULT",
                    color: "green.100",
                  }
                : !isDefinedCopyKey(row.key)
                ? {
                    label: "UNUSED",
                    color: "gray.200",
                  }
                : {
                    label: "EDITED",
                    color: "orange.100",
                  };

              return (
                <Stack direction="row">
                  <Box textAlign="center" borderRadius={"md"} width="3.6rem" fontSize={"10px"} color="gray.700" bg={labelAndColor.color}>
                    {labelAndColor.label}
                  </Box>
                  <Text maxWidth={"17rem"}>{row.key}</Text>
                </Stack>
              );
            },
          },
          {
            field: "Value",
            value(row) {
              // Handle string values
              if (typeof row.value === "string") {
                try {
                  // Check if the string is valid JSON
                  const parsedJson = JSON.parse(row.value);
                  return (
                    <Box maxWidth="100%" maxHeight={"8rem"} overflowY="auto">
                      <JSONSchemaViewer value={parsedJson} height="auto" simpleView backgroundColor={bgColor} />
                    </Box>
                  );
                } catch (e) {
                  // If not valid JSON, return as string
                  return row.value;
                }
              }

              // Handle array values that might contain JSON strings
              if (Array.isArray(row.value)) {
                try {
                  // Try to parse the first item as JSON
                  if (row.value.length > 0 && typeof row.value[0] === "string") {
                    const parsedJson = JSON.parse(row.value[0]);
                    if (typeof parsedJson === "object" && parsedJson !== null) {
                      // If it's valid JSON, return a JSONSchemaViewer for each item in the array
                      return (
                        <ShowMoreContainer height="5rem">
                          <Stack spacing={0}>
                            {row.value.map((item, index) => {
                              try {
                                const parsedItem = JSON.parse(item);
                                return (
                                  <Box key={index} maxWidth="100%">
                                    <JSONSchemaViewer value={parsedItem} height="auto" simpleView backgroundColor={bgColor} />
                                  </Box>
                                );
                              } catch (e) {
                                return <Text key={index}>{item}</Text>;
                              }
                            })}
                          </Stack>
                        </ShowMoreContainer>
                      );
                    }
                  }
                } catch (e) {
                  // If parsing fails, fallback to standard join with newlines
                }
                // If no JSON was found or parsing failed, use the default array handling
                return (
                  <ShowMoreContainer height="5rem">
                    <MarkdownViewer backgroundColor={bgColor} fontColor={textColor} content={row.value.join("\n")} fontSize="sm" />
                  </ShowMoreContainer>
                );
              }

              return (
                <ShowMoreContainer height="5rem">
                  <MarkdownViewer backgroundColor={bgColor} fontColor={textColor} content={row.value} fontSize="sm" />
                </ShowMoreContainer>
              );
            },
          },
          {
            field: "Updated at",
            width: "10rem",
            value(row) {
              return row.updatedAt
                ? formatDistanceToNow(new Date(row.updatedAt), {
                    addSuffix: true,
                    includeSeconds: true,
                  })
                : "N/A";
            },
          },
          {
            field: "Updated by",
            width: "10rem",
            value(row) {
              return row.updatedByUser || "N/A";
            },
          },
        ]}
        hasRead
        hasWrite={hasCopiesWrite}
        showViewType={false}
        viewType="table"
        isLoading={isLoading}
        showUpdatedInfo={false}
        onClickDelete={(key) => {
          if (isDefinedCopyKey(key)) {
            toast({
              title: "Cannot delete a copy that is used in the webapp",
              status: "error",
            });
          } else {
            const copy = localAndExistingCopies.find((c) => c.key === key);
            if (copy) {
              setSelectedCopy(copy);
              onOpenDelete();
            }
          }
        }}
        onClickEdit={(id) => {
          const copy = localAndExistingCopies.find((c) => c.key === id);
          if (copy) {
            setSelectedCopy(copy);
            onOpenUpsert();
          }
        }}
      />
    </>
  );
};
