import { Stack, Text, Box, useBreakpointValue, useColorModeValue } from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import React from "react";
import ECHighlighter from "react-ec-highlighter";
import { BlockSectionHeader } from "screens/content/contentView/previewSection/BlockSectionHeader";
import { SmallActionConfidenceScore } from "screens/content/contentCanvas/cell/SmallActionConfidenceScore";
import { MarkupTextViewer } from "screens/content/contentView/sections/MarkupTextViewer";
import type { AssessmentResultsPolicies as Policies } from "types/collection";

export interface Props {
  assessmentResults: Policies[];
  searchText?: string;
  subSection?: boolean;
}

export const AssessmentResultsPolicies: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  assessmentResults,
  searchText,
  subSection,
}) => {
  const textColor = useColorModeValue("primary.darkGray", "gray.400");
  const bgColor = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  return (
    <>
      {assessmentResults.length > 0 && (
        <Stack p={isMobile ? ".5rem" : "1rem"} width="100%" bg={bgColor} border="1px solid" borderColor={borderColor} borderRadius="md">
          <BlockSectionHeader title="Mapped Policies" subSection={subSection} />
          {assessmentResults.map((policy, index) => (
            <Stack key={index}>
              <Stack direction="row" justifyContent={"space-between"} width="100%">
                <Text
                  color={textColor}
                  fontSize="sm"
                  className="ch-assessment-result-mapped-policies"
                  key={index}
                  width="100%"
                  fontWeight="semibold">
                  {searchText && searchText.length > 0 && policy.topic ? (
                    <ECHighlighter searchPhrase={searchText} text={policy.topic} />
                  ) : (
                    policy.topic
                  )}
                </Text>
                {policy.score && (
                  <SmallActionConfidenceScore
                    score={Math.round(Number(policy.score) * 100)}
                    tooltip={`Confidence score ${Math.round(Number(policy.score) * 100)}`}
                  />
                )}
              </Stack>
              <Box fontSize="sm" key={index} width="100%" pb=".5rem" paddingInlineEnd={"1rem"}>
                {policy.content
                  .split("\n")
                  .map(
                    (sentence, index) =>
                      sentence.length > 0 && <MarkupTextViewer searchText={searchText} key={index} markupText={sentence} />
                  )}
              </Box>
            </Stack>
          ))}
        </Stack>
      )}
    </>
  );
};
