import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import React, { useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useCollectionKey, useCollections } from "hooks/useCollections";
import type { RequestEntities } from "types/charliui";
import { sendMessage } from "state/websocket/operations";
import { useButtonProps } from "hooks/useCommonProps";
import { CreatableSelect } from "chakra-react-select";
import { useConversationContext } from "screens/thread/ConversationContext";
import { useEntitlementConfigs } from "hooks/useEntitlementConfigs";
import { ToastMessageContent } from "screens/common/components";
import { useUserProfile } from "hooks";

export const SharePortfolioModal = ({
  onClose,
  isOpen,
  conversationId,
}: {
  onClose: () => void;
  isOpen: boolean;
  conversationId: string;
}) => {
  const primaryButtonProps = useButtonProps("sm", "primary");
  const secondaryButtonProps = useButtonProps("sm", "secondary");
  const [shareGroup, setShareGroup] = useState<string | undefined>(undefined);
  const [shareEntitlement, setShareEntitlement] = useState<string | undefined>(undefined);
  const allWebAppEntitlements = useEntitlementConfigs();
  const toast = useToast();
  const dispatch = useDispatch();
  const collections = useCollections();
  const textColor = useColorModeValue("primary.darkGray", "gray.400");
  const { sharePortfolioId, setSharePortfolioId } = useConversationContext();
  const portfolioName = useCollectionKey(sharePortfolioId, "name");
  const { id: currentUserId } = useUserProfile();
  const currentSharedDetails = useCollectionKey(sharePortfolioId, "shareDetails");

  const isCurrentProjectShared = useMemo(() => {
    return currentSharedDetails?.ownerId === currentUserId;
  }, [currentSharedDetails?.ownerId, currentUserId]);

  const existingGroups = useMemo(() => {
    const groups = new Set<string>();
    collections.forEach((collection) => {
      if (collection.shareDetails?.group) {
        groups.add(collection.shareDetails.group);
      }
    });
    return Array.from(groups);
  }, [collections]);

  const onCloseModal = useCallback(() => {
    onClose();
    setSharePortfolioId(undefined);
  }, [onClose, setSharePortfolioId]);

  const onShareProject = useCallback(() => {
    if (!sharePortfolioId || (!isCurrentProjectShared && !shareGroup)) return;
    onCloseModal();

    const entities: RequestEntities = [
      { entity: "collection_id", value: sharePortfolioId },
      { entity: "share_status", value: isCurrentProjectShared ? "not_shared" : "all_users" },
    ];

    if (shareEntitlement && shareEntitlement !== "all_users" && !isCurrentProjectShared) {
      entities.push({ entity: "share_entitlement", value: shareEntitlement });
    }
    if (shareGroup && !isCurrentProjectShared) {
      entities.push({ entity: "share_group", value: shareGroup });
    }

    dispatch(
      sendMessage({
        conversationId,
        intent: "/update_portfolio_share_status",
        entities,
      })
    );

    toast({
      render: ({ onClose: onCloseToast }) => (
        <ToastMessageContent
          message={`I'll ${
            isCurrentProjectShared ? "stop sharing" : "share"
          } the portfolio ${portfolioName} in the portfolio group ${shareGroup} now. ${
            isCurrentProjectShared
              ? "No users will be able to view this portfolio"
              : `All users with the entitlement ${shareEntitlement} will be able to view it`
          }.`}
          onClick={() => {
            onCloseToast();
          }}
          onClose={onCloseToast}
        />
      ),
      duration: 5000,
      isClosable: true,
      position: "top-right",
    });

    setSharePortfolioId(undefined);
  }, [
    sharePortfolioId,
    isCurrentProjectShared,
    shareGroup,
    onCloseModal,
    shareEntitlement,
    dispatch,
    conversationId,
    toast,
    setSharePortfolioId,
    portfolioName,
  ]);

  return (
    <Modal onClose={onCloseModal} isOpen={isOpen} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader color={textColor}>{`${isCurrentProjectShared ? "Un-S" : "S"}hare Portfolio`}</ModalHeader>
        <ModalCloseButton />
        <ModalBody fontSize="sm" color={textColor}>
          {isCurrentProjectShared ? (
            <Text>{`Are you sure you want to stop sharing the portfolio ${portfolioName} thats currently being shared in ${currentSharedDetails?.group}?`}</Text>
          ) : (
            <Stack spacing={"2rem"}>
              <Stack>
                <Text>Choose an existing portfolio group or enter a new one.</Text>
                <CreatableSelect
                  classNamePrefix="ch-share-group"
                  size="sm"
                  placeholder=""
                  onChange={(value) => value && setShareGroup(value.value)}
                  options={existingGroups.map((group) => ({
                    label: group,
                    value: group,
                  }))}
                />
              </Stack>
              <Stack>
                <Text>Select or enter an entitlement.</Text>
                <Select
                  className="ch-share-entitlement"
                  size="sm"
                  value={currentSharedDetails?.entitlement}
                  onChange={(e) => setShareEntitlement(e.target.value)}>
                  <option value="all_users">Share Portfolio with All users</option>
                  {allWebAppEntitlements
                    .filter((entitlement) => entitlement.code.startsWith("view_shared_portfolios_"))
                    .map((entitlement) => (
                      <option key={entitlement.code} value={entitlement.code}>
                        {entitlement.name}
                      </option>
                    ))}
                </Select>
              </Stack>
            </Stack>
          )}
        </ModalBody>
        <ModalFooter>
          <Stack spacing={6} width="100%">
            <Stack direction="row" spacing="1rem" justifyContent="flex-end">
              <Button {...secondaryButtonProps} onClick={onCloseModal}>
                Cancel
              </Button>
              <Button
                className="ch-share-project-confirm"
                {...primaryButtonProps}
                onClick={onShareProject}
                isDisabled={!isCurrentProjectShared ? !shareGroup : false}>
                Confirm
              </Button>
            </Stack>
          </Stack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
