import React, { useCallback, useMemo, useRef, useState } from "react";

import { addResource, editResource, removeResource } from "state/informationExtractionConfig/operations";
import { AdminTiles } from "screens/common/components";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Input,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { UpsertInformationExtractionConfigPanel } from "./components/UpsertInformationExtractionConfig";
import { useAppDispatch, useButtonProps, useEntitlements } from "hooks";
import type { FormValues } from "./components/UpsertInformationExtractionConfig";
import type { ResourceWithId } from "state/informationExtractionConfig/reducer";
import { useIsLoadingInformationExtractionConfig, useResources } from "hooks/useInformationExtractionConfig";
import { v4 } from "uuid";
import { AiOutlineDownload } from "react-icons/ai";
import { FaList } from "react-icons/fa";
import { EventLogsPanel } from "../shared/EventLogsPanel";
import { getResourceLogs } from "api/informationExtractionConfig";
import { getEnvironment } from "screens/common/app";
import { defaultDocumentConfig } from "screens/landing/tabs/admin/informationExtractionConfig/utils/defaultDocumentConfig";
import { defaultJsonConfig } from "screens/landing/tabs/admin/informationExtractionConfig/utils/defaultJsonConfig";
import { ImportInformationExtraction } from "./components/ImportInformationExtraction";
import type { UpsertResourceRequest } from "api/informationExtractionConfig/models/api/CreateResourceRequest";
import { BiCopy } from "react-icons/bi";

const VIEW_TITLE = "Information Extraction Config";

export const validateExactMatching = (entityType: string | undefined, exactMatching: boolean) =>
  entityType?.toLowerCase().includes("categorical") ? exactMatching : false;

export const mapToCreateResourceRequest = (values: FormValues): UpsertResourceRequest => {
  return {
    urn: values.urn,
    category: values.category,
    visibility: values.visibility,
    keywords: values.keywords?.split("\n") ?? [],
    confidenceThreshold: Number(values.confidenceThreshold),
    documentConfig: JSON.parse(values.documentConfig),
    jsonConfig: JSON.parse(values.jsonConfig),
    ...(values.entities.length > 0 && {
      entities: values.entities.map((entity) => ({
        id: v4(),
        resourceUrn: values.urn,
        entityName: entity.entityName,
        entityType: entity.entityType.split(","),
        keywords: entity.keywords ? entity.keywords.split("\n") : undefined,
        pattern: entity.pattern ?? undefined,
        categories: entity.categories ? JSON.parse(entity.categories) : undefined,
        allowMultipleValues: entity.allowMultipleValues,
        occurrence: entity.occurrence,
        titleKeywords: entity.titleKeywords ? entity.titleKeywords.split("\n") : undefined,
        confidenceThreshold: entity.confidenceThreshold ? Number(entity.confidenceThreshold) : undefined,
        negativeKeywords: entity.negativeKeywords ? entity.negativeKeywords.split("\n") : undefined,
        exactMatching: validateExactMatching(entity.entityType, entity.exactMatching),
      })),
    }),
  };
};

export const mapToInitialValues = (resource?: ResourceWithId): FormValues => {
  if (resource) {
    return {
      urn: resource.urn,
      category: resource.category,
      visibility: resource.visibility,
      keywords: resource.keywords.join("\n").trimEnd(),
      confidenceThreshold: resource.confidenceThreshold,
      documentConfig: JSON.stringify(resource.documentConfig, null, 2),
      jsonConfig: JSON.stringify(resource.jsonConfig, null, 2),
      entities: (resource.entities || []).map((entity) => ({
        id: v4(),
        entityId: entity.id,
        entityName: entity.entityName,
        entityType: entity.entityType.join(","),
        keywords: entity.keywords?.join("\n").trimEnd() ?? "",
        pattern: entity.pattern ?? "",
        categories: JSON.stringify(entity.categories ?? {}, null, 2),
        allowMultipleValues: entity.allowMultipleValues,
        occurrence: entity.occurrence,
        titleKeywords: entity.titleKeywords?.join("\n").trimEnd() ?? "",
        negativeKeywords: entity.negativeKeywords?.join("\n").trimEnd() ?? "",
        confidenceThreshold: entity.confidenceThreshold ?? undefined,
        exactMatching: entity.exactMatching ?? false,
        lastUpdatedDate: typeof entity.lastUpdatedDate === "string" ? new Date(entity.lastUpdatedDate) : entity.lastUpdatedDate,
      })),
    };
  } else {
    return {
      urn: "",
      category: "",
      keywords: "",
      visibility: "public",
      confidenceThreshold: 0,
      documentConfig: JSON.stringify(defaultDocumentConfig, null, 2),
      jsonConfig: JSON.stringify(defaultJsonConfig, null, 2),
      entities: [],
    };
  }
};

export const InformationExtractionConfig = () => {
  const { manage_information_extraction_config_service_read: hasRead, manage_information_extraction_config_service_write: hasWrite } =
    useEntitlements();

  const resources = useResources({ refreshFromNetwork: true });
  const [isOpen, setIsOpen] = useState<"upsert" | "delete" | "logs" | "import" | null>();
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const [initialValues, setInitialValues] = useState<FormValues | undefined>();
  const [importData, setImportData] = useState<ResourceWithId | undefined>();

  const dispatch = useAppDispatch();
  const cancelRef = useRef<HTMLButtonElement>(null);
  const toast = useToast();
  const commonButtonProps = useButtonProps("sm", "primary");
  const isLoading = useIsLoadingInformationExtractionConfig();
  const secondaryButtonProps = useButtonProps("sm", "secondary");

  const onClose = useCallback(() => {
    setIsOpen(null);
    setSelectedId(null);
    setInitialValues(undefined);
    setImportData(undefined);
  }, []);

  const onOpenUpsert = useCallback(
    (id?: string) => {
      if (id) {
        const resource = resources.find((r) => r.id === id);
        if (!resource) {
          return;
        }

        setInitialValues(mapToInitialValues(resource));
        setSelectedId(id);
      }

      setIsOpen("upsert");
    },
    [resources]
  );

  const onOpenDelete = useCallback((id: string) => {
    setSelectedId(id);
    setIsOpen("delete");
  }, []);

  const onOpenImport = useCallback((resource: ResourceWithId) => {
    setImportData(resource);
    setIsOpen("import");
  }, []);

  const onSubmit = useCallback(
    async (values: FormValues, previousResource?: ResourceWithId) => {
      const upsertResponse = await (() => {
        if (selectedId) {
          return dispatch(
            editResource({
              id: selectedId,
              payload: {
                ...(values.category !== previousResource?.category && { category: values.category }),
                ...(values.visibility !== previousResource?.visibility && { visibility: values.visibility }),
                ...(values.keywords !== previousResource?.keywords.join("\n") && { keywords: values.keywords.split("\n") }),
                ...(values.confidenceThreshold !== previousResource?.confidenceThreshold && {
                  confidenceThreshold: Number(values.confidenceThreshold),
                }),
                ...(values.documentConfig !== previousResource?.documentConfig && {
                  documentConfig: JSON.parse(values.documentConfig),
                }),
                ...(values.jsonConfig !== previousResource?.jsonConfig && { jsonConfig: JSON.parse(values.jsonConfig) }),
              },
            })
          );
        } else {
          return dispatch(addResource(mapToCreateResourceRequest(values)));
        }
      })();

      if (upsertResponse.type === addResource.rejected.type || upsertResponse.type === editResource.rejected.type) {
        toast({
          title: VIEW_TITLE,
          description: `Error when creating / updating ${VIEW_TITLE}`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });

        return;
      }

      toast({
        title: VIEW_TITLE,
        description: `Successfully ${selectedId ? "updated" : "created"} ${VIEW_TITLE}`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      onClose();
    },
    [dispatch, onClose, selectedId, toast]
  );

  const onDelete = useCallback(async () => {
    if (!selectedId) {
      return;
    }

    const deleteResponse = await dispatch(removeResource(selectedId));

    if (deleteResponse.type === removeResource.rejected.type) {
      toast({
        title: VIEW_TITLE,
        description: "Error when deleting report template",
        status: "error",
        duration: 15000,
        isClosable: true,
      });

      return;
    } else {
      toast({
        title: VIEW_TITLE,
        description: "Deleted successfully.",
        status: "success",
        duration: 15000,
        isClosable: true,
      });
    }

    onClose();
  }, [dispatch, onClose, selectedId, toast]);

  const onImportConfig = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      if (!evt.target.files || !evt.target.files[0]) {
        return;
      }

      const file = evt.target.files[0];
      const reader = new FileReader();

      evt.target.value = "";

      reader.onload = (e) => {
        try {
          if (!e.target?.result || typeof e.target.result !== "string") {
            return;
          }

          const data = JSON.parse(e.target.result);

          onOpenImport(data);
        } catch (error) {
          console.error("Error parsing JSON:", error);
        }
      };

      reader.readAsText(file);
    },
    [onOpenImport]
  );

  const onSubmitImport = useCallback(
    async (payload: UpsertResourceRequest) => {
      const upsertResponse = await dispatch(addResource(payload));

      if (upsertResponse.type === addResource.rejected.type) {
        toast({
          title: VIEW_TITLE,
          description: `Error when importing ${VIEW_TITLE}`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });

        return;
      }

      onClose();
    },
    [dispatch, onClose, toast]
  );

  const customOptions = useMemo(() => {
    return [
      {
        label: "Duplicate resource",
        icon: BiCopy,
        hasPermission: hasWrite,
        onClick: (row: ResourceWithId) => {
          setInitialValues(mapToInitialValues(row));
          setIsOpen("upsert");
        },
      },
      {
        label: "Resource logs",
        icon: FaList,
        hasPermission: true,
        onClick: (row: ResourceWithId) => {
          setSelectedId(row.id);
          setIsOpen("logs");
        },
      },
      {
        label: "Export resource",
        icon: AiOutlineDownload,
        hasPermission: hasWrite,
        onClick: (row: ResourceWithId) => {
          const jsonContent = JSON.stringify(row, null, 2);
          const blob = new Blob([jsonContent], { type: "application/json" });
          const url = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          const date = new Date();
          const formattedDate = `${date.getFullYear()}${("0" + (date.getMonth() + 1)).slice(-2)}${("0" + date.getDate()).slice(-2)}`;

          link.setAttribute("download", `${getEnvironment().label}-information-extraction-config-${row.urn}-${formattedDate}.json`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        },
      },
    ];
  }, [hasWrite]);

  const customFilterElement = useMemo(() => {
    return (
      <Stack direction="row">
        <Input onChange={(evt) => onImportConfig(evt)} type="file" accept=".json" hidden id="ch-import-information-extraction-config" />
        <Button
          disabled={!hasWrite}
          onClick={() => document.getElementById("ch-import-information-extraction-config")?.click()}
          width="9rem"
          {...secondaryButtonProps}>
          Import Config
        </Button>
      </Stack>
    );
  }, [hasWrite, onImportConfig, secondaryButtonProps]);

  return (
    <Box>
      <AdminTiles<ResourceWithId>
        items={resources}
        fieldsToRender={["category", "confidenceThreshold"]}
        filterByFields={["category", "urn", "lastUpdatedByUserName"]}
        sortByFields={["lastUpdatedByUserId", "lastUpdatedDate", "category", "urn"]}
        inputFilterPlaceholder="Filter by urn, category, or last updated by user"
        defaultSortByKey="lastUpdatedDate"
        onClickCreate={() => onOpenUpsert()}
        onClickDelete={(id) => onOpenDelete(id)}
        onClickEdit={(id) => onOpenUpsert(id)}
        hasWrite={hasWrite}
        hasRead={hasRead}
        tileTitle={VIEW_TITLE}
        tileTitleKey="urn"
        keyName="id"
        customOptions={customOptions}
        customFilterElements={{ customFilter: customFilterElement }}
      />

      {isOpen === "upsert" && (
        <UpsertInformationExtractionConfigPanel
          initialValues={initialValues}
          id={selectedId}
          isOpen
          onClose={onClose}
          onSubmit={onSubmit}
        />
      )}

      {isOpen === "logs" && selectedId && (
        <EventLogsPanel title="Resource" fetchLogs={getResourceLogs} id={selectedId} isOpen onClose={onClose} />
      )}

      {isOpen === "import" && importData && (
        <ImportInformationExtraction resource={importData} onClose={onClose} onSubmit={onSubmitImport} />
      )}

      <AlertDialog isOpen={isOpen === "delete"} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="sm" fontWeight="bold">
              Delete {VIEW_TITLE}
            </AlertDialogHeader>

            <AlertDialogBody fontSize="sm">Are you sure? You can't undo this action afterwards.</AlertDialogBody>

            <AlertDialogFooter>
              <Button disabled={isLoading} {...commonButtonProps} ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button disabled={isLoading} {...commonButtonProps} onClick={onDelete} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};
