import { Box, Divider, useColorModeValue, Stack, Text } from "@chakra-ui/react";
import React from "react";
import { ProjectWatchListTile } from "./ProjectWatchListTile";

interface ProjectWatchListGridProps {
  filterCollectionGroups: Array<{
    id: string;
    groupName: string;
    groupType: string;
    groupCount: number;
  }>;
}

export const ProjectWatchListGrid: React.FC<ProjectWatchListGridProps> = ({ filterCollectionGroups }) => {
  const cardBgColor = useColorModeValue("white", "gray.900");
  const borderColor = useColorModeValue("gray.200", "gray.700");
  const textColor = useColorModeValue("primary.darkGray", "gray.400");

  const displayedCollections = filterCollectionGroups;

  return (
    <Box
      className="ch-watch-list-grid"
      borderWidth="1px"
      borderRadius="lg"
      borderColor={borderColor}
      bgColor={cardBgColor}
      boxShadow="sm"
      overflow="hidden"
      width="100%"
      height="100%">
      <Text pt="1rem" px="1rem" pb="0" fontWeight="600" fontSize="md" lineHeight="1.4" color={textColor} mb={2}>
        Latest Projects Watch List
      </Text>
      <Stack divider={<Divider borderColor={borderColor} m="0!important" />}>
        {displayedCollections.map((project, index) => (
          <ProjectWatchListTile key={`ch-watch-list-tile-${index}`} collectionId={project.id} />
        ))}
      </Stack>
    </Box>
  );
};
