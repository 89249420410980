import { Box, Grid, Center, useBreakpointValue, Text } from "@chakra-ui/react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import type { DealFinderContentProps } from "../../../types/dealfinder";
import { DealTile } from "./DealTile";
import { DealSkeletonTile } from "./DealSkeletonTile";
import { useDealFinderTickers } from "../../../hooks/useDealFinderTickers";
import type { DealFinderTicker } from "../../../api/tickers/models/DealFinderTicker";
import type { TickerType } from "../../../api/tickers/models/TickerType";
import { useConversationContext } from "../../thread/ConversationContext";
import { useConversation, useConversationDataTickerSymbols } from "hooks/useConversation";
import { useSidebarNavigation, useInvestmentDetailModal } from "hooks";
import { useCopyValue } from "hooks/useCopies";

export const DealFinderContent: React.FC<DealFinderContentProps> = ({ activeFilter, setActiveFilter }) => {
  const [deals, setDeals] = useState<DealFinderTicker[]>([]);
  const { currentDealId } = useInvestmentDetailModal();
  const [dealsMap, setDealsMap] = useState<Map<string, DealFinderTicker>>(new Map());
  const [isLoading, setIsLoading] = useState(false);
  const loadedIdsRef = useRef<Set<string>>(new Set());
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(0);
  const [currentLimit, setCurrentLimit] = useState(0);
  const [isConversationCompleted, setIsConversationCompleted] = useState(false);
  const observer = useRef<IntersectionObserver | null>(null);
  const observerRef = useRef<HTMLDivElement>(null);
  const loadingTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const { getDealFinderTickers } = useDealFinderTickers();
  const { conversationId, setConversationId } = useConversationContext();
  const { conversationState } = useConversation(conversationId);
  const isMobile = useBreakpointValue({ base: true, md: false, lg: false }, { fallback: "md", ssr: false });
  const isTablet = useBreakpointValue({ base: false, md: true, lg: false }, { fallback: "md", ssr: false });
  const { currentSidebarType } = useSidebarNavigation();
  const exchanges = useCopyValue("copy_ticker_exchange_options");

  const getPrivateExchanges = useCallback(() => {
    return exchanges.filter((exch) => exch.toLowerCase().startsWith("private"));
  }, [exchanges]);

  useEffect(() => {
    if (!conversationId || conversationState === "complete" || conversationState === "action" || conversationState === "error") {
      setIsConversationCompleted(true);
    } else {
      setIsConversationCompleted(false);
    }
  }, [conversationId, conversationState]);

  // Event listener for Escape key
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        setConversationId("");
        setActiveFilter(null);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [setActiveFilter, setConversationId]);

  const tickerSymbolsFromConversation = useConversationDataTickerSymbols(conversationId);
  const validTickerSymbols = isConversationCompleted ? tickerSymbolsFromConversation : undefined;
  const columns = useBreakpointValue({ base: 1, sm: 2, md: 3, lg: 4 }) || 1;
  const PAGE_SIZE = 10;

  // Load specific deal if dealId is in URL
  useEffect(() => {
    if (currentDealId && !dealsMap.has(currentDealId) && !isLoading) {
      const loadDealById = async () => {
        setIsLoading(true);
        try {
          const tickers = await getDealFinderTickers({
            ids: [currentDealId],
          });

          if (tickers.length > 0) {
            setDealsMap((prevMap) => {
              const newMap = new Map(prevMap);
              tickers.forEach((ticker) => newMap.set(ticker.id, ticker));
              return newMap;
            });
          }
        } catch (error) {
          console.error("Error loading deal by ID:", error);
        } finally {
          setIsLoading(false);
        }
      };
      loadDealById();
    }
  }, [currentDealId, dealsMap, isLoading, getDealFinderTickers]);

  const loadMoreDeals = useCallback(async () => {
    if (isLoading) return;
    setIsLoading(true);

    // Set a timeout to stop loading after 30 seconds
    if (loadingTimeoutRef.current) {
      clearTimeout(loadingTimeoutRef.current);
    }

    loadingTimeoutRef.current = setTimeout(() => {
      setIsLoading(false);
    }, 30000); // 30 seconds

    try {
      const newLimit = page === 0 ? PAGE_SIZE : currentLimit + PAGE_SIZE;

      let tickerType: TickerType | undefined;
      if (activeFilter) {
        switch (activeFilter.toLowerCase()) {
          case "equity":
            tickerType = "equity";
            break;
          case "etf":
            tickerType = "etf";
            break;
          case "mutual funds":
            tickerType = "mutual_funds";
            break;
          // No default case needed
        }
      }

      let ids: string[] = [];

      if (validTickerSymbols && validTickerSymbols.length > 0) {
        ids = validTickerSymbols;
      }

      // Use the ref for loadedIds
      const loadedIds = loadedIdsRef.current;

      const tickers = await getDealFinderTickers({
        limit: newLimit,
        type: tickerType,
        exchange: activeFilter?.toLowerCase() === "private" ? getPrivateExchanges() : undefined,
        ids,
      });
      const newTickers = tickers.filter((ticker) => !loadedIds.has(ticker.id));

      if (newTickers.length === 0) {
        setHasMore(false);
        setIsLoading(false);

        // Clear the timeout since we finished loading
        if (loadingTimeoutRef.current) {
          clearTimeout(loadingTimeoutRef.current);
          loadingTimeoutRef.current = null;
        }

        return;
      }

      // Update loadedIds directly
      newTickers.forEach((ticker) => loadedIds.add(ticker.id));

      // Update the deals map with new tickers
      setDealsMap((prevMap) => {
        const newMap = new Map(prevMap);
        for (const ticker of newTickers) {
          newMap.set(ticker.id, ticker);
        }
        return newMap;
      });

      setPage((prev) => prev + 1);
      setCurrentLimit(newLimit);

      setHasMore(newTickers.length > 0);

      // Clear the timeout since we finished loading
      if (loadingTimeoutRef.current) {
        clearTimeout(loadingTimeoutRef.current);
        loadingTimeoutRef.current = null;
      }
    } catch (error: any) {
      console.error("Error loading deals:", error);
    } finally {
      setIsLoading(false);
    }
  }, [isLoading, page, currentLimit, activeFilter, validTickerSymbols, getDealFinderTickers, getPrivateExchanges]);

  // Clean up the timeout on unmount
  useEffect(() => {
    return () => {
      if (loadingTimeoutRef.current) {
        clearTimeout(loadingTimeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    setDealsMap(new Map());
    setDeals([]);
    loadedIdsRef.current = new Set(); // Reset the loadedIds ref
    setPage(0);
    setCurrentLimit(0);
    setHasMore(true);

    // Clear any existing timeout
    if (loadingTimeoutRef.current) {
      clearTimeout(loadingTimeoutRef.current);
      loadingTimeoutRef.current = null;
    }
  }, [conversationId, activeFilter, conversationState, validTickerSymbols]);

  // Update deals array from dealsMap whenever the map changes, applying any active filters
  useEffect(() => {
    const allDeals = Array.from(dealsMap.values());
    if (activeFilter) {
      const filteredDeals = allDeals.filter((deal) => {
        if (activeFilter.toLowerCase() === "equity" && deal.type === "equity") {
          return true;
        }
        if (activeFilter.toLowerCase() === "etf" && deal.type === "etf") {
          return true;
        }
        if (activeFilter.toLowerCase() === "mutual funds" && deal.type === "mutual_funds") {
          return true;
        }
        if (activeFilter.toLowerCase() === "private" && deal.exchange === "private") {
          return true;
        }
        return false;
      });

      setDeals(filteredDeals);
    } else {
      setDeals(allDeals);
    }
  }, [dealsMap, activeFilter]);

  useEffect(() => {
    if (deals.length === 0 && hasMore && !isLoading) {
      loadMoreDeals();
    }
  }, [deals.length, hasMore, isLoading, loadMoreDeals]);

  useEffect(() => {
    const currentObserver = observer.current;
    const observerElement = observerRef.current;

    if (!observerElement) return;

    const options = {
      root: null,
      rootMargin: "200px",
      threshold: 0.1,
    };

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && !isLoading && hasMore) {
        loadMoreDeals();
      }
    }, options);

    observer.current.observe(observerElement);

    const checkInitialVisibility = () => {
      if (observerElement) {
        const rect = observerElement.getBoundingClientRect();
        const isVisible = rect.top <= window.innerHeight && rect.bottom >= 0;
        if (isVisible && !isLoading && hasMore) {
          loadMoreDeals();
        }
      }
    };

    // Reduce the number of initial visibility checks and add delay
    setTimeout(checkInitialVisibility, 300);

    return () => currentObserver?.disconnect();
  }, [loadMoreDeals, isLoading, hasMore]);

  // Show skeleton loading state when there are no deals but loading is in progress
  if (!isConversationCompleted || (deals.length === 0 && isLoading)) {
    return (
      <Box
        width="100%"
        mx="auto"
        pl={isMobile || isTablet ? ".5rem" : currentSidebarType === "hidden" ? "0" : "1rem"}
        pr={isMobile || isTablet ? ".5rem" : currentSidebarType === "hidden" ? "0" : "1rem"}>
        <Grid
          templateColumns={`repeat(${columns}, 1fr)`}
          gap={{ base: 4, md: 6, lg: 8 }}
          width="100%"
          justifyContent="center"
          alignItems="flex-start">
          {Array.from({ length: columns * 2 }).map((_, index) => (
            <Box key={`skeleton-${index}`} height="auto">
              <DealSkeletonTile />
            </Box>
          ))}
        </Grid>
      </Box>
    );
  }

  if (deals.length === 0 && !isLoading) {
    return (
      <Center minH="50vh">
        <Text fontSize="lg" color="gray.500">
          No deals found matching your search criteria
        </Text>
      </Center>
    );
  }

  return (
    <Box
      width="100%"
      mx="auto"
      pl={isMobile || isTablet ? ".5rem" : currentSidebarType === "hidden" ? "0" : "1rem"}
      pr={isMobile || isTablet ? ".5rem" : currentSidebarType === "hidden" ? "0" : "1rem"}>
      <Grid
        templateColumns={`repeat(${columns}, 1fr)`}
        gap={{ base: 4, md: 6, lg: 8 }}
        width="100%"
        justifyContent="center"
        alignItems="flex-start">
        {deals.map((deal, id) => (
          <Box key={deal.id} height="auto">
            <DealTile deal={deal} />
          </Box>
        ))}
      </Grid>
      <Box
        height="100px"
        width="100%"
        className="deal-finder-observer"
        ref={observerRef}
        position="relative"
        zIndex={1}
        bgColor="transparent"
        mb={8}
      />
    </Box>
  );
};
