import { CloseIcon } from "@chakra-ui/icons";
import { IconButton, Stack, Text, useColorModeValue, useBreakpointValue } from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import { useContext } from "react";
import React from "react";
import { ConversationContext } from "../ConversationContext";
import { useCollectionKey, useProjectParams } from "hooks";
import { useAddToCharliContext } from "screens/panels/addToCharli/AddToCharliWizard/AddToCharliProvider";
import capitalize from "lodash/capitalize";

interface Props {
  collectionId?: string | undefined;
  title?: string | undefined;
}

export const ThreadTitleBar: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
  const { projectId } = useProjectParams();
  const {
    setIsConversationDialogExpanded,
    isConversationOpen,
    onConversationClose,
    onConversationOpen,
    setCollectionId,
    setConversationId,
    setShowCommands,
  } = useContext(ConversationContext);
  const textColor = useColorModeValue("primary.darkGray", "gray.400");
  const bgColor = useColorModeValue("white", "gray.800");
  const { setIsNewMenuAction } = useAddToCharliContext();
  const projectName = useCollectionKey(props.collectionId, "name");
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });

  const onHandleClose = () => {
    if (isConversationOpen) {
      setIsNewMenuAction(undefined);
      onConversationClose();
      setCollectionId("");
      setConversationId("");
      setIsConversationDialogExpanded(false);
      setShowCommands(false);
    } else {
      onConversationOpen();
    }
  };

  return (
    <Stack
      spacing="1rem"
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      py=".75rem"
      px="1rem"
      color={textColor}
      bgColor={bgColor}
      className="titleBar"
      cursor={projectId ? "default" : "move"}
      borderTopRadius={isMobile ? "0" : "1rem"}>
      <Text fontSize="md" fontWeight={"semibold"}>
        {projectName ? `${projectName} Conversation` : props.title ? capitalize(props.title.replace(/_/g, " ")) : "New Conversation"}
      </Text>
      <IconButton
        paddingInline={0}
        minW={0}
        size="sm"
        width="1rem"
        aria-label="Close"
        variant="ghost"
        icon={<CloseIcon boxSize={"12px"} />}
        _hover={{}}
        _active={{}}
        onClick={() => onHandleClose()}
      />
    </Stack>
  );
};
