import { useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import type { RootState } from "state/rootReducer";
import type { AuthRequest, Button as ButtonType, CanvasButton, CharliUI, RequestEntities } from "types/charliui";
import capitalize from "lodash/capitalize";
import last from "lodash/last";
import { shallowEqual } from "react-redux";
import type { Message } from "types/conversation";
import { parseISO } from "date-fns";
import { useDispatch } from "react-redux";
import { downloadConversationById } from "state/conversation/operations";
import { getMessagesForConversation } from "screens/utils";

/**
 * Returns messages for a given conversation.
 * @param {string} id - Conversation ID to query.
 * @param intents
 */
export function useConversation(id: string, intents?: string[]) {
  const { conversations, messages: normalizedMessages } = useSelector((state: RootState) => state.conversation);
  const messages = useMemo(
    () => getMessagesForConversation(conversations || {}, normalizedMessages || {}, id),
    [conversations, normalizedMessages, id]
  );

  const latestIntent = useMemo(() => {
    const filteredMessages = messages.filter(
      (message) => message.senderId !== "charli" && message.content !== "Yes" && message.content !== "OK" && message.content !== "No"
    );
    const maybeLastMessage = filteredMessages[filteredMessages.length - 1];
    if (!maybeLastMessage) return;
    if (!maybeLastMessage || !maybeLastMessage.intent) return;
    return maybeLastMessage.intent;
  }, [messages]);

  const initialIntent = useMemo(() => {
    const maybeFirstMessage = messages[0];
    if (!maybeFirstMessage) return;
    if (!maybeFirstMessage || !maybeFirstMessage.intent) return;
    return maybeFirstMessage.intent;
  }, [messages]);

  const latestUserRequest = useMemo(() => {
    const filteredMessages = messages.filter(
      (message) => message.senderId !== "charli" && (typeof message.content !== "string" || !["Yes", "OK", "No"].includes(message.content))
    );
    const lastMessage = filteredMessages[filteredMessages.length - 1];
    if (!lastMessage?.content) return;
    const messageText = lastMessage.content.trim();
    const tokens = messageText.split(" ");

    const intentToken = tokens.find((token) => token.startsWith("/"))?.slice(1);
    const entities: RequestEntities = [];

    for (let i = 0; i < tokens.length; i++) {
      if (tokens[i].startsWith(">")) {
        const entity = tokens[i].slice(1);
        const value = tokens[i + 1] || "";
        entities.push({ entity, value });
        i++;
      }
    }
    return { intent: intentToken, entities };
  }, [messages]);

  const latestResponseText = useMemo(() => {
    const filteredMessages = messages.filter((message) => message.senderId === "charli");
    const maybeLastMessage = filteredMessages[filteredMessages.length - 1];
    if (!maybeLastMessage) return;
    return maybeLastMessage.content || "";
  }, [messages]);

  const inlineActions = useMemo(() => {
    const filteredMessages = messages.filter((message) => message.senderId === "charli");
    const maybeLastMessage = filteredMessages[filteredMessages.length - 1];
    if (
      maybeLastMessage &&
      (maybeLastMessage.state === "action_required" || maybeLastMessage.state === "task_error") &&
      Array.isArray(maybeLastMessage.data)
    ) {
      const actions = maybeLastMessage.data.find((datum) => datum.type === "inline_action");
      if (actions?.type === "inline_action" && actions.body.inline_action.length > 0) {
        return actions.body.inline_action;
      }
    }
  }, [messages]);

  const initialCommandText = useMemo(() => {
    const maybeFirstMessage = messages[0];

    // If there's no messages in this conversation, don't set the initial input.
    if (!maybeFirstMessage) return;

    // If the conversation starts with a message from Charli, assume the app state is invalid and don't set the initial input.
    if (maybeFirstMessage.senderId === "charli") return;
    return maybeFirstMessage.content || "";
  }, [messages]);

  const userMessageArray = useMemo(() => {
    const maybeFirstMessage = messages[0];
    // If there's no messages in this conversation, don't set the initial input.
    if (!maybeFirstMessage) return;

    // If the conversation starts with a message from Charli, assume the app state is invalid and don't set the initial input.
    if (maybeFirstMessage.senderId === "charli" || !maybeFirstMessage.content) return;

    // Split the message content into an array of entities
    // create an array const for entities
    const messageArray: {
      name: string;
      value: string;
    }[] = [];

    const messageIntent = maybeFirstMessage.content
      .split(" ")
      .filter((token) => token.startsWith("/"))
      .map((token) => token.replace("/", ""));

    if (messageIntent.length > 0) {
      messageArray.push({ name: "intent", value: messageIntent[0] });
    }
    const messageEntities = maybeFirstMessage.content.split(" ").filter((token) => !token.startsWith("/"));

    if (messageEntities.length > 0) {
      // Map through each entity to extract its name and value
      // eslint-disable-next-line array-callback-return
      messageEntities.map((entity, index) => {
        if (entity.startsWith(">")) {
          const entityName = entity.replace(">", "");
          // Get the value of the entity from the next token
          const entityValue = messageEntities[index + 1];
          messageArray.push({ name: entityName, value: entityValue });
        }
      });
    }
    return messageArray;
  }, [messages]);

  const authRequest: AuthRequest | undefined = useMemo(() => {
    const msgData = messages
      .map(({ data }) => data)
      .filter((data): data is CharliUI[] => data !== undefined)
      .flatMap((data) => data.filter((datum): datum is AuthRequest => datum.type === "auth_request"));

    return last(msgData);
  }, [messages]);

  const initialText = useMemo(() => {
    const maybeFirstMessage = messages[0];

    // If there's no messages in this conversation, don't set the initial input.
    if (!maybeFirstMessage) return;

    // If the conversation starts with a message from Charli, assume the app state is invalid and don't set the initial input.
    if (maybeFirstMessage.senderId === "charli") return;

    const firstMessageText = maybeFirstMessage.content || "";

    if (firstMessageText) {
      const entityTokens = firstMessageText.trim().split(">");
      const entityItems = entityTokens
        .filter((item) => !item.startsWith("debug"))
        .map((token) => {
          const item = token.trim().split(" ");
          if (item[0].startsWith("/")) {
            return `${capitalize(item[0].replace("/", ""))} : `;
          }
          if (item[0] === "tag" || item[0] === "keywords") {
            return item[1];
          } else {
            return item[0];
          }
        });

      const newMessage = entityItems.join(" ") || "";
      return newMessage;
    }
  }, [messages]);

  const conversationState = useMemo(() => {
    const filteredMessages = messages.filter((message) => (intents ? message.intent && intents.includes(message.intent) : true));
    const lastMessage = filteredMessages[filteredMessages.length - 1];

    if (!lastMessage || lastMessage.state === null) return "unknown";
    if ((lastMessage.senderId === "charli" && lastMessage.state === "task_in_progress") || lastMessage.senderId !== "charli") {
      return "in_progress";
    } else if (lastMessage.senderId === "charli" && lastMessage.state === "task_error") {
      return "error";
    } else if (lastMessage.senderId === "charli" && lastMessage.state === "action_required") {
      return "action";
    } else if (lastMessage.senderId === "charli" && lastMessage.state === "task_complete") {
      return "complete";
    } else {
      return "unknown";
    }
  }, [intents, messages]);

  const metadataIds = useMemo(() => {
    for (const message of messages.slice(0).reverse()) {
      if (Array.isArray(message.data) && message.data.length > 0) {
        const charliUIStack = message.data!.find((datum) => datum.type === "charli-ui");
        if (charliUIStack) {
          return (charliUIStack.body as ButtonType).buttonElement?.content.metadataIds;
        }

        const maybeCanvasButton = message.data.find((datum): datum is CanvasButton => datum.type === "canvas_button");
        if (maybeCanvasButton) {
          return maybeCanvasButton.body.metadataIds;
        }
      }
    }
  }, [messages]);

  const {
    messageStateColor,
    messageStateTooltip,
    messageText,
    messageProgress,
  }: { messageStateColor: string; messageStateTooltip: string; messageText: string; messageProgress: number } = useMemo(() => {
    const filteredMessages = messages.filter((message) => (intents ? message.intent && intents.includes(message.intent) : true));
    const lastMessage = filteredMessages[filteredMessages.length - 1];
    if (!lastMessage)
      return {
        messageStateColor: "orange.300",
        messageStateTooltip: "Unknown",
        messageText: "",
        messageProgress: 100,
      };
    const textWithLinks = lastMessage.content
      ? lastMessage.content.replace(/\[([^\]]+)\]\(([^)]+)\)/g, '<a href="$2" style="text-decoration: underline;" target="_blank">$1</a>')
      : "";

    switch (lastMessage.state) {
      case "action_required":
        return {
          messageStateColor: "orange.300",
          messageStateTooltip: "Action Required",
          messageText: textWithLinks,
          messageProgress: 25,
        };
      case "task_error":
        return {
          messageStateColor: "red.400",
          messageStateTooltip: "Task Failed",
          messageText: textWithLinks,
          messageProgress: 25,
        };
      case "task_in_progress":
        return {
          messageStateColor: "green.400",
          messageStateTooltip: "In Progress",
          messageText: textWithLinks,
          messageProgress: 50,
        };
      case "task_complete":
        return {
          messageStateColor: "gray.300",
          messageStateTooltip: "Completed",
          messageText: textWithLinks,
          messageProgress: 100,
        };
      default:
        return {
          messageStateColor: "orange.300",
          messageStateTooltip: "Unknown",
          messageText: textWithLinks,
          messageProgress: 75,
        };
    }
  }, [intents, messages]);

  return {
    messages,
    initialText,
    conversationState,
    messageStateColor,
    messageStateTooltip,
    messageText,
    messageProgress,
    metadataIds,
    latestIntent,
    latestUserRequest,
    latestResponseText,
    inlineActions,
    initialCommandText,
    userMessageArray,
    initialIntent,
    authRequest,
  };
}

export function useConversationEntities(conversationId?: string): RequestEntities | undefined {
  return useSelector((state: RootState) => {
    if (!conversationId) {
      return undefined;
    }

    const messagesIds = state.conversation.conversations[conversationId]?.messages;
    if (!messagesIds) return;

    const messages = messagesIds.map((messageId) => state.conversation.messages[messageId]);
    const firstMessage = messages.find((message) => message?.senderId !== "charli");

    if (!firstMessage?.content) return;
    const messageText = firstMessage.content.trim();

    const tokens = messageText.split(" ");
    const entities: RequestEntities = [];

    for (let i = 0; i < tokens.length; i++) {
      if (tokens[i].startsWith(">")) {
        const entity = tokens[i].slice(1);
        const value = tokens[i + 1] || "";
        entities.push({ entity, value });
      }
    }

    return entities;
  }, shallowEqual);
}

export function useConversationDataTickerSymbols(conversationId?: string): string[] | undefined {
  return useSelector((state: RootState) => {
    if (!conversationId) {
      return undefined;
    }

    const messagesIds = state.conversation.conversations[conversationId]?.messages;
    if (!messagesIds) return undefined;
    const messages = messagesIds.map((messageId) => state.conversation.messages[messageId]);

    // Look for completed or action required messages from Charli
    const charliMessages = messages.filter((message) => message.senderId === "charli" && message.state === "task_in_progress");

    // Get the latest message that might contain ticker data
    const maybeLastMessage = charliMessages[charliMessages.length - 1];

    if (!maybeLastMessage || !Array.isArray(maybeLastMessage.data) || maybeLastMessage.data.length === 0) {
      return undefined;
    } else {
      const messageDataTickerIds = maybeLastMessage.data.find((datum) => datum.type === "ticker_ids");
      if (messageDataTickerIds?.type === "ticker_ids" && messageDataTickerIds.body.ticker_ids.length > 0) {
        return messageDataTickerIds.body.ticker_ids as string[];
      }
    }
  }, shallowEqual);
}

export function useGetConversationCompanyTickerValue(): (conversationId: string) => string | undefined {
  const conversationTicker = useSelector((state: RootState) => state.conversation.conversationTicker, shallowEqual);

  return useCallback(
    (conversationId: string) => {
      return conversationTicker && conversationTicker[conversationId] ? conversationTicker[conversationId] : undefined;
    },
    [conversationTicker]
  );
}

export function useConversationCompanyExchangeValue(conversationId?: string): string | undefined {
  return useSelector((state: RootState) => {
    if (!conversationId) {
      return undefined;
    }

    const messagesIds = state.conversation.conversations[conversationId]?.messages;
    if (!messagesIds) return;

    const messages = messagesIds.map((messageId) => state.conversation.messages[messageId]);
    const firstMessage = messages.find((message) => message?.senderId !== "charli");

    if (!firstMessage?.content) return;
    const messageText = firstMessage.content.trim();

    const tokens = messageText.split(" ");

    for (let i = 0; i < tokens.length; i++) {
      if (tokens[i].startsWith(">")) {
        const entity = tokens[i].slice(1);
        const value = tokens[i + 1] || "";

        if (entity === "company_stock_exchange") {
          return value;
        }
      }
    }
  }, shallowEqual);
}

export function useConversationMessages(conversationId: string | undefined): Message[] {
  return useSelector((state: RootState) => {
    if (!conversationId || !state.conversation.conversations[conversationId]) return [];

    return state.conversation.conversations[conversationId].messages
      .flatMap((messageId) => (state.conversation.messages[messageId] ? [state.conversation.messages[messageId]] : []))
      .filter((message) => !message.viewId)
      .sort((a, b) => (a.createdTime || parseISO(a.createdDate).getTime()) - (b.createdTime || parseISO(b.createdDate).getTime()));
  }, shallowEqual);
}

export function useConversationState(
  conversationId: string | undefined,
  intents?: string[]
): "unknown" | "in_progress" | "error" | "action" | "complete" {
  const messages = useConversationMessages(conversationId);

  return useMemo(() => {
    const filteredMessages = messages.filter((message) => (intents ? message.intent && intents.includes(message.intent) : true));
    const lastMessage = filteredMessages[filteredMessages.length - 1];

    if (!lastMessage || lastMessage.state === null) return "unknown";
    if ((lastMessage.senderId === "charli" && lastMessage.state === "task_in_progress") || lastMessage.senderId !== "charli") {
      return "in_progress";
    } else if (lastMessage.senderId === "charli" && lastMessage.state === "task_error") {
      return "error";
    } else if (lastMessage.senderId === "charli" && lastMessage.state === "action_required") {
      return "action";
    } else if (lastMessage.senderId === "charli" && lastMessage.state === "task_complete") {
      return "complete";
    } else {
      return "unknown";
    }
  }, [intents, messages]);
}

/**
 * Returns whether conversation exists or not in Redux
 * @param {string} id - Conversation ID to query.
 */
export function useHasConversation(id?: string): boolean {
  const { conversations } = useSelector((state: RootState) => state.conversation);
  return useMemo(() => {
    return id && conversations[id] ? true : false;
  }, [conversations, id]);
}

/**
 * Returns whether conversation is being downloading or not
 * @param {string} id - Conversation ID to query.
 */
export function useConversationLoading(id: string): boolean | undefined {
  return useSelector((state: RootState) => state.conversation.isLoadingConversationById[id]);
}

export function useDownloadConversation(id: string | undefined): void {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!id || id === "") return;

    dispatch(downloadConversationById({ conversationId: id }));
  }, [dispatch, id]);
}
