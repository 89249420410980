import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  useColorModeValue,
  Icon,
  InputGroup,
  InputRightElement,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";
import { useButtonProps } from "hooks";
import React from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { BiTrash } from "react-icons/bi";
import type { UpsertCopyForm } from "./UpsertCopy";
import { JSONEditor } from "screens/common/components";
import { ScorecardSchema } from "schemas/ScorecardSchema";

export const CopiesFieldArray = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext<UpsertCopyForm>();

  const commonButtonProps = useButtonProps("sm", "secondary");
  const buttonColor = useColorModeValue("gray.500", "gray.600");
  const buttonHoverColor = useColorModeValue("gray.600", "gray.400");
  const bgColor = useColorModeValue("#fbfbfb", "gray.800");

  const { fields, append, remove } = useFieldArray({
    control,
    name: "listValue",
  });

  const isAnyValueJson = fields.some((field) => {
    const currentValue = field.value;
    let isJsonValue = false;
    let parsedJson: any = null;
    // Try to parse the value as JSON
    if (typeof currentValue === "string") {
      try {
        parsedJson = JSON.parse(currentValue);
        isJsonValue = typeof parsedJson === "object" && parsedJson !== null;
      } catch (e) {
        // Not valid JSON, will use regular input
      }
    }
    return isJsonValue;
  });

  return (
    <>
      <Button {...commonButtonProps} mb={"1rem"} onClick={() => append({ value: "" })}>
        Add Value
      </Button>

      <Box p="6" rounded="md">
        {errors.listValue?.message && (
          <Alert status="error">
            <AlertIcon />
            {errors.listValue?.message}
          </Alert>
        )}
        {fields.map((field, index) => (
          <Flex alignItems={"center"} key={field.id}>
            <Controller
              name={`listValue.${index}.value`}
              control={control}
              render={({ field }) => {
                const currentValue = field.value;

                return (
                  <FormControl isInvalid={!!(errors.listValue && errors.listValue[index])} pb="1rem">
                    {isAnyValueJson ? (
                      <Box position="relative" mb={2}>
                        <JSONEditor
                          onChange={(newValue) => field.onChange(newValue)}
                          value={currentValue}
                          height="auto"
                          backgroundColor={bgColor}
                          jsonSchema={ScorecardSchema}
                          showSchemaButton={false}
                        />
                        <Box position="absolute" top={1} right={1}>
                          <Icon
                            cursor="pointer"
                            as={BiTrash}
                            color={buttonColor}
                            boxSize="1rem"
                            _hover={{ color: buttonHoverColor }}
                            onClick={() => remove(index)}
                          />
                        </Box>
                      </Box>
                    ) : (
                      <InputGroup size="sm">
                        <Input {...field} placeholder="Write copy here..." size="sm" pr="4.5rem" type="text" />
                        <InputRightElement width="4.5rem">
                          <Icon
                            cursor="pointer"
                            as={BiTrash}
                            color={buttonColor}
                            boxSize="1rem"
                            _hover={{ color: buttonHoverColor }}
                            onClick={() => remove(index)}
                          />
                        </InputRightElement>
                      </InputGroup>
                    )}
                    {errors.listValue && errors.listValue[index]?.value?.message && (
                      <FormErrorMessage>{errors.listValue[index]?.value?.message}</FormErrorMessage>
                    )}
                  </FormControl>
                );
              }}
            />
          </Flex>
        ))}
      </Box>
    </>
  );
};
