import { Stack, useColorMode, IconButton, useColorModeValue, useBreakpointValue, Center, Tag } from "@chakra-ui/react";
import { useCustomTheme, useEntitlementKey, useSidebarNavigation, useUserPreference, useUserSubscriptionDetails } from "hooks";
import type { FunctionComponent } from "react";
import React, { memo, useCallback } from "react";
import { UpgradePlanButton } from "screens/panels/settings/tabs/subscription/SubscriptionUpgrade";
import { NotificationsMenu } from "./NotificationsMenu";
import { ProfileSettingsMenu } from "./ProfileSettingsMenu";
import { MoonIcon, SunIcon } from "@chakra-ui/icons";
import { updateTypedUserPreference } from "state/userPreference/operations";
import { useDispatch } from "react-redux";
import { getEnvironment } from "screens/common/app";
import { DEFAULT_SIDEBAR_WIDTH } from "./SidebarNavigation";
import { HEADER_HEIGHT, HEADER_ICON_HEIGHT } from "../Landing";

interface Props {
  initialText?: string;
}

const InteractionBarComponent: FunctionComponent<Props> = ({ initialText = "" }) => {
  const { isEmbeddedApp } = useCustomTheme();
  // const hasSearch = useEntitlementKey("ui_enable_search");
  const subscriptionInfo = useUserSubscriptionDetails();
  const uiColorMode = useUserPreference("ui_color_mode") as string;
  const { colorMode, setColorMode } = useColorMode();
  const hasThemes = useEntitlementKey("ui_themes");
  const iconColor = useColorModeValue("gray.400", "gray.500");
  const dispatch = useDispatch();
  const showEnvBadgePreference = useUserPreference("ui_show_env_badge");
  const injectDebugEntityPreference = useUserPreference("ui_inject_debug_entity");
  const injectTestEntityPreference = useUserPreference("ui_inject_test_entity");
  const debugBgColor = useColorModeValue("#2c8741", "gray.700");
  const testBgColor = useColorModeValue("#6694a3", "gray.700");
  const badgeFontColor = useColorModeValue("gray.50", "gray.300");
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const sidebarWidth = (useUserPreference("ui_sidebar_width") as number) || (DEFAULT_SIDEBAR_WIDTH as number);
  const { currentSidebarType } = useSidebarNavigation();

  const toggleColorMode = useCallback(() => {
    const newColorMode = uiColorMode === "light" ? "dark" : "light";
    dispatch(updateTypedUserPreference({ preferenceKey: "ui_color_mode", value: newColorMode }));
    setColorMode(newColorMode);
  }, [uiColorMode, dispatch, setColorMode]);

  return (
    <Stack
      position="relative"
      direction="column"
      width={`${currentSidebarType === "hidden" ? "19rem" : `${sidebarWidth}px`}`}
      minWidth={`${currentSidebarType === "hidden" ? 0 : sidebarWidth}px`}
      justifyContent="flex-end"
      p="0.5rem"
      spacing=".5rem">
      <Stack direction="row" spacing="0.5rem" justifyContent="flex-end" align="center" height={"100%"}>
        {/* {hasSearch && <InteractionBarFindInput initialText={initialText} />} */}
        <NotificationsMenu />
        {hasThemes && (
          <IconButton
            aria-label="Toggle color mode"
            paddingInline={0}
            color={iconColor}
            icon={
              colorMode === "light" ? (
                <MoonIcon boxSize={`${HEADER_ICON_HEIGHT - 6}px`} />
              ) : (
                <SunIcon boxSize={`${HEADER_ICON_HEIGHT - 6}px`} />
              )
            }
            onClick={toggleColorMode}
            variant="ghost"
            backgroundColor="transparent"
            _hover={{ color: "primary.default", bgColor: "transparent" }}
          />
        )}
        {!isEmbeddedApp && <ProfileSettingsMenu />}
      </Stack>
      {!isMobile && (
        <Stack
          spacing="3px"
          direction="row"
          justifyContent={"flex-end"}
          right="0.5rem"
          position="absolute"
          zIndex={9}
          top={`${HEADER_HEIGHT - 10}px`}>
          {showEnvBadgePreference && getEnvironment().label.length > 0 && (
            <Center>
              <Tag
                borderRadius={"3px"}
                px="5px"
                py="1px"
                width="100%"
                textAlign={"center"}
                fontSize="8px"
                minHeight={".8rem"}
                color={badgeFontColor}
                backgroundColor={colorMode === "dark" ? "gray.700" : getEnvironment().color}>
                {getEnvironment().label}
              </Tag>
            </Center>
          )}
          {injectDebugEntityPreference && (
            <Center>
              <Tag
                borderRadius={"3px"}
                px="5px"
                py="1px"
                width="100%"
                textAlign={"center"}
                fontSize="8px"
                minHeight={".8rem"}
                color={badgeFontColor}
                backgroundColor={debugBgColor}>
                DEBUG
              </Tag>
            </Center>
          )}
          {injectTestEntityPreference && (
            <Center>
              <Tag
                borderRadius={"3px"}
                px="5px"
                py="1px"
                width="100%"
                textAlign={"center"}
                fontSize="8px"
                minHeight={".8rem"}
                color={badgeFontColor}
                backgroundColor={testBgColor}>
                TESTING
              </Tag>
            </Center>
          )}
          {subscriptionInfo.internalPlanName === "free_trial" && (
            <UpgradePlanButton
              tooltip="You can analyze up to 3 projects on your current plan. Upgrade to continue receiving the benefits of the Automated Analyst"
              buttonSize="xxs"
            />
          )}
        </Stack>
      )}
    </Stack>
  );
};

export const InteractionBar = memo(InteractionBarComponent);
