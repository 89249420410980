import { Stack, Text, useColorModeValue, Box, useOutsideClick, useBreakpointValue, Center, Image, useColorMode } from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import { useRef, useState, useEffect } from "react";
import React, { useContext, useMemo } from "react";
import ECHighlighter from "react-ec-highlighter";
import { ContentFilterContext } from "screens/content";
import type { Question, Answer } from "types/question";
import { AnswerStatus } from "types/question";
import { useCollectionKey, useLatestCollectionWorkflowId, useTileProps, useInfiniteLoading } from "hooks";
import { AnswerCardPinned } from "./AnswerCardPinned";
import { Popover } from "react-tiny-popover";
import { ProjectQuestionHotlinks } from "screens/collection/views/ProjectActions/ProjectQuestionHotlinks";
import HelpPopover from "screens/landing/components/popoverComponent/HelpPopover";
import { ConversationContext } from "screens/thread/ConversationContext";
import DataAnalysisGif from "screens/common/static/images/data-analysis.gif";
import { useWorkflowKey } from "hooks/useWorkflows";
import { useAnswerFocusOptions } from "hooks/useAnswerFocusOptions";
import LoadingMessage from "./AutocompleteSuggestion/LoadingMessage";

interface Props {
  questionAnswers: Question[];
  collectionId?: string;
  loadMoreQuestions: (token: string | null) => Promise<{ nextToken: string | null; totalCount?: number; data: Question[] }>;
}

export const QuestionAnswerResults: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  questionAnswers,
  collectionId,
  loadMoreQuestions,
}) => {
  const textColor = useColorModeValue("primary.darkGray", "gray.200");
  const bgColor = useColorModeValue("white", "#161B25");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const bgColorDataAnalysisMessage = useColorModeValue("white", "gray.700");
  const { searchText } = useContext(ContentFilterContext);
  const conversationId = useCollectionKey(collectionId, "conversationId") || "";
  const {
    items: filteredQuestions,
    lastMessageObserverRef,
    loading: isLoading,
    clear,
  } = useInfiniteLoading({
    loadItems: loadMoreQuestions,
  });

  // Handle initial load and question updates
  useEffect(() => {
    // Reset infinite loading to trigger reload with latest questions
    clear();
  }, [questionAnswers, clear]);

  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const { submittedQuestion, setSubmittedQuestion } = useContext(ConversationContext);
  const commonTileProps = useTileProps();
  const latestWorkflowId = useLatestCollectionWorkflowId(collectionId);
  const workflowStatus = useWorkflowKey(latestWorkflowId, "status");
  const shareDetails = useCollectionKey(collectionId, "shareDetails");
  const isSharedRead = shareDetails && shareDetails.accessMode === "read";

  const DataAnalysisMessage = () => (
    <Center>
      <Stack
        direction="row"
        spacing="1rem"
        justifyContent={"space-between"}
        {...commonTileProps}
        bgColor={bgColorDataAnalysisMessage}
        color={textColor}
        borderRadius={isMobile ? "md" : "none"}>
        <Center>
          <Image src={DataAnalysisGif} alt="AI Results Empty" width={isMobile ? "4rem" : "6rem"} />
        </Center>
        <Center width="100%">
          <Stack width="100%">
            <Text textAlign={"left"} fontSize="sm">
              {`I am collecting the data now and will generate a response for ${submittedQuestion} shortly.`}
            </Text>
            <Text textAlign={"left"} fontSize="sm">
              {`Once complete you can ask another question to dive even deeper.`}
            </Text>
          </Stack>
        </Center>
      </Stack>
    </Center>
  );

  useEffect(() => {
    const hasFilteredQuestionsContainsSubmittedQuestion = filteredQuestions.some((question) => question.question === submittedQuestion);
    hasFilteredQuestionsContainsSubmittedQuestion && setSubmittedQuestion(undefined);
  }, [filteredQuestions, submittedQuestion, setSubmittedQuestion]);

  const getQuestionAnswers = useMemo(() => {
    const questionsMap = filteredQuestions.reduce(
      (acc: Record<string, { pinnedAnswers: Answer[]; unpinnedAnswers: Answer[] }>, question) => ({
        ...acc,
        [question.id]: {
          pinnedAnswers: question.answers.filter((answer) => answer.answerStatus === "pinned"),
          unpinnedAnswers: question.answers.filter((answer) => answer.answerStatus === "unpinned"),
        },
      }),
      {}
    );

    return (questionId: string, answerStatus: AnswerStatus | "all") => {
      const answers = questionsMap[questionId];

      if (!answers) {
        return [];
      }

      return answerStatus === "pinned"
        ? answers.pinnedAnswers
        : answerStatus === "unpinned"
        ? answers.unpinnedAnswers
        : [...answers.pinnedAnswers, ...answers.unpinnedAnswers];
    };
  }, [filteredQuestions]);

  const [activeQuestionId, setActiveQuestionId] = useState<string | null>(null);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const mainRef = useRef<HTMLDivElement | null>(null);
  const popRef = useRef<HTMLDivElement | null>(null);
  const { answerFocusOptions } = useAnswerFocusOptions("due_diligence");
  const focusBgColor = useColorModeValue("gray.200", "gray.600");
  const { colorMode } = useColorMode();

  useOutsideClick({
    ref: popRef!,
    handler: () => {
      setIsPopoverOpen(false);
      setActiveQuestionId(null);
    },
  });

  const renderSentimentBadge = (focus: string) => {
    const filteredFocusData = answerFocusOptions?.filter((option) => option.key.toLowerCase() === focus.toLowerCase())[0];

    return (
      <Box
        className={`ch-qa-result-question-focus-${focus ? focus.toLowerCase() : "none"}`}
        whiteSpace={"nowrap"}
        borderRadius={"4px"}
        height="1.2rem"
        lineHeight={"1.2rem"}
        px="5px"
        fontSize={"10px"}
        color={textColor}
        bgColor={filteredFocusData ? `${filteredFocusData.color}.${colorMode === "light" ? "200" : "800"}` : focusBgColor}>
        {filteredFocusData ? filteredFocusData.label.toUpperCase() : focus}
      </Box>
    );
  };

  return (
    <Stack spacing={isMobile ? ".5rem" : "1rem"} width="100%">
      {submittedQuestion && workflowStatus === "in_progress" && (
        <Box mb={isMobile ? "1.5rem" : "2rem"} mt={isMobile ? "0" : "1rem"}>
          <DataAnalysisMessage />
        </Box>
      )}
      {filteredQuestions.length > 0 &&
        filteredQuestions.map((questionRecord, questionIndex) => (
          <Stack
            width="100%"
            key={questionRecord.id}
            backgroundColor={bgColor}
            borderColor={borderColor}
            borderWidth="1px"
            borderRadius="md">
            <Stack direction="row" justifyContent="space-between" pt={isMobile ? ".5rem" : "1rem"} px={isMobile ? ".5rem" : "1rem"}>
              <Stack
                ref={mainRef}
                position="relative"
                onTouchStart={() => {
                  setIsPopoverOpen(false);
                  setActiveQuestionId(null);
                }}
                onMouseEnter={() => {
                  !isSharedRead && setActiveQuestionId(questionRecord.id);
                }}>
                <Popover
                  isOpen={isPopoverOpen && activeQuestionId === questionRecord.id}
                  positions={["top"]}
                  padding={10}
                  align="start"
                  transformMode="relative"
                  onClickOutside={() => {
                    setIsPopoverOpen(false);
                    setActiveQuestionId(null);
                  }}
                  content={() => <ProjectQuestionHotlinks question={questionRecord.question} focus={questionRecord.focus ?? undefined} />}>
                  <Text
                    borderWidth={"1px"}
                    borderColor={isMobile ? "primary.default" : "transparent"}
                    borderStyle="dashed"
                    borderRadius={"md"}
                    px=".5rem"
                    _hover={{
                      borderColor: isSharedRead ? "unset" : "primary.default",
                      borderStyle: "dashed",
                    }}
                    cursor={isSharedRead ? "not-allowed" : "pointer"}
                    onClick={() => {
                      if (isSharedRead) return;
                      setIsPopoverOpen(!isPopoverOpen);
                      setActiveQuestionId(questionRecord.id);
                    }}
                    width="100%"
                    className="ch-qa-result-question"
                    fontWeight="semibold"
                    color={textColor}
                    fontSize="md">
                    {searchText && searchText.length > 0 && questionRecord.question ? (
                      <ECHighlighter searchPhrase={searchText} text={questionRecord.question} />
                    ) : (
                      questionRecord.question
                    )}
                  </Text>
                </Popover>
              </Stack>
              {questionRecord.focus ? (
                <>
                  {questionIndex === 0 ? (
                    <HelpPopover
                      title="Sentiment Badge"
                      message="Click here to view the attributions on the source material Charli used for generating the response.">
                      {renderSentimentBadge(questionRecord.focus)}
                    </HelpPopover>
                  ) : (
                    renderSentimentBadge(questionRecord.focus)
                  )}
                </>
              ) : null}
            </Stack>
            <Stack justifyContent="space-between" spacing="1rem" mt="0!important" width="100%">
              {getQuestionAnswers(questionRecord.id, "all").length === 0 ? (
                <AnswerCardPinned questionRecord={questionRecord} collectionId={collectionId || ""} conversationId={conversationId} />
              ) : (
                getQuestionAnswers(questionRecord.id, AnswerStatus.pinned).map((answerRecord) => (
                  <AnswerCardPinned
                    questionRecord={questionRecord}
                    answerRecord={answerRecord}
                    enableHelpPopover={questionIndex === 0}
                    key={answerRecord.id}
                    collectionId={collectionId || ""}
                    conversationId={conversationId}
                  />
                ))
              )}
            </Stack>
          </Stack>
        ))}
      {filteredQuestions.length > 0 && (
        <Box position="relative" pb="4">
          <Box ref={lastMessageObserverRef} position="absolute" bottom="0" left="0" right="0" h="300px" pointerEvents="none" />
          {isLoading && (
            <Center>
              <LoadingMessage message="Loading more questions" />
            </Center>
          )}
        </Box>
      )}
    </Stack>
  );
};
