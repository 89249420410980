import { Box, Stack, useColorModeValue, useBreakpointValue } from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import type { DealFinderProps } from "../../types/dealfinder";
import { DealFinderContent } from "./components/DealFinderContent";
import { TabTitle } from "screens/common/components/TabTitle";
import { useCustomScrollbar } from "hooks/useCustomScrollbar";
import { FilterButtons } from "screens/collection/views/AutocompleteSuggestion";

export const DealFinder: React.FC<DealFinderProps> = ({ initialFilter }) => {
  const bgColor = useColorModeValue("#fbfbfb", "gray.800");
  const isMobile = useBreakpointValue({ base: true, md: false });
  const containerRef = useRef<HTMLDivElement>(null);
  const { scrollbarStyle } = useCustomScrollbar(containerRef, { width: "2px", barTransparency: 0.2 });
  const [activeFilter, setActiveFilter] = useState<string | null>(null);
  const filterOptions = ["EQUITY", "ETF", "MUTUAL FUNDS", "PRIVATE"];

  return (
    <Box bg={bgColor} fontFamily="Montserrat" overflow="auto" height={"100%"} pb="2rem" css={scrollbarStyle}>
      <TabTitle title={`Deal Finder`} />
      <Box pb={0}>
        <Stack spacing={"2rem"} alignItems="center" pt={isMobile ? "1rem" : "2rem"}>
          <Box display="flex" justifyContent="center">
            <FilterButtons uniqueFilters={filterOptions} activeFilter={activeFilter} setActiveFilter={setActiveFilter} />
          </Box>
          <DealFinderContent activeFilter={activeFilter} setActiveFilter={setActiveFilter} />
        </Stack>
      </Box>
    </Box>
  );
};

export default DealFinder;
