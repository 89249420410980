import { Record, String, Array } from "runtypes";
import type { Static } from "runtypes";
import { TickerStatus } from "./TickerStatus";
import { ChangeLogEntry } from "./ChangeLogEntry";

export const Ticker = Record({
  creationByUser: String,
  creationDate: String,
  exchange: String,
  id: String,
  lastUpdatedByUser: String.nullable(),
  lastUpdatedDate: String.nullable(),
  name: String,
  dataProviderId: String.nullable().optional(),
  notes: String.nullable().optional(),
  companyLogoUrl: String.nullable().optional(),
  companyBannerUrl: String.nullable().optional(),
  companyUrl: String.nullable().optional(),
  companyTaxId: String.nullable().optional(),
  companyLegalName: String.nullable().optional(),
  status: TickerStatus,
  type: String.nullable(),
  symbol: String,
  changeLog: Array(ChangeLogEntry).optional(),
});

export type Ticker = Static<typeof Ticker>;
