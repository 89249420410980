import { Box, useColorModeValue, useBreakpointValue } from "@chakra-ui/react";
import React from "react";
import QuadrantChart from "../collections/chartComponents/QuadrantChart/QuadrantChart";
import type { QuadrantDataPoint } from "../collections/chartComponents/data/quadrantData";
import { useTileProps } from "hooks";
import { useCopyValue } from "hooks/useCopies";
import { SimpleMetric } from "./SimpleMetric";

interface HomeQuadrantChartProps {
  quadrantData: QuadrantDataPoint[];
}

export const HomeQuadrantChart: React.FC<HomeQuadrantChartProps> = ({ quadrantData }) => {
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const commonTileProps = useTileProps();
  const tileBgColor = useColorModeValue("white", "#161B25");
  const quadrantHelpInfo = useCopyValue("copy_chart_quadrant_help");
  const [quadrantHelpText, quadrantHelpUrl] = quadrantHelpInfo || [];

  return (
    <Box
      className={"ch-metric-chart-quadrant"}
      {...commonTileProps}
      px={isMobile ? "1rem" : "0"}
      py="0"
      bgColor={tileBgColor}
      borderRadius={isMobile ? "none" : "lg"}
      width="100%"
      flexShrink={0}
      height="100%">
      <SimpleMetric
        className="ch-chart-help-quadrant"
        title="Portfolio Investment (Pi) Quadrant"
        helpText={quadrantHelpText}
        helpUrl={quadrantHelpUrl}>
        <QuadrantChart data={quadrantData} xAxisLabel="Strength of Fundamentals" yAxisLabel="Strength of Market Perception" />
      </SimpleMetric>
    </Box>
  );
};
