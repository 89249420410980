import React, { useEffect, useState, useCallback, useMemo } from "react";
import type { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Stack,
  AccordionIcon,
  useBreakpointValue,
  Flex,
  Tooltip,
  IconButton,
  useColorModeValue,
  Skeleton,
  Center,
} from "@chakra-ui/react";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import type { DropResult } from "@hello-pangea/dnd";
import type { IconType } from "react-icons";
import { SidebarButton } from "./SidebarButton";
import { useConversationContext } from "screens/thread/ConversationContext";
import {
  useCollections,
  filterCollectionsByType,
  useUserPreference,
  useProjectParams,
  useAllEntitlementKeys,
  useUserProfile,
  useIsInitialCollectionSyncCompleted,
  useIsMainAppLoading,
  useSkeletonProps,
} from "hooks";
import type { Collection } from "types/collection";
import { updateTypedUserPreference } from "state/userPreference/operations";
import { AddIcon } from "@chakra-ui/icons";
import { FiShare2 } from "react-icons/fi";

interface Props {
  text?: string;
  selectedProjectCategory?: string;
  selectedProjectId?: string;
  icon?: IconType;
  className?: string;
}

export const SidebarCategory: FunctionComponent<React.PropsWithChildren<Props>> = React.memo(
  ({ text, selectedProjectCategory, selectedProjectId, icon, className }) => {
    const { projectId } = useProjectParams();
    const { onPortfolioModalOpen, setSelectedProjectCategory } = useConversationContext();
    const collections = useCollections();
    const navigate = useNavigate();
    const portfolioMenuOrder = useUserPreference("ui_sidebar_portfolio_order") as string[];
    const dispatch = useDispatch();
    const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
    const addButtonFontColor = useColorModeValue("gray.50", "gray.900");
    const { id: currentUserId } = useUserProfile();
    const skeletonStyle = useSkeletonProps();

    const isInitialCollectionSyncCompleted = useIsInitialCollectionSyncCompleted();
    const isMainAppLoading = useIsMainAppLoading();

    const filteredCollections = useMemo(
      () => filterCollectionsByType(collections, selectedProjectCategory || ""),
      [collections, selectedProjectCategory]
    );

    const [orderedCollections, setOrderedCollections] = useState<Collection[]>([]);
    const [sharedCollections, setSharedCollections] = useState<Collection[]>([]);
    const [groupedSharedCollections, setGroupedSharedCollections] = useState<{ [key: string]: Collection[] }>({});
    const [showSkeletons, setShowSkeletons] = useState(true);

    const sharedEntitlements = useMemo(() => {
      const entitlementSet = new Set<string>();
      filteredCollections.forEach((collection) => {
        if (collection.shareDetails?.entitlement) {
          entitlementSet.add(collection.shareDetails.entitlement);
        }
      });
      return Array.from(entitlementSet);
    }, [filteredCollections]);

    const entitlementResults = useAllEntitlementKeys(sharedEntitlements);

    useEffect(() => {
      const timeoutId = setTimeout(() => {
        setShowSkeletons(false);
      }, 5000);

      const storedOrder = portfolioMenuOrder || [];

      const nonSharedCollections = filteredCollections.filter(
        (collection) => !collection.shareDetails || collection.shareDetails?.ownerId === currentUserId
      );
      const sharedItems = filteredCollections.filter((collection) => collection.shareDetails);

      const grouped = sharedItems.reduce((acc, collection) => {
        if (
          collection.shareDetails?.group &&
          ((collection.shareDetails?.entitlement && entitlementResults[collection.shareDetails.entitlement]) ||
            !collection.shareDetails.entitlement) &&
          collection.shareDetails.ownerId !== currentUserId
        ) {
          const group = collection.shareDetails.group;
          if (!acc[group]) {
            acc[group] = [];
          }
          acc[group].push(collection);
        }
        return acc;
      }, {} as { [key: string]: Collection[] });

      const ungroupedShared = sharedItems.filter((collection) => {
        if (collection.shareDetails?.ownerId === currentUserId) {
          return false;
        }
        return !collection.shareDetails?.group;
      });

      const newItems = nonSharedCollections.filter((collection) => !storedOrder.includes(collection.id));
      const orderedItems = [...storedOrder, ...newItems.map((item) => item.id)];

      const reorderedCollections = orderedItems
        .map((id) => nonSharedCollections.find((collection) => collection.id === id))
        .filter((collection): collection is Collection => collection !== undefined);

      setOrderedCollections(reorderedCollections);
      setGroupedSharedCollections(grouped);
      setSharedCollections(ungroupedShared);
      setShowSkeletons(true);

      return () => {
        clearTimeout(timeoutId);
      };
    }, [filteredCollections, portfolioMenuOrder, entitlementResults, currentUserId]);

    const onClickHandler = useCallback(() => {
      setSelectedProjectCategory(selectedProjectCategory);
      onPortfolioModalOpen();
    }, [setSelectedProjectCategory, selectedProjectCategory, onPortfolioModalOpen]);

    const onDragEnd = useCallback(
      (result: DropResult) => {
        if (!result.destination) return;

        setOrderedCollections((prevOrderedCollections) => {
          const newOrder = Array.from(prevOrderedCollections);
          const [reorderedItem] = newOrder.splice(result.source.index, 1);
          newOrder.splice(result.destination!.index, 0, reorderedItem);

          const newOrderIds = newOrder.map((collection) => collection.id);
          dispatch(
            updateTypedUserPreference({
              preferenceKey: "ui_sidebar_portfolio_order",
              value: newOrderIds,
            })
          );

          return newOrder;
        });
      },
      [dispatch]
    );

    const renderDraggableItem = useCallback(
      (collection: Collection, index: number) => (
        <Draggable key={collection.id} draggableId={collection.id} index={index}>
          {(provided) => (
            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
              <SidebarButton
                onClick={() => navigate(`/portfolios/${collection.id}`)}
                text={collection.name}
                screen={`/portfolios/${collection.id}`}
                cssClasses={[
                  `ch-menu-category${
                    collection.shareDetails ? (collection.shareDetails.accessMode === "read" ? "-shared-read" : "-shared-write") : ""
                  }`,
                ]}
                isChildProject
                menuProjectId={collection.id}
              />
            </div>
          )}
        </Draggable>
      ),
      [navigate]
    );

    return (
      <Stack spacing="1rem">
        <Accordion allowToggle defaultIndex={0} reduceMotion>
          <AccordionItem border="none">
            <Flex alignItems="center" width="100%">
              <SidebarButton text={text} icon={icon} cssClasses={[className || "ch-menu-category-parent"]}>
                <Stack direction={"row"} spacing="5px">
                  <Tooltip
                    maxWidth={"10rem"}
                    label={projectId && projectId.length > 0 ? "" : "Add a new portfolio to manage your equities"}
                    aria-label="Add Portfolio">
                    <IconButton
                      as="div"
                      cursor={"pointer"}
                      className="ch-sidebar-portfolio-button"
                      minWidth={"unset"}
                      boxSize="1.2rem"
                      icon={<AddIcon boxSize={".7rem"} />}
                      onClick={(evt) => {
                        evt.preventDefault();
                        onClickHandler();
                      }}
                      _hover={{}}
                      _active={{}}
                      bgColor="primary.default"
                      color={addButtonFontColor}
                      aria-label={""}
                    />
                  </Tooltip>
                  <AccordionButton _expanded={{}} _hover={{}} p="0" width="auto" minWidth="unset" paddingInline={0}>
                    <AccordionIcon color="gray.500" />
                  </AccordionButton>
                </Stack>
              </SidebarButton>
            </Flex>
            {orderedCollections.length > 0 ? (
              <AccordionPanel pl={"0"} pb="1rem" pt="1rem" paddingInline={0}>
                <Stack spacing="1rem" pl={isMobile ? "2rem" : "unset"}>
                  {orderedCollections.length > 0 && (
                    <DragDropContext onDragEnd={onDragEnd}>
                      <Droppable droppableId="sidebar-buttons">
                        {(provided) => (
                          <Stack spacing="1rem" {...provided.droppableProps} ref={provided.innerRef}>
                            {orderedCollections.map(renderDraggableItem)}
                            {provided.placeholder}
                          </Stack>
                        )}
                      </Droppable>
                    </DragDropContext>
                  )}
                </Stack>
              </AccordionPanel>
            ) : showSkeletons && (isMainAppLoading || !isInitialCollectionSyncCompleted) ? (
              <Stack spacing="1.2rem" py="1rem">
                {Array.from({ length: 5 }, (_, i) => (
                  <Stack justifyContent={"space-between"} spacing=".5rem" direction="row" px="0" key={`skeleton-${i}`}>
                    <Skeleton {...skeletonStyle} height="1.4rem" width="2rem" fadeDuration={0.5} speed={0.8} />
                    <Skeleton {...skeletonStyle} height="1.4rem" width="100%" fadeDuration={0.5} speed={0.8} />
                    <Center>
                      <Skeleton {...skeletonStyle} height=".5rem" width=".5rem" fadeDuration={0.5} speed={0.8} />
                    </Center>
                  </Stack>
                ))}
              </Stack>
            ) : null}
          </AccordionItem>
        </Accordion>

        {Object.entries(groupedSharedCollections).map(([group, collections]) => (
          <Accordion key={group} allowToggle reduceMotion defaultIndex={0}>
            <AccordionItem border="none">
              <Flex alignItems="center" width="100%">
                <SidebarButton text={group} icon={FiShare2} cssClasses={["ch-menu-category-shared-parent"]} isStatic>
                  <AccordionButton _expanded={{}} _hover={{}} p="0" width="auto" minWidth="unset" paddingInline={0}>
                    <AccordionIcon color="gray.500" />
                  </AccordionButton>
                </SidebarButton>
              </Flex>
              <AccordionPanel pl={"0"} py="1rem" paddingInline={0}>
                <Stack spacing="1rem" pl={isMobile ? "2rem" : "unset"}>
                  {collections.map((collection) => (
                    <SidebarButton
                      key={collection.id}
                      onClick={() => navigate(`/portfolios/${collection.id}`)}
                      text={collection.name}
                      screen={`/portfolios/${collection.id}`}
                      cssClasses={[`ch-menu-category${collection.shareDetails?.accessMode === "read" ? "-shared-read" : "-shared-write"}`]}
                      isChildProject
                      isStatic
                      menuProjectId={collection.id}
                    />
                  ))}
                </Stack>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        ))}

        {sharedCollections.length > 0 && (
          <Accordion allowToggle reduceMotion defaultIndex={0}>
            <AccordionItem border="none">
              <Flex alignItems="center" width="100%">
                <SidebarButton text="Shared Portfolios" icon={FiShare2} cssClasses={["ch-menu-category-shared-parent"]} isStatic>
                  <AccordionButton _expanded={{}} _hover={{}} p="0" width="auto" minWidth="unset" paddingInline={0}>
                    <AccordionIcon color="gray.500" />
                  </AccordionButton>
                </SidebarButton>
              </Flex>
              <AccordionPanel pl={"0"} pb="1rem" pt="1rem" paddingInline={0}>
                <Stack spacing="1rem" pl={isMobile ? "2rem" : "unset"}>
                  {sharedCollections.map((collection) => (
                    <SidebarButton
                      key={collection.id}
                      onClick={() => navigate(`/portfolios/${collection.id}`)}
                      text={collection.name}
                      screen={`/portfolios/${collection.id}`}
                      cssClasses={[`ch-menu-category${collection.shareDetails?.accessMode === "read" ? "-shared-read" : "-shared-write"}`]}
                      isChildProject
                      isStatic
                      menuProjectId={collection.id}
                    />
                  ))}
                </Stack>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        )}
      </Stack>
    );
  }
);
