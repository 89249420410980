import React, { useEffect } from "react";
import { useColorModeValue, InputGroup, IconButton, InputRightElement, Box, InputLeftElement, Tooltip, useTheme } from "@chakra-ui/react";
import { ArrowForwardIcon, Search2Icon } from "@chakra-ui/icons";
import { AutoCompleteInput } from "@choc-ui/chakra-autocomplete";
import { TypingIndicator } from "screens/thread/components/cells/components";
import { useProjectParams } from "hooks";
import { InputBarAttachmentButton } from "screens/conversation/components/InputBar/InputBarAttachmentButton";
import { CharliLogoCircle } from "screens/thread/CharliLogoCircle";

interface AutocompleteSuggestionInputProps {
  inputId: string;
  value?: string;
  initialText?: string;
  isInputDisabled?: boolean;
  isSubmitDisabled?: boolean;
  hasLeftIcon?: boolean;
  isLoading?: boolean;
  className?: string;
  inputRef: React.RefObject<HTMLInputElement>;
  canBypassDisabled?: boolean;
  isFocusedInputDisabled: boolean;
  isConversationOpen: boolean;
  conversationState?: string;
  isWebsocketConnected?: boolean;
  handleInputOnClick: (onOpen: () => void) => void;
  handleSubmit: () => void;
  handleInputChange: (ev: React.ChangeEvent<HTMLInputElement>) => void;
  onHandleConversation: () => void;
  setIsAnotherInputFocused: (value: boolean) => void;
  onBlur?: () => void;
  onOpen: () => void;
}

export const AutocompleteSuggestionInput = React.memo(
  ({
    inputId,
    value,
    initialText,
    isInputDisabled,
    isSubmitDisabled,
    hasLeftIcon,
    isLoading,
    className,
    inputRef,
    canBypassDisabled,
    isFocusedInputDisabled,
    isConversationOpen,
    conversationState,
    isWebsocketConnected,
    handleInputOnClick,
    handleSubmit,
    handleInputChange,
    onHandleConversation,
    setIsAnotherInputFocused,
    onBlur,
    onOpen,
  }: AutocompleteSuggestionInputProps) => {
    const inputBgColor = useColorModeValue("#fbfbfb", "gray.700");
    const inputTextareaBgColor = useColorModeValue("white", "gray.700");
    const inputBorderColor = useColorModeValue("gray.300", "gray.700");
    const submitColor = useColorModeValue("primary.default", "gray.300");
    const { parentRoute } = useProjectParams();
    const theme = useTheme();

    // Manual auto-resize implementation
    useEffect(() => {
      if (inputRef.current) {
        const textareaElement = inputRef.current;
        const resizeTextarea = () => {
          if (textareaElement) {
            textareaElement.style.height = "auto";
            textareaElement.style.height = `${textareaElement.scrollHeight}px`;
          }
        };

        resizeTextarea();
        textareaElement.addEventListener("input", resizeTextarea);
        return () => textareaElement.removeEventListener("input", resizeTextarea);
      }
    }, [inputRef, value]);

    return (
      <InputGroup position="relative" backgroundColor={"transparent"}>
        <InputLeftElement height="100%" width="unset" zIndex={2}>
          {value?.startsWith("/") || conversationState === "action" ? (
            <InputBarAttachmentButton size={"md"} />
          ) : (
            hasLeftIcon && (
              <Tooltip label={isConversationOpen ? "Close conversation" : "Open conversation"} placement="top">
                <Box pl="7px">
                  <CharliLogoCircle className="project-conversation-button" onClick={onHandleConversation} isSquareIcon />
                </Box>
              </Tooltip>
            )
          )}
        </InputLeftElement>
        <AutoCompleteInput
          id={inputId}
          ref={inputRef}
          onClick={() => handleInputOnClick(onOpen)}
          onBlur={() => {
            setIsAnotherInputFocused(false);
            onBlur?.();
          }}
          autoComplete="off"
          paddingInlineStart="3rem"
          paddingInlineEnd="3rem"
          paddingLeft={value?.startsWith("/") ? "2.5rem" : hasLeftIcon ? "2.7rem" : "1rem"}
          borderRadius="1.4rem!important"
          aria-autocomplete="both"
          className={className ? className : "ch-question-input"}
          disabled={canBypassDisabled && !isFocusedInputDisabled ? false : isInputDisabled || isFocusedInputDisabled}
          backgroundColor={inputBgColor}
          placeholder={initialText}
          onChange={handleInputChange}
          _disabled={{
            cursor: "not-allowed",
            opacity: 0.7,
            color: "gray.200",
          }}
          _hover={{}}
          loadingIcon={<TypingIndicator size="small" />}
          borderColor={inputBorderColor}
          borderWidth="1px"
          bgColor={inputTextareaBgColor}
          value={value}
          resize="none"
          minHeight="40px"
          overflow="hidden"
          sx={{
            "&.chakra-textarea": {
              transition: "height 0.1s ease",
              lineHeight: theme.lineHeights.base,
              zIndex: 20,
            },
          }}
        />
        <InputRightElement height="100%" width="unset" zIndex={value && value.length > 0 ? 2 : 0}>
          <IconButton
            className="ch-autocomplete-submit"
            aria-label="submit"
            borderRadius="full"
            color={submitColor}
            size="xs"
            mr="5px"
            p="3px"
            bgColor={"transparent"}
            _disabled={{ cursor: "not-allowed", opacity: 0.6 }}
            isDisabled={
              canBypassDisabled && !isFocusedInputDisabled
                ? false
                : isLoading || !isWebsocketConnected || isSubmitDisabled || isInputDisabled || isFocusedInputDisabled
            }
            _hover={{ fontWeight: "bold" }}
            icon={
              parentRoute === "deal-finder" ? (
                <Search2Icon width="1rem" height="1rem" />
              ) : (
                <ArrowForwardIcon width="1.3rem" height="1.3rem" />
              )
            }
            onClick={handleSubmit}
          />
        </InputRightElement>
      </InputGroup>
    );
  }
);
