import { createAsyncThunk } from "@reduxjs/toolkit";
import { getCollections, getCollectionsByGroup, getCollectionsByPortfolio, getCollectionsQuery } from "api/collection/collection";
import { actions as contentActions } from "state/content/reducer";
import type { RootState } from "state/rootReducer";
import { actions as workflowActions } from "state/workflow/reducer";
import { requestToDownloadWorkflows } from "state/workflow/utils";
import type { Collection } from "types/collection";

const LIMIT_PROJECTS_DOWNLOADED = 100;
const LIMIT_HOME_PROJECTS = 10;
const LIMIT_HOME_PORTFOLIO_PROJECTS = 50;

const processAndDispatchCollectionsActions = (
  collections: Collection[],
  { dispatch, getState },
  options?: { shouldDownloadWorkflows?: boolean }
) => {
  if (options?.shouldDownloadWorkflows) {
    const state = getState() as RootState;
    const workflowsIds = collections.flatMap((collection) => (collection.workflowIds ? collection.workflowIds : []));

    requestToDownloadWorkflows(workflowsIds, state.workflow.isLoadingWorkflowMap, dispatch);
  }

  return;
};

export const downloadCollections = createAsyncThunk(
  "collections/download-all",
  async (
    { ids, downloadCollectionWorkflows = false }: { ids: string[]; downloadCollectionWorkflows?: boolean },
    { dispatch, getState }
  ) => {
    if (ids.length === 0) {
      return [];
    }

    const collections = await getCollections(ids);

    const collectionsInResponseMap = collections.reduce((acc: Set<string>, collection) => {
      acc.add(collection.id);
      return acc;
    }, new Set<string>());

    ids.forEach((id) => {
      if (!collectionsInResponseMap.has(id)) {
        dispatch(removeCollection({ id }));
      }
    });

    processAndDispatchCollectionsActions(collections, { dispatch, getState }, { shouldDownloadWorkflows: downloadCollectionWorkflows });

    return collections;
  }
);

export const downloadCollectionsByPortfolio = createAsyncThunk(
  "collections/download-by-portfolio",
  async ({ portfolioId }: { portfolioId: string }, { dispatch, getState }) => {
    const collections = await getCollectionsByPortfolio(portfolioId);

    processAndDispatchCollectionsActions(collections, { dispatch, getState }, { shouldDownloadWorkflows: true });

    return collections;
  }
);

export const downloadCollectionsByGroup = createAsyncThunk(
  "collections/download-by-group",
  async ({ groupId }: { groupId: string }, { dispatch, getState }) => {
    const collections = await getCollectionsByGroup(groupId);

    processAndDispatchCollectionsActions(collections, { dispatch, getState }, { shouldDownloadWorkflows: true });

    return collections;
  }
);

// TODO : we need to filter the collections with more parameters then just type
export const downloadCollectionsByType = createAsyncThunk(
  "collections/download-by-type",
  async ({ collectionType, limit = LIMIT_PROJECTS_DOWNLOADED }: { collectionType: string; limit?: number }, thunkAPI) => {
    const recentCollections = await getCollectionsQuery({ collectionType, limit });

    thunkAPI.dispatch(downloadCollections({ ids: recentCollections.metadataIds, downloadCollectionWorkflows: true }));

    return recentCollections;
  }
);

export const downloadInitialCollections = createAsyncThunk(
  "collections/download-initial-collections",
  async (
    {
      homeLimit = LIMIT_HOME_PROJECTS,
      portfolioLimit = LIMIT_HOME_PORTFOLIO_PROJECTS,
    }: { limit?: number; homeLimit?: number; portfolioLimit?: number },
    thunkAPI
  ) => {
    const [recentDDProjects, recentRecordManagementProjects] = await Promise.all([
      getCollectionsQuery({ collectionType: "due_diligence", limit: homeLimit }),
      getCollectionsQuery({ collectionType: "portfolio", limit: portfolioLimit }),
    ]);

    const recentMetadataIds = [...recentDDProjects.metadataIds, ...recentRecordManagementProjects.metadataIds];

    const collections = (thunkAPI.getState() as RootState).collection.collections;
    const collectionsToFetch = recentMetadataIds.filter((id) => !collections[id]);

    thunkAPI.dispatch(downloadCollections({ ids: collectionsToFetch, downloadCollectionWorkflows: true }));
  }
);

export const removeCollection = createAsyncThunk("collections/remove", async ({ id }: { id: string }, thunkAPI) => {
  const collection = (thunkAPI.getState() as RootState).collection.collections[id];

  if (collection && collection.workflowIds) {
    collection.workflowIds.forEach((workflowId) => {
      thunkAPI.dispatch(workflowActions.removeWorkflow(workflowId));
    });

    collection.metadataIds.forEach((metadataId) => {
      thunkAPI.dispatch(contentActions.removeContent(metadataId));
    });
  }

  return id;
});
