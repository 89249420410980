import { Box, Button, Icon, IconButton, useBreakpointValue, useToast, VStack } from "@chakra-ui/react";
import { useCollectionKey, useLinkActionProps } from "hooks";
import { motion } from "framer-motion";
import React, { useCallback, memo, useMemo, useRef, useContext } from "react";
import { Popover } from "react-tiny-popover";
import { TiThumbsDown, TiThumbsUp } from "react-icons/ti";
import { ToastMessageContent } from "screens/common/components";
import { sendMessage } from "state/websocket/operations";
import type { RequestEntities } from "types/charliui";
import { AnswerStatus } from "types/question";
import { useDispatch } from "react-redux";
import { ConversationContext } from "screens/thread/ConversationContext";
import { useAddToCharliContext } from "screens/panels/addToCharli/AddToCharliWizard/AddToCharliProvider";

interface Props {
  action: "up" | "down";
  size?: string;
  resultId?: string;
  collectionId?: string;
  isOpen: boolean;
  onToggle: () => void;
  onClose?: () => void;
  isDisabled?: boolean;
}

const MotionVStack = motion(VStack);
const MotionButton = motion(Button);

const buttonVariants = {
  hover: { scale: 1.1 },
};

const ProjectThumbHotlinks: React.FC<Props> = memo(
  ({ action, size = "sm", resultId, collectionId, isOpen, onToggle, onClose, isDisabled }) => {
    const commonLinkActionProps = useLinkActionProps(size);
    const mainRef = useRef<HTMLDivElement | null>(null);
    const { setConversationId, onConversationOpen } = useContext(ConversationContext);
    const dispatch = useDispatch();
    const toast = useToast();
    const conversationId = useCollectionKey(collectionId, "conversationId") || "";
    const { isVerifiedAIPanelOpen, onVerifiedAIPanelClose } = useAddToCharliContext();
    const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });

    const motionVStackProps = useMemo(
      () => ({
        alignItems: "flex-start" as const,
        initial: { opacity: 0 },
        animate: { opacity: 1 },
        transition: { duration: 0.5, staggerChildren: 0.5, delayChildren: 0.5 },
      }),
      []
    );

    const updateAnswerStatus = useCallback(
      (reason?: string, message?: string) => {
        if (!conversationId) {
          return;
        }
        const entities: RequestEntities = [];
        resultId && entities.push({ entity: "answer_id", value: resultId });
        entities.push({ entity: "answer_status", value: AnswerStatus.unpinned });
        reason && entities.push({ entity: "content_delete_reason", value: reason });
        collectionId && entities.push({ entity: "collection_id", value: collectionId });
        onToggle();
        isVerifiedAIPanelOpen && onVerifiedAIPanelClose();

        dispatch(
          sendMessage({
            conversationId: conversationId,
            intent: "/manage_answer",
            entities: entities,
          })
        );

        toast({
          render: ({ onClose }) => (
            <ToastMessageContent
              message={`I'll update this answer now ${message}. You can open the conversation I started about it by clicking this message`}
              onClick={() => {
                setConversationId(conversationId);
                onConversationOpen();
                onClose();
              }}
              onClose={onClose}
            />
          ),
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
      },
      [
        conversationId,
        resultId,
        collectionId,
        onToggle,
        isVerifiedAIPanelOpen,
        onVerifiedAIPanelClose,
        dispatch,
        toast,
        setConversationId,
        onConversationOpen,
      ]
    );

    const handleActionButtonClick = useCallback(
      (message: string | undefined, reason?: string, action?: "unpin" | undefined) => {
        if (action === "unpin") {
          updateAnswerStatus(reason, message);
          return;
        }
        onToggle();
        toast({
          render: ({ onClose }) => <ToastMessageContent message={`I'll update this answer now ${message}.`} onClose={onClose} />,
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
      },
      [onToggle, toast, updateAnswerStatus]
    );

    const handleClickOutside = useCallback(() => {
      onClose && onClose();
    }, [onClose]);

    const ThumbsUpHotlinkActions = useMemo(
      () => () =>
        (
          <MotionVStack {...motionVStackProps}>
            <MotionButton
              {...commonLinkActionProps}
              className={"ch-thumbs-up-hotlink-1"}
              variants={buttonVariants}
              whileHover="hover"
              onTouchStart={() => handleActionButtonClick("noting that you like the answer")}
              onMouseDown={() => handleActionButtonClick("noting that you like the answer")}>
              I like the answer
            </MotionButton>
            <MotionButton
              {...commonLinkActionProps}
              variants={buttonVariants}
              whileHover="hover"
              onTouchStart={() => handleActionButtonClick("and note that the question and answer should be used in the report")}
              onMouseDown={() => handleActionButtonClick("and note that the question and answer should be used in the report")}>
              Good question and answer for the report
            </MotionButton>
            <MotionButton
              {...commonLinkActionProps}
              variants={buttonVariants}
              whileHover="hover"
              onTouchStart={() => handleActionButtonClick("and note that its very insightful and should be prominent")}
              onMouseDown={() => handleActionButtonClick("and note that its very insightful and should be prominent")}>
              Very insightful answer that should be prominent
            </MotionButton>
            <MotionButton
              {...commonLinkActionProps}
              variants={buttonVariants}
              whileHover="hover"
              onTouchStart={() => handleActionButtonClick("and note that its a great question and brilliant answer for reporting")}
              onMouseDown={() => handleActionButtonClick("and note that its a great question and brilliant answer for reporting")}>
              Great question and brilliant answer for reporting
            </MotionButton>
            <MotionButton
              {...commonLinkActionProps}
              variants={buttonVariants}
              whileHover="hover"
              onTouchStart={() => handleActionButtonClick("and make it a highlight")}
              onMouseDown={() => handleActionButtonClick("and make it a highlight")}>
              Make this a highlight!
            </MotionButton>
          </MotionVStack>
        ),
      [commonLinkActionProps, motionVStackProps, handleActionButtonClick]
    );

    const ThumbsDownHotlinkActions = useMemo(
      () => () =>
        (
          <MotionVStack {...motionVStackProps}>
            <MotionButton
              {...commonLinkActionProps}
              className={"ch-thumbs-down-hotlink-1"}
              variants={buttonVariants}
              whileHover="hover"
              onTouchStart={() => handleActionButtonClick("stating that you do not like the answer")}
              onMouseDown={() => handleActionButtonClick("stating that you do not like the answer")}>
              Not that insightful, but keep it pinned
            </MotionButton>
            <MotionButton
              {...commonLinkActionProps}
              className={"ch-thumbs-down-hotlink-2"}
              variants={buttonVariants}
              whileHover="hover"
              onTouchStart={() =>
                handleActionButtonClick("citing that you feel this answer contains erroneous information", "erroneous", "unpin")
              }
              onMouseDown={() =>
                handleActionButtonClick("citing that you feel this answer contains erroneous information", "erroneous", "unpin")
              }>
              Erroneous information, unpin the answer
            </MotionButton>
            <MotionButton
              {...commonLinkActionProps}
              variants={buttonVariants}
              whileHover="hover"
              onMouseDown={() => handleActionButtonClick("stating that you do not like the answer", "not good", "unpin")}
              onTouchStart={() => handleActionButtonClick("stating that you do not like the answer", "not good", "unpin")}>
              Do not like the answer, unpin it
            </MotionButton>
            <MotionButton
              {...commonLinkActionProps}
              variants={buttonVariants}
              whileHover="hover"
              onMouseDown={() => handleActionButtonClick("and mark this answer as irrelevant", "irrelevant", "unpin")}
              onTouchStart={() => handleActionButtonClick("and mark this answer as irrelevant", "irrelevant", "unpin")}>
              Irrelevant, unpin the answer
            </MotionButton>
            <MotionButton
              {...commonLinkActionProps}
              variants={buttonVariants}
              whileHover="hover"
              onMouseDown={() =>
                handleActionButtonClick("citing that you do not like this question all together", "remove question", "unpin")
              }
              onTouchStart={() =>
                handleActionButtonClick("citing that you do not like this question all together", "remove question", "unpin")
              }>
              Do not like the question all together, unpin the answer
            </MotionButton>
          </MotionVStack>
        ),
      [commonLinkActionProps, motionVStackProps, handleActionButtonClick]
    );

    return (
      <Box
        ref={mainRef}
        borderWidth={"1px"}
        borderColor={isMobile ? "primary.default" : "transparent"}
        borderStyle="dashed"
        borderRadius={"full"}
        lineHeight={"1rem"}
        _hover={{
          borderColor: "primary.default",
        }}>
        <Popover
          parentElement={mainRef?.current || undefined}
          isOpen={isOpen}
          positions={["top"]}
          padding={10}
          align="start"
          transformMode="relative"
          onClickOutside={handleClickOutside}
          content={action === "up" ? ThumbsUpHotlinkActions : ThumbsDownHotlinkActions}>
          <IconButton
            isDisabled={isDisabled}
            color="gray.500"
            as="div"
            cursor={"pointer"}
            p="0!important"
            mt="3px!important"
            minWidth="unset!important"
            paddingInline="0!important"
            aria-label="action button"
            marginInline="0!important"
            className={`ch-qa-thumb-${action}`}
            icon={<Icon as={action === "up" ? TiThumbsUp : TiThumbsDown} fontSize="1.4rem" />}
            backgroundColor="transparent"
            _hover={{
              color: "primary.default",
            }}
            _active={{}}
            boxSize="1.1rem"
            onClick={(event) => {
              !isDisabled && onToggle();
              event.stopPropagation();
            }}
          />
        </Popover>
      </Box>
    );
  }
);

ProjectThumbHotlinks.displayName = "ProjectThumbHotlinks";

export { ProjectThumbHotlinks };
