import { FormControl, FormErrorMessage, Input, Box, Tag, TagLabel, TagCloseButton, HStack, VStack, Button, Text } from "@chakra-ui/react";
import React, { useCallback, useMemo, useState } from "react";
import type { FieldValues } from "react-hook-form";
import { Controller, useWatch } from "react-hook-form";
import { useFormContext } from "react-hook-form";
import { useController } from "react-hook-form";
import { SectionHeader } from "screens/content/contentView/previewSection/SectionHeader";
import type { IFieldMultiSelectWithTags } from "../types";

export const MultiSelectWithTags = <T extends FieldValues>({
  entity,
  label,
  placeholder,
  suggestions,
  background,
  size = "sm",
}: IFieldMultiSelectWithTags<T>) => {
  const { control } = useFormContext<FieldValues>();
  const { field } = useController({ control, name: entity });
  const [inputValue, setInputValue] = useState("");
  const [filteredSuggestions, setFilteredSuggestions] = useState<string[]>(suggestions);

  const value = useWatch({ control, name: entity });

  const setTags = useCallback((tags: string[]) => field.onChange(tags.join(",")), [field]);
  const tags = useMemo(() => (value ? value.split(",") : []), [value]);

  // Filter suggestions based on input value and already selected tags
  const updateFilteredSuggestions = useCallback(
    (input: string) => {
      const filtered = suggestions.filter(
        (suggestion) => !tags.includes(suggestion) && suggestion.toLowerCase().includes(input.toLowerCase())
      );
      setFilteredSuggestions(filtered);
    },
    [suggestions, tags]
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    updateFilteredSuggestions(newValue);
  };

  const handleAddTag = () => {
    if (inputValue && !tags.includes(inputValue)) {
      const newTags = [...tags, inputValue];
      setTags(newTags);
      setInputValue("");
      updateFilteredSuggestions("");
    }
  };

  const handleRemoveTag = (tagToRemove: string) => {
    const newTags = tags.filter((tag) => tag !== tagToRemove);
    setTags(newTags);
    updateFilteredSuggestions(inputValue);
  };

  const handleSuggestionClick = (suggestion: string) => {
    if (!tags.includes(suggestion)) {
      const newTags = [...tags, suggestion];
      setTags(newTags);
      updateFilteredSuggestions(inputValue);
    }
  };

  // Initialize filtered suggestions
  React.useEffect(() => {
    updateFilteredSuggestions(inputValue);
  }, [suggestions, tags, inputValue, updateFilteredSuggestions]);

  return (
    <Controller
      name={entity}
      control={control}
      render={({ field: { name, onBlur }, fieldState: { error } }) => (
        <>
          <FormControl isInvalid={!!error}>
            <SectionHeader title={label} />
            <VStack align="stretch" spacing={2}>
              <HStack>
                <Input
                  value={inputValue}
                  onChange={handleInputChange}
                  onBlur={(e) => {
                    onBlur();
                    // Add tag on blur if there's a value
                    if (inputValue.trim()) {
                      handleAddTag();
                    }
                  }}
                  name={name}
                  size={size}
                  backgroundColor={background}
                  placeholder={placeholder}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      handleAddTag();
                    }
                  }}
                />
                <Button size={size} onClick={handleAddTag} isDisabled={!inputValue.trim() || tags.includes(inputValue)}>
                  Add
                </Button>
              </HStack>

              {/* Display selected tags */}
              <Box>
                <HStack spacing={2} flexWrap="wrap">
                  {tags.map((tag, index) => (
                    <Tag key={`tag-${index}`} size="md" borderRadius="full" variant="solid" colorScheme="blue" my={1}>
                      <TagLabel>{tag}</TagLabel>
                      <TagCloseButton onClick={() => handleRemoveTag(tag)} />
                    </Tag>
                  ))}
                </HStack>
              </Box>

              {/* Display suggestions */}
              {filteredSuggestions.length > 0 && (
                <Box mt={2}>
                  <Text fontSize="sm" mb={1}>
                    Suggestions:
                  </Text>
                  <HStack spacing={2} flexWrap="wrap">
                    {filteredSuggestions.slice(0, 10).map((suggestion, index) => (
                      <Tag
                        key={`suggestion-${index}`}
                        size="md"
                        borderRadius="full"
                        variant="outline"
                        colorScheme="blue"
                        cursor="pointer"
                        onClick={() => handleSuggestionClick(suggestion)}
                        my={1}
                        _hover={{ bg: "blue.50" }}>
                        <TagLabel>{suggestion}</TagLabel>
                      </Tag>
                    ))}
                  </HStack>
                </Box>
              )}
            </VStack>

            {error?.message ? <FormErrorMessage>{error?.message as string}</FormErrorMessage> : null}
          </FormControl>
        </>
      )}
    />
  );
};
