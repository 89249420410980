import React, { memo, useMemo, useContext, useCallback, useState, useRef } from "react";
import { Box, Grid, GridItem, HStack, Stack, Text, useBreakpointValue, useColorMode, useColorModeValue } from "@chakra-ui/react";
import { BarChart, Bar, Cell, ResponsiveContainer, ReferenceLine, LabelList, YAxis, ReferenceArea } from "recharts";
import { useProjectParams, useTileProps } from "hooks";
import { ProjectHeaderImage } from "screens/landing/tabs/project/projectLandingTileLayouts/components/ProjectHeaderImage";
import type { RatingType } from "screens/landing/tabs/collections/CollectionsFilterContext";
import { CollectionsFilterContext } from "screens/landing/tabs/collections/CollectionsFilterContext";
import { useNavigate } from "react-router-dom";

export interface BuySellHoldChartItem {
  projectId?: string;
  ticker: string;
  currentPrice: number;
  rating: number;
}

export interface BuySellHoldData {
  items: BuySellHoldChartItem[];
}

interface BuySellHoldChartProps {
  data?: BuySellHoldData;
}

interface TooltipProps {
  active?: boolean;
  payload?: Array<{
    value: number;
    payload: BuySellHoldChartItem & { value: number };
  }>;
}

export type RatingLabel = RatingType;

export const useBuySellChartColors = () => ({
  buy: useColorModeValue("#A0D7FF", "#4A8CC7"),
  underPerform: useColorModeValue("#FFA0A0", "#CC4444"),
  hold: useColorModeValue("#FFE4A0", "#D4A012"),
  outPerform: useColorModeValue("#A0E4E2", "#4A9B99"),
  sell: useColorModeValue("#FFB5C0", "#CC5C66"),
  noRating: useColorModeValue("#D1D5DB", "#6B7280"),
});

type ChartColors = ReturnType<typeof useBuySellChartColors>;

const getColorForRating = (rating: number, chartColors: ChartColors): string => {
  if (rating <= 1) return chartColors.sell;
  else if (rating <= 2) return chartColors.underPerform;
  else if (rating <= 3) return chartColors.hold;
  else if (rating <= 4) return chartColors.outPerform;
  else return chartColors.buy;
};

export type ChartColorKeys = keyof ReturnType<typeof useBuySellChartColors>;

const CustomTooltip = memo(({ active, payload }: TooltipProps) => {
  const commonTileProps = useTileProps();
  const borderColor = useColorModeValue("gray.400", "gray.300");
  const tooltipBgColor = useColorModeValue("gray.700", "gray.900");
  const labelColor = useColorModeValue("gray.300", "gray.400");
  const chartColors = useBuySellChartColors();
  const { projectFilter, parentRoute } = useProjectParams();
  const navigate = useNavigate();
  const handleNavigate = useCallback(
    (projectId: string) => {
      navigate(`/${parentRoute}/${projectFilter}/${projectId}`);
    },
    [navigate, parentRoute, projectFilter]
  );

  const ratingConfig = useMemo(
    () =>
      [
        { max: 1, label: "Sell" as RatingLabel, color: chartColors.sell },
        { max: 2, label: "Underperform" as RatingLabel, color: chartColors.underPerform },
        { max: 3, label: "Hold" as RatingLabel, color: chartColors.hold },
        { max: 4, label: "Outperform" as RatingLabel, color: chartColors.outPerform },
        { max: Infinity, label: "Buy" as RatingLabel, color: chartColors.buy },
      ] as const,
    [chartColors.buy, chartColors.hold, chartColors.outPerform, chartColors.sell, chartColors.underPerform]
  );

  const getRatingInfo = useCallback(
    (rating: number) => {
      return ratingConfig.find((config) => rating <= config.max) || ratingConfig[ratingConfig.length - 1];
    },
    [ratingConfig]
  );

  if (!active || !payload?.length) return null;

  const item = payload[0].payload;

  return (
    <Stack
      {...commonTileProps}
      className="ch-chart-buysell-tooltip"
      zIndex={2000}
      boxShadow={"lg"}
      p="0"
      borderColor={borderColor}
      onClick={() => {
        if (item.projectId) {
          handleNavigate(item.projectId);
        }
      }}>
      <Box px="1rem" pt=".5rem" minH={"2rem"}>
        <Stack direction="row" spacing={2} align="space-between" width="100%">
          <ProjectHeaderImage maxHeight="1.5rem" width="8rem" collectionId={item.projectId} logoOnly />
          <Box bgColor={getColorForRating(item.rating, chartColors)} borderRadius="md" py={"2px"} px="5px">
            <Text fontSize="sm">{getRatingInfo(item.rating).label}</Text>
          </Box>
        </Stack>
      </Box>
      <Box p="1rem" bgColor={tooltipBgColor} color={"gray.100"}>
        <Grid templateColumns="auto 1fr" gap={2} alignItems="center">
          <GridItem>
            <Text fontSize="sm" color={labelColor}>
              Ticker:
            </Text>
          </GridItem>
          <GridItem>
            <Text fontSize="sm">{item.ticker}</Text>
          </GridItem>
          <GridItem>
            <Text fontSize="sm" color={labelColor}>
              Rating:
            </Text>
          </GridItem>
          <GridItem>
            <Text fontSize="sm">
              {getRatingInfo(item.rating).label} ({item.rating.toFixed(2)})
            </Text>
          </GridItem>
          <GridItem>
            <Text fontSize="sm" color={labelColor}>
              Current Price:
            </Text>
          </GridItem>
          <GridItem>
            <Text fontSize="sm">${item.currentPrice}</Text>
          </GridItem>
        </Grid>
      </Box>
    </Stack>
  );
});

CustomTooltip.displayName = "CustomTooltip";

const ChartLegend = memo(() => {
  const chartColors = useBuySellChartColors();
  const textColor = useColorModeValue("gray.600", "gray.300");
  const selectedBgColor = useColorModeValue("blue.100", "blue.700");
  const { selectedRating, setSelectedRating } = useContext(CollectionsFilterContext);

  const legendItems = [
    { label: "Buy" as RatingLabel, color: chartColors.buy },
    { label: "Out Perform" as RatingLabel, color: chartColors.outPerform },
    { label: "Hold" as RatingLabel, color: chartColors.hold },
    { label: "Under Perform" as RatingLabel, color: chartColors.underPerform },
    { label: "Sell" as RatingLabel, color: chartColors.sell },
  ] as const;

  const handleClick = (rating: RatingType) => {
    if (selectedRating === rating) {
      setSelectedRating(undefined);
    } else {
      setSelectedRating(rating);
    }
  };

  return (
    <HStack spacing={4} justify="center" flexWrap="wrap" pt={2}>
      {legendItems.map((item) => (
        <HStack
          key={item.label}
          className={`ch-buysell-${item.label?.toLowerCase()}`}
          spacing={"2px"}
          align="center"
          cursor="pointer"
          onClick={() => handleClick(item.label)}
          bg={selectedRating === item.label ? selectedBgColor : "transparent"}
          p={1}
          borderRadius="md"
          transition="background-color 0.2s"
          _hover={{ bg: selectedBgColor }}>
          <Box w="10px" h="10px" borderRadius="sm" bg={item.color} />
          <Text fontSize="xs" color={textColor}>
            {item.label}
          </Text>
        </HStack>
      ))}
    </HStack>
  );
});

ChartLegend.displayName = "ChartLegend";

export const BuySellHoldChart: React.FC<BuySellHoldChartProps> = memo(({ data }) => {
  const hideTooltipTimeout = useRef<NodeJS.Timeout>();
  const [tooltipData, setTooltipData] = useState<{
    active: boolean;
    payload?: Array<{
      value: number;
      payload: BuySellHoldChartItem & { value: number };
    }>;
    position?: { x: number; y: number };
  }>({ active: false });

  const chartColors = useBuySellChartColors();
  const { colorMode } = useColorMode();
  const isMobile = useBreakpointValue({ base: true, md: false, lg: false }, { fallback: "md", ssr: false });
  const tickerTextColor = useColorModeValue("#4A5568", "#A0AEC0");
  const { setSelectedProjectId, selectedProjectId } = useContext(CollectionsFilterContext);
  const { projectFilter, parentRoute } = useProjectParams();
  const navigate = useNavigate();

  const handleNavigate = useCallback(
    (projectId: string) => {
      navigate(`/${parentRoute}/${projectFilter}/${projectId}`);
    },
    [navigate, parentRoute, projectFilter]
  );

  const chartData = useMemo(() => {
    if (!data?.items) return [];
    return data.items.map((item) => ({
      ...item,
      value: item.rating === 0 ? 0.25 : item.rating,
    }));
  }, [data]);

  const chartSettings = useMemo(
    () => ({
      barSettings: {
        dataKey: "value" as const,
        isAnimationActive: false,
        maxBarSize: 35,
      },
      cellOpacity: colorMode === "dark" ? 0.7 : 1,
    }),
    [colorMode]
  );

  const cells = useMemo(() => {
    return chartData.map((entry, index) => {
      const isSelected = !selectedProjectId || entry.projectId === selectedProjectId;
      const opacity = isSelected ? chartSettings.cellOpacity : 0.3;

      return (
        <Cell
          key={`cell-${index}`}
          onClick={() => {
            if (entry.projectId) {
              handleNavigate(entry.projectId);
            }
          }}
          fill={getColorForRating(entry.rating, chartColors)}
          opacity={opacity}
          onMouseEnter={() => {
            setSelectedProjectId(entry.projectId);
          }}
        />
      );
    });
  }, [chartData, chartColors, selectedProjectId, chartSettings.cellOpacity, handleNavigate, setSelectedProjectId]);

  const backgroundBands = useMemo(() => {
    const bands = [
      { y1: 4, y2: 5, color: chartColors.buy },
      { y1: 3, y2: 4, color: chartColors.outPerform },
      { y1: 2, y2: 3, color: chartColors.hold },
      { y1: 1, y2: 2, color: chartColors.underPerform },
      { y1: 0, y2: 1, color: chartColors.sell },
    ];

    return bands.map((band, index) => (
      <ReferenceArea key={`band-${index}`} y1={band.y1} y2={band.y2} fill={band.color} fillOpacity={0.1} ifOverflow="hidden" />
    ));
  }, [chartColors]);

  return (
    <Box
      onMouseLeave={() => {
        setSelectedProjectId(undefined);
        setTooltipData({ active: false });
      }}
      height={isMobile ? "15rem" : "17rem"}
      width="100%"
      position="relative">
      <Box height="100%" position="relative">
        {tooltipData.active && tooltipData.position && (
          <Box
            position="absolute"
            ref={(el) => {
              if (el && tooltipData.position) {
                const container = el.parentElement;
                if (!container) return;

                const containerRect = container.getBoundingClientRect();
                const top = containerRect.height / 2;
                const spaceOnRight = containerRect.width - tooltipData.position.x;

                let left = "unset";
                let right = "unset";
                if (containerRect.width / 2 > spaceOnRight) {
                  right = `${containerRect.width / 2}px`;
                  left = "unset";
                } else {
                  right = "unset";
                  left = `${containerRect.width / 2}px`;
                }
                el.style.transform = "translate(0, -50%)";
                el.style.right = right;
                el.style.left = left;
                el.style.top = `${top}px`;
              }
            }}
            zIndex={1000}
            onMouseEnter={() => {
              if (hideTooltipTimeout.current) {
                clearTimeout(hideTooltipTimeout.current);
              }
            }}>
            <CustomTooltip active={tooltipData.active} payload={tooltipData.payload} />
          </Box>
        )}
        <ResponsiveContainer width="100%" height="100%">
          <BarChart data={chartData} margin={{ top: 0, right: 0, bottom: 0, left: 0 }}>
            <YAxis hide domain={[0, 5]} />
            {backgroundBands}
            <ReferenceLine y={0} stroke={useColorModeValue("#718096", "#A0AEC0")} />
            <Bar
              {...chartSettings.barSettings}
              style={{ cursor: "pointer" }}
              isAnimationActive={false}
              onMouseOver={(data: any) => {
                if (data && typeof data.value === "number") {
                  const chartItem = data as unknown as BuySellHoldChartItem & { value: number };
                  setTooltipData({
                    active: true,
                    payload: [
                      {
                        value: chartItem.value,
                        payload: chartItem,
                      },
                    ],
                    position: {
                      x: data.tooltipPosition?.x ?? 0,
                      y: data.tooltipPosition?.y ?? 0,
                    },
                  });
                }
              }}
              onMouseMove={(data: any) => {
                if (data && data.tooltipPosition) {
                  setTooltipData((prev) => ({
                    ...prev,
                    position: {
                      x: data.tooltipPosition?.x ?? 0,
                      y: data.tooltipPosition?.y ?? 0,
                    },
                  }));
                }
              }}>
              {cells}
              <LabelList dataKey="ticker" position="top" offset={10} fill={tickerTextColor} fontSize={10} />
              <LabelList
                dataKey="ticker"
                position="bottom"
                offset={10}
                fill={tickerTextColor}
                fontSize={12}
                fontWeight="bold"
                content={(props: any) => {
                  const { x, y, value, payload } = props;
                  if (!payload || payload.value >= 0) return null;
                  return (
                    <text x={x} y={y} dy={16} fill={tickerTextColor} fontSize={10} textAnchor="middle">
                      {value}
                    </text>
                  );
                }}
              />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </Box>
      <ChartLegend />
    </Box>
  );
});

BuySellHoldChart.displayName = "BuySellHoldChart";

export default BuySellHoldChart;
