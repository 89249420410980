import React, { useContext, useMemo } from "react";
import { Box, Stack, useBreakpointValue, useColorModeValue } from "@chakra-ui/react";
import {
  useCollectionKey,
  useConfigMap,
  useGetViewConfig,
  useMainCollectionWorkflowId,
  useProjectParams,
  useTileProps,
  useCompanyStockFromCollectionContentsOrConversation,
  useCollectionMetadataIds,
  useContents,
  useFilterAndGroupReports,
  useManualTags,
  useDownloadCollectionContents,
} from "hooks";
import type { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import { ConversationContext, useConversationContext } from "screens/thread/ConversationContext";
import { CollectionsFilterContext } from "screens/landing/tabs";
import { CollectionStatProgress } from "../../../../collection/components/CollectionStatProgress";
import { TextOverflowTooltip } from "screens/landing/components/TextOverflowTooltip";
import { ProjectHeaderImage } from "./components/ProjectHeaderImage";
import { useWorkflowStatus } from "hooks/useWorkflows";
import { usePausedWorkflowModal } from "screens/collection/views/ProjectActions/PausedWorkflowModal";
import { useWorkflowScheduleByProject } from "hooks/useWorkflowSchedules";
import { ContentPreview } from "screens/content/contentView/previewSection/ContentPreview";
import type { ContentDetails } from "types/content/ContentDetails";
import { ProjectAnalysisPopover } from "../../collections/chartComponents/ProjectAnalysisPopover";
import { ProjectDeleteButton } from "screens/collection/views/ProjectActions/ProjectDeleteButton";

interface Props {
  collectionId: string;
}

export const ProjectLandingSmallPreviousVersionItem: FunctionComponent<Props> = ({ collectionId }) => {
  const { projectFilter, parentRoute } = useProjectParams();
  const tileBorderColor = useColorModeValue("gray.300", "gray.600");
  const tileBgColor = useColorModeValue("white", "#161B25");
  const navigate = useNavigate();
  const { onConversationOpen, onConversationClose } = useConversationContext();
  const configMap = useConfigMap();
  const collectionType = useCollectionKey(collectionId, "collectionType");
  const collectionMetadataIds = useCollectionKey(collectionId, "metadataIds");
  const collectionName = useCollectionKey(collectionId, "name");
  const projectGroupId = useCollectionKey(collectionId, "projectGroupId");
  const shareDetails = useCollectionKey(collectionId, "shareDetails");
  const isSharedRead = shareDetails && shareDetails.accessMode === "read";
  const projectRoute = useGetViewConfig("route", collectionType, configMap);
  const { searchText } = useContext(CollectionsFilterContext);
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const commonTileProps = useTileProps();
  const filteredContentIdsWithoutProjectOutput = useCollectionMetadataIds(collectionId);
  const workflowId = useMainCollectionWorkflowId(collectionId);
  const workflowStatus = useWorkflowStatus(workflowId);
  const { onOpen: onOpenPausedWorkflow } = usePausedWorkflowModal();
  const companyStockDataSource = useCompanyStockFromCollectionContentsOrConversation(collectionId);
  const oneTimeSchedule = useWorkflowScheduleByProject(collectionId);
  const isPlaceholderTile = collectionId === "placeholder";
  const showStockTicker = useGetViewConfig("showStockTicker", collectionType, configMap) || isPlaceholderTile;
  const tagsFilter = useManualTags();
  const contentData = useContents(collectionMetadataIds ?? [], { refreshFromNetwork: false, filterByTags: tagsFilter });
  const contentDataFiltered = useFilterAndGroupReports(contentData);
  const firstContentDetailFromFilter = useMemo(() => {
    const firstGroup = Object.values(contentDataFiltered)[0];
    return firstGroup?.[0] as ContentDetails | undefined;
  }, [contentDataFiltered]);
  const { onDeleteProjectModalOpen } = useContext(ConversationContext);
  useDownloadCollectionContents(collectionMetadataIds, collectionId);

  const mockCompanyStockData = useMemo(() => {
    return {
      name: "--",
      exchange: "--",
      ticker: "--",
    };
  }, []);

  // If the stock data is not available, use the mock data
  const companyStockData = isPlaceholderTile ? mockCompanyStockData : companyStockDataSource;
  const hasOneTimeSchedule = useMemo(() => !!oneTimeSchedule, [oneTimeSchedule]);

  const getPath = () => {
    if (projectRoute) {
      onConversationClose();
      return navigate(`/${parentRoute}/${projectFilter}/${collectionId}`);
    } else {
      onConversationOpen();
    }
  };

  return (
    <Box
      {...commonTileProps}
      bgColor={tileBgColor}
      onClick={(event) => {
        event.stopPropagation();
        if (!isPlaceholderTile) {
          getPath();
        }
      }}
      _hover={{}}
      overflow="visible"
      textAlign="left">
      <Stack direction="row" justifyContent="space-between" align="center" width="100%">
        <Stack direction="row" align="center" spacing="1.2rem" justifyContent={"start"}>
          {!isMobile &&
            (isSharedRead && firstContentDetailFromFilter ? (
              <Box position="relative" width="3.3rem" className="ch-project-landing-report-preview-container">
                <ProjectAnalysisPopover projectId={collectionId}>
                  <Box borderColor={tileBorderColor} borderWidth="1px" borderRadius="sm">
                    <ContentPreview
                      thumbnailFit="fill"
                      maxHeight="4rem"
                      backgroundColor={"transparent"}
                      itemContent={firstContentDetailFromFilter}
                      thumbnailHeight={"120px"}
                      thumbnailWidth={"260px"}
                      noOnClickAction
                    />
                  </Box>
                </ProjectAnalysisPopover>
              </Box>
            ) : (
              <Stack width="6rem" align="center">
                <CollectionStatProgress
                  {...(workflowStatus === "failed_checkstop" && {
                    onClick: () => {
                      onOpenPausedWorkflow(collectionId);
                    },
                  })}
                  hasOneTimeSchedule={hasOneTimeSchedule}
                  showTimeDetails
                  collectionId={collectionId}
                  size="xxs"
                  isPlaceholderTile={isPlaceholderTile}
                />
              </Stack>
            ))}
          {(collectionType || isPlaceholderTile) && (
            <ProjectHeaderImage
              collectionId={collectionId}
              projectName={companyStockData?.name}
              metadataIds={filteredContentIdsWithoutProjectOutput}
              projectType={collectionType}
              projectGroupId={projectGroupId}
              logoOnly={isPlaceholderTile}
            />
          )}

          {showStockTicker ? (
            <>
              {!isMobile && (
                <Stack className="ch-project-tile-stock-details" spacing="0" width={["6rem", "10rem", "10rem", "10rem", "11rem"]}>
                  <>
                    <TextOverflowTooltip
                      className="ch-project-company-name"
                      breakWord={false}
                      searchText={searchText}
                      label={companyStockData ? companyStockData.name : collectionName ?? "n/a"}
                    />

                    <TextOverflowTooltip
                      className="ch-project-stock-details-data"
                      breakWord={false}
                      searchText={searchText}
                      label={`${companyStockData?.exchange || "--"} : ${companyStockData?.ticker || "--"}`}
                    />
                  </>
                </Stack>
              )}
            </>
          ) : (
            <>
              {collectionName && (
                <TextOverflowTooltip
                  className="ch-project-landing-default-tile-title"
                  label={collectionName}
                  style={{ width: showStockTicker ? "10rem" : "20rem" }}
                />
              )}
            </>
          )}
        </Stack>
        <ProjectDeleteButton
          label={"Delete Version"}
          onOpenConfirmation={onDeleteProjectModalOpen}
          projectId={collectionId}
          isIcon
          projectType={"version"}
        />
      </Stack>
    </Box>
  );
};
