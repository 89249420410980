import { useProjectParams, useContents, useCollectionKey, useManualTags } from "hooks";
import type { FunctionComponent } from "react";
import React, { useContext, useMemo } from "react";
import { AiOutlineFileText } from "react-icons/ai";
import { SmallActionButton } from "screens/content/contentCanvas/cell/SmallActionButton";
import { useAddToCharliContext } from "screens/panels/addToCharli/AddToCharliWizard/AddToCharliProvider";
import { ConversationContext } from "screens/thread/ConversationContext";

interface Props {
  collectionId: string;
  isDisabled?: boolean;
  size?: string;
}

export const ProjectReportPanelButton: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  collectionId,
  isDisabled,
  size = ".9rem",
}) => {
  const { onProjectReportPanelOpen } = useAddToCharliContext();
  const { projectId } = useProjectParams();
  const collectionMetadataIds = useCollectionKey(collectionId, "metadataIds");
  const tagsFilter = useManualTags();
  const contentData = useContents(collectionMetadataIds ?? [], { refreshFromNetwork: true, filterByTags: tagsFilter });
  const hasReports = useMemo(() => {
    return contentData && contentData.length > 0;
  }, [contentData]);
  const { setSelectedContentId } = useContext(ConversationContext);

  return (
    <SmallActionButton
      isDisabled={isDisabled || !hasReports}
      classNames={"ch-open-report-panel"}
      iconTypeName={AiOutlineFileText}
      tooltip={isDisabled ? "" : !hasReports ? "No reports created yet" : "Open Reports"}
      iconSize={size}
      onClick={() => {
        setSelectedContentId(collectionId || projectId);
        onProjectReportPanelOpen();
      }}
    />
  );
};
