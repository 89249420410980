import React, { useRef, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Flex,
  Box,
  Image,
  Text,
  Heading,
  Radio,
  RadioGroup,
  Button,
  Stack,
  useColorModeValue,
  HStack,
  Icon,
  Table,
  Tbody,
  Tr,
  Td,
  useBreakpointValue,
} from "@chakra-ui/react";
import type { DealFinderTicker } from "../../../../api/tickers/models/DealFinderTicker";
import { MarkdownViewer } from "screens/markdown/MarkdownViewer";
import { useCustomScrollbar } from "hooks/useCustomScrollbar";
import verifiedShield from "screens/common/static/misc/verified_shield_logo.png";
import soc2logo from "screens/common/static/logos/soc_logo.png";
import { useButtonProps } from "hooks";
import { DealActionButtons } from "../DealActionButtons";
import { ShowMoreContainer } from "screens/common/components/ShowMoreContainer";
import { useCopyValue } from "hooks/useCopies";

interface InvestmentDetailModalProps {
  isOpen: boolean;
  onClose: () => void;
  deal: DealFinderTicker | null;
}

export const InvestmentDetailModal: React.FC<InvestmentDetailModalProps> = ({ isOpen, onClose, deal }) => {
  const [selectedOption, setSelectedOption] = useState<string>("scorecard");
  const tileRef = useRef<HTMLDivElement>(null);
  const leftColumnRef = useRef<HTMLDivElement>(null);
  const middleColumnRef = useRef<HTMLDivElement>(null);
  const rightColumnRef = useRef<HTMLDivElement>(null);
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });

  const { scrollbarStyle } = useCustomScrollbar(tileRef, { width: "1px" });
  const leftScrollbarStyle = useCustomScrollbar(leftColumnRef, { width: "1px", barTransparency: 0.1 }).scrollbarStyle;
  const middleScrollbarStyle = useCustomScrollbar(middleColumnRef, { width: "1px", barTransparency: 0.1 }).scrollbarStyle;
  const rightScrollbarStyle = useCustomScrollbar(rightColumnRef, { width: "1px", barTransparency: 0.1 }).scrollbarStyle;
  const ctaButtonProps = useButtonProps("md", "cta");

  const bgColor = useColorModeValue("white", "gray.800");
  const purchaseBgColor = useColorModeValue("#F8F8F8", "gray.900");
  const purchaseSelectedBgColor = useColorModeValue("blue.50", "gray.800");
  const textColor = useColorModeValue("primary.darkGray", "gray.400");
  const borderColor = useColorModeValue("gray.200", "gray.700");
  const footerBgColor = useColorModeValue("white", "gray.800");
  const headerColor = useColorModeValue("primary.default", "primary.300");
  const productOptionsStrings = useCopyValue("copy_investment_product_options");
  const purchaseHeading = useCopyValue("copy_investment_purchase_heading");
  const purchaseDescription = useCopyValue("copy_investment_purchase_description");
  const productOptions = React.useMemo(() => productOptionsStrings.map((option) => JSON.parse(option)), [productOptionsStrings]);

  if (!deal) return null;

  // Mock data
  const financialMetrics = [
    { name: "Cash", value: "$4.2M" },
    { name: "Cost of Revenue (COR)", value: "58.3%" },
    { name: "EBITDA", value: "$1.8M" },
    { name: "EBITDA Margin", value: "12.4%" },
    { name: "Gross Profit", value: "$6.1M" },
    { name: "Gross Profit Margin", value: "42.1%" },
    { name: "Net Income", value: "-$0.8M" },
    { name: "Net Profit Margin", value: "-5.7%" },
    { name: "Operating Expenses", value: "$5.3M" },
    { name: "Operating Income", value: "$0.8M" },
    { name: "Revenue", value: "$14.5M" },
    { name: "Total Assets", value: "$19.1M" },
  ];

  const companyDescription =
    deal.companyOverview ||
    `${deal.name} is a private company that operates in the professional, scientific, and technical services sector, specifically in the management consulting services industry. The company was founded in 2011 and is headquartered in Portland, Oregon. ${deal.name} provides services that help organizations verify the identity of their customers, employees, or students, ensuring that they are who they claim to be. The company's solutions are designed to prevent fraud, improve customer experience, and reduce costs associated with manual verification processes. With a valuation of $37.45 million, ${deal.name} has established itself as a player in the identity verification market, serving clients across various industries.`;
  const investmentPotentialDescription = `${deal.name}'s investment potential is moderate, with a revenue growth rate of 12.5% quarterly YoY and 15.3% YoY. The company's gross profit margin is 42.1%, indicating a relatively low margin business. However, the company's asset turnover ratio is 0.762, suggesting efficient use of assets. The net profit margin is -5.7%, indicating the company is currently operating at a loss. With a valuation of $37.45 million, investors should carefully consider the company's growth prospects and financial performance before investing.`;

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="6xl" scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent
        className="ch-investment-detail-modal"
        bg={bgColor}
        maxW={{ base: "100%", lg: "1200px" }}
        maxH={{ base: "100%", lg: "80vh" }}
        mt={{ base: 0, lg: "auto" }}
        mb={{ base: 0, lg: "auto" }}
        borderRadius={{ base: 0, lg: "xl" }}>
        <ModalHeader p={2} pl="2rem" borderBottom="1px" borderColor={borderColor} position="relative">
          <Flex justifyContent="space-between" alignItems="center">
            <Flex alignItems="center" gap={4}>
              {deal.companyLogoUrl ? (
                <Image src={deal.companyLogoUrl} alt={`${deal.name} logo`} maxH="40px" />
              ) : (
                <Text fontWeight="bold" fontSize="lg" pl="1rem">
                  {deal.name}
                </Text>
              )}
              <Text color={textColor} fontSize="md" fontWeight="normal">
                {deal.exchange === "private" ? "PRIVATE.US" : deal.exchange}
              </Text>
            </Flex>
            <Box position="absolute" right="60px" top="50%" transform="translateY(-50%)">
              <Flex direction="column" alignItems="center">
                <Icon boxSize={6} color="orange.400" viewBox="0 0 24 24">
                  <circle cx="12" cy="12" r="10" fill="currentColor" opacity="0.4" />
                  <path
                    fill="currentColor"
                    d="M12,17.27L18.18,21l-1.64-7.03L22,9.24l-7.19-0.61L12,2L9.19,8.63L2,9.24l5.46,4.73L5.82,21L12,17.27z"
                  />
                </Icon>
                <Text fontSize="xs" color="orange.400" fontWeight="medium">
                  Premium
                </Text>
              </Flex>
            </Box>
          </Flex>
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody p={0} maxH={{ base: "100%", lg: "95vh" }} overflow={{ base: "auto", lg: "hidden" }}>
          <Flex direction={{ base: "column", lg: "row" }} h="100%">
            <Stack
              spacing={{ base: "1rem", lg: "1.5rem" }}
              direction={{ base: "row", lg: "column" }}
              justifyContent={isMobile ? "space-between" : "flex-start"}
              flex={{ lg: "1" }}
              py="1.5rem"
              pl={{ base: "1rem", lg: "1.5rem" }}
              pr={{ base: "1rem", lg: ".75rem" }}
              overflowY="auto"
              css={leftScrollbarStyle}
              ref={leftColumnRef}
              height={{ base: "100%", lg: "calc(80vh - 6rem)" }}>
              <Box width="100%" borderWidth="1px" borderColor={borderColor} borderRadius="md" overflow="hidden">
                <Box h="200px" bg={borderColor} display="flex" alignItems="center" justifyContent="center">
                  <Text color={textColor}>Chart Placeholder</Text>
                </Box>
              </Box>
              <Box width="100%" borderWidth="1px" borderColor={borderColor} borderRadius="md" overflow="hidden">
                <Box h="200px" bg={borderColor} display="flex" alignItems="center" justifyContent="center">
                  <Text color={textColor}>Chart Placeholder</Text>
                </Box>
              </Box>
              <Box width="100%" borderWidth="1px" borderColor={borderColor} borderRadius="md" overflow="hidden">
                <Box h="200px" bg={borderColor} display="flex" alignItems="center" justifyContent="center">
                  <Text color={textColor}>Chart Placeholder</Text>
                </Box>
              </Box>
            </Stack>
            <Box
              flex={{ lg: "1" }}
              color={textColor}
              py="1.5rem"
              pr={{ base: "1rem", lg: "1.5rem" }}
              pl={{ base: "1rem", lg: ".75rem" }}
              overflowY="auto"
              css={middleScrollbarStyle}
              ref={middleColumnRef}
              maxH={{ base: "100%", lg: "calc(100vh - 12rem)" }}
              h="100%">
              <Box mb={"3rem"}>
                <Heading size="md" mb={3}>
                  {deal.name} Business Description
                </Heading>
                <Box
                  alignContent={"top"}
                  height={"100%"}
                  maxHeight={{ base: "100%", lg: "26vh" }}
                  overflowY={"auto"}
                  overflowX={"hidden"}
                  ref={tileRef}
                  css={scrollbarStyle}>
                  <MarkdownViewer backgroundColor={bgColor} fontColor={textColor} content={companyDescription} fontSize="sm" />
                </Box>
              </Box>
              <Box mb={"10rem"}>
                <Heading size="md" mb={3}>
                  Investment Potential
                </Heading>
                <Box mb={4} borderWidth="1px" borderColor={borderColor} borderRadius="md" overflow="hidden">
                  <Table size="sm" variant="simple">
                    <Tbody>
                      {financialMetrics.map((metric) => (
                        <Tr key={metric.name}>
                          <Td fontSize="sm" fontWeight="medium" py={2}>
                            {metric.name}
                          </Td>
                          <Td isNumeric fontSize="sm" color="blue.600" py={2}>
                            {metric.value}
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </Box>
                <ShowMoreContainer height="14rem" minHeight="8rem">
                  <MarkdownViewer backgroundColor={bgColor} fontColor={textColor} content={investmentPotentialDescription} fontSize="sm" />
                </ShowMoreContainer>
              </Box>
            </Box>
            <Box
              flex={{ lg: "1" }}
              borderLeft="1px"
              borderColor={borderColor}
              bg={purchaseBgColor}
              p="1.5rem"
              overflowY="auto"
              css={rightScrollbarStyle}
              ref={rightColumnRef}
              height={{ base: "100%", lg: "calc(80vh - 6rem)" }}
              display="flex"
              flexDirection="column">
              <Box borderRadius="md" flex="1" display="flex" flexDirection="column">
                <Heading px="1rem" size="md" color={headerColor} mb={4} textAlign={"center"}>
                  {purchaseHeading}
                </Heading>
                <Text mb={6} fontSize="sm">
                  {purchaseDescription}
                </Text>
                <RadioGroup onChange={setSelectedOption} value={selectedOption} mb={6}>
                  <Stack spacing={0}>
                    {productOptions.map((option) => (
                      <Box
                        key={option.id}
                        p={4}
                        fontSize="sm"
                        border="1px"
                        bgColor={selectedOption === option.id ? purchaseSelectedBgColor : footerBgColor}
                        borderColor={selectedOption === option.id ? headerColor : borderColor}
                        borderRadius="none"
                        onClick={() => setSelectedOption(option.id)}
                        cursor="pointer"
                        _hover={{ borderColor: "blue.300" }}
                        transition="all 0.2s"
                        boxShadow={selectedOption === option.id ? "0 0 0 1px blue.500" : "none"}>
                        <Flex justifyContent="space-between" alignItems="center">
                          <Stack spacing={0}>
                            <HStack>
                              <Radio
                                value={option.id}
                                colorScheme="blue"
                                isChecked={selectedOption === option.id}
                                onChange={() => setSelectedOption(option.id)}
                              />
                              <Text fontSize={"md"} fontWeight="medium">
                                {option.name}
                              </Text>
                            </HStack>
                            <Text pl="1.6rem" fontSize="xs" color="gray.500">
                              {option.description}
                            </Text>
                          </Stack>
                          <Text fontWeight="medium" fontSize={"lg"}>
                            {option.price}
                          </Text>
                        </Flex>
                      </Box>
                    ))}
                  </Stack>
                </RadioGroup>
                <Button
                  {...ctaButtonProps}
                  width="100%"
                  borderRadius="md"
                  mb={4}
                  onClick={() => console.log(`Adding to cart: ${selectedOption}`)}>
                  Add to Cart
                </Button>
              </Box>
              <HStack justifyContent={"flex-end"} spacing="1rem" width="100%">
                <Image src={soc2logo} height="4rem" alt="SOC2 Logo" />
                <Image src={verifiedShield} height="4rem" alt="SOC2 Logo" />
              </HStack>
            </Box>
          </Flex>
        </ModalBody>
        <ModalFooter
          py={1}
          px="1rem"
          borderBottomRadius={{ base: 0, lg: "xl" }}
          borderTop="1px"
          bg={footerBgColor}
          borderColor={borderColor}
          justifyContent="space-between"
          width="100%">
          <DealActionButtons deal={deal} iconSize="1.5rem" showBorder={false} />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
