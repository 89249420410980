import React, { useRef, useState, memo, useCallback } from "react";
import {
  Box,
  Circle,
  useColorModeValue,
  Button,
  Text,
  Center,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  useOutsideClick,
  useBreakpointValue,
  PopoverCloseButton,
  PopoverHeader,
  Tooltip,
} from "@chakra-ui/react";
import { useNotifications } from "hooks";
import { BellIcon } from "@chakra-ui/icons";
import { NotificationTile } from "../tabs/notifications/NotificationTile";
import { useNavigate } from "react-router-dom";
import { HEADER_ICON_HEIGHT } from "../Landing";

const NotificationsMenuComponent = () => {
  const hoverColor = useColorModeValue("primary.default", "primary.default");
  const buttonColor = useColorModeValue("gray.400", "gray.500");
  const notificationColor = useColorModeValue("white", "gray.500");
  const { actionNotification, dismissNotification, newNotifications, dismissedNotAcceptedNotifications } = useNotifications();
  const actionableNotifications = newNotifications;
  const navigate = useNavigate();
  const [showPopover, setShowPopover] = useState(false);
  const popoverRef = useRef<HTMLDivElement>(null);
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });

  useOutsideClick({
    ref: popoverRef,
    handler: () => setShowPopover(false),
  });

  const handleViewAll = useCallback(() => {
    setShowPopover(false);
    navigate("/notifications");
  }, [navigate]);

  const handleNotificationAction = useCallback(
    (id: string) => {
      setShowPopover(false);
      actionNotification(id);
    },
    [actionNotification]
  );

  const handleNotificationDismiss = useCallback(
    (id: string) => {
      setShowPopover(false);
      dismissNotification(id);
    },
    [dismissNotification]
  );

  const togglePopover = useCallback(() => {
    setShowPopover(!showPopover);
  }, [showPopover]);

  return (
    <Box>
      <Popover isLazy isOpen={showPopover} placement="bottom-end" trigger="click" offset={[0, 5]}>
        <PopoverTrigger>
          <Tooltip
            label={actionableNotifications.length === 0 ? "No notifications" : `${actionableNotifications.length} new notifications`}
            aria-label="Notifications"
            placement="left">
            <Center className="ch-notification-menu-button">
              <Button
                paddingInline={0}
                cursor={actionableNotifications.length === 0 ? "default" : "pointer"}
                color={buttonColor}
                _hover={{ color: hoverColor }}
                _active={{ color: hoverColor }}
                _disabled={{ color: buttonColor }}
                bgColor={"transparent"}
                position="relative"
                onClick={togglePopover}>
                <BellIcon boxSize={`${HEADER_ICON_HEIGHT}px`} />
                {actionableNotifications.length > 0 && (
                  <Circle
                    id="ch-notification-menu-button-indicator"
                    position="absolute"
                    left="16px"
                    bottom="0"
                    size="17px"
                    bg={dismissedNotAcceptedNotifications.length > 0 ? "orange" : "green"}>
                    <Text p="4px" fontWeight="bold" fontSize="12px" color={notificationColor}>
                      {actionableNotifications.length}
                    </Text>
                  </Circle>
                )}
              </Button>
            </Center>
          </Tooltip>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverCloseButton zIndex={1} />
          <PopoverHeader fontSize="md">Notifications</PopoverHeader>
          <PopoverBody p="0">
            <Box
              ref={popoverRef}
              p="1rem"
              width={isMobile ? "100vw" : "100%"}
              minWidth="20rem"
              maxWidth={isMobile ? "100vw" : "30rem"}
              maxHeight="16rem">
              {actionableNotifications.length > 0 && (
                <Box display="flex" justifyContent="flex-end" width="100%">
                  <Button variant="link" cursor="pointer" onClick={handleViewAll} size="sm" color={hoverColor}>
                    <Text>View All</Text>
                  </Button>
                </Box>
              )}
              {actionableNotifications.length > 0 ? (
                actionableNotifications.map((notification) => (
                  <Box pb="3px" key={notification.id}>
                    <NotificationTile
                      notification={notification}
                      onDismiss={() => handleNotificationDismiss(notification.id)}
                      onAction={() => handleNotificationAction(notification.id)}
                      source="notifications_menu"
                    />
                  </Box>
                ))
              ) : (
                <Box>
                  <Text fontSize="sm" color="gray.500">
                    No new notifications
                  </Text>
                </Box>
              )}
            </Box>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
};

export const NotificationsMenu = memo(NotificationsMenuComponent);
