import React, { useCallback } from "react";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Badge,
  Box,
  Circle,
  Flex,
  Heading,
  Icon,
  IconButton,
  Link,
  SimpleGrid,
  Stack,
  Table,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { useConfiguredWorkflow, useGetWorkflowConfigByIntent } from "hooks/useConfiguredWorkflows";
import { formatDate } from "screens/common/modal/formatters";
import isEmpty from "lodash/isEmpty";
import { Wizard } from "react-use-wizard";
import { PanelStep } from "screens/panels/components/PanelStep";
import { PanelView } from "screens/panels/components/PanelView";
import { getCheckpointColorScheme } from "./CheckpointsFieldArray";
import { BiCopy } from "react-icons/bi";

interface IProps {
  id: string | null;
  isOpen: boolean;
  onClose: () => void;
}

export const ConfiguredWorkflowModal = (props: IProps) => {
  const { id, isOpen, onClose } = props;
  const configWorkflow = useConfiguredWorkflow(id);
  const toast = useToast();
  const getWorkflowConfigByIntent = useGetWorkflowConfigByIntent();
  const [selectedInnerId, setSelectedInnerId] = React.useState<string | null>(null);
  const selectedLinkColor = useColorModeValue("primaryButton.selectedColor", "primaryButton.selectedColorDarkMode");
  const buttonColor = useColorModeValue("gray.500", "gray.600");
  const buttonHoverColor = useColorModeValue("gray.600", "gray.400");
  const checkpointFontColor = useColorModeValue("gray.600", "gray.800");
  const checkpointBgColor = useColorModeValue("100", "300");

  const getCheckpoints = useCallback(() => {
    if (!configWorkflow) return [];

    const {
      config: { checkpoints },
    } = configWorkflow;

    return checkpoints ?? [];
  }, [configWorkflow]);

  const getDefaultEntities = useCallback(() => {
    if (!configWorkflow) return [];

    const {
      config: { defaultEntities },
    } = configWorkflow;

    return defaultEntities ?? [];
  }, [configWorkflow]);

  const copyExpandedUserIntent = useCallback(
    (userIntent: any) => {
      navigator.clipboard.writeText(userIntent);

      toast({
        title: "Configured Workflow",
        description: `${userIntent} text copied to clipboard.`,
        status: "info",
        duration: 1500,
        isClosable: true,
        position: "bottom-left",
      });
    },
    [toast]
  );

  const renderCheckpoints = useCallback(() => {
    const checkpoints = getCheckpoints();

    return checkpoints.map((checkpoint, index) => {
      if (checkpoint.type === "create_entities") {
        return (
          <AccordionItem key={`${checkpoint.type}-${index}`}>
            <AccordionButton>
              <Stack direction="row" width="100%" justifyContent="space-between" alignItems="center">
                <Flex alignItems="center">
                  <Circle
                    color={checkpointFontColor}
                    fontSize={"10px"}
                    fontWeight={"semibold"}
                    size="22px"
                    bgColor={`${getCheckpointColorScheme(checkpoint.type)}.${checkpointBgColor}`}
                    mr={2}>
                    {index + 1}
                  </Circle>
                  <Text size={"sm"} mr="1rem">
                    Create Entities
                  </Text>
                </Flex>
              </Stack>
              <Tag size="sm" colorScheme={getCheckpointColorScheme(checkpoint.type)} mr={2} width="6.5rem">
                {checkpoint.type}
              </Tag>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <Stack borderWidth="2px" borderRadius="md" mb="1rem">
                <TableContainer>
                  <Table variant="simple" fontSize={"sm"}>
                    <Thead>
                      <Tr>
                        <Th>Entity</Th>
                        <Th>Value</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {checkpoint.entitiesToInject.map(({ value, entity }, index) => (
                        <Tr key={`entity-to-inject-${entity}-${index}`}>
                          <Td>{entity}</Td>
                          <Td>{value !== null ? JSON.stringify(value) : "null"}</Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
              </Stack>
            </AccordionPanel>
          </AccordionItem>
        );
      } else if (checkpoint.type === "execute_intent") {
        const maybeWorkflowConfig = getWorkflowConfigByIntent(checkpoint.intent);
        return (
          <AccordionItem
            isDisabled={
              checkpoint.requirements.length === 0 &&
              (checkpoint.onlyIncludeTheseEntities ?? []).length === 0 &&
              !checkpoint.entityToSplit &&
              !checkpoint.entitiesToInject &&
              !checkpoint.entitiesToRename
            }
            key={`${checkpoint.type}-${checkpoint.intent}-${index}`}>
            <AccordionButton>
              <Stack direction="row" width="100%" justifyContent="space-between" alignItems="center">
                <Flex alignItems="center">
                  <Circle
                    color={checkpointFontColor}
                    fontSize={"10px"}
                    fontWeight={"semibold"}
                    size="22px"
                    bgColor={`${getCheckpointColorScheme(checkpoint.type)}.${checkpointBgColor}`}
                    mr={2}>
                    {index + 1}
                  </Circle>
                  <Text size={"sm"} mr="1rem">
                    {checkpoint.intent}
                  </Text>
                  <Text size={"sm"} mr="1rem">
                    {checkpoint.needsConfirmation ? "(needs confirmation)" : null}{" "}
                    {checkpoint.runInNewChildWorkflow ? "(runs in separate child workflow)" : null}{" "}
                    {checkpoint.doNotWaitForPreviousChildWorkflowsToComplete
                      ? "(does not wait for previous child workflow to complete)"
                      : null}
                  </Text>
                </Flex>
                {maybeWorkflowConfig && (
                  <Link color={selectedLinkColor} onClick={() => setSelectedInnerId(maybeWorkflowConfig.id)}>
                    {maybeWorkflowConfig.config.checkpoints.length} intents
                  </Link>
                )}
              </Stack>
              <IconButton
                onClick={() => copyExpandedUserIntent(checkpoint.intent)}
                aria-label="Copy intent"
                backgroundColor="unset"
                icon={<Icon as={BiCopy} color={buttonColor} boxSize="1rem" _hover={{ color: buttonHoverColor }} />}
                size="sm"
                _hover={{ backgroundColor: "unset" }}
              />
              <Tag size="sm" colorScheme={getCheckpointColorScheme(checkpoint.type)} width="6.5rem" mr={2}>
                {checkpoint.type}
              </Tag>
              <AccordionIcon />
            </AccordionButton>

            <AccordionPanel pb={4}>
              {checkpoint.onlyIncludeTheseEntities && checkpoint.onlyIncludeTheseEntities.length > 0 && (
                <Stack borderWidth="2px" borderRadius="md" padding="1rem" mb="1rem">
                  <Text fontWeight="semibold" fontSize={"md"}>
                    Input Entities for ChildWorkflow:
                  </Text>
                  {checkpoint.onlyIncludeTheseEntities.length > 0 && (
                    <Stack direction={"row"} flexWrap="wrap" ml={2}>
                      {checkpoint.onlyIncludeTheseEntities.map((entity) => (
                        <Tag key={`entity-to-inject-${entity}`} size="sm" colorScheme={"gray"} mr={2}>
                          {entity}
                        </Tag>
                      ))}
                    </Stack>
                  )}
                </Stack>
              )}
              {checkpoint.entitiesToRename && checkpoint.entitiesToRename.length > 0 && (
                <Stack borderWidth="2px" borderRadius="md" padding="1rem" mb="1rem">
                  <Text fontWeight="semibold" fontSize={"md"}>
                    Entities to rename:
                  </Text>
                  <TableContainer>
                    <Table variant="simple" fontSize={"sm"}>
                      <Thead>
                        <Tr>
                          <Th>Name</Th>
                          <Th>Rename to</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {checkpoint.entitiesToRename.map((entity) => (
                          <Tr key={`entity-to-rename-${entity.entityName}-${entity.renameTo}`}>
                            <Td>{entity.entityName}</Td>
                            <Td>{entity.renameTo}</Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </TableContainer>
                </Stack>
              )}
              {checkpoint.entitiesToInject && checkpoint.entitiesToInject.length > 0 && (
                <Stack borderWidth="2px" borderRadius="md" padding="1rem" mb="1rem">
                  <Text fontWeight="semibold" fontSize={"md"}>
                    Entities to inject:
                  </Text>
                  <TableContainer>
                    <Table variant="simple" fontSize={"sm"}>
                      <Thead>
                        <Tr fontSize={"sm"}>
                          <Th>Entity</Th>
                          <Th>Value</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {checkpoint.entitiesToInject.map(({ value, entity }, index) => (
                          <Tr key={`entity-to-inject-${entity}-${index}`} fontSize={"sm"}>
                            <Td fontSize={"sm"}>{entity}</Td>
                            <Td fontSize={"sm"}>{value !== null ? JSON.stringify(value) : "null"}</Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </TableContainer>
                </Stack>
              )}
              {checkpoint.entityToSplit && (
                <Stack direction={"row"} borderWidth="2px" borderRadius="md" padding="1rem" mb="1rem">
                  <Text fontWeight="semibold" fontSize={"md"}>
                    Entity to split:
                  </Text>{" "}
                  <Text fontSize={"sm"}>
                    {checkpoint.entityToSplit.entityName}{" "}
                    {checkpoint.entityToSplit.renameTo ? `(Rename to ${checkpoint.entityToSplit.renameTo})` : ""}
                    {checkpoint.entityToSplit.shouldUnwrap ? `(Unwrap)` : ""}
                  </Text>
                </Stack>
              )}
              {checkpoint.requirements.length > 0 && (
                <Stack borderWidth="2px" borderRadius="md" padding="1rem" mb="1rem">
                  <Text fontWeight="semibold" fontSize={"md"}>
                    Requirements:
                  </Text>
                  <TableContainer>
                    <Table variant="simple" fontSize={"sm"}>
                      <Thead>
                        <Tr>
                          <Th>Entity</Th>
                          <Th>Operator</Th>
                          <Th>Value</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {checkpoint.requirements.map((_requirement, index) => (
                          <Tr key={`requirement-${_requirement.entity}-${_requirement.operator}-${index}`}>
                            <Td>{_requirement.entity}</Td>
                            <Td>
                              <Badge>{_requirement.operator}</Badge>
                            </Td>
                            <Td>{_requirement.value !== null ? JSON.stringify(_requirement?.value) : "null"}</Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </TableContainer>
                </Stack>
              )}
            </AccordionPanel>
          </AccordionItem>
        );
      } else if (checkpoint.type === "milestone") {
        return (
          <AccordionItem key={`${checkpoint.type}-${index}`}>
            <AccordionButton pr="2.1rem">
              <Stack direction="row" width="100%" justifyContent="space-between" alignItems="center">
                <Flex alignItems="center">
                  <Circle
                    color={checkpointFontColor}
                    fontSize={"10px"}
                    fontWeight={"semibold"}
                    size="22px"
                    bgColor={`${getCheckpointColorScheme(checkpoint.type)}.${checkpointBgColor}`}
                    mr={2}>
                    {index + 1}
                  </Circle>
                  <Text size={"sm"} mr="1rem">
                    {checkpoint.label} Milestone
                  </Text>
                </Flex>
              </Stack>
              <Tag size="sm" colorScheme={getCheckpointColorScheme(checkpoint.type)} mr={2}>
                {checkpoint.type}
              </Tag>
            </AccordionButton>
          </AccordionItem>
        );
      } else {
        return null;
      }
    });
  }, [
    getCheckpoints,
    checkpointFontColor,
    checkpointBgColor,
    getWorkflowConfigByIntent,
    selectedLinkColor,
    buttonColor,
    buttonHoverColor,
    copyExpandedUserIntent,
  ]);

  const renderDefaultEntities = useCallback(() => {
    const defaultEntities = getDefaultEntities();

    if (isEmpty(defaultEntities)) {
      return <Text fontSize="md">No Default Entities</Text>;
    }

    return (
      <Table size={"sm"} variant="simple">
        <Thead>
          <Tr>
            <Th>Entity</Th>
            <Th>Value</Th>
          </Tr>
        </Thead>
        <Tbody>
          {defaultEntities.map(({ entity, value }) => (
            <Tr key={entity}>
              <Td>{entity}</Td>
              <Td>{value !== null ? JSON.stringify(value) : "null"}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    );
  }, [getDefaultEntities]);

  if (!configWorkflow) {
    return null;
  }

  return (
    <PanelView isOpen={isOpen} onClose={onClose} panelTitle="Configured Workflow detail">
      <Wizard>
        <PanelStep>
          <Stack spacing={"3rem"} px="1rem" width="100%">
            <Stack spacing="1rem">
              <Stack direction="row" alignItems="center">
                <Heading fontSize="md">User intent:</Heading>
                <Text fontSize="md">{configWorkflow.userIntent}</Text>
              </Stack>
              <SimpleGrid columns={2} spacing={0}>
                <Stack direction="row" alignItems="center">
                  <Heading fontSize="md">Created By:</Heading>
                  <Text wordBreak="break-word" fontWeight="light" fontSize="md">{`${configWorkflow.creationByUserName}`}</Text>
                </Stack>
                <Stack direction="row" alignItems="center">
                  <Heading fontSize="md">Created On:</Heading>{" "}
                  <Text wordBreak="break-word" fontWeight="light" fontSize="md">{`${formatDate(
                    new Date(configWorkflow.creationDate)
                  )}`}</Text>
                </Stack>
              </SimpleGrid>
              <SimpleGrid columns={2} spacing={0}>
                <Stack direction="row" alignItems="center">
                  <Heading fontSize="md">Last Updated By:</Heading>
                  <Text wordBreak="break-word" fontWeight="light" fontSize="md">{`${configWorkflow.lastUpdatedByUserName}`}</Text>
                </Stack>
                <Stack direction="row" alignItems="center">
                  <Heading fontSize="md">Last Updated On:</Heading>{" "}
                  <Text wordBreak="break-word" fontWeight="light" fontSize="md">{`${formatDate(
                    new Date(configWorkflow.lastUpdatedDate)
                  )}`}</Text>
                </Stack>
              </SimpleGrid>
            </Stack>

            <Box>
              <Heading marginBottom={4} fontSize="md">
                Default Entities
              </Heading>
              {renderDefaultEntities()}
            </Box>

            <Box>
              <Heading marginBottom={4} fontSize="md">
                Checkpoints
              </Heading>
              <Accordion allowToggle>{renderCheckpoints()}</Accordion>
            </Box>
          </Stack>
        </PanelStep>
      </Wizard>

      {selectedInnerId && (
        <ConfiguredWorkflowModal
          id={selectedInnerId}
          isOpen
          onClose={() => {
            setSelectedInnerId(null);
          }}
        />
      )}
    </PanelView>
  );
};
