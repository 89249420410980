import React, { useMemo } from "react";
import type { Control } from "react-hook-form";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { Box, FormControl, Text, Input, Tag, TagLabel, TagCloseButton, HStack, VStack, Button } from "@chakra-ui/react";
import type { FormValues } from "./ConfiguredWorkflowUpsertModal";
import { useConfiguredWorkflowsMostUsedEntities } from "hooks/useConfiguredWorkflows";

interface IProps {
  control?: Control<FormValues>;
  checkpointIndex: number;
}

export const IncludedEntitiesFieldArray = (props: IProps) => {
  const { checkpointIndex } = props;
  const { control } = useFormContext<FormValues>();
  const mostUsedEntities = useConfiguredWorkflowsMostUsedEntities();
  const { fields, append, remove } = useFieldArray({
    control,
    name: `config.checkpoints.${checkpointIndex}.onlyIncludeTheseEntities`,
  });
  const [inputValue, setInputValue] = React.useState("");

  const onlyIncludeTheseEntities = useWatch({ control, name: `config.checkpoints.${checkpointIndex}.onlyIncludeTheseEntities` });

  const controlledFields = useMemo(
    () =>
      fields.map((field, index) => ({
        ...field,
        ...(onlyIncludeTheseEntities ? onlyIncludeTheseEntities[index] : {}),
      })),
    [fields, onlyIncludeTheseEntities]
  );

  const dedupedEntities = useMemo(() => {
    const entities = controlledFields.reduce((acc, { value }) => {
      acc.add(value);
      return acc;
    }, new Set<string>());

    return mostUsedEntities.filter((entity) => !entities.has(entity));
  }, [controlledFields, mostUsedEntities]);

  const filteredEntities = useMemo(() => {
    if (!inputValue) return dedupedEntities;
    return dedupedEntities.filter((entity) => entity.toLowerCase().includes(inputValue.toLowerCase()));
  }, [dedupedEntities, inputValue]);

  const handleAddEntity = () => {
    if (inputValue.trim()) {
      append({ value: inputValue.trim() });
      setInputValue("");
    }
  };

  return (
    <Box backgroundColor={"transparent"}>
      <FormControl>
        <VStack align="stretch" spacing={2}>
          <HStack>
            <Input
              size="sm"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              placeholder="Add entity"
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  handleAddEntity();
                }
              }}
            />
            <Button size="sm" onClick={handleAddEntity}>
              Add
            </Button>
          </HStack>

          <Box>
            <HStack spacing={2} flexWrap="wrap">
              {controlledFields.map((field, index) => (
                <Tag key={field.id} size="md" borderRadius="full" variant="solid" colorScheme="blue" my={1}>
                  <TagLabel>{field.value}</TagLabel>
                  <TagCloseButton onClick={() => remove(index)} />
                </Tag>
              ))}
            </HStack>
          </Box>

          {filteredEntities.length > 0 && (
            <Box mt={2}>
              <Text fontSize="sm" mb={1}>
                Suggestions:
              </Text>
              <HStack spacing={2} flexWrap="wrap">
                {filteredEntities.slice(0, 10).map((entity) => (
                  <Tag
                    key={entity}
                    size="md"
                    borderRadius="full"
                    variant="outline"
                    colorScheme="blue"
                    cursor="pointer"
                    onClick={() => {
                      append({ value: entity });
                    }}
                    my={1}>
                    <TagLabel>{entity}</TagLabel>
                  </Tag>
                ))}
              </HStack>
            </Box>
          )}
        </VStack>
      </FormControl>
    </Box>
  );
};
