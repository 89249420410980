import { Stack, Text, useBreakpointValue, useColorModeValue } from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import React from "react";
import ECHighlighter from "react-ec-highlighter";
import type { AssessmentResultsData } from "types/collection";
import { AssessmentResultsFollowupQuestions } from "./AssessmentResultsFollowupQuestions";
import { AssessmentResultsPolicies } from "./AssessmentResultsPolicies";
import { AssessmentResultsRiskAssessment } from "./AssessmentResultsRiskAssessment";

export interface Props {
  assessmentResults: AssessmentResultsData[];
  searchText?: string;
}

export const AssessmentResultsQuestion: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  assessmentResults,
  searchText,
}) => {
  const textColor = useColorModeValue("primary.darkGray", "gray.400");
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });

  return (
    <Stack spacing="2rem">
      {assessmentResults.length > 0 &&
        assessmentResults.map((questionRecord, index) => (
          <Stack key={index} p={isMobile ? ".5rem" : "1rem"} width="100%">
            <Text className="ch-assessment-result-question" color={textColor} fontSize="md" fontWeight={"semibold"}>
              {searchText && searchText.length > 0 && questionRecord.section.question ? (
                <ECHighlighter searchPhrase={searchText} text={questionRecord.section.question} />
              ) : (
                questionRecord.section.question
              )}
            </Text>
            {questionRecord.section.answer.length > 0 && (
              <Text fontSize="sm" className="ch-assessment-result-answer" width="100%">
                {searchText && searchText.length > 0 && questionRecord.section.answer ? (
                  <ECHighlighter searchPhrase={searchText} text={questionRecord.section.answer} />
                ) : (
                  questionRecord.section.answer
                )}
              </Text>
            )}
            <AssessmentResultsRiskAssessment subSection assessmentResults={questionRecord.risk_assessment_result} searchText={searchText} />
            <AssessmentResultsPolicies subSection assessmentResults={questionRecord.policies} searchText={searchText} />
            <AssessmentResultsFollowupQuestions subSection assessmentResults={questionRecord.followup_questions} searchText={searchText} />
          </Stack>
        ))}
    </Stack>
  );
};
