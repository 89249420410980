import { useColorModeValue, Image, Center, useBreakpointValue, IconButton, Icon, Stack } from "@chakra-ui/react";
import { useSidebarNavigation, useUserPreference } from "hooks";
import React, { useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import CharliLogoSmall from "screens/common/static/logos/charli-logo-small.png";
import { ReactComponent as CharliLogoFull } from "screens/common/static/logos/charli-logo-full.svg";
import { ConversationContext } from "screens/thread/ConversationContext";
import { SettingsProviderContext } from "screens/panels/settings/SettingsProvider";
import { FiMenu } from "react-icons/fi";
import { DEFAULT_SIDEBAR_WIDTH } from "./SidebarNavigation";
import { HEADER_HEIGHT, HEADER_ICON_HEIGHT } from "../Landing";

export const SidebarNavigationLogos = () => {
  const navigate = useNavigate();
  const sidebarWidth = (useUserPreference("ui_sidebar_width") as number) || (DEFAULT_SIDEBAR_WIDTH as number);
  const defaultHomePage = useUserPreference("ui_home_page");
  const { onConversationClose, setConversationId } = useContext(ConversationContext);
  const isMobile = useBreakpointValue({ base: true, md: false, lg: false }, { fallback: "md", ssr: false });
  const isTablet = useBreakpointValue({ base: false, md: true, lg: false }, { fallback: "md", ssr: false });
  const { isSideNavigationOpen, setIsSideNavigationOpen } = useContext(SettingsProviderContext);
  const { currentSidebarType } = useSidebarNavigation();
  const menuColor = useColorModeValue("gray.400", "gray.500");

  const renderCharliLogo = useMemo(() => {
    return isMobile || isTablet ? (
      <Image
        className="sidebar-logo-button"
        height="1.2rem"
        width="auto"
        cursor="pointer"
        onClick={() => {
          onClickHome && onClickHome();
        }}
        src={CharliLogoSmall}
      />
    ) : (
      <CharliLogoFull
        className="sidebar-logo-button"
        style={{
          width: "100%",
          height: `${HEADER_ICON_HEIGHT - 4}px`,
          paddingLeft: "0.5rem",
        }}
        onClick={() => onClickHome && onClickHome()}
        cursor="pointer"
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile, isTablet]);

  const onClickHome = () => {
    setConversationId("");
    const homeRoute = defaultHomePage ? `${String(defaultHomePage)}` : "home";
    navigate(`/${homeRoute}`);

    onConversationClose();
  };

  return (
    <Stack
      className="ch-sidebar"
      direction="row"
      width={`${currentSidebarType === "hidden" ? (isMobile ? "9rem" : "19rem") : `${sidebarWidth}px`}`}
      minWidth={`${currentSidebarType === "hidden" ? 0 : sidebarWidth}px`}
      pl={isMobile || currentSidebarType === "hidden" ? "0.5rem" : "1rem"}
      justifyContent={"flex-start"}
      cursor="pointer"
      spacing=".5rem"
      height={`${HEADER_HEIGHT}px`}>
      {currentSidebarType === "hidden" && (
        <Center>
          <IconButton
            className="sidebar-menu_hamburger"
            minWidth="0"
            _hover={{}}
            _active={{}}
            zIndex={10}
            height="2.6rem"
            left="0"
            variant="ghost"
            aria-label="Open Menu"
            fontSize="20px"
            color={menuColor}
            onClick={() => setIsSideNavigationOpen(!isSideNavigationOpen)}
            cursor="pointer"
            icon={<Icon as={FiMenu} />}
          />
        </Center>
      )}
      <Center>{renderCharliLogo}</Center>
    </Stack>
  );
};
