import type { FunctionComponent } from "react";
import { useMemo } from "react";
import { useContext } from "react";
import React from "react";
import { Stack, useBreakpointValue, useColorModeValue, Box, Tooltip, Center } from "@chakra-ui/react";
import { InteractionBar } from "screens/landing/components";
import { SettingsProviderContext } from "screens/panels/settings/SettingsProvider";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { sendMessage } from "state/websocket/operations";
import { useCollectionKey, useItemSelector, useProjectParams, useUserProfile } from "hooks";
import { EditableInput } from "screens/content/common/TwoColumnTable/EditableInput";
import { ChevronLeftIcon } from "@chakra-ui/icons";
import { ConversationContext } from "screens/thread/ConversationContext";
import { SidebarNavigationLogos } from "screens/landing/components/SidebarNavigationLogo";
import { ConversationDialogInput } from "screens/thread/ConversationDialogInput";
import { useCopyValue } from "hooks/useCopies";
import { HEADER_HEIGHT } from "screens/landing";

interface Props {
  inputbarInitialText?: string;
  showTickerInput?: boolean;
}

export const HEADER_INTERACTION_BAR_SELECTOR = "charli-header-interaction-bar";

export const HeaderInteractionBar: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  inputbarInitialText,
  showTickerInput,
}) => {
  const isMobile = useBreakpointValue({ base: true, md: false, lg: false }, { fallback: "md", ssr: false });
  const isTablet = useBreakpointValue({ base: false, md: true, lg: false }, { fallback: "md", ssr: false });
  const { headerText, setHeaderText, setIsEditing } = useContext(SettingsProviderContext);
  const bgColor = useColorModeValue("white", "gray.800");
  const { projectId, contentId, isPortfolios, projectFilter } = useProjectParams();
  const dispatch = useDispatch();
  const hoverColor = useColorModeValue("secondaryButton.hover", "secondaryButton.hoverDarkMode");
  const { onConversationClose, setRequestEntities, setConversationId } = useContext(ConversationContext);
  const { resetSelectedItems } = useItemSelector();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const fontColor = useColorModeValue("gray.700", "gray.400");
  const borderColor = useColorModeValue("rgba(61, 61, 61, 0.25)", "rgba(255, 255, 255, 0.25)");
  const copyTickerPlaceholder = useCopyValue("copy_ticker_placeholder_text");
  const shareDetails = useCollectionKey(projectFilter, "shareDetails");
  const { id: currentUserId } = useUserProfile();
  const isSharedRead = shareDetails && shareDetails.accessMode === "read" && shareDetails.ownerId !== currentUserId;

  const sendEditMessage = ({ entity, value }: { entity: string; value: string }) => {
    if (!projectId) {
      return;
    }
    const entities: {
      entity: string;
      value: string;
    }[] = [];
    entities.push({ entity: "collection_id", value: projectId });
    entities.push({ entity: entity, value: value });

    setTimeout(() => {
      setIsEditing(false);
    }, 300);

    dispatch(
      sendMessage({
        conversationId: projectId,
        intent: "/edit_collection",
        entities: entities,
      })
    );
  };

  const getPath = useMemo(() => {
    if (pathname.includes("/json")) {
      return -1;
    }

    const truncatePath = pathname.includes("/library/resource") ? 2 : 1;
    const path = pathname.includes("/search/") ? "/" : pathname.split("/").slice(0, -truncatePath).join("/");
    return path;
  }, [pathname]);

  return (
    <Stack direction="row" spacing="0" height={`${HEADER_HEIGHT}px`} borderBottom={`1px solid ${borderColor}`} backgroundColor={bgColor}>
      <SidebarNavigationLogos />
      <Stack
        spacing="0"
        height="100%"
        className={`${HEADER_INTERACTION_BAR_SELECTOR}`}
        width="100%"
        direction="row"
        justifyContent="space-between"
        border="none"
        backgroundColor={bgColor}>
        {(isMobile || projectId || contentId) && !pathname.includes("/panel") && (
          <Stack direction="row" width="100%" justifyContent="flex-start" pl={isMobile ? "0.5rem" : "1rem"}>
            {(projectId || contentId) && (
              <Box>
                <Tooltip label="Go back">
                  <ChevronLeftIcon
                    color={fontColor}
                    cursor="pointer"
                    zIndex="3"
                    height={`${HEADER_HEIGHT - 2}px`}
                    width="1.3rem"
                    backgroundColor={bgColor}
                    marginInlineStart="0px!important"
                    _hover={{ color: hoverColor }}
                    onClick={() => {
                      setRequestEntities([]);
                      setConversationId("");
                      onConversationClose();
                      setIsEditing(false);
                      resetSelectedItems();

                      if (getPath === -1) {
                        navigate(-1);
                      } else {
                        navigate(getPath);
                      }
                    }}
                  />
                </Tooltip>
              </Box>
            )}
            <Center width="100%" height={`${HEADER_HEIGHT - 2}px`} alignContent="center">
              <EditableInput
                size={isMobile ? "md" : "lg"}
                fontWeight="normal"
                maxHeight={`${HEADER_HEIGHT - 2}px`}
                isTextTruncated
                value={headerText}
                onEdit={(entity, value) => {
                  setHeaderText(value);
                  sendEditMessage({ entity, value });
                }}
                onCancel={() => setIsEditing(false)}
                isEditEnabled={projectId ? projectId.length > 0 : false}
                entity={"name"}
                backgroundColor="transparent"
                fontColor={fontColor}
              />
            </Center>
          </Stack>
        )}
        {!isMobile && showTickerInput && !isSharedRead && (
          <Center width="100%">
            <Box zIndex={6} alignContent={"flex-start"} height={"100%"} mt="1rem">
              <ConversationDialogInput
                minWidth={isTablet ? "30rem" : "37rem"}
                disableComponent={isSharedRead}
                hasLeftIcon={false}
                tooltip={isSharedRead ? "New tickers can not be added to shared projects" : undefined}
                inputId="view-input"
                className="ch-question-input ch-question-input-conversation-dialog ch-autocomplete-input-landing"
                initialText={
                  pathname.includes("/deal-finder")
                    ? "Search for private and public companies for investment insights"
                    : inputbarInitialText || (projectId && !pathname.includes("/panel"))
                    ? "Ask me a question about this project"
                    : pathname.includes("/home") || pathname.includes("/due_diligence")
                    ? copyTickerPlaceholder
                    : `Add a new ticker to this ${isPortfolios ? "portfolio" : "project"}`
                }
              />
            </Box>
          </Center>
        )}
      </Stack>
      <InteractionBar initialText={inputbarInitialText} />
    </Stack>
  );
};
