import { Box, Skeleton, Stack, useBreakpointValue, useColorModeValue } from "@chakra-ui/react";
import React from "react";

export const DealSkeletonTile: React.FC = () => {
  const borderColor = useColorModeValue("gray.300", "gray.600");
  const bgColor = useColorModeValue("white", "gray.700");
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const startColor = useColorModeValue("gray.200", "gray.900");
  const endColor = useColorModeValue("gray.50", "gray.800");

  const skeletonStyle = {
    startColor: startColor,
    endColor: endColor,
  };

  return (
    <Box
      borderWidth="1px"
      borderColor={borderColor}
      borderRadius="xl"
      overflow="hidden"
      bg={bgColor}
      height="100%"
      display="flex"
      flexDirection="column">
      <Stack spacing={0} flex={1}>
        {/* Logo Section */}
        <Box height="60px" display="flex" alignItems="center">
          <Skeleton {...skeletonStyle} ml="1rem" borderRadius={"lg"} height="28px" width="80%" />
        </Box>

        {/* Main Image */}
        <Skeleton {...skeletonStyle} height="128px" width="100%" />

        <Stack spacing={2} flex={1} alignItems="flex-start" height={"132px"} justifyContent={"space-between"}>
          <Box p="1rem" alignContent={"top"} height={"5rem"}>
            <Skeleton {...skeletonStyle} height="3rem" width="100%" mb={2} />
            <Skeleton {...skeletonStyle} height="1rem" width="80%" />
          </Box>
          <Stack
            direction="row"
            width="100%"
            justify="space-between"
            px={isMobile ? ".5rem" : "1rem"}
            py={isMobile ? ".2rem" : ".5rem"}
            borderTop="1px solid"
            borderColor={borderColor}>
            <Skeleton {...skeletonStyle} borderRadius={"lg"} height="1rem" width="1rem" />
            <Skeleton {...skeletonStyle} borderRadius={"lg"} height="1rem" width="1rem" />
            <Skeleton {...skeletonStyle} borderRadius={"lg"} height="1rem" width="1rem" />
            <Skeleton {...skeletonStyle} borderRadius={"lg"} height="1rem" width="1rem" />
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};
