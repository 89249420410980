import React, { useState } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Button,
  FormErrorMessage,
  useColorModeValue,
  VStack,
  Flex,
  Text,
} from "@chakra-ui/react";
import type { DealFinderTicker } from "../../../../api/tickers/models/DealFinderTicker";
import { useButtonProps } from "hooks";
import { CommonModal } from "screens/landing/components/popoverComponent/CommonModal";

interface ShareEmailModalProps {
  isOpen: boolean;
  onClose: () => void;
  deal: DealFinderTicker;
  onShare: (email: string, message?: string) => void;
}

export const ShareEmailModal: React.FC<ShareEmailModalProps> = ({ isOpen, onClose, deal, onShare }) => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const borderColor = useColorModeValue("gray.200", "gray.700");
  const submitButtonProps = useButtonProps("sm", "cta");
  const cancelButtonProps = useButtonProps("sm", "secondary");

  // Email validation regex
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const validateEmail = (email: string): boolean => {
    if (!email.trim()) {
      setEmailError("Email is required");
      return false;
    }

    if (!emailRegex.test(email)) {
      setEmailError("Please enter a valid email address");
      return false;
    }

    setEmailError("");
    return true;
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setEmail(value);
    if (emailError) {
      validateEmail(value);
    }
  };

  const handleSubmit = () => {
    if (validateEmail(email)) {
      setIsSubmitting(true);
      onShare(email, message.trim() || undefined);
      setIsSubmitting(false);
      onClose();

      // Reset form
      setEmail("");
      setMessage("");
      setEmailError("");
    }
  };

  // Modal header component
  const modalHeader = (
    <Box borderBottom="1px" borderColor={borderColor} p={4} width="100%">
      <Text fontSize="lg" fontWeight="bold">
        Share {deal.name} Deal
      </Text>
    </Box>
  );

  // Modal body component
  const modalBody = (
    <Box p={4}>
      <VStack spacing={4} align="stretch">
        <FormControl isInvalid={!!emailError} isRequired>
          <FormLabel htmlFor="email">Email Address</FormLabel>
          <Input
            id="email"
            type="email"
            value={email}
            onChange={handleEmailChange}
            placeholder="Enter recipient's email address"
            size="md"
            rounded="md"
          />
          {emailError && <FormErrorMessage>{emailError}</FormErrorMessage>}
        </FormControl>

        <FormControl>
          <FormLabel htmlFor="message">Message (Optional)</FormLabel>
          <Textarea
            id="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Add a personal message"
            size="md"
            rounded="md"
            resize="vertical"
            minHeight="100px"
          />
        </FormControl>
      </VStack>
    </Box>
  );

  // Modal footer component
  const modalFooter = (
    <Flex justifyContent="flex-end" p={4} borderTop="1px" borderColor={borderColor} width="100%">
      <Button {...cancelButtonProps} mr={3} onClick={onClose}>
        Cancel
      </Button>
      <Button {...submitButtonProps} isLoading={isSubmitting} onClick={handleSubmit}>
        Share
      </Button>
    </Flex>
  );

  return (
    <CommonModal
      isOpen={isOpen}
      onClose={onClose}
      modalHeader={modalHeader}
      modalBody={modalBody}
      modalFooter={modalFooter}
      showCloseButton
      minWidth={["90%", "30rem", "30rem"]}
    />
  );
};
