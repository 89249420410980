import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  useToast,
  Box,
} from "@chakra-ui/react";
import { useEntitlements, useButtonProps, useAppDispatch } from "hooks";
import { useReportTemplatesList } from "hooks/useReportTemplates";
import type { ReportTemplate } from "types/reportTemplates/ReportTemplate";
import type { FormValues } from "./components/UpsertReportTemplate";
import { UpsertReportTemplateModal } from "./components/UpsertReportTemplate";
import { AdminTiles } from "screens/common/components";
import { addReportTemplate, removeReportTemplate, updateReportTemplate } from "state/reportTemplates/operations";
import { v4 } from "uuid";
import { BiCopy } from "react-icons/bi";

const MODALS_KEYS = {
  DETAIL: "detail",
  UPSERT: "upsert",
  DELETE: "delete",
};

export const ReportTemplates = () => {
  const [isOpen, setIsOpen] = useState<string | undefined>();
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const [tempReportTemplate, setTempReportTemplate] = useState<FormValues | null>(null);
  const reportTemplates = useReportTemplatesList();
  const { manage_report_template_write: hasReportTemplatesWrite } = useEntitlements();
  const cancelRef = useRef<HTMLButtonElement>(null);
  const dispatch = useAppDispatch();
  const toast = useToast();
  const commonButtonProps = useButtonProps("sm", "primary");

  const onClose = useCallback(() => setIsOpen(undefined), []);

  const onSubmit = useCallback(
    async (fields: FormValues) => {
      try {
        const response = await (() => {
          const entities = fields.entities.map((entity) => {
            const newValue = (() => {
              try {
                return JSON.parse(`${entity.value}`);
              } catch (error) {
                return entity.value;
              }
            })();

            return { name: entity.name, value: newValue };
          });

          if (!selectedId) {
            return dispatch(
              addReportTemplate({
                id: v4(),
                name: fields.name,
                entities,
                ...(fields.organization !== "default" && { organizationId: fields.organization }),
              })
            );
          } else {
            return dispatch(
              updateReportTemplate({
                id: selectedId,
                reportTemplate: {
                  name: fields.name,
                  entities,
                  ...(fields.organization !== "default" && { organizationId: fields.organization }),
                },
              })
            );
          }
        })();

        if (response.type === addReportTemplate.rejected.type) {
          toast({
            title: "Error",
            description: "Error when creating report template",
            status: "error",
            duration: 5000,
            isClosable: true,
          });

          return;
        } else if (response.type === updateReportTemplate.rejected.type) {
          toast({
            title: "Error",
            description: "Error when updating report template",
            status: "error",
            duration: 5000,
            isClosable: true,
          });

          return;
        } else {
          toast({
            title: "Success",
            description: `Successfully ${selectedId ? "updated" : "created"} report template`,
            status: "success",
            duration: 5000,
            isClosable: true,
          });

          onClose();
        }
      } catch (error) {
        console.log(error);
      }
    },
    [dispatch, onClose, selectedId, toast]
  );

  const onOpenUpsert = useCallback((id?: string) => {
    if (id) {
      setSelectedId(id);
    }

    setIsOpen(MODALS_KEYS.UPSERT);
  }, []);

  const onOpenDelete = useCallback((id: any) => {
    setSelectedId(id);
    setIsOpen(MODALS_KEYS.DELETE);
  }, []);

  const onDelete = useCallback(async () => {
    if (!selectedId) {
      return;
    }

    const deleteResponse = await dispatch(removeReportTemplate(selectedId));

    if (deleteResponse.type === removeReportTemplate.rejected.type) {
      toast({
        title: "Report Template",
        description: "Error when deleting report template",
        status: "error",
        duration: 15000,
        isClosable: true,
      });

      return;
    } else {
      toast({
        title: "Report Template",
        description: "Report template deleted.",
        status: "success",
        duration: 15000,
        isClosable: true,
      });
    }

    onClose();
  }, [dispatch, onClose, selectedId, toast]);

  const customOptions = useMemo(() => {
    return [
      {
        label: "Duplicate report template",
        icon: BiCopy,
        hasPermission: hasReportTemplatesWrite,
        onClick: (reportTemplate: ReportTemplate) => {
          setTempReportTemplate({
            name: reportTemplate.name,
            entities: reportTemplate.entities,
            organization: reportTemplate.organizationId,
          });

          onOpenUpsert();
        },
      },
    ];
  }, [hasReportTemplatesWrite, onOpenUpsert]);

  useEffect(() => {
    if (isOpen === undefined) {
      setTimeout(() => {
        setSelectedId(null);
        setTempReportTemplate(null);
      }, 500);
    }
  }, [isOpen]);

  useEffect(() => {
    if (tempReportTemplate) {
      onOpenUpsert();
    }
  }, [tempReportTemplate, onOpenUpsert]);

  return (
    <Box>
      <AdminTiles<ReportTemplate>
        items={reportTemplates}
        fieldsToRender={["organizationName"]}
        filterByFields={["name"]}
        sortByFields={["name", "organizationName", "lastUpdatedDate"]}
        defaultSortByKey="lastUpdatedDate"
        inputFilterPlaceholder="Filter by template name"
        onClickCreate={() => onOpenUpsert()}
        onClickDelete={onOpenDelete}
        onClickEdit={onOpenUpsert}
        hasWrite={hasReportTemplatesWrite}
        hasRead
        tileTitle="Report Template"
        tileTitleKey="name"
        customOptions={customOptions}
        keyName="id"
      />

      {isOpen === "upsert" && (
        <UpsertReportTemplateModal
          {...(selectedId && { id: selectedId })}
          isOpen
          onClose={onClose}
          onSubmit={onSubmit}
          {...(tempReportTemplate && { initialValues: tempReportTemplate })}
        />
      )}

      <AlertDialog isOpen={isOpen === MODALS_KEYS.DELETE} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="sm" fontWeight="bold">
              Delete Report Template
            </AlertDialogHeader>

            <AlertDialogBody fontSize="sm">Are you sure? You can't undo this action afterwards.</AlertDialogBody>

            <AlertDialogFooter>
              <Button {...commonButtonProps} ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button {...commonButtonProps} onClick={onDelete} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};
