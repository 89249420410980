import { Box, Stack, useColorModeValue, Tooltip } from "@chakra-ui/react";
import {
  useCollectionHasHighlights,
  useCollectionKey,
  useCompanyStockFromCollectionContentsOrConversation,
  useCollectionMetadataIds,
  useConfigMap,
  useDownloadCollectionContents,
  useLatestCollectionWorkflowId,
  useProjectStockEquityDataFromCollectionMetadata,
  useCollectionCreatedDate,
  useTileProps,
} from "hooks";
import type { FunctionComponent } from "react";
import React, { useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { ConversationContext } from "screens/thread/ConversationContext";
import { useWorkflowCompletionDateOrNow } from "hooks/useWorkflows";
import { getViewConfig } from "configs/configMap";
import { WorkflowProgressBar } from "screens/landing/components/WorkflowProgressBar";
import { formatDistanceToNow } from "date-fns";
import { TextOverflowTooltip } from "screens/landing/components/TextOverflowTooltip";
import { StockEquityChart } from "screens/collection/components/StockEquityChart";
import { formatDateWithOutputFormat } from "screens/common/modal/formatters";
import { ProjectHeaderImage } from "screens/landing/tabs/project/projectLandingTileLayouts/components/ProjectHeaderImage";
import { generateMockStockEquityData } from "types/stock";

interface Props {
  collectionId: string;
}

export const ProjectWatchListTile: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({ collectionId }) => {
  const bgColor = useColorModeValue("white", "gray.800");
  const fontColor = useColorModeValue("gray.700", "gray.400");
  const navigate = useNavigate();
  const currentWorkflowId = useLatestCollectionWorkflowId(collectionId);
  const currentWorkflowCompletionDate = useWorkflowCompletionDateOrNow(currentWorkflowId);
  const configMap = useConfigMap();
  const { onConversationOpen } = useContext(ConversationContext);
  const collectionMetadataIds = useCollectionKey(collectionId, "metadataIds");
  const collectionConversationId = useCollectionKey(collectionId, "conversationId");
  const collectionType = useCollectionKey(collectionId, "collectionType");
  const collectionName = useCollectionKey(collectionId, "name");
  const projectGroupId = useCollectionKey(collectionId, "projectGroupId");
  const companyStockData = useCompanyStockFromCollectionContentsOrConversation(collectionId);
  const filteredContentData = useCollectionMetadataIds(collectionId);
  const stockEquityData = useProjectStockEquityDataFromCollectionMetadata(collectionMetadataIds || []);
  const projectCreationDate = useCollectionCreatedDate(collectionId);
  const placeholderStockEquityData = useMemo(() => generateMockStockEquityData(), []);
  const hoverBgColor = useColorModeValue("gray.50", "gray.700");

  const getPath = () => {
    const projectRoute = getViewConfig("route", collectionType || "", configMap);
    if (projectRoute) {
      return navigate(`/${projectRoute}/${collectionId}`);
    } else {
      onConversationOpen();
    }
  };
  const hasHighlights = useCollectionHasHighlights(collectionId);

  useDownloadCollectionContents(collectionMetadataIds, collectionId);
  const commonTileProps = useTileProps();

  return (
    <Box
      {...commonTileProps}
      border={"none"}
      borderRadius={"0"}
      color={fontColor}
      py="1rem"
      px="1rem"
      minHeight="100%"
      onClick={() => {
        getPath();
      }}
      _hover={{
        bg: hoverBgColor,
      }}
      className={`ch-collections-tab-collection-tile simple-tile-layout ${hasHighlights ? "has-highlights" : ""}`}>
      {currentWorkflowId && (
        <Box position="absolute" right="1rem" top="6px" bgColor={bgColor} borderRadius="full" boxSize={"1.4rem"}>
          <WorkflowProgressBar
            workflowId={currentWorkflowId}
            size="compact"
            lastUpdated={formatDistanceToNow(new Date(currentWorkflowCompletionDate), {
              addSuffix: true,
              includeSeconds: false,
            })}
            progressIndicatorStyle="circle"
            conversationId={collectionConversationId}
          />
        </Box>
      )}
      <Stack direction="row" height="100%" width="100%" spacing="1rem" align="center">
        <Box width="6.5rem" flexShrink={0} display="flex" justifyContent="center">
          {collectionType && (
            <ProjectHeaderImage
              projectGroupId={projectGroupId}
              projectName={companyStockData?.name}
              metadataIds={filteredContentData}
              projectType={collectionType}
              collectionId={collectionId}
              numberOfThumbnails={4}
            />
          )}
        </Box>
        <Stack className="ch-project-tile-stock-details" spacing="0" flex="1" minWidth="0">
          <TextOverflowTooltip
            breakWord={false}
            style={{ fontWeight: "bold" }}
            fontSize="sm"
            label={companyStockData ? companyStockData.name : collectionName ?? "n/a"}
          />
          <TextOverflowTooltip
            breakWord={false}
            fontSize="xs"
            label={`${companyStockData?.exchange || "--"} : ${companyStockData?.ticker || "--"}`}
          />
        </Stack>
        <Box width="45%" flexShrink={0}>
          <Tooltip
            zIndex={2}
            aria-label="stock-equity-tooltip"
            label={`As of ${projectCreationDate && formatDateWithOutputFormat(new Date(projectCreationDate), "do MMM yyyy hh:mm")}`}>
            <Box zIndex={1} width="100%">
              <StockEquityChart
                height="2.5rem"
                hideAxis
                stockEquityData={stockEquityData || placeholderStockEquityData}
                usingMockData={!stockEquityData}
              />
            </Box>
          </Tooltip>
        </Box>
      </Stack>
    </Box>
  );
};
