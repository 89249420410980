import type { JSONSchema7 } from "json-schema";

export const ScorecardSchema: JSONSchema7 = {
  $schema: "http://json-schema.org/draft-07/schema#",
  $id: "https://charli.ai/schemas/Scorecard.json",
  title: "Scorecard Product",
  description: "Schema for Scorecard product information",
  type: "object",
  properties: {
    id: {
      type: "string",
      description: "Unique identifier for the Scorecard product",
    },
    name: {
      type: "string",
      description: "Name of the Scorecard product",
    },
    price: {
      type: "string",
      description: "Price of the Scorecard product in USD",
      pattern: "^\\$\\d+(\\.\\d{1,2})?$",
    },
    description: {
      type: "string",
      description: "Description of the Scorecard product",
    },
  },
  required: ["id", "name", "price", "description"],
  additionalProperties: false,
};
