import { useColorModeValue, Text, Stack, Box, Tooltip, IconButton, Icon, useBreakpointValue } from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import React from "react";
import { BiLike, BiDislike } from "react-icons/bi";
import { IoMdHelpCircleOutline } from "react-icons/io";
import { SmallActionButton } from "screens/content/contentCanvas/cell/SmallActionButton";

interface Props {
  title: string;
  subtitle?: string;
  helpText?: string;
  helpUrl?: string;
  className?: string;
}

export const SimpleMetric: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  title,
  subtitle,
  helpText,
  helpUrl,
  className,
  children,
}) => {
  const textColor = useColorModeValue("primary.darkGray", "gray.400");
  const subtitleColor = useColorModeValue("gray.400", "gray.200");
  const helpIconColor = useColorModeValue("gray.400", "gray.400");
  const borderColor = useColorModeValue("gray.200", "gray.700");
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });

  const handleHelpClick = () => {
    if (helpUrl) {
      window.open(helpUrl, "_blank");
    }
  };

  return (
    <Stack spacing="0" width="100%" height="100%" justifyContent={"space-between"}>
      <Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Text
            px={isMobile ? "0" : "1rem"}
            pt={isMobile ? "0" : "1rem"}
            fontWeight="600"
            fontSize="md"
            lineHeight="1.4"
            color={textColor}
            mb={2}>
            {title}
          </Text>
          {helpText && (
            <Tooltip label={helpText} maxWidth={"12rem"} className={`${className}-tooltip`}>
              <IconButton
                icon={
                  <Icon
                    className={className}
                    as={IoMdHelpCircleOutline}
                    boxSize={"1rem"}
                    _hover={{ opacity: helpUrl ? 0.8 : 1 }}
                    cursor={helpUrl ? "pointer" : "default"}
                    onClick={handleHelpClick}
                  />
                }
                aria-label="Help"
                variant="ghost"
                size="sm"
                height={"0"}
                color={helpIconColor}
              />
            </Tooltip>
          )}
        </Stack>
        <Text isTruncated mt="0!important" fontSize="10px" fontWeight="light" color={subtitleColor} textAlign="left">
          {subtitle}
        </Text>
      </Stack>
      <Box height={isMobile ? "15rem" : "19rem"} px={isMobile ? "0" : "1rem"}>
        {children}
      </Box>
      <Stack
        spacing={isMobile ? ".5rem" : "1rem"}
        width="100%"
        px={isMobile ? ".5rem" : "1rem"}
        py={isMobile ? ".2rem" : ".5rem"}
        direction="row"
        borderTop="1px solid"
        borderColor={borderColor}
        align="center">
        <SmallActionButton iconSize="1rem" tooltip="Like" iconTypeName={BiLike} />
        <SmallActionButton iconSize="1rem" tooltip="Dislike" iconTypeName={BiDislike} />
      </Stack>
    </Stack>
  );
};
