import { Stack, Text, Tooltip, useColorModeValue } from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import React from "react";

interface Props {
  onClick: () => void;
  isLoading?: boolean;
  label?: string;
  totalItemCount: number;
  currentItemCount: number;
  tooltip?: string;
}

export const ItemLoadingIndicator: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  onClick,
  label = "Projects",
  totalItemCount,
  currentItemCount,
  tooltip,
}) => {
  const buttonColor = useColorModeValue("gray.600", "gray.500");

  return (
    <Stack direction="row" spacing="0.75rem">
      <Tooltip label={tooltip} placement="top" maxWidth={"10rem"} textAlign={"center"}>
        <Text
          onClick={onClick}
          px=".5rem"
          fontSize="xs"
          color={buttonColor}
          fontWeight="normal">{`Showing ${currentItemCount} of ${totalItemCount} ${label}`}</Text>
      </Tooltip>
    </Stack>
  );
};
