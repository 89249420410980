import type { FunctionComponent } from "react";
import React, { createContext, useMemo, useState, useCallback } from "react";
import type { WorkflowTaskStatus } from "types/workflows/workflow";
import { useWorkflowsMap } from "hooks/useWorkflows";
import { ManualTags } from "screens/collection/components/utils";
import type { CollectionWithAdditionalProps } from "types/collection";
import { parseISO } from "date-fns";

export type QuadrantType = "volatile" | "stable" | "marginal" | "potential" | undefined;
export type RatingType = "Buy" | "Out Perform" | "Hold" | "Under Perform" | "Sell" | undefined;

export const CollectionsFilterContext = createContext({
  selectedTags: [] as string[],
  setSelectedTags: (tags: string[]): void => {
    throw new Error("Component must be nested in <CollectionsFilterContext.Provider />");
  },
  searchText: "",
  setSearchText: (text: string): void => {
    throw new Error("Component must be nested in <CollectionsFilterContext.Provider />");
  },
  deletedCollectionIds: [] as string[],
  addDeletedCollectionId: (id: string): void => {
    throw new Error("Component must be nested in <CollectionsFilterContext.Provider />");
  },
  endDate: new Date() as Date | null,
  setEndDate: (date: Date | null): void => {
    throw new Error("Component must be nested in <CollectionsFilterContext.Provider />");
  },
  startDate: new Date() as Date | null,
  setStartDate: (date: Date | null): void => {
    throw new Error("Component must be nested in <CollectionsFilterContext.Provider />");
  },
  hasReportFilter: undefined as boolean | undefined,
  setHasReportFilter: (value: boolean | undefined): void => {
    throw new Error("Component must be nested in <CollectionsFilterContext.Provider />");
  },
  collectionCount: 10,
  setCollectionCount: (() => {
    throw new Error("Component must be nested in <CollectionsFilterContext.Provider />");
  }) as React.Dispatch<React.SetStateAction<number>>,
  totalCollectionCount: 0,
  filteredCollections: [] as CollectionWithAdditionalProps[],
  groupedCollections: [] as CollectionWithAdditionalProps[],
  areImagesLoading: true as boolean,
  setAreImagesLoading: (value: boolean): void => {
    throw new Error("Component must be nested in <CollectionsFilterContext.Provider />");
  },
  selectedStates: [] as { label: string; value: string }[],
  setSelectedStates: (states: { label: string; value: string }[]): void => {
    throw new Error("Component must be nested in <CollectionsFilterContext.Provider />");
  },
  selectedWorkflowIds: [] as string[],
  setSelectedWorkflowIds: (ids: string[]): void => {
    throw new Error("Component must be nested in <CollectionsFilterContext.Provider />");
  },
  selectedProjectId: undefined as string | undefined,
  setSelectedProjectId: (value: string | undefined): void => {
    throw new Error("Component must be nested in <CollectionsFilterContext.Provider />");
  },
  selectedQuadrant: undefined as QuadrantType,
  setSelectedQuadrant: (quadrant: QuadrantType): void => {
    throw new Error("Component must be nested in <CollectionsFilterContext.Provider />");
  },
  selectedRating: undefined as RatingType,
  setSelectedRating: (rating: RatingType): void => {
    throw new Error("Component must be nested in <CollectionsFilterContext.Provider />");
  },
});

interface Props {
  collections?: CollectionWithAdditionalProps[];
}

const determineQuadrant = (x: number, y: number): QuadrantType => {
  const midPoint = 50; // Assuming 50 is the midpoint for both axes

  if (x >= midPoint && y >= midPoint) return "stable";
  if (x < midPoint && y >= midPoint) return "volatile";
  if (x < midPoint && y < midPoint) return "marginal";
  if (x >= midPoint && y < midPoint) return "potential";

  return undefined;
};

const getRatingForScore = (score: number): RatingType => {
  if (score === 0) return undefined;
  if (score <= 1) return "Sell";
  if (score <= 2) return "Under Perform";
  if (score <= 3) return "Hold";
  if (score <= 4) return "Out Perform";
  return "Buy";
};

type FilterConditions = {
  selectedTags: string[];
  selectedWorkflowIds: string[];
  selectedStates: { label: string; value: string }[];
  hasReportFilter: boolean | undefined;
  searchText: string;
  startDate: Date | null;
  endDate: Date | null;
  selectedQuadrant: QuadrantType;
  selectedRating: RatingType;
  workflowsById: Record<string, any>;
  collections: CollectionWithAdditionalProps[];
};

const passesFilters = (collection: CollectionWithAdditionalProps, conditions: FilterConditions): boolean => {
  const tags = collection.metadata.autoTags.concat(collection.metadata.manualTags);
  const workflowIds: string[] | undefined = collection.workflowIds!;

  // Early return optimizations for most common/fastest checks
  if (conditions.searchText) {
    const searchTokens = conditions.searchText.toLowerCase().trim().split(" ");
    const cellTokens = [collection.name, collection.ticker, collection.stockExchange].join(" ").toLowerCase();
    if (!searchTokens.every((searchToken) => cellTokens.includes(searchToken))) return false;
  }

  if (conditions.selectedTags.length > 0 && !conditions.selectedTags.every((item) => tags.includes(item))) {
    return false;
  }

  if (conditions.hasReportFilter && !tags.includes(ManualTags.projectOutput)) {
    return false;
  }

  // Date range checks
  if (conditions.startDate || conditions.endDate) {
    const createdTime = collection.metadata.createdTime ? parseISO(collection.metadata.createdTime).getTime() : undefined;
    if (conditions.startDate && createdTime && createdTime < conditions.startDate.getTime()) return false;
    if (conditions.endDate && createdTime && createdTime > conditions.endDate.getTime()) return false;
  }

  // Workflow and state checks
  if (workflowIds && conditions.selectedWorkflowIds.length > 0) {
    if (!conditions.selectedWorkflowIds.some((item) => workflowIds.includes(item))) return false;

    if (conditions.selectedStates.length > 0) {
      const selectedWorkflows = workflowIds.map((id) => conditions.workflowsById[id]).filter(Boolean);

      if (selectedWorkflows.length > 0) {
        const updatedSelectedStates = conditions.selectedStates.map((state) => (state.value === "incomplete" ? "failed" : state.value));
        const workflowStatuses = selectedWorkflows.map((workflow) => workflow.status);
        if (!updatedSelectedStates.some((stateValue) => workflowStatuses.includes(stateValue as WorkflowTaskStatus))) return false;
      }
    }
  }

  // Quadrant check
  if (conditions.selectedQuadrant) {
    const hasActiveProject = collection.isActiveProjectInGroup === true;
    const x = Number(collection?.investmentScoreAnalytical?.value) || 0;
    const y = Number(collection?.investmentScoreSentiment?.value) || 0;

    if (!hasActiveProject || x === 0 || y === 0) return false;
    if (determineQuadrant(x, y) !== conditions.selectedQuadrant.toLowerCase()) return false;
  }

  // Buy/Sell Rating check
  if (conditions.selectedRating) {
    const buySellScore = Number(collection?.buySellScoreSentiment?.value) || 0;
    if (getRatingForScore(buySellScore) !== conditions.selectedRating) return false;
  }

  return true;
};

export const CollectionsFilterContextProvider: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  children,
  collections = [],
}) => {
  const [selectedProjectId, setSelectedProjectId] = useState<string | undefined>(undefined);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [hasReportFilter, setHasReportFilter] = useState<boolean | undefined>(undefined);
  const [areImagesLoading, setAreImagesLoading] = useState<boolean>(true);
  const [searchText, setSearchText] = useState("");
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [collectionCount, setCollectionCount] = useState<number>(15);
  const [selectedStates, setSelectedStates] = useState<{ label: string; value: string }[]>([]);
  const [selectedWorkflowIds, setSelectedWorkflowIds] = useState<string[]>([]);
  const [selectedQuadrant, setSelectedQuadrant] = useState<QuadrantType>(undefined);
  const [selectedRating, setSelectedRating] = useState<RatingType>(undefined);
  const [deletedCollectionIds, setDeletedCollectionIds] = useState<string[]>([]);
  const workflowsById = useWorkflowsMap();

  const addDeletedCollectionId = useCallback((id: string) => {
    setDeletedCollectionIds((prev) => [...prev, id]);
  }, []);

  const { filteredCollections, groupedCollections } = useMemo(() => {
    if (!collections.length) return { filteredCollections: [], groupedCollections: [] };

    const conditions: FilterConditions = {
      selectedTags,
      selectedWorkflowIds,
      selectedStates,
      hasReportFilter,
      searchText,
      startDate,
      endDate,
      selectedQuadrant,
      selectedRating,
      workflowsById,
      collections,
    };

    // Optimize by combining filtering and grouping in a single pass
    const groupedMap = new Map<string | undefined, CollectionWithAdditionalProps[]>();
    const filtered: CollectionWithAdditionalProps[] = [];

    for (const collection of collections) {
      // Skip collections that have been marked as deleted
      if (deletedCollectionIds.includes(collection.id)) continue;

      if (!passesFilters(collection, conditions)) continue;

      filtered.push(collection);

      const groupId = collection.projectGroupId;
      if (groupId) {
        const group = groupedMap.get(groupId) || [];
        group.push(collection);
        groupedMap.set(groupId, group);
      }
    }

    // Build final grouped collections array
    const grouped: CollectionWithAdditionalProps[] = [];
    for (const collection of filtered) {
      const groupId = collection.projectGroupId;

      if (!groupId) {
        grouped.push(collection);
        continue;
      }

      const group = groupedMap.get(groupId);
      if (!group || group.length === 1) {
        grouped.push(collection);
        continue;
      }

      // Only add active projects from groups
      if (collection.isActiveProjectInGroup) {
        grouped.push(collection);
      }
    }

    return {
      filteredCollections: filtered,
      groupedCollections: grouped,
    };
  }, [
    collections,
    selectedTags,
    selectedWorkflowIds,
    selectedStates,
    hasReportFilter,
    searchText,
    startDate,
    endDate,
    selectedQuadrant,
    selectedRating,
    workflowsById,
    deletedCollectionIds, // Add dependency on deleted collection IDs
  ]);

  return (
    <CollectionsFilterContext.Provider
      value={{
        selectedProjectId,
        setSelectedProjectId,
        selectedTags,
        setSelectedTags,
        searchText,
        setSearchText,
        deletedCollectionIds,
        addDeletedCollectionId,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        filteredCollections,
        groupedCollections,
        hasReportFilter,
        setHasReportFilter,
        collectionCount,
        setCollectionCount,
        totalCollectionCount: collections.length,
        areImagesLoading,
        setAreImagesLoading,
        selectedStates,
        setSelectedStates,
        selectedWorkflowIds,
        setSelectedWorkflowIds,
        selectedQuadrant,
        setSelectedQuadrant,
        selectedRating,
        setSelectedRating,
      }}>
      {children}
    </CollectionsFilterContext.Provider>
  );
};
