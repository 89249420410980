import { Box, Stack, useToast, Text, useColorModeValue, Center, useBreakpointValue } from "@chakra-ui/react";
import {
  useCollection,
  useCollectionKey,
  useContents,
  useHasConversation,
  useInitialSyncCompleted,
  useLatestCollectionWorkflowId,
  useProjectParams,
  useSidebarNavigation,
  useUserPreference,
  useUserProfile,
} from "hooks";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastMessageContent } from "screens/common/components";
import { ContentFilterContextProvider } from "screens/content";
import { ContentSortingGroupingContext } from "screens/content/contentCanvas/body/ContentSortingGroupingContext";
import type { GroupByOption, GroupBySortDirection, SortDirection, SortOption } from "types/SortingAndGroupingPreferences";
import { TabTitle } from "screens/common/components/TabTitle";
import { useConversationContext } from "screens/thread/ConversationContext";
import { toTitleCase } from "screens/common/app";
import { SettingsProviderContext } from "screens/panels/settings/SettingsProvider";
import { useAddToCharliContext } from "screens/panels/addToCharli/AddToCharliWizard/AddToCharliProvider";
import { ProjectDetailViewContent } from "./views/ProjectDetailViewContent";
import { TagsPanel } from "screens/panels/tags/TagsPanel";
import { ContentViewPanel } from "screens/content/contentView/ContentViewPanel";
import { NotesPanel } from "screens/content/common/notes/NotesPanel";
import { SharePanel } from "screens/panels/share/SharePanel";
import { VerifiedAIPanel } from "screens/content/contentView/VerifiedAIPanel";
import { ProjectKeywordsNotesPanel } from "screens/panels/notes/ProjectKeywordsNotesPanel";
import { ProjectAddSource } from "./views/ProjectAddSource";
import { useWorkflowKey } from "hooks/useWorkflows";
import { deleteUserPreference } from "state/userPreference/operations";
import { useDispatch } from "react-redux";
import { downloadConversationById } from "state/conversation/operations";
import { ProjectQuestionsProvider } from "./ProjectQuestionsContext";
import { DEFAULT_SIDEBAR_WIDTH } from "screens/landing/components";
import { ConversationDialogInput } from "screens/thread/ConversationDialogInput";
import { useCopyValue } from "hooks/useCopies";
import { ConversationContent } from "screens/thread/components";
import { InputBarDropzone } from "screens/conversation/components";

export const ProjectDetailView: React.FC = () => {
  // Type assertion is needed here as useParams assumes all parameters are present. viewContentId may be undefined, we want the type to accurately reflect this.
  const { parentRoute, projectFilter, projectId, contentId } = useProjectParams();
  const { setConversationId, onConversationClose, isConversationOpen } = useConversationContext();
  const collectionName = useCollectionKey(projectId, "name");
  const collectionMetadataIds = useCollectionKey(projectId, "metadataIds");
  const projectConversationId = useCollectionKey(projectId, "conversationId");
  const maybeCollectionId = useCollectionKey(projectId, "id");
  const [sortName] = useState<SortOption>("Date");
  const [sortDirection] = useState<SortDirection>("DESC");
  const [groupByName] = useState<GroupByOption>("");
  const [groupByDirection] = useState<GroupBySortDirection>("ASC");
  const textColor = useColorModeValue("gray.700", "gray.100");
  const bgColor = useColorModeValue("#fbfbfb", "gray.800");
  const boxShadowColor = useColorModeValue("0, 0, 0", "255, 255, 255");
  const { setHeaderText } = useContext(SettingsProviderContext);
  const { onContentViewOpen, onContentViewClose, isContentViewOpen } = useAddToCharliContext();
  const workflowId = useLatestCollectionWorkflowId(projectId);
  const workflowConversationId = useWorkflowKey(workflowId, "conversationId");
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const isTablet = useBreakpointValue({ base: false, md: true, lg: false }, { fallback: "md", ssr: false });
  const hasInitialSyncCompleted = useInitialSyncCompleted();
  const navigate = useNavigate();
  const toast = useToast();
  const shareDetails = useCollectionKey(projectId, "shareDetails");
  const { id: currentUserId } = useUserProfile();
  const isSharedRead = shareDetails && shareDetails.accessMode === "read" && shareDetails.ownerId !== currentUserId;
  const copyTickerPlaceholder = useCopyValue("copy_ticker_placeholder_text");
  const { pathname } = useLocation();

  const contentData = useContents(collectionMetadataIds || [], { refreshFromNetwork: true });
  const dispatch = useDispatch();
  const userPreferenceSourceWeight = useUserPreference("source_weights_index") as 0 | 1;
  const hasConversation = useHasConversation(projectConversationId);

  const { currentSidebarType } = useSidebarNavigation();
  const sidebarWidth = (useUserPreference("ui_sidebar_width") as number) || (DEFAULT_SIDEBAR_WIDTH as number);

  const collection = useCollection(projectId, { refreshFromNetwork: true });
  const injectDebugEntityPreference = useUserPreference("ui_inject_debug_entity");
  useEffect(() => {
    if (injectDebugEntityPreference) {
      return () => {
        console.log(`Project Data: ${collectionName}`, collection);
      };
    }
  }, [collection, collectionName, injectDebugEntityPreference]);

  useEffect(() => {
    userPreferenceSourceWeight && dispatch(deleteUserPreference({ preferenceKey: "source_weights_index" }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const viewConversationId = projectConversationId || contentId || workflowConversationId;
    viewConversationId && setConversationId(viewConversationId);
    setHeaderText(collectionName ?? "Project", true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentRoute, projectFilter, projectId, contentId, collectionName]);

  useEffect(() => {
    if (contentId) {
      onContentViewOpen();
    } else {
      onContentViewClose();
    }
  }, [contentId, onContentViewClose, onContentViewOpen]);

  useEffect(() => {
    if (hasInitialSyncCompleted && maybeCollectionId && maybeCollectionId !== projectId) {
      onConversationClose();
      toast({
        render: ({ onClose }) => (
          <ToastMessageContent
            message={`Unfortunately I can't find that ${parentRoute}, please ensure that you are logged in with the correct account and try again.`}
            onClick={() => {
              onConversationClose();
              onClose();
            }}
            onClose={onClose}
          />
        ),
        duration: 10000,
        isClosable: true,
        position: "top-right",
      });
    }
  }, [hasInitialSyncCompleted, projectId, toast, parentRoute, maybeCollectionId, onConversationClose]);

  useEffect(() => {
    if (projectConversationId && !hasConversation) {
      dispatch(downloadConversationById({ conversationId: projectConversationId }));
    }
  }, [projectConversationId, hasConversation, dispatch]);

  const contentToRender = useMemo(() => {
    if (!maybeCollectionId) {
      return (
        <Center height="100%">
          <Stack fontSize={"md"} align="center" spacing="1rem" minHeight="11rem" justifyContent="center">
            <Text color={textColor}>
              I couldn't find any resources for this project.{" "}
              <Text as="span" color="blue.500" textDecoration="underline" cursor="pointer" onClick={() => navigate("/home")}>
                click here
              </Text>{" "}
              to return home
            </Text>
          </Stack>
        </Center>
      );
    } else {
      return (
        <ProjectQuestionsProvider collectionId={maybeCollectionId}>
          <ProjectDetailViewContent collectionId={maybeCollectionId} />
        </ProjectQuestionsProvider>
      );
    }
  }, [maybeCollectionId, navigate, textColor]);

  const renderFixedProjectInput = useMemo(() => {
    return (
      <Center
        position="fixed"
        bottom="0"
        left={currentSidebarType === "hidden" ? 0 : `${sidebarWidth}px`}
        width="100%"
        maxWidth={`calc(100vw - ${currentSidebarType === "hidden" ? 0 : sidebarWidth}px)`}
        zIndex="10"
        pointerEvents="none"
        pb="1rem">
        <Box
          width={isMobile ? "100%" : "40rem"}
          borderRadius="2xl"
          backgroundColor={bgColor}
          boxShadow={`0 4px 20px rgba(${boxShadowColor}, 0.25)`}
          pointerEvents="auto">
          <InputBarDropzone
            borderRadius={"1rem"}
            attachmentId="conversation"
            display="flex"
            flexGrow={1}
            flexDirection="column"
            background={bgColor}
            minWidth={0}
            maxH={"60vh"}
            height="100%">
            {projectConversationId && isConversationOpen && <ConversationContent conversationId={projectConversationId} />}
            <Box width="100%" px="1rem" pt="1.5rem" pb="1.5rem">
              <ConversationDialogInput
                disableComponent={isSharedRead}
                tooltip={isSharedRead ? "Questions can not be asked in shared projects" : undefined}
                minWidth="unset"
                inputId={"view-input"}
                className="ch-question-input-conversation-dialog"
                initialText={projectId && !pathname.includes("/panel") ? "Ask me a question about this project" : copyTickerPlaceholder}
                renderRole="embedded"
              />
            </Box>
          </InputBarDropzone>
        </Box>
      </Center>
    );
  }, [
    currentSidebarType,
    sidebarWidth,
    isMobile,
    bgColor,
    boxShadowColor,
    projectConversationId,
    isConversationOpen,
    isSharedRead,
    projectId,
    pathname,
    copyTickerPlaceholder,
  ]);

  return (
    <ContentFilterContextProvider contentItems={contentData}>
      <TabTitle title={`Charli > ${toTitleCase(collectionName || parentRoute || "Project")}`} />
      <ContentSortingGroupingContext.Provider
        value={{
          sortName,
          sortDirection,
          groupByName,
          groupByDirection,
        }}>
        <Box
          width="100%"
          height="100%"
          pl={isMobile || isTablet ? ".5rem" : "1rem"}
          pr={isMobile || isTablet ? ".5rem" : "1rem"}
          pt={isMobile ? ".5rem" : "1rem"}>
          {contentToRender}
          {!isMobile && contentData && contentData.length > 0 && !isSharedRead && renderFixedProjectInput}
        </Box>
        <TagsPanel />
        <ContentViewPanel />
        <VerifiedAIPanel />
        <ProjectKeywordsNotesPanel />
        {isContentViewOpen && (
          <>
            <SharePanel />
            <NotesPanel />
          </>
        )}
        <ProjectAddSource />
      </ContentSortingGroupingContext.Provider>
    </ContentFilterContextProvider>
  );
};
