import React, { useCallback, useRef, useState, useEffect, useMemo } from "react";
import { Stack, useBreakpointValue, useColorModeValue, useToast } from "@chakra-ui/react";
import { BiLike, BiShareAlt } from "react-icons/bi";
import { BsPinAngle } from "react-icons/bs";
import { SmallActionButton } from "../../content/contentCanvas/cell/SmallActionButton";
import type { DealFinderTicker } from "../../../api/tickers/models/DealFinderTicker";
import { useDispatch } from "react-redux";
import { sendMessage } from "state/websocket/operations";
import type { RequestEntities } from "types/charliui";
import { ToastMessageContent } from "screens/common/components";
import { v4 as uuid } from "uuid";
import { ShareEmailModal } from "./modals/ShareEmailModal";
import type { IconType } from "react-icons";

interface DealActionButtonsProps {
  deal: DealFinderTicker;
  iconSize?: string;
  showBorder?: boolean;
}

interface ActionButtonConfig {
  tooltip: string;
  icon: React.ComponentType;
  action?: string;
  message?: string;
}

export const DealActionButtons: React.FC<DealActionButtonsProps> = ({ deal, iconSize = "1rem", showBorder = true }) => {
  const borderColor = useColorModeValue("gray.300", "gray.600");
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const dispatch = useDispatch();
  const toast = useToast();
  const containerRef = useRef<HTMLDivElement>(null);
  const [isWideContainer, setIsWideContainer] = useState(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);

  // Define action button configurations
  const actionButtons = useMemo<ActionButtonConfig[]>(
    () => [
      {
        tooltip: "Like deal",
        icon: BiLike,
        action: "deal_like",
        message: `I'll like the ${deal.name} deal for you now.`,
      },
      {
        tooltip: "Share deal",
        icon: BiShareAlt,
        action: "deal_share",
        message: `I'll share the ${deal.name} deal now.`,
      },
      {
        tooltip: "Pin deal",
        icon: BsPinAngle,
        action: "deal_pin",
        message: `I'll pin the ${deal.name} deal for you now.`,
      },
    ],
    [deal.name]
  );

  // Check if container width is greater than 500px
  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    const checkWidth = () => {
      if (containerRef.current) {
        setIsWideContainer(containerRef.current.offsetWidth > 500);
      }
    };

    const debouncedCheckWidth = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(checkWidth, 100);
    };

    checkWidth();
    window.addEventListener("resize", debouncedCheckWidth);

    return () => {
      window.removeEventListener("resize", debouncedCheckWidth);
      clearTimeout(timeoutId);
    };
  }, []);

  const baseEntities: RequestEntities = useMemo(() => [{ entity: "deal_id", value: deal.id }], [deal.id]);

  const onSubmitWorkflow = useCallback(
    (intent: string, entities: RequestEntities, message?: string) => {
      if (!intent) return;
      const conversationId = uuid();

      try {
        dispatch(
          sendMessage({
            conversationId: conversationId,
            intent: `/${intent}`,
            entities,
          })
        );

        if (message) {
          toast.closeAll();
          toast({
            render: ({ onClose }) => <ToastMessageContent message={message} onClick={onClose} onClose={onClose} />,
            duration: 5000,
            isClosable: true,
            position: "top-right",
          });
        }
      } catch (error) {
        toast.closeAll();
        toast({
          render: ({ onClose }) => (
            <ToastMessageContent
              message={`The following error occurred: ${error}. Please try again.`}
              onClick={onClose}
              onClose={onClose}
            />
          ),
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
      }
    },
    [dispatch, toast]
  );

  const handleShareUrl = useCallback(() => {
    const url = new URL(window.location.href);
    const shareableUrl = url.toString();
    const shareableDealUrl = shareableUrl.includes("dealId") ? shareableUrl : `${shareableUrl}?dealId=${deal.id}`;
    navigator.clipboard.writeText(shareableDealUrl);
    return shareableDealUrl;
  }, [deal.id]);

  const handleShareDeal = useCallback(
    (email: string, message?: string) => {
      const shareableDealUrl = handleShareUrl();
      const entities: RequestEntities = [
        ...baseEntities,
        { entity: "email_address", value: email },
        { entity: "deal_url", value: shareableDealUrl },
      ];
      if (message) {
        entities.push({ entity: "message", value: message });
      }

      onSubmitWorkflow("deal_share", entities, `I'll share the ${deal.name} deal with ${email}.`);
    },
    [handleShareUrl, onSubmitWorkflow, baseEntities, deal.name]
  );

  const renderActionButton = useCallback(
    (config: ActionButtonConfig, index: number) => (
      <SmallActionButton
        classNames={`ch-deal-action-buttons ch-deal-action-button-${config.action}`}
        key={`action-button-${index}`}
        iconSize={iconSize}
        tooltip={config.tooltip}
        iconTypeName={config.icon as IconType}
        onClick={() => {
          switch (config.action) {
            case "deal_like":
            case "deal_pin":
              onSubmitWorkflow(config.action, [...baseEntities], config.message);
              break;
            case "share":
              // This case is no longer used - we open the modal instead
              break;
            default:
              toast.closeAll();
              toast({
                render: ({ onClose }) => <ToastMessageContent message={`${config.message}`} onClick={onClose} onClose={onClose} />,
                duration: 5000,
                isClosable: true,
                position: "top-right",
              });
              break;
          }
        }}
      />
    ),
    [iconSize, onSubmitWorkflow, baseEntities, toast]
  );

  return (
    <>
      <ShareEmailModal isOpen={isShareModalOpen} onClose={() => setIsShareModalOpen(false)} deal={deal} onShare={handleShareDeal} />

      {/* Action buttons */}
      <Stack
        direction="row"
        width="100%"
        justify="space-between"
        px={isMobile ? ".5rem" : "1rem"}
        py={isMobile ? ".2rem" : ".5rem"}
        borderTop={showBorder ? "1px solid" : undefined}
        borderColor={borderColor}
        ref={containerRef}>
        {isWideContainer ? (
          <>
            <Stack direction="row" spacing={"2rem"}>
              {actionButtons.slice(0, 1).map(renderActionButton)}
              <SmallActionButton
                classNames="ch-deal-action-buttons ch-deal-action-button-share"
                key="action-button-share"
                iconSize={iconSize}
                tooltip="Share deal"
                iconTypeName={BiShareAlt as IconType}
                onClick={() => setIsShareModalOpen(true)}
              />
              {actionButtons.slice(2, 3).map(renderActionButton)}
            </Stack>
          </>
        ) : (
          <>
            {actionButtons.slice(0, 1).map(renderActionButton)}
            <SmallActionButton
              classNames="ch-deal-action-buttons  ch-deal-action-button-share"
              key="action-button-share"
              iconSize={iconSize}
              tooltip="Share deal"
              iconTypeName={BiShareAlt as IconType}
              onClick={() => setIsShareModalOpen(true)}
            />
            {actionButtons.slice(2).map(renderActionButton)}
          </>
        )}
      </Stack>
    </>
  );
};
