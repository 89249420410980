import { Box, useColorModeValue } from "@chakra-ui/react";
import { useCollectionKey, useHasConversation, useProjectParams } from "hooks";
import React, { useEffect } from "react";
import { InputBarDropzone } from "screens/conversation/components";
import { ConversationContent } from "./components";
import { useConversationContext } from "./ConversationContext";
import { useDispatch } from "react-redux";
import { downloadConversationById, downloadTags } from "state/conversation/operations";
import { v4 as uuid } from "uuid";
import { useAddToCharliContext } from "screens/panels/addToCharli/AddToCharliWizard/AddToCharliProvider";
import { ConversationDialogInput } from "./ConversationDialogInput";
import { useCopyValue } from "hooks/useCopies";

export const ConversationDialog = () => {
  const { projectId } = useProjectParams();
  const projectConversationId = useCollectionKey(projectId || "", "conversationId");
  const { conversationId, setConversationId } = useConversationContext();
  const { isNewMenuAction } = useAddToCharliContext();
  const bgColor = useColorModeValue("white", "gray.800");
  const hasConversation = useHasConversation(conversationId);
  const bgInputColor = useColorModeValue("charli.lightBlue", "gray.800");
  const copyTickerPlaceholder = useCopyValue("copy_ticker_placeholder_text");

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(downloadTags());
  }, [dispatch]);

  useEffect(() => {
    if (isNewMenuAction) {
      return;
    }

    if (conversationId.length > 0 && !hasConversation) {
      dispatch(downloadConversationById({ conversationId: conversationId }));
    }
  }, [hasConversation, isNewMenuAction, conversationId, dispatch]);

  useEffect(() => {
    setConversationId((prevConversationId: string) => {
      const newConversationId = uuid();
      const nextConversationId = isNewMenuAction ? newConversationId : prevConversationId || newConversationId;

      return nextConversationId;
    });
  }, [isNewMenuAction, setConversationId]);

  return (
    <InputBarDropzone
      borderRadius={"1rem"}
      attachmentId="conversation"
      display="flex"
      flexGrow={1}
      flexDirection="column"
      background={bgColor}
      minWidth={0}
      height="100%">
      <ConversationContent conversationId={projectConversationId || conversationId} />
      <Box
        width="100%"
        flexGrow={0}
        flexShrink={0}
        bgColor={bgInputColor}
        borderBottomRadius="1rem"
        px="1rem"
        py="1rem"
        className="conversation-dialog-autocomplete-input">
        <ConversationDialogInput
          minWidth="100%"
          conversationId={conversationId}
          inputId={"conversation-input"}
          className=" ch-autocomplete-input-floating-modal ch-question-input-conversation-dialog"
          initialText={projectId ? "Ask me a question about this project" : copyTickerPlaceholder}
          renderRole="cli"
        />
      </Box>
    </InputBarDropzone>
  );
};
