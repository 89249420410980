import React, { useMemo } from "react";
import { useColorModeValue, Stack, Text, Box, Tooltip, useBreakpointValue } from "@chakra-ui/react";
import { useWorkflowKey } from "hooks/useWorkflows";
import {
  useCollectionKey,
  useConfigMap,
  useGetViewConfig,
  useLatestCollectionWorkflowId,
  useProjectParams,
  useCompanyStockFromCollectionContentsOrConversation,
  useCollectionCreatedDate,
  useDownloadCollectionContents,
  useFilterAndGroupReports,
  useContents,
} from "hooks";
import { useLocation } from "react-router-dom";
import { formatDateWithOutputFormat } from "screens/common/modal/formatters";
import { RiskIndicator } from "screens/landing/tabs/project/projectLandingTileLayouts/components/RiskIndicator";
import { StockIndicator } from "screens/landing/tabs/project/projectLandingTileLayouts/components/StockIndicator";
import { ProjectHeaderImage } from "screens/landing/tabs/project/projectLandingTileLayouts/components/ProjectHeaderImage";
import { ContentPreview } from "screens/content/contentView/previewSection/ContentPreview";
import { ProjectAnalysisPopover } from "screens/landing/tabs/collections/chartComponents/ProjectAnalysisPopover";
import type { ContentDetails } from "types/content/ContentDetails";

interface Props {
  collectionId: string;
}

export const ProjectDetailDefaultViewHeader = ({ collectionId }: Props) => {
  const { isPortfolios } = useProjectParams();
  const textColor = useColorModeValue("primary.darkGray", "gray.400");
  const { pathname } = useLocation();
  const configMap = useConfigMap();
  const isProject = pathname.includes("project");
  const route = isPortfolios ? "due_diligence" : pathname.split("/").slice(isProject ? 2 : 1, isProject ? 3 : 2)[0];
  const workflowIntentFilters = useGetViewConfig("workflowIntentFilters", route, configMap);
  const workflowId = useLatestCollectionWorkflowId(collectionId, workflowIntentFilters);
  const workflowStatus = useWorkflowKey(workflowId, "status");
  const collectionUnstructuredData = useCollectionKey(collectionId, "unstructuredData");
  const collectionType = useCollectionKey(collectionId, "collectionType");
  const collectionName = useCollectionKey(collectionId, "name");
  const projectGroupId = useCollectionKey(collectionId, "projectGroupId");
  const projectMetadataId = useCollectionKey(collectionId, "metadataIds");
  const showStockTicker = useGetViewConfig("showStockTicker", route, configMap);
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const companyStockData = useCompanyStockFromCollectionContentsOrConversation(collectionId);
  const projectCreationDate = useCollectionCreatedDate(collectionId);
  const bgColorReadOnly = useColorModeValue("orange.100", "gray.700");
  const tileBorderColor = useColorModeValue("gray.300", "gray.600");
  const memoizedMetadataIds = useMemo(() => projectMetadataId || [], [projectMetadataId]);
  const contentData = useContents(memoizedMetadataIds || []);
  const contentDataFiltered = useFilterAndGroupReports(contentData);
  const firstContentDetailFromFilter = useMemo(() => {
    const firstGroup = Object.values(contentDataFiltered)[0];
    return firstGroup?.[0] as ContentDetails | undefined;
  }, [contentDataFiltered]);

  const stockTitle = useMemo(() => {
    if (!companyStockData) return "-- : --";
    return `${companyStockData?.exchange}: ${companyStockData?.ticker}`;
  }, [companyStockData]);

  const hasRiskAssessment = useMemo(() => {
    return collectionUnstructuredData?.data?.assessment_results[0]?.risk_assessment_result || undefined;
  }, [collectionUnstructuredData]);

  const workflowStateMessage = useMemo(() => {
    if (workflowStatus) {
      if (workflowStatus === "complete") {
        return "Project completed";
      } else if (workflowStatus === "in_progress") {
        return "Project in progress";
      } else {
        return "Project incomplete";
      }
    }
    return "Project not started";
  }, [workflowStatus]);

  const collectionMetadataIds = useCollectionKey(collectionId, "metadataIds");
  useDownloadCollectionContents(collectionMetadataIds, collectionId);
  const shareDetails = useCollectionKey(collectionId, "shareDetails");

  return (
    <Stack direction="row" className="project-detail-section" width="100%" justifyContent={"space-between"}>
      <Stack alignItems={"top"}>
        {collectionType && (
          <ProjectHeaderImage
            projectGroupId={projectGroupId}
            projectName={companyStockData?.name}
            metadataIds={projectMetadataId}
            projectType={collectionType}
            width={isMobile ? "100%" : "20rem"}
          />
        )}
        {shareDetails?.accessMode === "read" && (
          <Box
            bgColor={bgColorReadOnly}
            textAlign={"center"}
            width={"4.5rem"}
            fontSize="10px"
            px="6px"
            py="3px"
            fontWeight={"semibold"}
            borderRadius={"md"}>
            READ ONLY
          </Box>
        )}
      </Stack>
      <Stack align="flex-end" spacing="0">
        <Stack direction="row" alignItems="top" spacing="1rem">
          {!isMobile &&
            (showStockTicker && companyStockData ? (
              <Box width="13rem">
                <StockIndicator
                  style={{ cursor: "default" }}
                  companyStockTitle={stockTitle}
                  companyTicker={companyStockData.ticker}
                  companyStockExchange={companyStockData.exchange}
                />
              </Box>
            ) : (
              <>
                {hasRiskAssessment && (
                  <Box width="6.5rem">
                    <RiskIndicator riskLevel={hasRiskAssessment} />
                  </Box>
                )}
              </>
            ))}
          <Stack spacing="0" width="100%">
            <Text
              className="ch-project-stock-indicator-company-name"
              textAlign={"right"}
              fontSize={isMobile ? "sm" : "md"}
              color={textColor}
              fontWeight="semibold">
              {companyStockData?.name ? companyStockData?.name : collectionName}
            </Text>

            {projectCreationDate && (
              <Tooltip label="Project completed date">
                <Text textAlign={"right"} fontSize="xs" color={textColor}>
                  {`As of ${
                    projectCreationDate
                      ? formatDateWithOutputFormat(new Date(projectCreationDate), "do MMM yyyy hh:mm")
                      : workflowStateMessage
                  }`}
                </Text>
              </Tooltip>
            )}
          </Stack>
          {firstContentDetailFromFilter && (
            <Box position="relative" width="8rem" className="ch-project-landing-report-preview-container">
              <ProjectAnalysisPopover projectId={collectionId}>
                <Box borderColor={tileBorderColor} borderWidth="1px" borderRadius="sm">
                  <ContentPreview
                    thumbnailFit="fill"
                    maxHeight="4rem"
                    backgroundColor={"transparent"}
                    itemContent={firstContentDetailFromFilter}
                    thumbnailHeight={"120px"}
                    thumbnailWidth={"260px"}
                    noOnClickAction
                  />
                </Box>
              </ProjectAnalysisPopover>
            </Box>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};
