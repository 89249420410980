import React, { useEffect, useMemo, useState } from "react";
import { createBillingPlan, downloadBillingPlans, updateBillingPlan } from "state/billingPlans/operations";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  useToast,
  HStack,
  VStack,
  Tag,
  TagLabel,
  TagCloseButton,
  Text,
} from "@chakra-ui/react";
import { PanelStep } from "screens/panels/components/PanelStep";
import { PanelView } from "screens/panels/components/PanelView";
import { useAppDispatch, useButtonProps } from "hooks";
import { useBillingPlan, useBillingPlanCodes } from "hooks/useBillingPlans";
import { Controller, FormProvider, useForm, useWatch } from "react-hook-form";
import { Wizard } from "react-use-wizard";
import type { BillingFormValues } from "../utils/BillingFormValues";
import { generateCode } from "../utils/generateCode";
import { PlanDetailsField } from "./PlanDetailsField";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

interface IProps {
  code: string | null;
  isOpen: boolean;
  onClose: () => void;
}

// Create a separate component for plans selector to avoid the useState hook issue
const PlansSelector = ({ control, noSelectedPlans }: { control: any; noSelectedPlans: string[] }) => {
  const [inputValue, setInputValue] = useState("");

  return (
    <Controller
      name="plansCanSwitchFrom"
      control={control}
      render={({ field }) => {
        const currentValue = field.value || [];

        const handleAddPlan = () => {
          if (inputValue && !currentValue.includes(inputValue) && noSelectedPlans.includes(inputValue)) {
            field.onChange([...currentValue, inputValue]);
            setInputValue("");
          }
        };

        const handleRemovePlan = (planToRemove: string) => {
          field.onChange(currentValue.filter((plan: string) => plan !== planToRemove));
        };

        const handlePlanClick = (plan: string) => {
          if (!currentValue.includes(plan)) {
            field.onChange([...currentValue, plan]);
          }
        };

        return (
          <FormControl mb={"1rem"}>
            <FormLabel htmlFor={field.name}>Plans can switch from</FormLabel>
            <VStack align="stretch" spacing={2}>
              <HStack>
                <Input
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  placeholder="Add plan"
                  size="md"
                  ref={field.ref}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      handleAddPlan();
                    }
                  }}
                />
                <Button
                  size="md"
                  onClick={handleAddPlan}
                  isDisabled={!inputValue || currentValue.includes(inputValue) || !noSelectedPlans.includes(inputValue)}>
                  Add
                </Button>
              </HStack>

              {/* Display selected plans */}
              <Box>
                <HStack spacing={2} flexWrap="wrap">
                  {currentValue.map((plan: string, index: number) => (
                    <Tag key={`plan-${index}`} size="md" borderRadius="full" variant="solid" colorScheme="blue" my={1}>
                      <TagLabel>{plan}</TagLabel>
                      <TagCloseButton onClick={() => handleRemovePlan(plan)} />
                    </Tag>
                  ))}
                </HStack>
              </Box>

              {/* Display available plans */}
              {noSelectedPlans.length > 0 && (
                <Box mt={2}>
                  <Text fontSize="sm" mb={1}>
                    Available plans:
                  </Text>
                  <HStack spacing={2} flexWrap="wrap">
                    {noSelectedPlans.map((plan, index) => (
                      <Tag
                        key={`available-${index}`}
                        size="md"
                        borderRadius="full"
                        variant="outline"
                        colorScheme="blue"
                        cursor="pointer"
                        onClick={() => handlePlanClick(plan)}
                        my={1}
                        _hover={{ bg: "blue.50" }}>
                        <TagLabel>{plan}</TagLabel>
                      </Tag>
                    ))}
                  </HStack>
                </Box>
              )}
            </VStack>
          </FormControl>
        );
      }}
    />
  );
};

const validationSchema = yup.object().shape({
  id: yup.string().optional(),
  code: yup.string().required("Code is required"),
  name: yup.string().required("Name is required"),
  isDefault: yup.boolean().required("isDefault is required"),
  plansCanSwitchFrom: yup.array().of(yup.string().required()).optional(),
  productHandle: yup.string().required("Product handle is required"),
  components: yup
    .array()
    .of(
      yup.object().shape({
        componentHandle: yup.string().required("Component handle is required"),
        quantityToAllocate: yup.number().required("Quantity to allocate is required").min(1, "Quantity to allocate must be at least 1"),
        pricePointHandle: yup.string().optional(),
      })
    )
    .min(1, "At least one component is required")
    .required(),
});

export const BillinPlanUpsertModal = (props: IProps) => {
  const dispatch = useAppDispatch();
  const toast = useToast();
  const commonButtonProps = useButtonProps("sm", "primary");
  const { code, isOpen, onClose } = props;

  const billingPlan = useBillingPlan(code);
  const planCodes = useBillingPlanCodes(code ? [code] : undefined);
  const isUpdate = !!code;

  const methods = useForm<BillingFormValues>({
    defaultValues: billingPlan
      ? {
          name: billingPlan.name,
          code: billingPlan.code,
          isDefault: billingPlan.isDefault ?? false,
          plansCanSwitchFrom: billingPlan.plansCanSwitchFrom ?? [],
          productHandle: billingPlan.details.productHandle,
          components: billingPlan.details.components.map((component) => ({
            componentHandle: component.componentHandle,
            quantityToAllocate: component.quantityToAllocate ?? 0,
            pricePointHandle: component.pricePointHandle ?? "",
          })),
        }
      : {
          name: "",
          code: "",
          isDefault: false,
          plansCanSwitchFrom: [],
          productHandle: "",
          components: [],
        },
    resolver: yupResolver<BillingFormValues>(validationSchema),
  });

  const {
    handleSubmit,
    formState: { errors, isDirty },
    control,
    setValue,
  } = methods;

  const watchName = useWatch({ control, name: "name" });
  const watchPlansCanSwitchFrom = useWatch({ control, name: "plansCanSwitchFrom" });

  const noSelectedPlans = useMemo(() => {
    return planCodes.filter((code) => !watchPlansCanSwitchFrom?.includes(code));
  }, [planCodes, watchPlansCanSwitchFrom]);

  const onSubmit = async (formValues: BillingFormValues) => {
    const billing = formValues;

    const response = await (async () => {
      if (code) {
        return dispatch(
          updateBillingPlan({
            code,
            payload: {
              name: billing.name,
              isDefault: billing.isDefault,
              plansCanSwitchFrom: billing.plansCanSwitchFrom,
              planDetails: {
                productHandle: billing.productHandle,
                components: billing.components,
              },
            },
          })
        );
      } else {
        return dispatch(
          createBillingPlan({
            code: billing.code,
            name: billing.name,
            isDefault: billing.isDefault,
            plansCanSwitchFrom: billing.plansCanSwitchFrom,
            planDetails: {
              productHandle: billing.productHandle,
              components: billing.components,
            },
          })
        );
      }
    })();

    if (response.type === updateBillingPlan.rejected.type || response.type === createBillingPlan.rejected.type) {
      toast({
        title: "Billing plan error",
        description: "An error occurred while trying to save the billing plan.",
        status: "error",
        duration: 10000,
        isClosable: true,
      });

      return;
    }

    toast({
      title: "Billing plan",
      description: `Successfully ${isUpdate ? "updated" : "created"}.`,
      status: "success",
      duration: 5000,
      isClosable: true,
    });

    dispatch(downloadBillingPlans());
    onClose();
  };

  useEffect(() => {
    if (isUpdate) {
      return;
    }

    setValue("code", generateCode(watchName), {
      shouldTouch: true,
    });
  }, [watchName, isUpdate, setValue]);

  return (
    <PanelView isOpen={isOpen} onClose={onClose} panelTitle={`${isUpdate ? "Update" : "Create"} billing plan`}>
      <Wizard>
        <PanelStep>
          <Box pl=".5rem">
            <FormProvider {...methods}>
              <form id="upsert-billing-plan-form" onSubmit={handleSubmit(onSubmit)}>
                <Controller
                  name="code"
                  control={control}
                  render={({ field }) => (
                    <FormControl mb={"1rem"} isInvalid={!!errors[field.name]?.message}>
                      <FormLabel htmlFor={field.name}>Code</FormLabel>
                      <Input {...field} type="text" disabled />
                      {!!errors[field.name]?.message && <FormErrorMessage>{errors[field.name]?.message}</FormErrorMessage>}
                    </FormControl>
                  )}
                />

                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <FormControl mb={"1rem"} isInvalid={!!errors[field.name]?.message}>
                      <FormLabel htmlFor="name">Name</FormLabel>
                      <Input {...field} type="text" />
                      {!!errors[field.name]?.message && <FormErrorMessage>{errors[field.name]?.message}</FormErrorMessage>}
                    </FormControl>
                  )}
                />

                <Controller
                  name="isDefault"
                  control={control}
                  defaultValue={false}
                  render={({ field: { name, onChange, value, ref } }) => (
                    <FormControl mb={"1rem"} isInvalid={!!errors[name]?.message}>
                      <Checkbox ref={ref} name={name} onChange={onChange} isChecked={value} textTransform="capitalize">
                        Is default?
                      </Checkbox>
                      {!!errors[name]?.message && <FormErrorMessage>{errors[name]?.message}</FormErrorMessage>}
                    </FormControl>
                  )}
                />

                <PlansSelector control={control} noSelectedPlans={noSelectedPlans} />

                <PlanDetailsField />
              </form>

              <Box display={"flex"} justifyContent={"flex-end"} width="100%" py="1rem">
                <Button isDisabled={!isDirty} {...commonButtonProps} type="submit" form="upsert-billing-plan-form">
                  Save Billing Plan
                </Button>
              </Box>
            </FormProvider>
          </Box>
        </PanelStep>
      </Wizard>
    </PanelView>
  );
};
