import { Stack, useColorModeValue, Box, useBreakpointValue, Icon, Tooltip } from "@chakra-ui/react";
import {
  useConfigMap,
  useGetViewConfig,
  useMenuConfig,
  useProjectConfigEntitlements,
  useSidebarNavigation,
  useUserPreference,
} from "hooks";
import React, { useEffect, useState, useRef, useContext } from "react";
import { SidebarNavigationMain } from "./SidebarNavigationMain";
import { HelpMenu } from "./HelpMenu";
import { SidebarAdminButton } from "./SidebarAdminButton";
import { useDebouncedCallback } from "use-debounce";
import { updateTypedUserPreference } from "state/userPreference/operations";
import { useDispatch } from "react-redux";
import { useCustomScrollbar } from "hooks/useCustomScrollbar";
import OnboardingVideoPopover from "./popoverComponent/OnboardingVideoPopover";
import OnboardingVideo from "./OnboardingVideo";
import { getTypeFromRoute, getTypeIcon, getViewConfig } from "configs/configMap";
import { AiOutlineHome } from "react-icons/ai";
import { SidebarButton } from "./SidebarButton";
import { SidebarDealFinder } from "./SidebarDealFinder";
import { BsPinAngle, BsPin } from "react-icons/bs";
import { SettingsProviderContext } from "screens/panels/settings/SettingsProvider";

export const SIDEBAR_PADDING = "1rem";

const MIN_SIDEBAR_WIDTH = 208;
const MAX_SIDEBAR_WIDTH = 500;
export const DEFAULT_SIDEBAR_WIDTH = 208;

export const SidebarNavigation = () => {
  const bgColor = useColorModeValue("white", "gray.900");
  const userPrefSidebarWidth = useUserPreference("ui_sidebar_width") as number;
  const showOnboardingVideos = useUserPreference("ui_hide_onboarding_videos") as boolean;
  const [width, setWidth] = useState(userPrefSidebarWidth || DEFAULT_SIDEBAR_WIDTH);
  const isMobile = useBreakpointValue({ base: true, md: false, lg: false }, { fallback: "md", ssr: false });
  const isTablet = useBreakpointValue({ base: false, md: true, lg: false }, { fallback: "md", ssr: false });
  const dispatch = useDispatch();
  const bgColorResizer = useColorModeValue("gray.300", "gray.900");
  const containerRef = useRef<HTMLDivElement>(null);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const { scrollbarStyle } = useCustomScrollbar(scrollContainerRef, { width: "1px", barTransparency: 0.1 });
  const configMap = useConfigMap();
  const homeOnboardingSteps = useGetViewConfig("onBoardingSteps", "home", configMap);
  const videoURL = homeOnboardingSteps && homeOnboardingSteps[0].url ? homeOnboardingSteps[0].url : "";
  const borderColor = useColorModeValue("rgba(61, 61, 61, 0.25)", "rgba(255, 255, 255, 0.25)");
  const { setSidebarType, currentSidebarType } = useSidebarNavigation();
  const { setIsSideNavigationOpen } = useContext(SettingsProviderContext);
  const selectedButtonColor = useColorModeValue("charli.primaryBlue", "charli.primaryBlue");
  const buttonColor = useColorModeValue("charli.darkBlue", "gray.300");

  const handlePinOnClick = () => {
    const sidebarType = currentSidebarType === "hidden" ? "desktop" : "hidden";
    setSidebarType(sidebarType);
    setIsSideNavigationOpen(false);
    dispatch(updateTypedUserPreference({ preferenceKey: "ui_navigation_type", value: sidebarType }));
  };

  useEffect(() => {
    if (userPrefSidebarWidth) {
      setWidth(userPrefSidebarWidth);
    }
  }, [userPrefSidebarWidth]);

  const debouncedUpdateWidth = useDebouncedCallback((newWidth: number) => {
    dispatch(updateTypedUserPreference({ preferenceKey: "ui_sidebar_width", value: newWidth }));
  }, 150);

  const handleMouseDown = (e: React.MouseEvent) => {
    e.preventDefault();

    const handleMouseMove = (e: MouseEvent) => {
      const newWidth = Math.min(Math.max(e.clientX, MIN_SIDEBAR_WIDTH), MAX_SIDEBAR_WIDTH);
      setWidth(newWidth);

      debouncedUpdateWidth(newWidth);
    };

    const handleMouseUp = () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);

      debouncedUpdateWidth.flush();
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const handleResetWidth = () => {
    const newWidth = DEFAULT_SIDEBAR_WIDTH;
    dispatch(updateTypedUserPreference({ preferenceKey: "ui_sidebar_width", value: newWidth }));
    setWidth(newWidth);
  };

  const menuConfig = useMenuConfig(configMap, "all", false);
  const homeMenuItem = menuConfig.find((menuItem) => menuItem.config.collectionType === "home");
  const dueDiligenceMenuItem = menuConfig.find((menuItem) => menuItem.config.collectionType === "due_diligence");
  const { due_diligence_project: hasDueDiligence } = useProjectConfigEntitlements();

  return (
    <Stack
      borderRight={`1px solid ${borderColor}`}
      gap="0"
      mt="0!important"
      direction="row"
      backgroundColor={bgColor}
      width={["100vw", "100%", "100%", `${width}px`, `${width}px`]}
      justifyContent="space-between"
      height="calc(100vh - 43px)"
      ref={containerRef}
      zIndex={1}>
      <Stack className="ch-sidebar-inner" height="calc(100vh - 43px)" width="100%" overflow="hidden">
        <Stack gap="1rem" width="100%" direction="column" flex={1} overflow="hidden" pl="1rem" pb="1rem">
          <Stack width="100%" flex={1} minHeight={0}>
            <Stack spacing={"1rem"} width="100%" pt="1rem" pr=".5rem">
              <SidebarButton
                key={`sidebar-nav-home`}
                text={homeMenuItem?.config.title}
                icon={AiOutlineHome}
                screen={"home"}
                cssClasses={[`ch-sidebar-nav-${getTypeFromRoute(homeMenuItem?.config.route)}`]}
              />
              {hasDueDiligence && (
                <SidebarButton
                  key={`sidebar-nav-dd`}
                  text={dueDiligenceMenuItem?.config.title}
                  icon={getTypeIcon(getViewConfig("icon", dueDiligenceMenuItem?.id || "", configMap))}
                  screen={getViewConfig("route", dueDiligenceMenuItem?.id || "", configMap)}
                  cssClasses={[`ch-sidebar-nav-${getTypeFromRoute(dueDiligenceMenuItem?.config.route)}`]}
                />
              )}
            </Stack>
            <Stack flex={1} minHeight={0} overflow="auto" ref={scrollContainerRef} css={scrollbarStyle} width="100%">
              <SidebarNavigationMain />
            </Stack>
          </Stack>
          <Stack width="100%" spacing="1rem">
            {!isMobile && showOnboardingVideos && (
              <Box className="ch-sidebar-onboarding-popover" position="relative">
                <OnboardingVideo videoURL={videoURL} />
              </Box>
            )}
            <SidebarDealFinder />
            <SidebarAdminButton />
            <Stack direction="row" spacing="0" width="100%" justifyContent="space-between">
              <HelpMenu />
              {!isMobile && !isTablet && (
                <Tooltip label={currentSidebarType === "hidden" ? "Pin Sidebar" : "Unpin Sidebar"} placement="right">
                  <Box width="2rem" cursor="pointer" onClick={() => handlePinOnClick()}>
                    <Icon
                      as={currentSidebarType === "hidden" ? BsPinAngle : BsPin}
                      color={buttonColor}
                      zIndex="3"
                      backgroundColor={"transparent"}
                      _hover={{ color: selectedButtonColor }}
                      cursor="pointer"
                      onClick={() => handlePinOnClick()}
                    />
                  </Box>
                </Tooltip>
              )}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      {!isMobile && <OnboardingVideoPopover />}
      {!isMobile && !isTablet && (
        <Box
          height="100%"
          width="3px"
          cursor="ew-resize"
          backgroundColor="transparent"
          onMouseDown={handleMouseDown}
          onDoubleClick={handleResetWidth}
          _hover={{ backgroundColor: bgColorResizer }}
        />
      )}
    </Stack>
  );
};
