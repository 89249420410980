import { useColorModeValue, Stack, Text, Button } from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import { useContext } from "react";
import React from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { TypingIndicator } from "screens/thread/components/cells/components";
import { OptionsMenuItem } from "../../components/OptionsMenuItem";
import { ConversationContext } from "screens/thread/ConversationContext";
import { useButtonProps } from "hooks";
import { SmallActionButton } from "screens/content/contentCanvas/cell/SmallActionButton";

interface Props {
  size?: string;
  isMenuItem?: boolean;
  projectType: "portfolio" | "project" | "version";
  isDeletingCollection?: boolean;
  onOpenConfirmation: () => void;
  isIcon?: boolean;
  label?: string;
  className?: string;
  projectId: string;
  navigatePath?: string;
  onCloseFunction?: () => void;
  isDisabled?: boolean;
}

export const ProjectDeleteButton: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  size = "1rem",
  isMenuItem,
  projectType = "version",
  isDeletingCollection = false,
  onOpenConfirmation,
  isIcon,
  label,
  className = "ch-delete-project",
  projectId,
  navigatePath,
  onCloseFunction,
  isDisabled,
}) => {
  const additionalThumbnailCountTextColor = useColorModeValue("gray.500", "gray.400");
  const { setIsDeleteProjectId, setNavigatePath, setIsDeleteProjectType } = useContext(ConversationContext);
  const deleteButtonProps = useButtonProps("sm", "delete");

  const onHandleDeleteProject = (deleteType?: string) => {
    onCloseFunction && onCloseFunction();
    setIsDeleteProjectId(projectId);
    setIsDeleteProjectType(projectType);
    onOpenConfirmation();
    navigatePath && setNavigatePath(navigatePath);
  };

  return (
    <>
      {isMenuItem ? (
        <OptionsMenuItem
          menuLabel={label ? label : `Delete ${projectType}`}
          className={className}
          onClick={(event) => {
            event.stopPropagation();
            onHandleDeleteProject();
          }}
          isDisabled={isDeletingCollection || isDisabled}
        />
      ) : isDeletingCollection ? (
        <Stack direction="row" spacing="1rem">
          <Text color={additionalThumbnailCountTextColor} fontSize={"xs"}>
            {`Deleting ${projectType}`}
          </Text>
          <TypingIndicator size="small" />
        </Stack>
      ) : !isIcon ? (
        <Button
          className={className}
          aria-label={`Delete ${projectType}`}
          {...deleteButtonProps}
          onClick={(event) => {
            event.stopPropagation();
            onHandleDeleteProject();
          }}>
          {label ? label : `Delete ${projectType}`}
        </Button>
      ) : (
        <SmallActionButton
          isDisabled={isDeletingCollection || isDisabled}
          classNames={className}
          iconTypeName={AiOutlineDelete}
          tooltip={label ? label : `Delete ${projectType}`}
          iconSize={size}
          onClick={() => {
            onHandleDeleteProject();
          }}
        />
      )}
    </>
  );
};
