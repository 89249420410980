import type { Static } from "runtypes";
import { Record, String, Unknown } from "runtypes";

export const ChangeLogEntry = Record({
  field: String,
  oldValue: Unknown,
  newValue: Unknown,
  userId: String,
  userName: String.optional(),
  date: String,
  additionalContext: String.optional(),
});

export type ChangeLogEntry = Static<typeof ChangeLogEntry>;
