import { Box, Button, useColorModeValue } from "@chakra-ui/react";
import React, { useState, useCallback, useRef, useEffect, memo } from "react";

const ANIMATION_DURATION = 0.3;

interface ShowMoreContainerProps {
  height: string;
  minHeight?: string;
  showMoreText?: string;
  showLessText?: string;
  animationDuration?: number;
  children: React.ReactNode;
}

export const ShowMoreContainer = memo(
  ({
    children,
    height,
    minHeight,
    showMoreText = "Show More",
    showLessText = "Show Less",
    animationDuration = ANIMATION_DURATION,
  }: ShowMoreContainerProps) => {
    const [showMore, setShowMore] = useState(false);
    const [shouldShowButton, setShouldShowButton] = useState(true);
    const [contentHeight, setContentHeight] = useState<number | null>(null);
    const contentRef = useRef<HTMLDivElement>(null);

    const convertToNumber = (value: string | undefined) => {
      if (!value) return 0;
      const numValue = parseFloat(value);
      const unit = value.replace(numValue.toString(), "");
      if (unit === "rem") {
        return numValue * parseFloat(getComputedStyle(document.documentElement).fontSize);
      } else {
        return numValue;
      }
    };

    const transitionStyle = `max-height ${animationDuration}s ease-in-out`;
    const buttonColor = useColorModeValue("primary.default", "gray.300");

    const toggleShowMore = useCallback(() => {
      if (contentRef.current) {
        const { scrollHeight } = contentRef.current;
        const containerHeight = convertToNumber(height);
        if (!showMore) {
          setContentHeight(scrollHeight + 100);
        } else if (scrollHeight > containerHeight) {
          setShouldShowButton(true);
        }
      }
      setShowMore((prev) => !prev);
    }, [height, showMore]);

    useEffect(() => {
      if (contentRef.current) {
        const { scrollHeight } = contentRef.current;
        setContentHeight(scrollHeight);
        const containerHeight = convertToNumber(height);
        const shouldShow = scrollHeight > containerHeight;
        setShouldShowButton(shouldShow);
      }
    }, [children, height, minHeight]);

    return (
      <Box width="100%" position="relative" minHeight={minHeight}>
        <Box
          className={shouldShowButton ? (showMore ? "show-less-content" : "show-more-content") : "show-all-content"}
          ref={contentRef}
          width="100%"
          alignContent="top"
          maxHeight={showMore && contentHeight ? `${contentHeight}px` : height}
          fontSize="xs"
          overflowY="hidden"
          overflowX="hidden"
          transition={transitionStyle}
          css={{ willChange: "max-height" }}
          mb={shouldShowButton ? 1 : 0}>
          {children}
        </Box>

        {shouldShowButton && (
          <Button
            variant="link"
            size="xs"
            color={buttonColor}
            onClick={(event) => {
              event.stopPropagation();
              toggleShowMore();
            }}
            aria-expanded={showMore}
            aria-label={showMore ? "Show less content" : "Show more content"}
            fontWeight="normal"
            _hover={{ textDecoration: "underline" }}
            mt={0}>
            {showMore ? showLessText : showMoreText}
          </Button>
        )}
      </Box>
    );
  }
);

ShowMoreContainer.displayName = "ShowMoreContainer";
