import { useState, useCallback, useEffect } from "react";
import { useNavigate, useLocation, useParams, useSearchParams } from "react-router-dom";
import type { DealFinderTicker } from "../api/tickers/models/DealFinderTicker";

export interface UseInvestmentDetailModalResult {
  isOpen: boolean;
  selectedDeal: DealFinderTicker | null;
  currentDealId: string | null;
  openModal: (deal: DealFinderTicker) => void;
  closeModal: () => void;
}

/**
 * Custom hook to handle the investment detail modal state via URL and local state
 * This allows for sharing URLs that open specific deals while maintaining compatibility
 * with the existing implementation
 */
export const useInvestmentDetailModal = (): UseInvestmentDetailModalResult => {
  const [selectedDeal, setSelectedDeal] = useState<DealFinderTicker | null>(null);

  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useParams<{ dealId?: string }>();

  // Get the current deal ID from the URL params or search params
  const currentDealId = params.dealId ?? searchParams.get("dealId") ?? null;

  // Determine if the modal should be open based on the presence of dealId in the URL or search params
  const isOpen = !!currentDealId && (!!params.dealId || searchParams.get("dealId") === currentDealId);

  // Effect to handle state consistency with URL changes (e.g., back button)
  useEffect(() => {
    if (!isOpen && selectedDeal) {
      // Optional: Clear selected deal when modal closes via URL change
      // Kept commented for smoother transition if closed via button
      // setSelectedDeal(null);
    }
  }, [isOpen, selectedDeal]);

  // Open a deal modal by adding the dealId to the URL and updating local state
  const openModal = useCallback(
    (deal: DealFinderTicker) => {
      setSelectedDeal(deal);
      if (location.pathname.includes("/deal-finder")) {
        // If we're already in the deal finder section, use the deal finder path
        navigate(`/deal-finder/${deal.id}`);
      } else {
        // If we're on another page (like home), use search params instead of changing the path
        searchParams.set("dealId", deal.id);
        setSearchParams(searchParams);
      }
    },
    [navigate, location.pathname, searchParams, setSearchParams]
  );

  // Close the deal modal by removing the dealId from the URL and updating local state
  const closeModal = useCallback(() => {
    // Navigate back to the base path
    if (location.pathname.includes("/deal-finder")) {
      // Only navigate to /deal-finder if we're already in the deal finder section
      navigate("/deal-finder");
    } else if (searchParams.has("dealId")) {
      // If we're using search params, remove the dealId param
      searchParams.delete("dealId");
      setSearchParams(searchParams);
    }
    // We'll keep the selected deal in state to prevent UI flicker when closing
    // It will be replaced when a new deal is selected
  }, [navigate, location.pathname, searchParams, setSearchParams]);

  return {
    isOpen,
    selectedDeal,
    currentDealId,
    openModal,
    closeModal,
  };
};

export default useInvestmentDetailModal;
