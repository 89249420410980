import React from "react";
import { useEntitlementKey } from "hooks";
import { SidebarButton } from "./SidebarButton";
import { SIDEBAR_ADMIN_BUTTON_CLICKED } from "api/analytics/events";
import { TbZoomMoney } from "react-icons/tb";

export const SidebarDealFinder = () => {
  const hasDealFinder = useEntitlementKey("ui_enable_dealfinder");

  if (!hasDealFinder) {
    return null;
  }

  return (
    <SidebarButton
      text="Deal Finder"
      icon={TbZoomMoney}
      screen="deal-finder"
      analyticsEvent={SIDEBAR_ADMIN_BUTTON_CLICKED}
      cssClasses={["ch-sidebar-nav-deal-finder"]}
    />
  );
};
