import React, { useMemo } from "react";
import { Box, Button, Stack, Text, useColorModeValue } from "@chakra-ui/react";
import { Wizard } from "react-use-wizard";
import { PanelStep } from "screens/panels/components/PanelStep";
import { PanelView } from "screens/panels/components/PanelView";
import { useButtonProps } from "hooks";
import type { IField } from "screens/common/components";
import { DynamicForm } from "screens/common/components";
import type { Ticker } from "api/tickers/models/Ticker";
import { formatDate } from "screens/common/modal/formatters";
import { useTickerTypeOptions } from "../utils/tickerTypesOptions";
import { useExchangeOptions } from "../utils/exchangeOptions";
import { TickerChangeLog } from "./TickerChangeLog";

export interface UpdateTickerForm {
  symbol: string;
  name: string;
  dataProviderId: string | "null";
  exchange: string;
  status: "active" | "inactive" | "beta";
  type: string | "null";
  notes: string;
  companyLogoUrl: string;
  companyBannerUrl: string;
  companyUrl: string;
  companyTaxId: string;
  companyLegalName: string;
}

interface IProps {
  onSubmit: (values: UpdateTickerForm, id?: string) => void;
  isLoading?: boolean;
  ticker?: Ticker;
}

interface IPanelProps extends IProps {
  onClose: () => void;
  isOpen: boolean;
}

const UpdateTicker = ({ onSubmit, ticker, isLoading }: IProps) => {
  const commonButtonProps = useButtonProps("sm", "primary");
  const textColor = useColorModeValue("primary.darkGray", "gray.400");

  const tickerTypeOptions = useTickerTypeOptions();
  const exchangeOptions = useExchangeOptions();

  const fields: IField<UpdateTickerForm>[] = useMemo(
    () => [
      {
        type: "stack",
        isInline: true,
        spacing: "2rem",
        fields: [
          {
            type: "select",
            entity: "status",
            label: "Status",
            isRequired: true,
            options: [
              { value: "active", label: "Active" },
              { value: "inactive", label: "Inactive" },
              { value: "beta", label: "Beta" },
            ],
            defaultValue: "active",
          },
          {
            type: "text",
            entity: "symbol",
            label: "Symbol",
            isRequired: true,
            defaultValue: "",
          },
          {
            type: "text",
            entity: "name",
            label: "Name",
            isRequired: true,
            defaultValue: "",
          },
        ],
      },
      {
        type: "stack",
        isInline: true,
        spacing: "2rem",
        fields: [
          {
            type: "select",
            entity: "exchange",
            label: "Exchange",
            isRequired: true,
            options: exchangeOptions,
            defaultValue: "",
          },
          {
            type: "select",
            entity: "type",
            label: "Type",
            isRequired: true,
            options: [{ label: "Not added", value: "null" }, ...tickerTypeOptions],
            defaultValue: "",
          },
          {
            type: "text",
            entity: "dataProviderId",
            label: "Data Provider Id",
            isRequired: false,
            defaultValue: "",
          },
        ],
      },
      {
        type: "stack",
        isInline: true,
        spacing: "2rem",
        fields: [
          {
            type: "text",
            entity: "companyLogoUrl",
            label: "Company Logo URL",
            isRequired: false,
            defaultValue: "",
          },
          {
            type: "text",
            entity: "companyBannerUrl",
            label: "Company Banner URL",
            isRequired: false,
            defaultValue: "",
          },
          {
            type: "text",
            entity: "companyUrl",
            label: "Company Website URL",
            isRequired: false,
            defaultValue: "",
          },
        ],
      },
      {
        type: "stack",
        isInline: true,
        spacing: "2rem",
        fields: [
          {
            type: "text",
            entity: "companyTaxId",
            label: "Company Tax ID",
            isRequired: false,
            defaultValue: "",
          },
          {
            type: "text",
            entity: "companyLegalName",
            label: "Company Legal Name",
            isRequired: false,
            defaultValue: "",
          },
        ],
      },
      {
        type: "textarea",
        entity: "notes",
        label: "Notes",
        isRequired: false,
        minHeight: "5rem",
        defaultValue: "",
      },
    ],
    [tickerTypeOptions, exchangeOptions]
  );

  const defaultValues: UpdateTickerForm = useMemo(() => {
    return ticker
      ? {
          symbol: ticker.symbol,
          exchange: ticker.exchange,
          dataProviderId: ticker.dataProviderId ?? "",
          name: ticker.name,
          status: ticker.status,
          type: ticker.type ?? "null",
          notes: ticker.notes ?? "",
          companyLogoUrl: ticker.companyLogoUrl ?? "",
          companyBannerUrl: ticker.companyBannerUrl ?? "",
          companyUrl: ticker.companyUrl ?? "",
          companyTaxId: ticker.companyTaxId ?? "",
          companyLegalName: ticker.companyLegalName ?? "",
        }
      : {
          symbol: "",
          exchange: "",
          dataProviderId: "",
          name: "",
          status: "active",
          type: "null",
          notes: "",
          companyLogoUrl: "",
          companyBannerUrl: "",
          companyUrl: "",
          companyTaxId: "",
          companyLegalName: "",
        };
  }, [ticker]);

  return (
    <Stack spacing="1rem">
      {ticker && (
        <Stack direction="row" spacing="2rem" width="100%" justifyContent={"space-between"} align="center">
          <Text wordBreak="break-word" fontWeight="light" fontSize="sm">{`Created ${formatDate(new Date(ticker.creationDate))} by ${
            ticker?.creationByUser
          }`}</Text>
          {ticker.lastUpdatedDate && ticker.lastUpdatedByUser && (
            <Text wordBreak="break-word" fontWeight="light" fontSize="sm">{`Modified ${formatDate(new Date(ticker.lastUpdatedDate))} by ${
              ticker.lastUpdatedByUser
            }`}</Text>
          )}
        </Stack>
      )}

      <DynamicForm<UpdateTickerForm>
        formId="update-ticker-form"
        defaultValues={defaultValues}
        fields={fields}
        onSubmit={(values) => onSubmit(values, ticker?.id)}
        title="Update ticker"
        isDisabled={isLoading}
      />

      <Box display={"flex"} justifyContent={"flex-end"} width="100%" py="1rem">
        <Button isLoading={isLoading} {...commonButtonProps} type="submit" form="update-ticker-form">
          Save Ticker
        </Button>
      </Box>

      {ticker?.changeLog && (
        <Stack spacing="1rem">
          <Text color={textColor} fontSize="sm" fontWeight={"bold"} paddingLeft="sm">
            Change Log
          </Text>
          <TickerChangeLog changeLog={ticker.changeLog} maxHeight="16rem" />
        </Stack>
      )}
    </Stack>
  );
};

const UpdateTickerPanel = (props: IPanelProps) => {
  const { onClose, isOpen, ...rest } = props;

  return (
    <PanelView isOpen={isOpen} onClose={onClose} panelTitle={"Update ticker"}>
      <Wizard>
        <PanelStep>
          <Box pt="1rem" pb="4rem">
            {isOpen && rest.ticker && <UpdateTicker {...rest} />}
          </Box>
        </PanelStep>
      </Wizard>
    </PanelView>
  );
};

export { UpdateTickerPanel };
