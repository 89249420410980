import React from "react";
import type { FunctionComponent } from "react";
import { Tooltip, Image, Stack, Text, useColorModeValue, Center, useBreakpointValue } from "@chakra-ui/react";
import verifiedShield from "screens/common/static/misc/verified_shield.svg";
import verifiedShieldGray from "screens/common/static/misc/verified_shield_gray.svg";

interface Props {
  shieldColor?: "blue" | "gray";
  shieldTooltip?: string;
  boxSize?: string;
  onClick?: () => void;
  className?: string;
}

export const SmallActionVerificationBadge: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  shieldTooltip,
  shieldColor = "blue",
  onClick,
  className,
}) => {
  const textColor = useColorModeValue("primary.darkGray", "gray.400");
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });

  return (
    <Tooltip aria-label="" label={shieldTooltip} placement="left" hasArrow>
      <Stack
        spacing="0"
        height={["2.2rem", "3rem", "4rem"]}
        width={["2rem", "2.8rem", "3rem"]}
        border="1px solid"
        borderRadius={"md"}
        borderColor="rgba(0, 154, 216, 0.5)"
        p="5px"
        className={`ch-shield-verification ${className}`}
        onClick={onClick}
        cursor={onClick ? "pointer" : "default"}
        alignContent={"center"}>
        <Center>
          <Image src={shieldColor === "blue" ? verifiedShield : verifiedShieldGray} boxSize={["1.5rem", "2.3rem", "2.3rem"]} />
        </Center>
        {!isMobile && (
          <Center>
            <Text
              fontWeight={"normal"}
              color={shieldColor === "blue" ? "primary.default" : textColor}
              fontSize={"12px"}
              fontStyle={"italic"}
              textAlign={"center"}>
              Ref
            </Text>
          </Center>
        )}
      </Stack>
    </Tooltip>
  );
};
