import { Box } from "@chakra-ui/react";
import React from "react";
import { MessagesView, ThreadTitleBar } from "./";
import { TypingIndicator } from "./cells/components";
import { getMessageWithProjectDetails } from "screens/common/app";
import { useConversation, useConversationLoading, useHasConversation } from "hooks";

interface ConversationContentProps {
  conversationId: string;
}

export const ConversationContent: React.FC<ConversationContentProps> = ({ conversationId }) => {
  const { messages } = useConversation(conversationId);
  const projectDetails = getMessageWithProjectDetails(messages);
  const hasConversation = useHasConversation(conversationId);
  const isLoadingConversation = useConversationLoading(conversationId);

  return (
    <>
      <ThreadTitleBar collectionId={projectDetails?.body.collectionId} />
      {isLoadingConversation && !hasConversation ? (
        <Box height={"100%"} minHeight={"10rem"}>
          <TypingIndicator height="100%" />
        </Box>
      ) : (
        <MessagesView conversationId={conversationId} messages={messages} />
      )}
    </>
  );
};
