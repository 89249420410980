import React, { useCallback, useState } from "react";
import { useToast } from "@chakra-ui/react";
import { ToastMessageContent } from "screens/common/components";
import { useWorkflowScheduleByProject, useCancelWorkflowSchedule } from "hooks/useWorkflowSchedules";
import {
  useCollectionKey,
  useCompanyStockFromCollectionContentsOrConversation,
  useLatestCollectionWorkflowId,
  useProjectInPortfolios,
} from "hooks";
import { useConversationDialogSubmitFunctions } from "screens/thread/useConversationDialogSubmitFunctions";
import { useConversationContext } from "screens/thread/ConversationContext";
import { OptionsMenuItem } from "../../components/OptionsMenuItem";
import { sendMessage } from "state/websocket/operations";
import { useDispatch } from "react-redux";
import { useWorkflowKey } from "hooks/useWorkflows";
import { SmallActionButton } from "screens/content/contentCanvas/cell/SmallActionButton";
import { ImCancelCircle } from "react-icons/im";
import { HiOutlineRefresh } from "react-icons/hi";

interface Props {
  reRunProjectId: string;
  isIcon?: boolean;
  isDisabled?: boolean;
}

type MenuClickEvent = {
  stopPropagation: () => void;
};

export const ProjectRerunCancelButton: React.FC<Props> = ({ reRunProjectId, isIcon, isDisabled }) => {
  const [isRequesting, setIsRequesting] = useState(false);
  const toast = useToast();
  const workflowSchedule = useWorkflowScheduleByProject(reRunProjectId);
  const cancelWorkflowSchedule = useCancelWorkflowSchedule();
  const collectionConversationId = useCollectionKey(reRunProjectId, "conversationId");
  const hasOneTimeSchedule = !!workflowSchedule;
  const { onSubmitDueDiligenceProject } = useConversationDialogSubmitFunctions({});
  const portfoliosProjectIsIn = useProjectInPortfolios(reRunProjectId);
  const companyStockData = useCompanyStockFromCollectionContentsOrConversation(reRunProjectId);
  const reRunPortfolioId = portfoliosProjectIsIn?.[0]?.id;
  const { setSelectedQuestionAndFocus } = useConversationContext();
  const isEmbeddingModelVersionOutdated = useCollectionKey(reRunProjectId, "isEmbeddingModelVersionOutdated");
  const dispatch = useDispatch();
  const latestWorkflowId = useLatestCollectionWorkflowId(reRunProjectId);
  const workflowStatus = useWorkflowKey(latestWorkflowId, "status");
  const isWorkflowInProgress = workflowStatus === "in_progress" || workflowStatus === "paused";

  const handleCancelSchedule = (event?: MenuClickEvent) => {
    event && event.stopPropagation();

    if (workflowSchedule) {
      cancelWorkflowSchedule(workflowSchedule, reRunProjectId, collectionConversationId);

      toast.closeAll();
      toast({
        render: ({ onClose }) => <ToastMessageContent message="Cancelling the projects scheduled rerun now." onClose={onClose} />,
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const submitMessage = (event?: MenuClickEvent) => {
    event && event.stopPropagation();
    if (!companyStockData) {
      return;
    }
    setSelectedQuestionAndFocus({
      projectTicker: companyStockData.ticker,
      projectExchange: companyStockData.exchange,
      projectId: reRunProjectId,
    });
    onSubmitDueDiligenceProject(
      companyStockData.ticker,
      companyStockData.exchange,
      undefined,
      reRunProjectId,
      reRunPortfolioId,
      undefined,
      undefined,
      isEmbeddingModelVersionOutdated
    );

    setTimeout(() => {
      setIsRequesting(false);
    }, 5000);
    setIsRequesting(true);
  };

  const handleCancelWorkflow = useCallback(
    (event?: MenuClickEvent) => {
      if (!collectionConversationId) return;
      event && event.stopPropagation();
      dispatch(
        sendMessage({
          conversationId: collectionConversationId,
          intent: "/cancel",
        })
      );
    },
    [collectionConversationId, dispatch]
  );

  if (hasOneTimeSchedule) {
    return isIcon ? (
      <SmallActionButton
        isDisabled={isRequesting || isDisabled}
        classNames="ch-project-landing-cancel-schedule-button"
        iconTypeName={ImCancelCircle}
        tooltip="Cancel Schedule"
        iconSize={"1rem"}
        onClick={() => {
          handleCancelSchedule();
        }}
      />
    ) : (
      <OptionsMenuItem menuLabel="Cancel Schedule" className="ch-project-landing-cancel-schedule-button" onClick={handleCancelSchedule} />
    );
  }

  if (isWorkflowInProgress) {
    return isIcon ? (
      <SmallActionButton
        isDisabled={isRequesting || isDisabled}
        classNames="ch-project-landing-cancel-workflow-button"
        iconTypeName={ImCancelCircle}
        tooltip="Cancel Project"
        iconSize={"1rem"}
        iconColor="red.700"
        onClick={() => {
          handleCancelWorkflow();
        }}
      />
    ) : (
      <OptionsMenuItem menuLabel="Cancel Project" className="ch-project-landing-cancel-workflow-button" onClick={handleCancelWorkflow} />
    );
  }

  return isIcon ? (
    <SmallActionButton
      isDisabled={isRequesting || isDisabled}
      classNames="ch-project-landing-rerun-button"
      iconTypeName={HiOutlineRefresh}
      tooltip={isRequesting ? "Rerunning Project..." : "Rerun Project"}
      iconSize={"1rem"}
      onClick={() => {
        submitMessage();
      }}
    />
  ) : (
    <OptionsMenuItem
      menuLabel={isRequesting ? "Rerunning Project..." : "Rerun Project"}
      className="ch-project-landing-rerun-button"
      onClick={submitMessage}
      isDisabled={isRequesting || isDisabled}
    />
  );
};
