import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { downloadCollections } from "state/collection/operations";
import type { RootState } from "state/rootReducer";
import type { Collection, CollectionWithAdditionalProps } from "types/collection";
import uniq from "lodash/uniq";
import { useFilterWorkflowsIdsByIntents } from "./useWorkflows";
import { useLocation, useParams } from "react-router-dom";
import type { StockExchangeRecord } from "types/stock";
import { useCollectionMetadataWithTagId } from "hooks";
import orderBy from "lodash/orderBy";
import type { Question } from "api/collection/models/Question";
import { getCollectionQuestions, getCollections, getCollectionsQuery } from "api/collection/collection";

const LIMIT_PROJECTS = 50;

interface ProjectParams {
  parentRoute?: string;
  projectFilter?: string;
  projectId?: string;
  contentId?: string;
  isPortfolios?: boolean;
  isProjects?: boolean;
  isDueDiligence?: boolean;
}

export const filterCollectionsByType = (collections: Collection[], type: string): Collection[] => {
  if (type === "all") return collections.filter((collection) => collection.collectionType !== "portfolio");
  return collections.filter((collection) => collection.collectionType === type);
};

export const useProjectParams = (): ProjectParams => {
  const { projectFilter, projectId, contentId } = useParams<{
    projectFilter: string;
    projectId: string;
    contentId?: string;
  }>();
  const { pathname } = useLocation();
  const parentRoute = pathname.split("/")[1];
  const isDueDiligence = projectFilter === "due_diligence" || parentRoute === "portfolios" || parentRoute === "home";

  return useMemo(() => {
    return {
      parentRoute,
      projectFilter,
      projectId,
      contentId,
      isPortfolios: parentRoute === "portfolios",
      isProjects: parentRoute === "projects",
      isDueDiligence,
    };
  }, [parentRoute, projectFilter, projectId, contentId, isDueDiligence]);
};

export const getMainCollectionWorkflowId = (collection: Collection | undefined): string | undefined => {
  return collection?.workflowIds?.[0];
};

export function useCollectionCreatedDate(id: string): string | undefined {
  const collection = useSelector((state: RootState) => (id ? state.collection.collections[id] : undefined), shallowEqual);

  return collection?.metadata.createdTime;
}

export const useEquityPortfolioId = (portfolioCollections: Array<{ id: string; name: string }>, equityCollectionName: string) => {
  return useMemo(() => {
    return portfolioCollections.find((collection) => collection.name === equityCollectionName)?.id;
  }, [portfolioCollections, equityCollectionName]);
};

export const useAllDueDiligenceProjectMetaData = () => {
  const [dueDiligenceProjects, setDueDiligenceProjects] = useState<{ metadataIds?: string[]; totalCount?: number }>({});
  const { isPortfolios } = useProjectParams();

  useEffect(() => {
    !isPortfolios &&
      getCollectionsQuery({ collectionType: "due_diligence" })
        .then((response) => {
          setDueDiligenceProjects(response);
        })
        .catch((error) => {
          console.error(error);
        });
  }, [isPortfolios]);
  return dueDiligenceProjects;
};

export const useAllDueDiligenceProjects = () => {
  const [dueDiligenceProjects, setDueDiligenceProjects] = useState<Collection[]>([]);
  const dueDiligenceProjectMetaData = useAllDueDiligenceProjectMetaData();
  const limitMetadataIds = useMemo(() => {
    if (dueDiligenceProjectMetaData.metadataIds) {
      return dueDiligenceProjectMetaData.metadataIds.slice(0, LIMIT_PROJECTS);
    }
    return [];
  }, [dueDiligenceProjectMetaData.metadataIds]);

  useEffect(() => {
    getCollections(limitMetadataIds)
      .then((response) => {
        setDueDiligenceProjects(response);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [dueDiligenceProjectMetaData, limitMetadataIds]);
  return dueDiligenceProjects;
};

interface PortfolioCollectionsOptions {
  filterShared?: boolean;
  portfolioId?: string;
  limit?: number;
}

export function usePortfolioCollections(options: PortfolioCollectionsOptions = {}) {
  const collections = useCollections();
  const portfolioMetadataIds = useCollectionKey(options.portfolioId, "metadataIds");

  // Calculate filtered collections based on dependencies
  const filteredCollections = useMemo(() => {
    let result = [...collections];

    if (options.portfolioId) {
      result = collections.filter((collection) => {
        if (portfolioMetadataIds) {
          return portfolioMetadataIds.includes(collection.id);
        }
        return false;
      });
    } else {
      result = collections.filter((collection) => collection.collectionType === "portfolio");
    }

    if (options.filterShared) {
      result = result.filter((collection) => !collection.shareDetails);
    }

    return result;
  }, [collections, options.filterShared, options.portfolioId, portfolioMetadataIds]);

  return filteredCollections;
}

export function useDownloadPortfolioCollections(options: PortfolioCollectionsOptions = {}) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const collections = useSelector((state: RootState) => state.collection.collections, shallowEqual);
  const portfolioMetadataIds = useCollectionKey(options.portfolioId, "metadataIds");
  const initialLoadCompleted = useRef(false);
  const BATCH_SIZE = 100; // Download collections in batches of 100

  // Memoize the limited metadata IDs to prevent unnecessary re-renders
  const limitMetadataIds: string[] = useMemo(() => {
    if (portfolioMetadataIds) {
      return portfolioMetadataIds.slice(0, options.limit);
    }
    return [];
  }, [options.limit, portfolioMetadataIds]);

  // Split IDs into batches of 100
  const idBatches = useMemo(() => {
    const batches: string[][] = [];
    for (let i = 0; i < limitMetadataIds.length; i += BATCH_SIZE) {
      batches.push(limitMetadataIds.slice(i, i + BATCH_SIZE));
    }
    return batches;
  }, [limitMetadataIds]);

  // Check if collections are already loaded
  const areCollectionsLoaded = useMemo(() => {
    if (limitMetadataIds.length === 0) return true;

    return limitMetadataIds.every((id) => collections[id]);
  }, [collections, limitMetadataIds]);

  // Function to manually refresh the collections
  const refreshCollections = useCallback(() => {
    if (limitMetadataIds.length > 0 && (!areCollectionsLoaded || !initialLoadCompleted.current)) {
      setIsLoading(true);
      setError(null);

      // Track how many batches have completed
      let completedBatches = 0;
      const totalBatches = idBatches.length;

      // Process each batch
      idBatches.forEach((batchIds) => {
        dispatch(downloadCollections({ ids: batchIds, downloadCollectionWorkflows: false }));

        completedBatches++;

        if (completedBatches === totalBatches) {
          setTimeout(() => {
            initialLoadCompleted.current = true;
            setIsLoading(false);
          }, 0);
        }
      });
    }
  }, [areCollectionsLoaded, dispatch, limitMetadataIds, idBatches]);

  return { isLoading, error, refreshCollections };
}

export function useProjectInPortfolios(projectId: string) {
  const portfolioCollections = usePortfolioCollections({ portfolioId: projectId });
  const portfolioMetadataIds = useCollectionKey(projectId, "metadataIds");

  return useMemo(() => {
    return { projects: portfolioCollections, totalCount: portfolioMetadataIds?.length };
  }, [portfolioCollections, portfolioMetadataIds?.length]);
}

/**
 * Returns an array for all collections.
 */
export function useCollections() {
  return useSelector((state: RootState) => {
    return state.collection.order.map((id) => state.collection.collections[id]);
  }, shallowEqual);
}

// export an array of tags from all collections
export function useCollectionTags(tagType: "autoTags" | "manualTags" | "topics" | "all" = "all") {
  const collections = useCollections();
  const tags: string[] = [];

  collections.forEach((collection) => {
    if (collection.metadata.autoTags && (tagType === "autoTags" || tagType === "all")) {
      collection.metadata.autoTags.forEach((tag) => {
        tags.push(tag);
      });
    }
    if (collection.metadata.manualTags && (tagType === "manualTags" || tagType === "all")) {
      collection.metadata.manualTags.forEach((tag) => {
        tags.push(tag);
      });
    }
    if (collection.searchHistory && (tagType === "topics" || tagType === "all")) {
      collection.searchHistory
        .map((search) => search.entities?.filter((entity) => entity.entity === "topic"))
        .forEach((entities) => {
          if (entities) {
            entities.forEach((entity) => {
              if (Array.isArray(entity.value)) {
                const values: string[] = entity.value;
                tags.push(...values);
              }
            });
          }
        });
    }
  });

  return uniq(tags).map((option) => ({ label: option, value: option }));
}

/**
 * Returns data for a given collection.
 * @param {string} id - Collection ID to query. If undefined is provided, undefined will be returned
 * @param options
 */
export function useCollection(
  id?: string,
  options: { refreshFromNetwork: boolean } = { refreshFromNetwork: false }
): CollectionWithAdditionalProps | undefined {
  const collection = useSelector((state: RootState) => (id ? state.collection.collections[id] : undefined), shallowEqual);
  const dispatch = useDispatch();

  useEffect(() => {
    if (id && options.refreshFromNetwork) dispatch(downloadCollections({ ids: [id] }));
  }, [dispatch, id, options.refreshFromNetwork]);

  return collection;
}

export function useCollectionKey<T extends keyof Collection>(collectionId: string | undefined, key: T): Collection[T] | undefined {
  return useSelector(
    (state: RootState) =>
      collectionId && state.collection.collections[collectionId] ? state.collection.collections[collectionId][key] : undefined,
    shallowEqual
  );
}

export function useCollectionQuestions(collectionId: string | undefined, filters?: { byQuestions?: string[] }) {
  const [questions, setQuestions] = useState<Question[]>([]);

  useEffect(() => {
    if (!collectionId) return;

    getCollectionQuestions(collectionId, filters)
      .then((response) => setQuestions(response))
      .catch((error) => {
        console.error(error);
      });
  }, [collectionId, filters]);

  return questions;
}

export function useProjectGroupIdToGetMetaDataIds(projectGroupId: string | undefined): string | undefined {
  const collections = useCollections();

  const lastProjectGroupCollection = useMemo(() => {
    if (!projectGroupId) return undefined;
    return collections.filter((collection) => collection.projectGroupId === projectGroupId).pop();
  }, [collections, projectGroupId]);

  const metadataIds = lastProjectGroupCollection?.metadataIds ?? [];
  const logoContent = useCollectionMetadataWithTagId(metadataIds, "company_logo");

  return useMemo(() => {
    if (!projectGroupId) return undefined;
    return logoContent;
  }, [projectGroupId, logoContent]);
}

export function useCollectionHasHighlights(collectionId: string): boolean {
  const extractedHighlights = useCollectionKey(collectionId, "extractedHighlights");

  return useMemo(
    () => (extractedHighlights && extractedHighlights[0]?.highlights[0]?.highlight?.length > 0) || false,
    [extractedHighlights]
  );
}

export function useCollectionWorkflowsIds(collectionId: string | undefined): string[] {
  return useSelector(
    (state: RootState) => (collectionId ? state.collection.collections[collectionId]?.workflowIds || [] : []),
    shallowEqual
  );
}

export const useIsLoadingCollections = (): boolean => {
  return useSelector((state: RootState) => state.collection.isLoading);
};

export const useIsInitialCollectionSyncCompleted = (): boolean => {
  return useSelector((state: RootState) => state.collection.hasInitialSyncCompleted);
};

export const useCollectionByTypeCount = (collectionType?: string): number | undefined => {
  return useSelector((state: RootState) => (collectionType ? state.collection.collectionsByTypeCount[collectionType] : undefined));
};

export const useSortedCollectionsIds = (collectionIds: string[] | undefined): string[] | undefined => {
  return useSelector((state: RootState) => {
    return collectionIds
      ? orderBy(collectionIds, (id) => new Date(state.collection.collections[id]?.metadata.createdTime), "desc")
      : undefined;
  }, shallowEqual);
};

export const useTotalCollectionsByType = (collectionType?: string): number => {
  return useSelector((state: RootState) => {
    return collectionType
      ? state.collection.order.filter((id) => state.collection.collections[id].collectionType === collectionType).length
      : 0;
  });
};

export const useRefreshCollections = () => {
  const dispatch = useDispatch();
  const collections = useSelector((state: RootState) => state.collection.collections, shallowEqual);
  const { projectFilter, projectId } = useParams<{
    projectFilter: string;
    projectId: string;
  }>();
  const { pathname } = useLocation();
  const parentRoute = pathname.split("/")[1];
  const isProjects = parentRoute === "projects";
  const isPortfolios = parentRoute === "portfolios";

  return () => {
    if (projectId && collections[projectId]) {
      dispatch(downloadCollections({ ids: [projectId], downloadCollectionWorkflows: true }));
    } else if (isProjects && projectFilter) {
      const relevantCollections = Object.keys(collections).filter((id) => collections[id].collectionType === projectFilter);
      if (relevantCollections.length > 0) {
        dispatch(downloadCollections({ ids: relevantCollections, downloadCollectionWorkflows: true }));
      }
    } else if (isPortfolios && projectFilter) {
      const relevantCollections = collections[projectFilter]?.metadataIds || [];
      const portfolioProjectId = collections[projectFilter]?.id;
      if (relevantCollections.length > 0) {
        dispatch(downloadCollections({ ids: [portfolioProjectId, ...relevantCollections], downloadCollectionWorkflows: true }));
      }
    }
  };
};

export const useRefreshPortfolioProjects = () => {
  const dispatch = useDispatch();
  const collections = useSelector((state: RootState) => state.collection.collections, shallowEqual);

  return () => {
    const portfolioProjectIds = Object.keys(collections).filter((id) => collections[id].collectionType === "portfolio");
    if (portfolioProjectIds.length > 0) {
      dispatch(downloadCollections({ ids: portfolioProjectIds, downloadCollectionWorkflows: true }));
    }
  };
};

export const useLatestCollectionWorkflowId = (collectionId: string | undefined, includedIntents?: string[]): string | undefined => {
  const workflowsIds = useCollectionWorkflowsIds(collectionId);
  const filteredWorkflowsIdsByIntent = useFilterWorkflowsIdsByIntents(workflowsIds, includedIntents);

  return filteredWorkflowsIdsByIntent.length > 0 ? filteredWorkflowsIdsByIntent[filteredWorkflowsIdsByIntent.length - 1] : undefined;
};

export const useCollectionWorkflowIdInProgress = (collectionId: string | undefined): string | undefined => {
  const workflowsIds = useCollectionKey(collectionId, "workflowIds");
  return useSelector((state: RootState) => {
    if (workflowsIds) {
      return workflowsIds.find((id) => {
        const workflow = state.workflow.workflowsById[id];
        return workflow && workflow.status === "in_progress";
      });
    }
  }, shallowEqual);
};

export const useFilterCollectionInProgress = (collectionIds: string[]): string | undefined => {
  return useSelector((state: RootState) => {
    const inProgress = collectionIds.find((id) => {
      const workflowsIds = state.collection.collections[id]?.workflowIds;
      if (workflowsIds) {
        return workflowsIds.find((id) => {
          const workflow = state.workflow.workflowsById[id];
          return workflow && workflow.status === "in_progress";
        });
      } else {
        return false;
      }
    });

    return inProgress;
  }, shallowEqual);
};

export const useCollectionType = (collectionId: string): string | undefined => {
  return useSelector((state: RootState) => state.collection.collections[collectionId]?.collectionType);
};

export const useMainCollectionWorkflowId = (collectionId: string | undefined, includedIntents?: string[]): string | undefined => {
  const workflowsIds = useCollectionWorkflowsIds(collectionId);
  const filteredWorkflowsIdsByIntent = useFilterWorkflowsIdsByIntents(workflowsIds, includedIntents);

  return filteredWorkflowsIdsByIntent[0];
};

export const useCollectionCancelWorkflowId = (collectionId: string | undefined): string | undefined => {
  const workflowsIds = useCollectionWorkflowsIds(collectionId);
  return useFilterWorkflowsIdsByIntents(workflowsIds, ["cancel"])[0];
};

export const useCollectionStock = (collectionId: string | undefined): StockExchangeRecord | undefined => {
  const maybeCompanyName = useCollectionKey(collectionId, "companyName");
  const maybeTicker = useCollectionKey(collectionId, "ticker");
  const maybeStockExchange = useCollectionKey(collectionId, "stockExchange");
  const maybeName = useCollectionKey(collectionId, "name");

  const companyName = maybeCompanyName || maybeName;

  return useMemo(() => {
    if (companyName && maybeTicker && maybeStockExchange) {
      return {
        name: companyName,
        exchange: maybeStockExchange,
        ticker: maybeTicker,
      };
    } else {
      return undefined;
    }
  }, [companyName, maybeTicker, maybeStockExchange]);
};

export const useGroupCollectionsIds = (groupId: string | undefined): string[] | undefined => {
  return useSelector(
    (state: RootState) => (state.collection.collectionsIdsByGroup && groupId ? state.collection.collectionsIdsByGroup[groupId] : undefined),
    shallowEqual
  );
};

// this hook is used to check if the portfolio collections are loaded
export const usePortfolioCollectionsLoaded = (portfolioId: string | undefined): boolean | undefined => {
  const portfolioCollections = usePortfolioCollections({ portfolioId });
  const collections = useSelector((state: RootState) => state.collection.collections, shallowEqual);
  const portfolioMetadataIds = useCollectionKey(portfolioId, "metadataIds");
  const [hasTimeoutElapsed, setTimeoutElapsed] = useState(false);

  // console.log({
  //   portfolioId,
  //   portfolioCollections,
  //   collections,
  //   portfolioMetadataIds,
  //   hasTimeoutElapsed,
  // });

  // Set a timeout to return true after 3 seconds if portfolioCollections is still empty
  useEffect(() => {
    if (portfolioCollections.length === 0 && !hasTimeoutElapsed) {
      const timer = setTimeout(() => {
        setTimeoutElapsed(true);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [portfolioCollections.length, hasTimeoutElapsed]);

  // Determine if portfolio collections are loaded
  const arePortfolioCollectionsLoaded = useMemo(() => {
    // Special case for due_diligence
    if (portfolioId === "due_diligence") {
      return true;
    }

    if (portfolioCollections.length > 0) {
      return true;
    }

    if (portfolioMetadataIds && portfolioMetadataIds?.length > 0) {
      return portfolioMetadataIds.every((id) => collections[id]);
    }

    // Return true if the timeout has elapsed (3 seconds) and collections are still empty
    if (hasTimeoutElapsed) {
      return true;
    }

    return false;
  }, [collections, portfolioMetadataIds, portfolioCollections, portfolioId, hasTimeoutElapsed]);
  return arePortfolioCollectionsLoaded;
};
