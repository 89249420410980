import { Record, String } from "runtypes";
import type { Static } from "runtypes";
import { TickerStatus } from "./TickerStatus";

export const SimplifiedTicker = Record({
  exchange: String,
  id: String,
  name: String,
  companyLogoUrl: String.nullable().optional(),
  companyBannerUrl: String.nullable().optional(),
  companyUrl: String.nullable().optional(),
  companyTaxId: String.nullable().optional(),
  companyLegalName: String.nullable().optional(),
  status: TickerStatus,
  type: String.nullable(),
  symbol: String,
});

export type SimplifiedTicker = Static<typeof SimplifiedTicker>;
