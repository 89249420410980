import React, { useCallback, useMemo, useState } from "react";
import type { ChakraProps } from "@chakra-ui/react";
import {
  Box,
  Button,
  Icon,
  IconButton,
  SimpleGrid,
  Stack,
  Table,
  Tbody,
  Text,
  Thead,
  Tr,
  Th,
  Td,
  Tooltip,
  useColorModeValue,
  Select,
  Center,
} from "@chakra-ui/react";
import { useFilterInput } from "hooks/useFilterInput";
import { useButtonProps } from "hooks";
import { AiOutlineDelete } from "react-icons/ai";
import { formatDistanceToNow } from "date-fns";
import { camelCaseToWords, useSortList } from "hooks/useSortList";
import { Select as MultiSelect } from "chakra-react-select";
import type { IconType } from "react-icons";
import { v4 as uuid } from "uuid";
import { BiInfoSquare } from "react-icons/bi";
import { TextOverflowTooltip } from "screens/landing/components/TextOverflowTooltip";

interface CustomOption<T> {
  label: string | ((row: T) => string);
  onClick?: (object: T, index: number) => void;
  hasPermission: boolean;
  isHidden?: (row: T) => boolean;
  icon: IconType;
  iconColor?: ChakraProps["color"] | ((row: T) => ChakraProps["color"]);
}

interface CustomOptionWithId<T> extends CustomOption<T> {
  id: string;
}

interface CustomFilter<T> {
  key: keyof T;
}

interface CustomMultiSelectFilter<T> extends CustomFilter<T> {
  type: "multi-select";
  defaultOptions: string[];
  options: { label: string; value: string }[];
}

interface Props<T> {
  tileTitle: string;
  items: T[];
  fieldsToRender: (keyof T)[];
  filterByFields: (keyof T)[];
  customFields?: { field: string; value: (row: T) => string | JSX.Element; width?: string }[];
  sortByFields?: (keyof T)[];
  defaultSortByKey?: keyof T;
  defaultOrderBy?: "asc" | "desc";
  inputFilterPlaceholder?: string;
  hasWrite: boolean;
  hasRead: boolean;
  hasDelete?: boolean;
  tileTitleKey: keyof T;
  onClickHelp?: () => void;
  onClickCreate?: () => void;
  onClickDelete?: (id: string, index: number) => void;
  onClickEdit?: (id: string, index: number) => void;
  customOptions?: (CustomOption<T> | ((row: T) => CustomOption<T>))[];
  keyName: keyof T;
  isLoading?: boolean;
  viewType?: "tiles" | "table";
  showViewType?: boolean;
  customFilters?: CustomMultiSelectFilter<T>[];
  customFilterElements?: {
    [key: string]: React.ReactNode;
  };
  showUpdatedInfo?: boolean;
  hideFilter?: boolean;
  showKeyInList?: boolean;
}

export const AdminTiles = <T extends { lastUpdatedDate?: string | Date; lastUpdatedByUserName?: string }>(props: Props<T>) => {
  const {
    items,
    inputFilterPlaceholder,
    filterByFields,
    sortByFields,
    defaultSortByKey,
    defaultOrderBy,
    fieldsToRender,
    tileTitleKey,
    tileTitle,
    hasWrite,
    hasRead,
    hasDelete,
    customOptions,
    onClickHelp,
    onClickCreate,
    onClickDelete,
    onClickEdit,
    keyName,
    isLoading = false,
    viewType = "table",
    showViewType = true,
    customFields,
    customFilters,
    customFilterElements,
    showUpdatedInfo = true,
    hideFilter = false,
    showKeyInList = true,
  } = props;

  const [internalViewType, setViewType] = useState<"tiles" | "table">(viewType);
  const [localFilters, setLocalFilters] = useState<Record<string, unknown>>(
    customFilters?.reduce(
      (acc, { key, type, defaultOptions, options }) => ({
        ...acc,
        [key]:
          type === "multi-select"
            ? defaultOptions.flatMap((defaultOpt) => {
                const option = options.find((opt) => opt.value === defaultOpt);
                return option ? [option] : [];
              })
            : "",
      }),
      {}
    ) ?? {}
  );
  const commonButtonProps = useButtonProps("sm", "primary");
  const bgColor = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const titleColor = useColorModeValue("charli.lightGray", "gray.500");
  const subTitlecolor = useColorModeValue("charli.mediumGray", "gray.400");
  const buttonColor = useColorModeValue("gray.700", "gray.300");
  const buttonHoverColor = useColorModeValue("gray.600", "gray.400");

  const getCustomOptionsWithId: (row: T) => CustomOptionWithId<T>[] | undefined = useCallback(
    (row: T) => {
      return customOptions?.map((option) => {
        if (typeof option === "function") {
          return {
            id: uuid(),
            ...option(row),
          };
        }

        return {
          id: uuid(),
          ...option,
        };
      });
    },
    [customOptions]
  );

  const filteredListWithCustomFilters = useMemo(() => {
    if (customFilters && customFilters.length > 0) {
      return items.filter((row) => {
        return customFilters.every(({ key, type }) => {
          const value = localFilters[key as string];

          if (type === "multi-select") {
            const selectedValues = value as { label: string; value: string }[];
            return selectedValues.length === 0 || selectedValues.some((selectedValue) => selectedValue.value === row[key]);
          } else {
            return value === "" || value === row[key];
          }
        });
      });
    } else {
      return items;
    }
  }, [customFilters, items, localFilters]);

  const { filteredList, renderFilterInputComponent } = useFilterInput<T>(filterByFields, filteredListWithCustomFilters);
  const { sortedList, selectSortComponent: SortComponent } = useSortList<T>(
    filteredList,
    sortByFields ?? [],
    defaultSortByKey,
    defaultOrderBy
  );

  const deleteIcon = useCallback(
    ({ label, recordId, index }: { label: string; recordId: string; index: number }) => {
      return onClickDelete ? (
        <Tooltip aria-label="" label={`Delete ${label}`} placement="top" hasArrow>
          <IconButton
            backgroundColor={"transparent"}
            height="unset"
            minWidth={"unset"}
            isDisabled={hasDelete === false || !hasWrite || isLoading}
            cursor="pointer"
            icon={<Icon as={AiOutlineDelete} color={buttonColor} boxSize="1rem" _hover={{ color: buttonHoverColor }} />}
            aria-label="Delete"
            onClick={(event: { stopPropagation: () => void }) => {
              onClickDelete(recordId, index);
              event.stopPropagation();
            }}
            _hover={{ backgroundColor: "unset" }}
          />
        </Tooltip>
      ) : null;
    },
    [buttonColor, buttonHoverColor, hasWrite, hasDelete, isLoading, onClickDelete]
  );

  const renderCustomOptions = useCallback(
    (row: T, index: number) => {
      const customOptionsWithId = getCustomOptionsWithId && getCustomOptionsWithId(row);

      return (
        customOptionsWithId &&
        customOptionsWithId.length > 0 && (
          <>
            {customOptionsWithId
              .flatMap((option) => (option.isHidden && option.isHidden(row) ? [] : [option]))
              .map((option) => {
                const label = typeof option.label === "function" ? option.label(row) : option.label;

                return (
                  <Tooltip key={option.id} aria-label={label} label={label} placement="top" hasArrow>
                    <IconButton
                      backgroundColor={"transparent"}
                      height="unset"
                      minWidth={"unset"}
                      isDisabled={!option.hasPermission || isLoading}
                      {...(option.onClick ? { cursor: "pointer" } : { cursor: "default" })}
                      icon={
                        <Icon
                          as={option.icon}
                          color={
                            option.iconColor
                              ? typeof option.iconColor === "function"
                                ? option.iconColor(row)
                                : option.iconColor
                              : buttonColor
                          }
                          boxSize="1rem"
                          _hover={{ color: buttonHoverColor }}
                        />
                      }
                      aria-label={label}
                      onClick={(event: { stopPropagation: () => void }) => {
                        event.stopPropagation();
                        if (option.onClick) {
                          option.onClick(row, index);
                        }
                      }}
                      _hover={{ backgroundColor: "unset" }}
                    />
                  </Tooltip>
                );
              })}
          </>
        )
      );
    },
    [buttonColor, buttonHoverColor, getCustomOptionsWithId, isLoading]
  );

  const getOnClickFunction = useCallback(
    (recordId: string, index: number) => {
      return hasRead && onClickEdit && !isLoading
        ? { cursor: `${hasRead ? "pointer" : "default"}`, onClick: () => onClickEdit(recordId, index) }
        : {};
    },
    [hasRead, isLoading, onClickEdit]
  );

  const renderTiles = useCallback(
    ({ list, recordKey }: { list: T[]; recordKey: string }) => {
      return (
        <SimpleGrid columns={[1, 2, 3, 4, 5]} spacingX="1rem" spacingY="1rem">
          {list.map((cw, index) => {
            const recordId = cw[recordKey] as string;

            return (
              <Box
                fontSize={"sm"}
                key={recordId}
                id={recordId}
                backgroundColor={bgColor}
                borderRadius="md"
                overflow="hidden"
                position="relative"
                borderColor={borderColor}
                borderWidth="1px"
                boxShadow="none"
                {...getOnClickFunction(recordId, index)}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  width="100%"
                  backgroundColor={borderColor}
                  p=".5rem">
                  <Text width="100%" isTruncated fontSize="sm" color={subTitlecolor} fontWeight="semibold">
                    {cw[tileTitleKey] as string}
                  </Text>
                  {renderCustomOptions(cw, index)}
                  {deleteIcon({ label: cw[tileTitleKey] as string, recordId, index })}
                </Stack>
                <Stack p=".5rem">
                  <Stack align="flex-start" justifyContent="space-between" width="100%">
                    {fieldsToRender.length > 0 &&
                      fieldsToRender.map((field) => (
                        <Stack key={field as string}>
                          <Text fontSize="xs" color={titleColor}>
                            {camelCaseToWords(field as string)}
                          </Text>
                          <Text wordBreak={"break-all"} fontSize="sm" color={subTitlecolor} fontWeight="semibold" mt="0!important">
                            {cw[field] as string}
                          </Text>
                        </Stack>
                      ))}
                    {customFields &&
                      customFields.map(({ field, value }) => {
                        const valueToRender = value(cw);

                        return (
                          <Stack key={`custom-field-${field}`}>
                            <Text fontSize="xs" color={titleColor}>
                              {field}
                            </Text>
                            <Text wordBreak={"break-all"} fontSize="sm" color={subTitlecolor} fontWeight="semibold" mt="0!important">
                              {valueToRender}
                            </Text>
                          </Stack>
                        );
                      })}
                    {showUpdatedInfo && cw.lastUpdatedByUserName && (
                      <Stack>
                        <Text fontSize="xs" color={titleColor}>
                          Last Updated By
                        </Text>
                        <Text fontSize="sm" color={subTitlecolor} fontWeight="semibold" mt="0!important">
                          {cw.lastUpdatedByUserName ?? "n/a"}
                        </Text>
                      </Stack>
                    )}
                    {showUpdatedInfo && cw.lastUpdatedDate && (
                      <Stack>
                        <Text fontSize="xs" color={titleColor}>
                          Last Updated
                        </Text>
                        <Text fontSize="sm" color={subTitlecolor} fontWeight="semibold" mt="0!important">
                          {formatDistanceToNow(new Date(cw.lastUpdatedDate), {
                            addSuffix: true,
                            includeSeconds: true,
                          })}
                        </Text>
                      </Stack>
                    )}
                  </Stack>
                </Stack>
              </Box>
            );
          })}
        </SimpleGrid>
      );
    },
    [
      bgColor,
      borderColor,
      getOnClickFunction,
      subTitlecolor,
      tileTitleKey,
      renderCustomOptions,
      deleteIcon,
      fieldsToRender,
      customFields,
      titleColor,
      showUpdatedInfo,
    ]
  );

  const renderTable = useCallback(
    ({ list, recordKey }: { list: T[]; recordKey: string }) => {
      return (
        <Box pb="5rem">
          <Table variant="simple" fontSize={"sm"}>
            <Thead>
              <Tr>
                {showKeyInList && (
                  <Th px="2px" py=".5rem">
                    {camelCaseToWords(tileTitleKey as string)}
                  </Th>
                )}

                {fieldsToRender.length > 0 &&
                  fieldsToRender.map((field) => (
                    <Th px="2px" py=".5rem" key={`field-key-${field as string}`}>
                      {camelCaseToWords(field as string)}
                    </Th>
                  ))}
                {customFields &&
                  customFields.map(({ field, width }) => {
                    return (
                      <Th px="2px" py=".5rem" key={`custom-field-key-${field}`} width={width}>
                        {field}
                      </Th>
                    );
                  })}
                {showUpdatedInfo ? (
                  <>
                    <Th px="2px" py=".5rem" maxWidth={"8rem"}>
                      Updated By
                    </Th>
                    <Th px="2px" py=".5rem" maxWidth={"8rem"}>
                      Updated Date
                    </Th>
                    <Th px="2px" py=".5rem"></Th>
                  </>
                ) : null}
              </Tr>
            </Thead>
            <Tbody>
              {list.map((row, index) => {
                const recordId = row[recordKey] as string;

                return (
                  <Tr key={recordId} {...getOnClickFunction(recordId, index)}>
                    {showKeyInList && (
                      <Td px="0" py=".5rem">
                        {row[tileTitleKey] as string}
                      </Td>
                    )}

                    {fieldsToRender.length > 0 &&
                      fieldsToRender.map((field, index) => (
                        <Td px="2px" py=".5rem" key={`field-value-${row[field]}-${index}`}>
                          <TextOverflowTooltip label={row[field] as string} />
                        </Td>
                      ))}
                    {customFields &&
                      customFields.length > 0 &&
                      customFields.map(({ value, field, width }) => {
                        const valueToRender = value(row);
                        return (
                          <Td px="2px" py=".5rem" key={`custom-field-${field}-value`} width={width}>
                            <TextOverflowTooltip noOfLines={3} label={typeof valueToRender === "string" ? valueToRender : ""} />
                            {typeof valueToRender !== "string" ? valueToRender : null}
                          </Td>
                        );
                      })}
                    {showUpdatedInfo ? (
                      <>
                        <Td px="2px" py=".5rem" maxWidth={"8rem"}>
                          <TextOverflowTooltip label={row.lastUpdatedByUserName ?? "n/a"} />
                        </Td>
                        <Td px="2px" py=".5rem" maxWidth={"8rem"}>
                          {row.lastUpdatedDate
                            ? formatDistanceToNow(new Date(row.lastUpdatedDate), {
                                addSuffix: true,
                                includeSeconds: true,
                              })
                            : "n/a"}
                        </Td>
                      </>
                    ) : null}

                    <Td px="2px" py=".5rem">
                      <Stack direction="row" width="100%" justifyContent={"flex-end"}>
                        {renderCustomOptions(row, index)}
                        {deleteIcon({ label: row[tileTitleKey] as string, recordId, index })}
                      </Stack>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Box>
      );
    },
    [showKeyInList, tileTitleKey, fieldsToRender, customFields, showUpdatedInfo, getOnClickFunction, renderCustomOptions, deleteIcon]
  );

  const renderStrategy = useCallback(() => {
    switch (internalViewType) {
      case "tiles": {
        return renderTiles({ list: sortedList, recordKey: keyName as string });
      }

      case "table": {
        return renderTable({ list: sortedList, recordKey: keyName as string });
      }

      default:
        return <Text>Invalid type</Text>;
    }
  }, [internalViewType, sortedList, keyName, renderTiles, renderTable]);

  return (
    <>
      <Stack direction="row" justifyContent="space-between" mt="0!important" spacing="1rem" pb=".5rem">
        <Stack direction="row" width="100%" justifyContent="space-between">
          {!hideFilter && <Box width="100%">{renderFilterInputComponent(inputFilterPlaceholder ?? "")}</Box>}
          <Box minWidth="13rem">{sortByFields && sortByFields.length > 0 && SortComponent}</Box>
          {showViewType && (
            <Box minWidth={"10rem"}>
              <Select
                size="sm"
                width={"100%"}
                value={internalViewType}
                onChange={(evt) => {
                  setViewType(evt.target.value as "tiles" | "table");
                }}>
                <option value={"tiles"}>Tiles</option>
                <option value={"table"}>Table</option>
              </Select>
            </Box>
          )}
          {customFilters &&
            customFilters.length > 0 &&
            customFilters.map(({ key: filterKey, options }) => (
              <Box key={filterKey as string} minWidth={"10rem"}>
                <MultiSelect
                  className="ch-multi-select"
                  useBasicStyles
                  size="sm"
                  isLoading={isLoading}
                  selectedOptionStyle="check"
                  options={options}
                  onChange={(newValues) => {
                    setLocalFilters((prev) => ({ ...prev, [filterKey as string]: newValues }));
                  }}
                  isMulti
                  isClearable={false}
                  placeholder={`Select ${camelCaseToWords(filterKey as string)}`}
                  value={localFilters[filterKey as string] as { label: string; value: string }[]}
                />
              </Box>
            ))}
        </Stack>
        {customFilterElements &&
          Object.entries(customFilterElements).map(([key, element]) => (
            <Box key={key} minWidth={"10rem"}>
              {element}
            </Box>
          ))}
        {onClickCreate ? (
          <Button {...commonButtonProps} minWidth={"unset"} isDisabled={!hasWrite} onClick={onClickCreate}>
            {`Create ${tileTitle}`}
          </Button>
        ) : null}

        {onClickHelp ? (
          <Center>
            <Button
              onClick={onClickHelp}
              bgColor={"transparent"}
              className="ch-admin-help"
              paddingInline={0}
              aria-label="Admin Help"
              height={"1rem"}
              color={buttonColor}
              _hover={{ color: "primary.default", backgroundColor: "transparent" }}
              _active={{}}
              variant="ghost"
              fontSize="sm"
              fontWeight={"normal"}
              leftIcon={<Icon as={BiInfoSquare} boxSize={"1.2rem"} />}>
              Help
            </Button>
          </Center>
        ) : null}
      </Stack>
      {renderStrategy()}
    </>
  );
};
