import React from "react";
import { IoMdCheckmarkCircleOutline, IoIosTimer } from "react-icons/io";
import { Icon, useColorModeValue, CircularProgress } from "@chakra-ui/react";
import type { IconProps } from "@chakra-ui/react";
import { FiCheck } from "react-icons/fi";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { MdOutlineCircle, MdOutlineQuestionMark, MdOutlineRefresh } from "react-icons/md";
import { AiOutlineClose } from "react-icons/ai";

interface Props {
  status: string;
  boxSize?: string;
  altCheckmark?: boolean;
}

const iconProps: IconProps = {
  boxSize: "1rem",
  color: "white",
  fontWeight: "bold",
  p: "2px",
  borderRadius: "full",
  border: "none",
};

export const getStatusColor = (status: string) => {
  switch (status) {
    case "succeeded":
    case "complete":
      return "green";
    case "queued":
    case "in_progress":
    case "denied_intent_confirmation":
    case "failed_checkstop":
    case "awaiting_async_task": {
      return "orange";
    }
    case "failed":
    case "error":
      return "red";
    case "clarification_needed":
    case "paused":
      return "blue";
    case "scheduled":
      return "purple";
    default:
      return "gray";
  }
};

export const WorkflowStatusIcon = ({ status, boxSize, altCheckmark }: Props) => {
  const iconColor = useColorModeValue("500", "700");
  const fontColor = useColorModeValue("gray.50", "gray.500");
  const bgColor = useColorModeValue("white", "gray.700");

  switch (status) {
    case "complete":
      return (
        <>
          {altCheckmark ? (
            <Icon
              as={IoMdCheckmarkCircleOutline}
              {...iconProps}
              color={`${getStatusColor(status)}.${iconColor}`}
              bgColor={bgColor}
              boxSize={"22px"}
              p="0"
              display={"flex"}
              justifyContent={"center"}
            />
          ) : (
            <Icon as={FiCheck} color={fontColor} bgColor={`${getStatusColor(status)}.${iconColor}`} {...iconProps} boxSize={boxSize} />
          )}
        </>
      );
    case "queued":
      return <Icon as={BiDotsHorizontalRounded} bgColor={`${getStatusColor(status)}.${iconColor}`} {...iconProps} boxSize={boxSize} />;
    case "in_progress":
      return <CircularProgress size={boxSize} isIndeterminate color={`${getStatusColor(status)}.${iconColor}`} />;
    case "clarification_needed":
      return <Icon as={MdOutlineQuestionMark} bgColor={`${getStatusColor(status)}.${iconColor}`} {...iconProps} boxSize={boxSize} />;
    case "failed":
    case "error":
    case "denied_intent_confirmation":
      return <Icon as={MdOutlineRefresh} bgColor={`${getStatusColor(status)}.${iconColor}`} {...iconProps} boxSize={boxSize} />;
    case "failed_checkstop":
      return <Icon as={MdOutlineRefresh} bgColor={`${getStatusColor(status)}.${iconColor}`} {...iconProps} boxSize={boxSize} />;
    case "scheduled":
      return <Icon as={IoIosTimer} bgColor={`${getStatusColor(status)}.${iconColor}`} {...iconProps} boxSize={boxSize} />;
    case "cancelled":
      return <Icon as={AiOutlineClose} bgColor={`${getStatusColor(status)}.${iconColor}`} {...iconProps} boxSize={boxSize} />;
    default:
      return <Icon as={MdOutlineCircle} bgColor={`${getStatusColor(status)}.${iconColor}`} {...iconProps} boxSize={boxSize} />;
  }
};
