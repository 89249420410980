import { Box, Skeleton, SimpleGrid, useBreakpointValue } from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import React from "react";
import { useSkeletonProps } from "hooks";

interface Props {
  isLoading: boolean;
  height?: string;
}

export const ProjectDetailViewSkeleton: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  isLoading,
  height = "100%",
  children,
}) => {
  const skeletonStyle = useSkeletonProps();
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });

  if (isLoading) {
    return (
      <Box height={height} pt="0">
        <SimpleGrid columns={isMobile ? 1 : 2} spacing="2rem">
          <Skeleton {...skeletonStyle} borderRadius={"sm"} height="20rem" width="100%" />
          <Skeleton {...skeletonStyle} borderRadius={"sm"} height="20rem" width="100%" />
          <Skeleton {...skeletonStyle} borderRadius={"sm"} height="20rem" width="100%" />
          <Skeleton {...skeletonStyle} borderRadius={"sm"} height="20rem" width="100%" />
          <Skeleton {...skeletonStyle} borderRadius={"sm"} height="20rem" width="100%" />
          <Skeleton {...skeletonStyle} borderRadius={"sm"} height="20rem" width="100%" />
        </SimpleGrid>
      </Box>
    );
  } else {
    return <>{children}</>;
  }
};
