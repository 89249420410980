import React, { useEffect, useRef, useState, useMemo, useCallback } from "react";
import { useColorModeValue, Stack, useToast } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { useConversationContext } from "screens/thread/ConversationContext";
import { AutoComplete, AutoCompleteItem } from "@choc-ui/chakra-autocomplete";
import { AutoCompleteSuggestionList } from "./AutoCompleteSuggestionList";
import type { AutoCompleteRefMethods } from "@choc-ui/chakra-autocomplete";
import { useSelector } from "react-redux";
import type { RootState } from "state/rootReducer";
import { useAddToCharliContext } from "screens/panels/addToCharli/AddToCharliWizard/AddToCharliProvider";
import { v4 as uuid } from "uuid";
import { AutocompleteSuggestionInput } from "./AutocompleteSuggestionInput";
import { useCustomScrollbar } from "hooks/useCustomScrollbar";
import { SuggestionItem } from "./SuggestionItem";
import type { AutocompleteSuggestionWrapperProps } from "./types";
import { isTickerEntitled, useTickerEntitlementConfig } from "screens/thread/useConversationDialogSuggestionLists";
import { ToastMessageContent } from "screens/common/components";
import { useCopyValue } from "hooks/useCopies";
import { useProjectParams } from "hooks";

export const AutocompleteSuggestionWrapper = React.memo(
  ({
    initialText = "",
    suggestedQuestions = [],
    suggestionListType,
    isInputDisabled = false,
    isSubmitDisabled = false,
    hasLeftIcon = true,
    onSelectOption,
    value,
    onChange,
    onReset,
    isLoading = false,
    onClickSubmit,
    conversationId,
    conversationState,
    minWidthList = "32rem",
    marginLeftList,
    disableFilter = false,
    defaultIsOpen = false,
    className,
    externalInputRef,
    inputId,
    canBypassDisabled,
    setCanBypassDisabled,
    onFocus,
    onBlur,
    disableComponent,
    activeFilter,
    setActiveFilter,
  }: AutocompleteSuggestionWrapperProps) => {
    const isWebsocketConnected = useSelector((state: RootState) => state.websocket.isConnected);
    const inputSubmitColor = useColorModeValue("gray.50", "gray.800");
    const textColor = useColorModeValue("primary.darkGray", "gray.400");
    const betaBgColor = useColorModeValue("blue.100", "blue.700");
    const upgradeBgColor = useColorModeValue("yellow.50", "yellow.800");
    const toast = useToast();
    const {
      onFeedbackModalOpen,
      setFeedbackValue,
      isConversationOpen,
      onConversationClose,
      onConversationOpen,
      isDialogOpen,
      onDialogClose,
      focusedInputId,
      setFocusedInputId,
      setIsUsingDefaultConversationDialog,
      setIsAnotherInputFocused,
    } = useConversationContext();
    const { setIsNewMenuAction } = useAddToCharliContext();
    const autocompleteRef = useRef<AutoCompleteRefMethods>();
    const internalInputRef = useRef<HTMLInputElement>(null);
    const listRef = useRef<HTMLDivElement>(null);
    const filterContainerRef = useRef<HTMLDivElement>(null);
    const inputRef = externalInputRef || internalInputRef;
    const { pathname } = useLocation();
    const { scrollbarStyle } = useCustomScrollbar(listRef, { width: "3px", barTransparency: 0.1 });
    const entitlementConfig = useTickerEntitlementConfig();
    const { projectId } = useProjectParams();

    const [showSuggestionList, setShowSuggestionList] = useState(Boolean(defaultIsOpen));
    const isDefaultOpen = useMemo(() => Boolean(defaultIsOpen), [defaultIsOpen]);
    const handleSetActiveFilter = useCallback(
      (filter: string | null) => {
        setActiveFilter?.(filter);
      },
      [setActiveFilter]
    );
    const isFilterDisabled = useMemo(() => Boolean(disableFilter), [disableFilter]);

    const isListVisible = useCallback(() => {
      return !!(listRef.current && listRef.current.offsetParent !== null);
    }, []);

    const suggestedQuestionsWithId = useMemo(() => {
      return suggestedQuestions.map((question) => ({ ...question, id: uuid() }));
    }, [suggestedQuestions]);

    const filteredSuggestedQuestions = useMemo(() => {
      if (!activeFilter) return suggestedQuestionsWithId;
      return suggestedQuestionsWithId.filter(
        (q) => q.type?.toUpperCase() === activeFilter.toUpperCase() || q.focus?.toUpperCase().includes(activeFilter.toUpperCase())
      );
    }, [suggestedQuestionsWithId, activeFilter]);

    const onClickFeedback = useCallback(() => {
      if (!value) {
        return;
      }
      setFeedbackValue(value);
      onFeedbackModalOpen();
    }, [value, setFeedbackValue, onFeedbackModalOpen]);

    const onHandleConversation = useCallback(() => {
      if (isConversationOpen) {
        onConversationClose();
      } else {
        setIsNewMenuAction(conversationId ? undefined : "command");
        onConversationOpen(conversationId);
      }
    }, [isConversationOpen, onConversationClose, setIsNewMenuAction, onConversationOpen, conversationId]);

    useEffect(() => {
      if (inputRef.current) {
        setIsUsingDefaultConversationDialog(false);
        isConversationOpen && focusedInputId === "conversation-input" && inputRef.current.focus();
      }
    }, [focusedInputId, inputRef, isConversationOpen, setIsUsingDefaultConversationDialog]);

    const isFocusedInputDisabled = useMemo(() => {
      return !projectId && focusedInputId !== inputId;
    }, [focusedInputId, inputId, projectId]);

    useEffect(() => {
      if (isConversationOpen) {
        setFocusedInputId("conversation-input");
        if (isDialogOpen) {
          onDialogClose();
        }
      } else {
        setFocusedInputId("view-input");
      }
      setTimeout(() => {
        !disableComponent && inputRef.current && inputRef.current.focus();
      }, 300);
    }, [isConversationOpen, setFocusedInputId, pathname, isDialogOpen, onDialogClose, inputRef, disableComponent]);

    const isSubmitAvailable = useCallback((): boolean => {
      if (value?.startsWith("/")) {
        return false;
      }
      return !!(
        value &&
        value.length > 0 &&
        !suggestedQuestions.some((suggestion) => suggestion.question.toLowerCase().trim() === value.toLowerCase().trim())
      );
    }, [suggestedQuestions, value]);

    const handleInputOnClick = useCallback(
      (onOpen: () => void) => {
        if ((isInputDisabled || isFocusedInputDisabled) && !canBypassDisabled) {
          return;
        }
        setIsAnotherInputFocused(true);
        if (inputId === "view-input" && isConversationOpen) {
          onConversationClose();
        }
        setShowSuggestionList(true);
        onOpen();
        onFocus?.();
        inputRef.current && inputRef.current.focus();
      },
      [
        inputRef,
        isInputDisabled,
        isFocusedInputDisabled,
        canBypassDisabled,
        setIsAnotherInputFocused,
        inputId,
        isConversationOpen,
        onFocus,
        onConversationClose,
      ]
    );

    useEffect(() => {
      const handleKeyDown = (ev: KeyboardEvent) => {
        const { key } = ev;
        if (isInputDisabled) return;
        const suggestedQuestion =
          suggestedQuestions.length > 0 &&
          suggestedQuestions.filter(
            (suggestion) => value?.toLocaleLowerCase() && suggestion.question.toLocaleLowerCase().includes(value)
          )[0];

        const isListCurrentlyVisible = isListVisible();

        switch (key) {
          case "Enter":
            (!isListCurrentlyVisible || isSubmitAvailable()) && onClickSubmit?.();
            setShowSuggestionList(false);
            setCanBypassDisabled?.(false);
            break;

          case "Tab": {
            if (!isListCurrentlyVisible) {
              return;
            }
            suggestedQuestion && onSelectOption(suggestedQuestion);
            setShowSuggestionList(false);
            setCanBypassDisabled?.(false);
            ev.preventDefault();
            break;
          }

          case " ":
            {
              const doesValueContainEntity = value?.includes(">") || false;
              setShowSuggestionList(doesValueContainEntity);
            }
            break;

          case "Escape":
            projectId && onConversationClose();
            setShowSuggestionList(false);
            setCanBypassDisabled?.(false);
            onReset();
            onChange("");
            break;

          default:
            break;
        }
      };

      document.addEventListener("keydown", handleKeyDown);
      return () => document.removeEventListener("keydown", handleKeyDown);
    }, [
      isSubmitAvailable,
      onClickSubmit,
      onReset,
      onChange,
      value,
      suggestedQuestions,
      onSelectOption,
      setCanBypassDisabled,
      showSuggestionList,
      setIsAnotherInputFocused,
      onBlur,
      inputRef,
      isListVisible,
      isInputDisabled,
      projectId,
      onConversationClose,
    ]);

    const handleInputChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
      setShowSuggestionList(true);
      onChange(ev.target.value);
    };

    const handleSubmit = useCallback(() => {
      if (isLoading) {
        return;
      }
      const suggestedQuestion =
        suggestedQuestions.length > 0 &&
        suggestedQuestions.filter((suggestion) => value?.toLocaleLowerCase() && suggestion.question.toLocaleLowerCase().includes(value))[0];

      onClickSubmit && onClickSubmit(suggestedQuestion ? suggestedQuestion : undefined);
      setShowSuggestionList(false);
    }, [isLoading, onClickSubmit, suggestedQuestions, value]);

    const renderSuggestedQuestions = useCallback(
      (questions: typeof suggestedQuestionsWithId) => {
        return questions.map((suggestion) => (
          <AutoCompleteItem
            _focus={{
              bgColor:
                suggestionListType === "tickers" &&
                !isTickerEntitled(
                  suggestion.type?.toUpperCase(),
                  suggestion.focus?.toUpperCase(),
                  entitlementConfig,
                  suggestion.matchFilter?.[0]
                )
                  ? upgradeBgColor
                  : inputSubmitColor,
            }}
            key={`option-${suggestion.id}`}
            value={JSON.stringify({
              question: suggestion.question,
              focus: suggestion.focus,
              matchFilter: suggestion.matchFilter,
              type: suggestion.type,
            })}
            className="ch-autocomplete-suggestion"
            marginInline={0}
            p="1rem"
            borderRadius="none">
            <SuggestionItem
              suggestion={suggestion}
              value={value}
              isDisabled={
                suggestionListType === "tickers" &&
                !isTickerEntitled(
                  suggestion.type?.toUpperCase(),
                  suggestion.focus?.toUpperCase(),
                  entitlementConfig,
                  suggestion.matchFilter?.[0]
                )
              }
              textColor={textColor}
              betaBgColor={betaBgColor}
              suggestionType={suggestionListType}
            />
          </AutoCompleteItem>
        ));
      },
      [entitlementConfig, inputSubmitColor, upgradeBgColor, value, textColor, betaBgColor, suggestionListType]
    );

    const copyUpgradeNotification = useCopyValue("copy_ticker_upgrade_notification");
    const handleToast = useCallback(() => {
      toast.closeAll();
      toast({
        render: ({ onClose }) => (
          <ToastMessageContent
            message={copyUpgradeNotification}
            onClick={() => {
              onConversationOpen();
              onClose();
            }}
            onClose={onClose}
          />
        ),
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }, [toast, copyUpgradeNotification, onConversationOpen]);

    return (
      <Stack spacing="9px" width="100%" height="100%">
        <AutoComplete
          ref={autocompleteRef}
          onSelectOption={({ item: { originalValue } }) => {
            if (!isWebsocketConnected) return;
            const suggestedQuestion = JSON.parse(originalValue);
            const isEntitled = isTickerEntitled(
              suggestedQuestion.type,
              suggestedQuestion.focus,
              entitlementConfig,
              suggestedQuestion.matchFilter?.[0]
            );
            if (suggestionListType === "tickers" && !isEntitled) {
              handleToast();
            } else {
              onSelectOption(suggestedQuestion);
            }
          }}
          defaultIsOpen={isDefaultOpen}
          disableFilter={isFilterDisabled}
          freeSolo
          emptyState={false}>
          {({ onOpen }) => (
            <>
              <AutocompleteSuggestionInput
                inputId={inputId}
                value={value}
                initialText={initialText}
                isInputDisabled={isInputDisabled}
                isSubmitDisabled={isSubmitDisabled}
                hasLeftIcon={hasLeftIcon}
                isLoading={isLoading}
                className={className}
                inputRef={inputRef}
                canBypassDisabled={canBypassDisabled}
                isFocusedInputDisabled={isFocusedInputDisabled}
                isConversationOpen={isConversationOpen}
                conversationState={conversationState}
                isWebsocketConnected={isWebsocketConnected}
                handleInputOnClick={handleInputOnClick}
                handleSubmit={handleSubmit}
                handleInputChange={handleInputChange}
                onHandleConversation={onHandleConversation}
                setIsAnotherInputFocused={setIsAnotherInputFocused}
                onBlur={onBlur}
                onOpen={onOpen}
              />
              <AutoCompleteSuggestionList
                showSuggestionList={showSuggestionList}
                suggestionListType={suggestionListType}
                isWebsocketConnected={isWebsocketConnected}
                pathname={pathname}
                value={value}
                activeFilter={activeFilter || null}
                setActiveFilter={handleSetActiveFilter}
                onClickFeedback={onClickFeedback}
                listRef={listRef}
                filterContainerRef={filterContainerRef}
                scrollbarStyle={scrollbarStyle}
                marginLeftList={marginLeftList}
                minWidthList={minWidthList}
                isLoading={isLoading}>
                {filteredSuggestedQuestions.length > 0 && renderSuggestedQuestions(filteredSuggestedQuestions)}
              </AutoCompleteSuggestionList>
            </>
          )}
        </AutoComplete>
      </Stack>
    );
  }
);

export default AutocompleteSuggestionWrapper;
