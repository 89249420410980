import type { Dispatch } from "redux";
import uniq from "lodash/uniq";
import partition from "lodash/partition";
import isEmpty from "lodash/isEmpty";
import { actions as workflowActions } from "state/workflow/reducer";
import { downloadWorkflows } from "state/workflow/operations";
import type { Workflow } from "types/workflows/workflow";
import { downloadConversationById } from "state/conversation/operations";

const CHUNK_SIZE = 500;

export type RequestDownloadWorkflowsOrigin = "websocket" | "api";

export const requestToDownloadWorkflows = async (
  workflowIds: string[],
  isLoadingWorkflowMap: Record<string, boolean | undefined>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: Dispatch<any>,
  { origin }: { origin?: RequestDownloadWorkflowsOrigin } = {}
): Promise<void> => {
  const deduplicatedIds = uniq(workflowIds);

  const [tentativeWorkflowIdsToQueue, tentativeWorkflowIdsToFetch] = partition(deduplicatedIds, (id) => {
    const isLoading = isLoadingWorkflowMap[id];

    const shouldQueue = isLoading ?? false;

    return shouldQueue;
  });

  const workflowIdsToQueue = [...tentativeWorkflowIdsToFetch.slice(CHUNK_SIZE), ...tentativeWorkflowIdsToQueue];
  const workflowIdsToFetch = tentativeWorkflowIdsToFetch.slice(0, CHUNK_SIZE);

  if (!isEmpty(workflowIdsToQueue)) {
    dispatch(workflowActions.queueWorkflowIds(workflowIdsToQueue));
  }

  if (!isEmpty(workflowIdsToFetch)) {
    dispatch(downloadWorkflows({ workflowIds: workflowIdsToFetch, origin }));
  }
};

export const requestToDownloadWorkflowsThatMayHaveChanged = async (
  workflowsById: Record<string, Workflow>,
  isLoadingWorkflowMap: Record<string, boolean>,
  dispatch: Dispatch<any>
): Promise<void> => {
  const workflowIdsToRefetch = Object.entries(workflowsById)
    .filter(([_, workflow]) => {
      return (
        workflow.dismissed === undefined ||
        ((workflow.status === "failed" || workflow.status === "error") && !workflow.dismissed) ||
        workflow.status === "clarification_needed" ||
        workflow.status === "in_progress" ||
        workflow.status === "queued"
      );
    })
    .map(([workflowId, _]) => workflowId);

  const conversationsIdsThatMayHaveChanged = workflowIdsToRefetch.flatMap((workflowId) => {
    const workflow = workflowsById[workflowId];

    if (!workflow || !workflow.conversationId || workflow.status !== "in_progress") {
      return [];
    } else {
      return [workflow.conversationId];
    }
  });

  Promise.all(conversationsIdsThatMayHaveChanged.map((id) => dispatch(downloadConversationById({ conversationId: id }) as never)));

  if (!isEmpty(workflowIdsToRefetch)) {
    requestToDownloadWorkflows(workflowIdsToRefetch, isLoadingWorkflowMap, dispatch);
  }
};
