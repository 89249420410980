import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions as navigationActions } from "state/navigation/reducer";
import type { RootState } from "state/rootReducer";
import { useUserPreference } from "./useUserPreferences";
import { useBreakpointValue } from "@chakra-ui/react";

export function useSidebarNavigation() {
  const selectedView = useSelector((state: RootState) => state.navigation.selectedView);
  const defaultView = useSelector((state: RootState) => state.navigation.defaultView);
  const reduxNavigationType = useSelector((state: RootState) => state.navigation.setSidebarType);
  const isMobile = useBreakpointValue({ base: true, md: true, lg: false }, { fallback: "md", ssr: false });
  const dispatch = useDispatch();

  const setDefaultSidebar = useCallback(
    (view: typeof defaultView) => {
      dispatch(navigationActions.setDefault(view));
    },
    [dispatch]
  );

  const setSelectedSidebar = useCallback(
    (view: typeof defaultView) => {
      dispatch(navigationActions.setSelectedView(view));
    },
    [dispatch]
  );

  const setSidebarType = useCallback(
    (sidebarType: typeof reduxNavigationType) => {
      dispatch(navigationActions.setSidebarType(sidebarType));
    },
    [dispatch]
  );

  const userPrefNavigationType = useUserPreference("ui_navigation_type");
  const currentSidebarType = isMobile ? "hidden" : userPrefNavigationType || reduxNavigationType;

  return { selectedView, setSelectedSidebar, currentSidebarType, setSidebarType, defaultView, setDefaultSidebar };
}
