import {
  Box,
  Button,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuList,
  Stack,
  Tooltip,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import {
  useButtonProps,
  useCollectionKey,
  useConfigMap,
  useContents,
  useGetViewConfig,
  useLatestCollectionWorkflowId,
  useManualTags,
  useProjectParams,
  useUserPreference,
} from "hooks";
import type { FunctionComponent } from "react";
import React, { useContext, useState, useMemo, useEffect, useRef } from "react";
import { useAddToCharliContext } from "screens/panels/addToCharli/AddToCharliWizard/AddToCharliProvider";
import { OptionsMenuItem } from "../components/OptionsMenuItem";
import { HamburgerIcon, TriangleDownIcon } from "@chakra-ui/icons";
import { ProjectDeleteButton } from "./ProjectActions/ProjectDeleteButton";
import { ProjectRerunCancelButton } from "./ProjectActions/ProjectRerunCancelButton";
import { TbListSearch } from "react-icons/tb";
import { AiOutlineFileText } from "react-icons/ai";
import { ResearchContext } from "screens/panels/research/ResearchContext";
import { useRequestDefaultReport } from "../components/useRequestDefaultReport";
import { hasProjectOutput, ManualTags } from "../components/utils";
import { MoveProjectModal } from "./ProjectActions/MoveProjectModal";
import { ProjectMoveButton } from "./ProjectActions/ProjectMoveButton";
import { v4 as uuid } from "uuid";
import { useWorkflowKey } from "hooks/useWorkflows";
import { ConversationContext, useConversationContext } from "screens/thread/ConversationContext";
import { ProjectReportPanelNewReportModal } from "screens/panels/researchReport/ProjectReportPanelNewReportModal";
import { useProjectQuestionsContext } from "../ProjectQuestionsContext";
import { FloatingDialogModal } from "components/FloatingDialogModal";

interface Props {
  collectionId: string;
}

export const ProjectDetailDefaultActionMenu: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  collectionId,
}) => {
  const { isPortfolios } = useProjectParams();
  const {
    onProjectReportPanelOpen,
    onKeywordsNotesPanelOpen,
    onProjectAddSourceOpen,
    onProjectReportSelectorOpen,
    isProjectReportSelectorOpen,
    onProjectReportSelectorClose,
    isProjectReportPanelOpen,
  } = useAddToCharliContext();
  const { onResearchHistoryPanelOpen } = useContext(ResearchContext);
  const isMobile = useBreakpointValue({ base: true, md: false, lg: false }, { fallback: "md", ssr: false });
  const commonButtonProps = useButtonProps("sm", "primary");
  const newReportButtonProps = useButtonProps("sm", "cta");
  const [isRequesting, setIsRequesting] = useState(false);
  const configMap = useConfigMap();
  const projectDefaultReport = useGetViewConfig("defaultReport", "due_diligence", configMap);
  const userDefaultReportTemplateType = useUserPreference("default_report_template_type");
  const requestDefaultReport = useRequestDefaultReport({
    collectionId,
    setIsRequesting,
    selectedReportTemplate: isPortfolios && projectDefaultReport ? projectDefaultReport : undefined,
  });
  const { questions: collectionQuestions } = useProjectQuestionsContext();
  const collectionMetadataIds = useCollectionKey(collectionId, "metadataIds");
  const collectionConversationId = useCollectionKey(collectionId, "conversationId");
  const collectionResearch = useCollectionKey(collectionId, "searchHistory");
  const isActiveProjectInGroup = useCollectionKey(collectionId, "isActiveProjectInGroup");
  const tagsFilter = useManualTags();
  const contentData = useContents(collectionMetadataIds ?? [], { refreshFromNetwork: true, filterByTags: tagsFilter });
  const latestWorkflowId = useLatestCollectionWorkflowId(collectionId);
  const workflowStatus = useWorkflowKey(latestWorkflowId, "status");
  const { onOpen: onOpenMoveProject, onClose: onCloseMoveProject, isOpen: isOpenMoveProject } = useDisclosure();
  const { onDeleteProjectModalOpen } = useContext(ConversationContext);
  const shareDetails = useCollectionKey(collectionId, "shareDetails");
  const isSharedRead = shareDetails && shareDetails.accessMode === "read";

  const collectionQuestionsFilter = useMemo(() => {
    const userQuestions = collectionQuestions?.filter((question) => question.initiator === "user");

    if (userQuestions?.length === 0) return "";
    const lastUserQuestion = userQuestions?.sort((a, b) => new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime())[0]
      .creationDate;
    return lastUserQuestion || "";
  }, [collectionQuestions]);

  const collectionReportsFilter = useMemo(() => {
    const projectReports = contentData?.filter((content) => content.manualTags?.includes(ManualTags.projectOutput));
    if (projectReports?.length === 0) return "";
    const latestReport = projectReports?.sort((a, b) => new Date(b.timeCreated).getTime() - new Date(a.timeCreated).getTime())[0]
      .timeCreated;
    return latestReport || "";
  }, [contentData]);

  const hasReports = useMemo(() => {
    const filtered = contentData?.filter((content) => {
      return hasProjectOutput(content);
    });
    return filtered && filtered.length > 0;
  }, [contentData]);

  const hasQuestionOlderThenReport = useMemo(() => {
    return new Date(collectionQuestionsFilter) > new Date(collectionReportsFilter);
  }, [collectionQuestionsFilter, collectionReportsFilter]);

  useEffect(() => {
    setIsRequesting(false);
  }, [contentData]);

  const handleReportOnClick = () => {
    if (userDefaultReportTemplateType) {
      setIsRequesting(true);
      requestDefaultReport();
    } else {
      onProjectReportSelectorOpen();
    }
  };

  const addTickerButtonRef = useRef<HTMLButtonElement>(null);
  const ctaButtonProps = useButtonProps("sm", "cta");
  const { onDialogOpen, isDialogOpen, onDialogClose } = useConversationContext();

  return (
    <Stack direction="row" spacing="0.5rem" width="100%" justifyContent={isMobile ? "space-between" : "flex-end"}>
      {isMobile && (
        <>
          <Box cursor="pointer">
            <Button ref={addTickerButtonRef} {...ctaButtonProps} onClick={onDialogOpen} className="ch-project-detail-ask-question-button">
              Ask Question
            </Button>
          </Box>
          <FloatingDialogModal isOpen={isDialogOpen} onClose={onDialogClose} buttonRef={addTickerButtonRef} />
        </>
      )}
      {!isMobile && (
        <Tooltip
          label={
            isSharedRead
              ? ""
              : hasQuestionOlderThenReport
              ? "The project details have changed and you may have asked new questions, click here for me to send a new report."
              : "The project details have not changed since the last report was generated, click the View AI Generated Reports button ➜ to see the last report."
          }>
          <Button
            cursor="pointer"
            isDisabled={workflowStatus === "in_progress" || isSharedRead}
            aria-label="New Report"
            className="ch-new-report-panel"
            {...(isRequesting || !hasQuestionOlderThenReport ? commonButtonProps : newReportButtonProps)}
            leftIcon={<Icon as={AiOutlineFileText} boxSize="1.3rem" />}
            onClick={handleReportOnClick}>
            Send Report
          </Button>
        </Tooltip>
      )}
      <Stack direction={"row"}>
        <Tooltip
          label={
            isSharedRead ? "" : hasReports ? "View AI Generated Reports" : "No reports created yet. Use Send Report button to create one."
          }
          maxWidth={"12rem"}
          aria-label="Show/Hide Reports">
          <Box cursor="pointer">
            <IconButton
              isDisabled={isSharedRead || !hasReports}
              aria-label="Open Reports"
              className="ch-open-report-panel"
              {...commonButtonProps}
              icon={<Icon as={AiOutlineFileText} boxSize="1.3rem" />}
              onClick={() => {
                onProjectReportPanelOpen();
              }}
            />
          </Box>
        </Tooltip>
        {!isMobile && (
          <Tooltip label="View AI Keywords and Notes" aria-label="Show/Hide Notes">
            <Box cursor="pointer">
              <IconButton
                isDisabled={isSharedRead}
                aria-label="Open Notes"
                className="ch-open-keywords-notes-panel"
                {...commonButtonProps}
                icon={<Icon as={TbListSearch} boxSize="1.3rem" />}
                onClick={() => {
                  onKeywordsNotesPanelOpen();
                }}
              />
            </Box>
          </Tooltip>
        )}
        <Box>
          <Menu isLazy>
            {isMobile ? (
              <MenuButton
                {...commonButtonProps}
                isDisabled={isSharedRead}
                as={IconButton}
                aria-label="Options"
                icon={<HamburgerIcon boxSize="1.3rem" />}
                variant="outline"
                onClick={(event) => event.stopPropagation()}
              />
            ) : (
              <MenuButton
                {...commonButtonProps}
                isDisabled={isSharedRead}
                className="ch-project-options"
                as={Button}
                aria-label="Options"
                rightIcon={<TriangleDownIcon />}
                onClick={(event) => event.stopPropagation()}>
                Actions
              </MenuButton>
            )}
            <MenuList minWidth={"unset"} zIndex={10} py="0!important">
              {isActiveProjectInGroup && (
                <>
                  <ProjectMoveButton isDisabled={workflowStatus === "in_progress"} onClick={onOpenMoveProject} />
                  <OptionsMenuItem
                    isDisabled={isSharedRead || workflowStatus === "in_progress"}
                    menuLabel={"Add Source Content"}
                    className="ch-open-add-resource-panel"
                    onClick={onProjectAddSourceOpen}
                  />
                  {collectionResearch && (
                    <OptionsMenuItem menuLabel={"Search History"} className="ch-open-research-panel" onClick={onResearchHistoryPanelOpen} />
                  )}
                  <ProjectRerunCancelButton reRunProjectId={collectionId} />
                  <MenuDivider />
                </>
              )}
              <ProjectDeleteButton
                projectType="project"
                onOpenConfirmation={onDeleteProjectModalOpen}
                projectId={collectionId}
                isMenuItem
              />
            </MenuList>
          </Menu>
          <MoveProjectModal
            isOpen={isOpenMoveProject}
            onClose={() => {
              onCloseMoveProject();
            }}
            collectionId={collectionId}
            conversationId={collectionConversationId || uuid()}
          />
          {!isProjectReportPanelOpen && (
            <ProjectReportPanelNewReportModal
              onClose={onProjectReportSelectorClose}
              isOpen={isProjectReportSelectorOpen}
              collectionId={collectionId}
            />
          )}
        </Box>
      </Stack>
    </Stack>
  );
};
