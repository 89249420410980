import { useCallback, useEffect, useMemo, useState } from "react";
import type { CSSObject } from "@emotion/react";
import { useColorModeValue } from "@chakra-ui/react";

type ScrollbarStyle = CSSObject;

interface CustomScrollbarProps {
  width?: string;
  barTransparency?: number;
  direction?: "right" | "left";
  overflowY?: CSSObject["overflowY"];
}

export const useCustomScrollbar = (
  ref: React.RefObject<HTMLElement>,
  { width = "5px", barTransparency = 0.15, direction = "right", overflowY }: CustomScrollbarProps = {}
) => {
  const [isScrolling, setIsScrolling] = useState(false);
  const baseColor = useColorModeValue("100, 100, 100", "255, 255, 255");

  const isWindows = useMemo(() => /Windows/.test(navigator.userAgent), []);
  const isChrome = useMemo(() => /Chrome/.test(navigator.userAgent) && !/Edge/.test(navigator.userAgent), []);
  const isWindowsChrome = isWindows && isChrome;

  const handleScroll = useCallback(() => {
    setIsScrolling(true);
    const scrollTimer = setTimeout(() => setIsScrolling(false), 3000); // Extended timeout to 3 seconds
    return () => clearTimeout(scrollTimer);
  }, []);

  useEffect(() => {
    const container = ref.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);

      return () => {
        container.removeEventListener("scroll", handleScroll);
      };
    }
  }, [handleScroll, ref]);

  // Create scrollbar styles with platform-specific optimizations
  const scrollbarStyle = useMemo<ScrollbarStyle>(() => {
    // Base styles for all browsers
    const styles: ScrollbarStyle = {
      "--scrollbar-width": width,
      overflow: overflowY ? undefined : "auto",
      overflowY: overflowY || "unset",

      // Standard scrollbar styling (Firefox)
      scrollbarWidth: "thin",
      scrollbarColor: `rgba(${baseColor}, ${barTransparency}) transparent`,

      // Direction handling
      direction: direction === "left" ? "rtl" : "ltr",
      "& > *": {
        direction: "ltr",
      },
    };

    // Visibility condition - show only when scrolling
    const isVisible = isScrolling;

    // WebKit scrollbar styling (Chrome, Safari, Edge) - consistent width
    styles["&::-webkit-scrollbar"] = {
      width: "var(--scrollbar-width)",
      ...(direction === "left" && {
        left: 0,
      }),
      background: "transparent",
    };

    styles["&::-webkit-scrollbar-track"] = {
      width: "var(--scrollbar-width)",
      background: "transparent",
    };

    // Windows Chrome specific fixes
    if (isWindowsChrome) {
      styles["&::-webkit-scrollbar-track"] = {
        ...styles["&::-webkit-scrollbar-track"],
        borderRadius: "5px",
        marginTop: "2px",
        marginBottom: "2px",
        background: "transparent",
      };

      styles["&::-webkit-scrollbar-thumb"] = {
        width: "var(--scrollbar-width)",
        background: isVisible ? `rgba(${baseColor}, ${barTransparency * 1.5})` : "rgba(0, 0, 0, 0)",
        borderRadius: "5px",
        minHeight: "40px",
        transition: "background 0.5s ease-out, opacity 0.5s ease-out",
        opacity: isVisible ? 1 : 0,
      };
    } else {
      // Regular styling for other browsers
      styles["&::-webkit-scrollbar-thumb"] = {
        width: "var(--scrollbar-width)",
        background: isVisible ? `rgba(${baseColor}, ${barTransparency})` : "rgba(0, 0, 0, 0)",
        borderRadius: "5px",
        transition: "background 0.5s ease-out, opacity 0.5s ease-out",
        opacity: isVisible ? 1 : 0,
      };
    }

    // Firefox scrollbar styling
    styles.scrollbarWidth = "thin";
    styles.scrollbarColor = isVisible ? `rgba(${baseColor}, ${barTransparency}) transparent` : "transparent transparent";

    // No special hover styles since we only want scrollbar visible during scrolling
    styles["&:hover::-webkit-scrollbar-thumb"] = {
      background: isVisible ? `rgba(${baseColor}, ${isWindowsChrome ? barTransparency * 2 : barTransparency * 1.5})` : "rgba(0, 0, 0, 0)",
      opacity: isVisible ? 1 : 0,
    };

    // Hide scrollbar buttons
    styles["&::-webkit-scrollbar-button"] = {
      display: "none",
    };

    return styles;
  }, [baseColor, barTransparency, direction, isScrolling, isWindowsChrome, overflowY, width]);

  return { scrollbarStyle, isScrolling };
};
