import React, { useContext, useCallback } from "react";
import { Stack, useBreakpointValue, useColorModeValue, useDisclosure } from "@chakra-ui/react";
import type { Answer, Question } from "types/question";
import { AnswerStatus } from "types/question";
import { useAddToCharliContext } from "screens/panels/addToCharli/AddToCharliWizard/AddToCharliProvider";
import { ContentFilterContext } from "screens/content";
import { SmallActionVerificationBadge } from "screens/content/contentCanvas/cell/SmallActionVerificationBadge";
import { MarkupTextViewer } from "screens/content/contentView/sections/MarkupTextViewer";
import { BiCopy } from "react-icons/bi";
import { SmallActionButton } from "screens/content/contentCanvas/cell/SmallActionButton";
import { ProjectThumbHotlinks } from "./ProjectActions/ProjectThumbHotlinks";
import { useCollectionKey } from "hooks";
import { ShowMoreContainer } from "screens/common/components/ShowMoreContainer";

interface AnswerCardProps {
  answerRecord?: Answer;
  questionRecord?: Question;
  collectionId: string;
  conversationId: string;
  enableHelpPopover?: boolean;
}

const copyAnswer = (question?: Question) => {
  if (!question) return "";
  let answerText = "";
  if (question.question) {
    answerText += `Question: ${question.question}\r\n`;
  }
  if (question.answers && Array.isArray(question.answers)) {
    question.answers.forEach((answer) => {
      if (answer.answer) {
        answerText += `${answer.answer}\r\n`;
      }
    });
  }
  return answerText + "\r\n";
};

const noAnswerCopy =
  "Sorry but I couldn't find an answer to your question using the resources available in this project. Can you try asking that question another way?";

export const AnswerCardPinned: React.FC<React.PropsWithChildren<React.PropsWithChildren<AnswerCardProps>>> = ({
  answerRecord,
  questionRecord,
  collectionId,
  conversationId,
  enableHelpPopover,
}) => {
  const borderColor = useColorModeValue("gray.200", "gray.700");
  const { onVerifiedAIPanelOpen } = useAddToCharliContext();
  const { setSelectedResult, searchText } = useContext(ContentFilterContext);
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const shareDetails = useCollectionKey(collectionId, "shareDetails");
  const isSharedRead = shareDetails && shareDetails.accessMode === "read";

  const { isOpen: isThumbsUpOpen, onToggle: onThumbsUpToggle, onClose: onThumbsUpClose } = useDisclosure();
  const { isOpen: isThumbsDownOpen, onToggle: onThumbsDownToggle, onClose: onThumbsDownClose } = useDisclosure();

  const toggleThumbsUp = useCallback(() => {
    onThumbsUpToggle();
    onThumbsDownClose();
  }, [onThumbsUpToggle, onThumbsDownClose]);

  const toggleThumbsDown = useCallback(() => {
    onThumbsDownToggle();
    onThumbsUpClose();
  }, [onThumbsDownToggle, onThumbsUpClose]);

  const handleOnClick = useCallback(() => {
    setSelectedResult({
      questionText: questionRecord?.question || "",
      resultId: answerRecord?.id || questionRecord?.id || "",
      resultStatus: answerRecord?.answerStatus || questionRecord?.status || AnswerStatus.pinned,
      resultText: answerRecord?.answer || noAnswerCopy || "",
      confidence: answerRecord?.confidence || 0,
      attributionMetadataIds: answerRecord?.metadataIds || [],
      collectionId: collectionId,
      conversationId: conversationId,
      isQuestion: answerRecord?.answer && answerRecord?.answer.length > 0 ? false : true,
      verifiedStatus: answerRecord?.answer && answerRecord?.answer.length > 0 ? "verified" : "unverified",
      resultType: "answer",
      focus: questionRecord?.focus || "",
    });
  }, [answerRecord, questionRecord, collectionId, conversationId, setSelectedResult]);

  const renderSmallActionVerificationBadge = useCallback(() => {
    return (
      <SmallActionVerificationBadge
        className={`ch-qa-result-badge-${answerRecord?.answer && answerRecord?.answer.length > 0 ? "verified" : "unverified"}`}
        shieldColor={answerRecord?.answer && answerRecord?.answer.length > 0 ? "blue" : "gray"}
        shieldTooltip={
          answerRecord?.answer && answerRecord?.answer.length > 0
            ? "Verified by AI. Click to see the details."
            : "No answer available. Click to see the details."
        }
        onClick={() => {
          handleOnClick();
          onVerifiedAIPanelOpen();
        }}
      />
    );
  }, [answerRecord, handleOnClick, onVerifiedAIPanelOpen]);

  if (!answerRecord && !questionRecord) {
    return null;
  } else {
    return (
      <Stack>
        <Stack
          direction={isMobile ? "column" : "row"}
          justifyContent={"flex-start"}
          spacing="1rem"
          width="100%"
          px={isMobile ? ".5rem" : "1rem"}
          pt={isMobile ? ".5rem" : "1rem"}>
          {!isMobile && renderSmallActionVerificationBadge()}
          <ShowMoreContainer height="18rem" minHeight="7rem">
            <MarkupTextViewer
              className="ch-qa-result-answer"
              markupText={answerRecord?.answer && answerRecord?.answer.length > 0 ? answerRecord?.answer : noAnswerCopy}
              searchText={searchText}
            />
          </ShowMoreContainer>
        </Stack>
        <Stack
          spacing={isMobile ? ".5rem" : "1rem"}
          width="100%"
          px={isMobile ? ".5rem" : "1rem"}
          py={isMobile ? ".2rem" : ".5rem"}
          direction="row"
          borderTop="1px solid"
          borderColor={borderColor}
          align="center">
          {isMobile && renderSmallActionVerificationBadge()}
          <ProjectThumbHotlinks
            isDisabled={isSharedRead}
            action="up"
            resultId={answerRecord?.id}
            collectionId={collectionId}
            isOpen={isThumbsUpOpen}
            onToggle={toggleThumbsUp}
            onClose={onThumbsUpClose}
          />
          <ProjectThumbHotlinks
            isDisabled={isSharedRead}
            action="down"
            resultId={answerRecord?.id}
            collectionId={collectionId}
            isOpen={isThumbsDownOpen}
            onToggle={toggleThumbsDown}
            onClose={onThumbsDownClose}
          />
          <SmallActionButton
            isDisabled={isSharedRead}
            classNames="ch-copy-record"
            iconTypeName={BiCopy}
            onClick={() => navigator.clipboard.writeText(copyAnswer(questionRecord))}
            tooltip={"Copy Answer"}
          />
        </Stack>
      </Stack>
    );
  }
};
