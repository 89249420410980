import type { RefObject } from "react";
import React, { useState, useEffect, useMemo } from "react";
import { Box, Icon, useColorModeValue, usePrevious } from "@chakra-ui/react";
import { AutoCompleteList as ChakraAutoCompleteList } from "@choc-ui/chakra-autocomplete";
import { IoCloudOffline } from "react-icons/io5";
import { FilterButtons } from "./FilterButtons";
import { NoResultsMessage } from "./NoResultsMessage";
import { LoadingMessage } from "./LoadingMessage";
import { MessageContent } from "screens/common/components/MessageContent";
import { useCopyValue } from "hooks/useCopies";
import { useProjectParams } from "hooks";
import type { SuggestionTypes } from "./types";

interface AutoCompleteSuggestionListProps {
  showSuggestionList: boolean;
  suggestionListType?: SuggestionTypes;
  isWebsocketConnected?: boolean;
  didWebsocketPreviouslyConnect?: boolean;
  pathname: string;
  value?: string;
  activeFilter: string | null;
  setActiveFilter: (filter: string | null) => void;
  onClickFeedback: () => void;
  listRef: RefObject<HTMLDivElement>;
  filterContainerRef: RefObject<HTMLDivElement>;
  scrollbarStyle: any;
  marginLeftList?: string;
  minWidthList?: string;
  children: React.ReactNode;
  isLoading?: boolean;
}

export const AutoCompleteSuggestionList = React.memo(
  ({
    showSuggestionList,
    suggestionListType,
    isWebsocketConnected = false,
    pathname,
    value,
    activeFilter,
    setActiveFilter,
    onClickFeedback,
    listRef,
    filterContainerRef,
    scrollbarStyle,
    marginLeftList = "0!important",
    minWidthList = "32rem",
    children,
    isLoading = false,
  }: AutoCompleteSuggestionListProps) => {
    const [showNoResults, setShowNoResults] = useState(false);
    const inputBgColor = useColorModeValue("white", "gray.700");
    const disconnectedBgColor = useColorModeValue("#f9e4dc", "gray.800");
    const textColor = useColorModeValue("primary.darkGray", "gray.400");
    const didWebsocketPreviouslyConnect = usePrevious(isWebsocketConnected);
    const filterTypes = useCopyValue("copy_ticker_filter_options") as string[];
    const uniqueFilters = useMemo(() => filterTypes, [filterTypes]);
    const { parentRoute } = useProjectParams();

    useEffect(() => {
      let timeoutId: NodeJS.Timeout;

      if (!children && !isLoading) {
        timeoutId = setTimeout(() => {
          setShowNoResults(true);
        }, 500);
      } else {
        setShowNoResults(false);
      }

      return () => {
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
      };
    }, [children, isLoading, suggestionListType, isWebsocketConnected]);

    if (!showSuggestionList) {
      return null;
    }

    return (
      <ChakraAutoCompleteList
        position="relative"
        ref={listRef}
        css={scrollbarStyle}
        boxShadow="xl"
        _active={{}}
        _focus={{}}
        p="0"
        marginLeft={marginLeftList ? marginLeftList : ["unset", "unset", "3rem!important"]}
        marginTop="-3px!important"
        minWidth={["calc(100vw - 2rem)", "unset", minWidthList]}
        width="100%"
        maxHeight={parentRoute === "register" ? "320px" : ["250px", "350px", "350px"]}
        minHeight={suggestionListType === "tickers" ? "6.3rem" : "unset"}>
        {suggestionListType === "tickers" && uniqueFilters.length > 0 ? (
          <Box
            ref={filterContainerRef}
            className="ch-autocomplete-filter"
            position="fixed"
            zIndex={2}
            overflowX="auto"
            css={{
              "&::-webkit-scrollbar": {
                display: "none",
              },
              scrollbarWidth: "none",
            }}
            backgroundColor={inputBgColor}
            minHeight="2.4rem"
            minWidth={["calc(100vw - 2rem)", "unset", minWidthList]}
            width="100%">
            <Box px={"1rem"} py="8px">
              <FilterButtons uniqueFilters={uniqueFilters} activeFilter={activeFilter} setActiveFilter={setActiveFilter} />
            </Box>
          </Box>
        ) : (
          !isWebsocketConnected &&
          didWebsocketPreviouslyConnect && (
            <Box
              ref={filterContainerRef}
              className="ch-autocomplete-filter"
              position="fixed"
              zIndex={2}
              backgroundColor={disconnectedBgColor}
              width={["100%", "100%", `${pathname.includes("/register") ? "25.5rem" : "100%"}`]}>
              <Box p={"1rem"}>
                <Box color={textColor}>
                  <MessageContent
                    fontSize="sm"
                    message="Charli is trying to connect, requests can not be sent yet."
                    icon={<Icon as={IoCloudOffline} color="gray.600" boxSize="1.8rem" />}
                    moreDetails="Charli relies on standard and secure WebSockets for communication with the cloud services and the AI system. <br>Charli cannot currently connect with the cloud services, and this might be blocked due to your network firewall policies or ad blockers in your browser. Please check with your IT organization or network administrator to have WebSockets enabled for charliai.com.<br>You can also contact our <a href='https://support.charli.ai/hc/en-us/articles/33739429556877--Charli-is-trying-to-connect-information-message' target='_blank'>Customer Service</a> team for troubleshooting."
                  />
                </Box>
              </Box>
            </Box>
          )
        )}
        <Box
          mt={suggestionListType === "tickers" && uniqueFilters.length > 0 ? "2.4rem" : "-3px!important"}
          minHeight={suggestionListType === "tickers" && uniqueFilters.length > 0 ? "4.4rem" : "unset"}>
          {children}
          {!children && isLoading && <LoadingMessage />}
          {suggestionListType === "tickers" && showNoResults && !isLoading && (
            <NoResultsMessage value={value} activeFilter={activeFilter} onClickFeedback={onClickFeedback} />
          )}
        </Box>
      </ChakraAutoCompleteList>
    );
  }
);

export default AutoCompleteSuggestionList;
