import type { FunctionComponent } from "react";
import React from "react";
import { Divider, Stack, useColorModeValue } from "@chakra-ui/react";
import { TextOverflowTooltip } from "screens/landing/components/TextOverflowTooltip";

interface Props {
  text: string;
  titleMaxWidth?: string;
  color?: string;
  underline?: boolean;
  className?: string;
  tooltip?: string;
  [key: string]: unknown;
}

export const LandingTitle: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  text,
  titleMaxWidth = "20rem",
  color,
  underline = false,
  className,
  tooltip,
  children,
  ...styles
}) => {
  const buttonColor = useColorModeValue("gray.700", "gray.400");

  return (
    <Stack {...styles} className={className} width="100%">
      <Stack direction="row" width="100%" justifyContent={"space-between"} align="center">
        {text.length > 0 && (
          <Stack maxW={children ? titleMaxWidth : "100%"} width="100%">
            <TextOverflowTooltip
              label={text}
              tooltip={tooltip}
              color={color || buttonColor}
              fontSize="md"
              style={{
                fontWeight: 600,
                minWidth: "9rem",
                paddingLeft: ".5rem",
                paddingRight: ".5rem",
              }}
            />
            {underline && <Divider marginTop={"0!important"} borderColor={color || buttonColor} />}
          </Stack>
        )}
        {children}
      </Stack>
    </Stack>
  );
};
