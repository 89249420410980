import { useDispatch, useSelector } from "react-redux";
import type { RootState } from "state/rootReducer";
import type { EntitlementConfig } from "../api/entitlementConfigs/models/Entitlement";
import { useEffect } from "react";
import { downloadEntitlementConfigs } from "../state/entitlements/operations";

type UseEntitlementConfig = EntitlementConfig[];

export function useEntitlementConfigs(): UseEntitlementConfig {
  const dispatch = useDispatch();
  const entitlements = useSelector((state: RootState) => state.entitlement.entitlementConfigs);

  useEffect(() => {
    dispatch(downloadEntitlementConfigs());
  }, [dispatch]);

  return entitlements;
}

export function useEntitlementConfig(code: string | null): EntitlementConfig | undefined {
  return useSelector((state: RootState) => state.entitlement.entitlementConfigs.find((e) => e.code === code));
}

export function useIsEntitlementUpdating(): boolean {
  return useSelector((state: RootState) => state.entitlement.isLoading);
}
