import type { FunctionComponent } from "react";
import React from "react";
import { OptionsMenuItem } from "../../components/OptionsMenuItem";
import { SmallActionButton } from "screens/content/contentCanvas/cell/SmallActionButton";
import { RiShareForwardLine } from "react-icons/ri";

interface Props {
  isDisabled?: boolean;
  isIcon?: boolean;
  onClick: () => void;
}

export const ProjectMoveButton: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  isDisabled = false,
  isIcon = false,
  onClick,
}) => {
  return isIcon ? (
    <SmallActionButton
      classNames="ch-move-project"
      isDisabled={isDisabled}
      iconTypeName={RiShareForwardLine}
      tooltip="Move to Portfolio"
      iconSize={"1rem"}
      onClick={() => {
        onClick();
      }}
    />
  ) : (
    <OptionsMenuItem
      isDisabled={isDisabled}
      menuLabel="Move to Portfolio"
      className="ch-move-project"
      onClick={(event) => {
        event.stopPropagation();
        onClick();
      }}
    />
  );
};
