import { Box, Divider, useColorModeValue, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { DealTile } from "screens/dealfinder/components/DealTile";
import type { DealFinderTicker } from "api/tickers/models/DealFinderTicker";

interface DealWatchListGridProps {
  deals?: DealFinderTicker[];
}

export const DealWatchListGrid: React.FC<DealWatchListGridProps> = ({ deals }) => {
  const cardBgColor = useColorModeValue("white", "gray.900");
  const borderColor = useColorModeValue("gray.200", "gray.700");
  const textColor = useColorModeValue("primary.darkGray", "gray.400");

  return (
    <Box
      className="ch-deal-watch-list-grid"
      borderWidth="1px"
      borderRadius="lg"
      borderColor={borderColor}
      bgColor={cardBgColor}
      boxShadow="sm"
      overflow="hidden"
      width="100%"
      height="100%">
      <Text pt="1rem" px="1rem" pb="0" fontWeight="600" fontSize="md" lineHeight="1.4" color={textColor} mb={2}>
        Deals Watch List
      </Text>
      <VStack spacing={0} divider={<Divider borderColor={borderColor} m="0!important" />} align="stretch">
        {deals && deals.length > 0 ? (
          deals.map((deal) => (
            <Box key={`deal-${deal.id}`}>
              <DealTile deal={deal} displayOption="list" />
            </Box>
          ))
        ) : (
          <Box p="1rem" textAlign="center">
            <Text color={textColor}>No deals available</Text>
          </Box>
        )}
      </VStack>
    </Box>
  );
};
