import React, { useCallback, useMemo, useState } from "react";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import type { FieldArrayWithId } from "react-hook-form";
import type { FormValues } from "./UpsertInformationExtractionConfig";
import { AdminTiles } from "screens/common/components";
import { UpsertTargetEntityPanel } from "./UpsertTargetEntity";
import type { TargetEntityFormValues } from "./UpsertInformationExtractionConfig";
import { SectionHeader } from "screens/content/contentView/previewSection/SectionHeader";
import find from "lodash/find";
import capitalize from "lodash/capitalize";
import { addTargetEntities, editTargetEntity, removeTargetEntity } from "state/informationExtractionConfig/operations";
import { useAppDispatch } from "hooks";
import { useToast } from "@chakra-ui/react";
import { useIsLoadingInformationExtractionConfig } from "hooks/useInformationExtractionConfig";
import type { TargetEntity } from "api/informationExtractionConfig/models/TargetEntity";
import type { ResourceWithId } from "state/informationExtractionConfig/reducer";
import { validateExactMatching } from "../InformationExtractionConfig";
import { v4 as uuid } from "uuid";

export const TargetEntitiesFieldArray = ({ resource }: { resource?: ResourceWithId }) => {
  const { control } = useFormContext<FormValues>();
  const [isOpen, setIsOpen] = useState<"upsert" | "delete" | null>();
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const {
    fields: uncontrolledFields,
    append,
    remove,
    update,
  } = useFieldArray({
    name: "entities",
    control,
  });

  const watchedFields = useWatch({ name: "entities", control });
  const fields = useMemo(
    () =>
      uncontrolledFields.map((field, index) => {
        return {
          ...field,
          ...watchedFields[index],
        };
      }),
    [watchedFields, uncontrolledFields]
  );

  /*
  fields indexes doesn't change when the list is reordered.
  We use this map to get the index of the field by its id
  */
  const fieldsIndexMap = useMemo(
    () =>
      fields.reduce((acc, field, index) => {
        acc.set(field.id, index);
        return acc;
      }, new Map<string, number>()),
    [fields]
  );

  const isLoading = useIsLoadingInformationExtractionConfig();

  const dispatch = useAppDispatch();

  const [selectedEntity, setSelectedEntity] = useState<TargetEntityFormValues | null>(null);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const toast = useToast();

  const resourceUrn = useWatch({ name: "urn", control });

  const onClose = useCallback(() => {
    setIsOpen(null);
    setSelectedId(null);
    setSelectedIndex(null);
  }, []);

  const onOpenUpsert = useCallback((id?: string) => {
    if (id) {
      setSelectedId(id);
    } else {
      setSelectedIndex(null);
      setSelectedEntity(null);
    }

    setIsOpen("upsert");
  }, []);

  const onSubmitTargetEntity = useCallback(
    async (entity: TargetEntityFormValues) => {
      const actionResponse = await (async () => {
        if (resource) {
          const response = await (async () => {
            const entityConfidenceThreshold = Number(entity.confidenceThreshold);

            // If the entity already exists, we don't need to add it again
            // We don't use "id" because is autogenerated by the form
            // and may defer from the "entityId" that is the one that we need to check
            const commonPayloadData = {
              entityName: entity.entityName,
              entityType: entity.entityType.split(","),
              keywords: entity.keywords ? entity.keywords.split("\n") : undefined,
              pattern: entity.pattern ?? undefined,
              categories: entity.categories ? JSON.parse(entity.categories) : undefined,
              occurrence: entity.occurrence,
              allowMultipleValues: entity.allowMultipleValues,
              titleKeywords: entity.titleKeywords ? entity.titleKeywords.split("\n") : undefined,
              negativeKeywords: entity.negativeKeywords ? entity.negativeKeywords.split("\n") : undefined,
              confidenceThreshold:
                entityConfidenceThreshold === 0 || entityConfidenceThreshold === resource.confidenceThreshold
                  ? null
                  : entityConfidenceThreshold,
              exactMatching: validateExactMatching(entity.entityType, entity.exactMatching),
            };

            if (entity.entityId) {
              return dispatch(
                editTargetEntity({
                  id: entity.entityId,
                  payload: commonPayloadData,
                })
              );
            } else {
              return dispatch(
                addTargetEntities([
                  {
                    id: uuid(),
                    resourceUrn: resourceUrn,
                    ...commonPayloadData,
                    confidenceThreshold: commonPayloadData.confidenceThreshold ?? undefined,
                  },
                ])
              );
            }
          })();

          if (response.type === addTargetEntities.rejected.type || response.type === editTargetEntity.rejected.type) {
            toast({
              title: "Error",
              description: "Something went wrong while creating / updating the entity.",
              status: "error",
              duration: 3000,
              isClosable: true,
            });

            return { success: false, targetEntity: null };
          }

          if (response.type === addTargetEntities.fulfilled.type) {
            return { success: true, targetEntity: (response.payload as TargetEntity[])[0] };
          } else {
            return { success: true, targetEntity: response.payload as TargetEntity };
          }
        } else {
          return { success: null, targetEntity: null };
        }
      })();

      // If success is false, it means that the endpoint
      // was called and returned an error
      if (actionResponse.success === false) {
        return;
      }

      const { targetEntity: maybeTargetEntity } = actionResponse;

      if (selectedIndex !== null) {
        update(selectedIndex, {
          ...entity,
          lastUpdatedDate: maybeTargetEntity?.lastUpdatedDate ? new Date(maybeTargetEntity.lastUpdatedDate) : new Date(),
          confidenceThreshold: maybeTargetEntity?.confidenceThreshold ?? undefined,
        });
      } else {
        append({
          ...entity,
          ...(maybeTargetEntity && {
            entityId: maybeTargetEntity.id,
            lastUpdatedDate: new Date(maybeTargetEntity.lastUpdatedDate),
            confidenceThreshold: maybeTargetEntity.confidenceThreshold ?? undefined,
          }),
        });
      }

      onClose();
    },
    [selectedIndex, onClose, resource, dispatch, resourceUrn, toast, update, append]
  );

  const onDelete = useCallback(
    async (id: string, index: number) => {
      if (resource) {
        const entity = find(fields, (field) => field.id === id);
        if (entity?.entityId) {
          const response = await dispatch(removeTargetEntity(entity.entityId));

          if (response.type === removeTargetEntity.rejected.type) {
            toast({
              title: "Error",
              description: "Something went wrong while deleting the entity.",
              status: "error",
              duration: 3000,
              isClosable: true,
            });

            return;
          }
        }
      }

      remove(index);
    },
    [resource, fields, toast, remove, dispatch]
  );

  const onOpenEdit = useCallback(
    (id: string) => {
      const index = fieldsIndexMap.get(id);

      if (index !== undefined) {
        setSelectedIndex(index);
      }

      const entity = find(fields, (field) => field.id === id);

      if (entity) {
        setSelectedEntity(entity);
      }

      onOpenUpsert(id);
    },
    [fields, fieldsIndexMap, onOpenUpsert]
  );

  return (
    <>
      <SectionHeader title="Entities" />
      <AdminTiles<FieldArrayWithId<FormValues, "entities", "id">>
        items={fields}
        fieldsToRender={["entityType"]}
        customFields={[
          {
            field: "Confidence Threshold",
            value: ({ confidenceThreshold }) => (confidenceThreshold ? String(confidenceThreshold) : "Global"),
          },
          { field: "Occurrence", value: ({ occurrence }) => capitalize(occurrence.replaceAll(/_/g, " ")) },
        ]}
        filterByFields={["entityName", "entityType", "keywords", "categories", "pattern"]}
        sortByFields={["lastUpdatedDate"]}
        defaultSortByKey="lastUpdatedDate"
        inputFilterPlaceholder="Filter by name, type, keywords, categories or pattern"
        onClickCreate={() => onOpenUpsert()}
        onClickDelete={onDelete}
        onClickEdit={onOpenEdit}
        hasWrite
        hasRead
        tileTitle={"Entity"}
        tileTitleKey="entityName"
        keyName="id"
        isLoading={isLoading}
      />

      {isOpen === "upsert" && (
        <UpsertTargetEntityPanel
          {...(selectedEntity && { initialValues: selectedEntity })}
          id={selectedId}
          isOpen
          onClose={onClose}
          onSubmit={onSubmitTargetEntity}
        />
      )}
    </>
  );
};
