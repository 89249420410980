import {
  Box,
  Text,
  Stack,
  useToast,
  useBreakpointValue,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useCallback, useContext } from "react";
import { ConversationContext } from "screens/thread/ConversationContext";
import { getStatusColor } from "screens/common/components/WorkflowSummary";
import { ToastMessageContent } from "screens/common/components";
import {
  useAllProjectConfigsIntents,
  useCollectionKey,
  useCollectionWorkflowIdInProgress,
  useLatestCollectionWorkflowId,
  useMainCollectionWorkflowId,
} from "hooks";
import { useWorkflowCompletionDateOrNow, useWorkflowKey } from "hooks/useWorkflows";
import { formatDistanceToNow } from "date-fns";
import { WorkflowProgressBar } from "./WorkflowProgressBar";
import { WorkflowStatusIcon } from "screens/common/components/WorkflowStatusIcon";
import { MilestonesStepper } from "./MilestonesStepper";
import { useWorkflowScheduleByProject, useCancelWorkflowSchedule } from "hooks/useWorkflowSchedules";

export interface ContainerProps {
  collectionId: string;
  size?: "xs" | "sm" | "md";
  isProjectView?: boolean;
  maxWidth?: string;
  hideProgressBar?: boolean;
  hasOneTimeSchedule?: boolean;
  oneTimeScheduleDesiredExecutionDate?: string | null | undefined;
}

interface WorkflowProgressStepperProps extends ContainerProps {
  conversationId?: string;
  hasOneTimeSchedule?: boolean;
  oneTimeScheduleDesiredExecutionDate?: string | null | undefined;
}

const WorkflowProgressStepper = (props: WorkflowProgressStepperProps) => {
  const { collectionId, isProjectView, hideProgressBar = true, maxWidth, hasOneTimeSchedule, oneTimeScheduleDesiredExecutionDate } = props;
  const { onConversationOpen } = useContext(ConversationContext);
  const toast = useToast();
  const collectionConversationId = useCollectionKey(collectionId, "conversationId");
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const currentWorkflowId = useLatestCollectionWorkflowId(collectionId);
  const mainWorkflowId = useMainCollectionWorkflowId(collectionId);
  const currentWorkflowCompletionDate = useWorkflowCompletionDateOrNow(currentWorkflowId);
  const inProgressWorkflowId = useCollectionWorkflowIdInProgress(collectionId);
  const iconColor = useColorModeValue("200", "800");
  const workflowSchedule = useWorkflowScheduleByProject(collectionId);
  const cancelWorkflowSchedule = useCancelWorkflowSchedule();

  const onCancelSchedule = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();

      cancelWorkflowSchedule(workflowSchedule, collectionId, collectionConversationId);

      toast.closeAll();
      toast({
        render: ({ onClose }) => (
          <ToastMessageContent
            message="Cancelling the projects scheduled rerun now."
            onClick={() => {
              onConversationOpen();
              onClose();
            }}
            onClose={onClose}
          />
        ),
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    },
    [workflowSchedule, collectionId, collectionConversationId, cancelWorkflowSchedule, toast, onConversationOpen]
  );

  return (
    <Stack className="ch-workflow-stepper" direction="row" justifyContent={"space-between"} spacing="2rem" width="100%">
      <Stack position={"relative"} alignItems={"center"} direction="row" justifyContent={"end"}>
        {hasOneTimeSchedule && (
          <Popover isLazy trigger="hover" placement="top">
            <PopoverTrigger>
              <Box
                onClick={(event) => onCancelSchedule(event)}
                p="3px"
                borderRadius={"full"}
                backgroundColor={`${getStatusColor("scheduled")}.${iconColor}`}
                border="none"
                height={isProjectView ? "2rem" : "unset"}
                className="ch-workflow-step-scheduled"
                textAlign="left"
                cursor="pointer">
                <Stack direction="row" justifyContent="left" alignItems="left">
                  <WorkflowStatusIcon status={"scheduled"} />
                </Stack>
              </Box>
            </PopoverTrigger>
            <PopoverContent style={{ position: "absolute", top: "30px" }}>
              <PopoverBody>
                <Stack>
                  <Box onClick={(event) => onCancelSchedule(event)} pl="1rem" borderLeft="1px solid #718096">
                    <Stack direction="row" align="center">
                      <Text fontSize="xs" as="em">
                        Scheduled : Click to cancel
                      </Text>
                    </Stack>
                    <Stack direction="row" align="center">
                      <Text fontSize="xs" as="em">{`This project is scheduled to run at
                            ~${
                              oneTimeScheduleDesiredExecutionDate && new Date(oneTimeScheduleDesiredExecutionDate).toLocaleTimeString()
                            }`}</Text>
                    </Stack>
                  </Box>
                </Stack>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        )}
        {mainWorkflowId && (
          <MilestonesStepper
            hasOneTimeSchedule={hasOneTimeSchedule}
            collectionId={collectionId}
            workflowId={mainWorkflowId}
            isProjectView={!!isProjectView}
          />
        )}
      </Stack>
      {!isMobile && inProgressWorkflowId && !hideProgressBar && (
        <Stack direction={"row"} spacing="1rem" justifyContent={"end"} width="100%">
          <WorkflowProgressBar
            maxWidth={maxWidth}
            workflowId={inProgressWorkflowId}
            size="compact"
            lastUpdated={formatDistanceToNow(new Date(currentWorkflowCompletionDate), {
              addSuffix: true,
              includeSeconds: false,
            })}
            hideCompletedInDate
            conversationId={collectionConversationId}
          />
        </Stack>
      )}
    </Stack>
  );
};

export const WorkflowProgressStepperContainer = (props: ContainerProps) => {
  const { collectionId } = props;

  const includedIntentsForStepper = useAllProjectConfigsIntents();
  const mainWorkflowId = useMainCollectionWorkflowId(collectionId, includedIntentsForStepper);
  const conversationId = useWorkflowKey(mainWorkflowId, "conversationId");

  return <WorkflowProgressStepper {...props} conversationId={conversationId} />;
};
