import { Box, useBreakpointValue, Stack, useColorModeValue, Center } from "@chakra-ui/react";
import React, { useContext, useEffect, useMemo, useCallback, useRef } from "react";
import { TabTitle } from "screens/common/components/TabTitle";
import {
  filterCollectionsByType,
  useCollections,
  useConfigMap,
  useDealFinderTickers,
  useIsInitialCollectionSyncCompleted,
  useIsMainAppLoading,
  useMenuConfig,
} from "hooks";
import { SettingsProviderContext } from "screens/panels/settings/SettingsProvider";
import { useConversationContext } from "screens/thread/ConversationContext";
import { useAddToCharliContext } from "screens/panels/addToCharli/AddToCharliWizard/AddToCharliProvider";
import { HomeSkeleton } from "./HomeSkeleton";
import { ScrollingTickers } from "screens/common/components/ScrollingTickers";
import groupBy from "lodash/groupBy";
import { ProjectWatchListGrid } from "./ProjectWatchList/ProjectWatchListGrid";
import { FloatingDialogModal } from "components/FloatingDialogModal";
import { NewsTilesSection } from "./NewsTilesSection";
import { generateQuadrantData } from "../collections/chartComponents/data/quadrantData";
import { HomeQuadrantChart } from "./HomeQuadrantChart";
import { DealWatchListGrid } from "./DealWatchList/DealWatchListGrid";
import type { DealFinderTicker } from "api/tickers/models/DealFinderTicker";

export const HomeContent = () => {
  const { filteredProjectsIds, setFilteredProjectsIds } = useConversationContext();
  const isMobile = useBreakpointValue({ base: true, md: false, lg: false }, { fallback: "md", ssr: false });
  const isTablet = useBreakpointValue({ base: false, md: true, lg: false }, { fallback: "md", ssr: false });
  const { setHeaderText } = useContext(SettingsProviderContext);
  const { onConversationClose, isOnboardingModalOpen, isDialogOpen, onDialogClose } = useConversationContext();
  const { setActionView } = useAddToCharliContext();
  const bgSectionsColor = useColorModeValue("#fbfbfb", "gray.800");
  const collections = useCollections();
  const configMap = useConfigMap();
  const menuConfig = useMenuConfig(configMap, "all", false);
  const isInitialCollectionSyncCompleted = useIsInitialCollectionSyncCompleted();
  const isMainAppLoading = useIsMainAppLoading();
  const addTickerButtonRef = useRef<HTMLButtonElement>(null);
  const { getDealFinderTickers } = useDealFinderTickers();
  const [deals, setDeals] = React.useState<DealFinderTicker[]>([]);

  // Fetch deals using getDealFinderTickers with limit=10
  useEffect(() => {
    const fetchDeals = async () => {
      try {
        const fetchedDeals = await getDealFinderTickers({
          limit: 7,
        });
        setDeals(fetchedDeals);
      } catch (error) {
        console.error("Error fetching deals for watch list:", error);
      }
    };

    fetchDeals();
  }, [getDealFinderTickers]);

  const filterCollectionsByPortfolio = useCallback(
    (isFiltered: boolean) => {
      const portfolioCategory = menuConfig.filter((menuItem) => !!menuItem.config.portfolioProjectType);
      const portfolioCategoryTypes = portfolioCategory.map((menuItem) => menuItem.config.collectionType);

      if (!collections) {
        return [];
      }

      return collections.filter((collection) =>
        isFiltered
          ? portfolioCategoryTypes.includes(collection.collectionType)
          : !portfolioCategoryTypes.includes(collection.collectionType)
      );
    },
    [collections, menuConfig]
  );

  useEffect(() => {
    onConversationClose();
    setActionView(undefined);
    setHeaderText("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);

  useEffect(() => {
    const newFilteredProjects = filterCollectionsByPortfolio(false);
    setFilteredProjectsIds(newFilteredProjects.map((project) => project.id));
  }, [filterCollectionsByPortfolio, setFilteredProjectsIds]);

  const filteredCollections = useMemo(() => filterCollectionsByType(collections, "due_diligence"), [collections]);
  const filterCollectionGroups = useMemo(() => {
    const groupedCollections = groupBy(filteredCollections, (collection) => collection.projectGroupId || collection.id);
    return Object.values(groupedCollections).map((group) => {
      const groupCount = group.length;
      const groupName = group[0].name;
      const groupType = group[0].collectionType;

      return {
        id: group[0].id,
        groupName,
        groupType,
        groupCount,
      };
    });
  }, [filteredCollections]);
  const quadrantData = useMemo(() => generateQuadrantData(filteredCollections.slice(0, 10)), [filteredCollections]);

  if (isMainAppLoading || !isInitialCollectionSyncCompleted) {
    return <HomeSkeleton />;
  }
  if (!filteredProjectsIds || isOnboardingModalOpen) {
    return <HomeSkeleton />;
  }

  return (
    <>
      <Box height="100%">
        <Center py={isMobile ? "1rem" : "2rem"}>
          <ScrollingTickers />
        </Center>
        <Stack
          pb="2rem"
          pl={isMobile || isTablet ? ".5rem" : "1rem"}
          pr={isMobile || isTablet ? ".5rem" : "1rem"}
          width="100%"
          direction={isMobile ? "column" : "row"}
          justifyContent="space-between"
          bgColor={bgSectionsColor}
          spacing={isMobile || isTablet ? "1rem" : "2rem"}>
          <Stack width={"100%"} spacing={isMobile || isTablet ? "1rem" : "2rem"} height={"100%"}>
            <HomeQuadrantChart quadrantData={quadrantData} />
            {filterCollectionGroups && filterCollectionGroups.length > 0 && (
              <ProjectWatchListGrid filterCollectionGroups={filterCollectionGroups.slice(0, 10)} />
            )}
          </Stack>
          <Stack width="100%" spacing={isMobile || isTablet ? "1rem" : "2rem"} height={"100%"}>
            {deals && deals.length > 0 && <DealWatchListGrid deals={deals} />}
            <NewsTilesSection />
          </Stack>
        </Stack>
      </Box>
      <TabTitle title="Charli > Home" />
      <FloatingDialogModal isOpen={isDialogOpen} onClose={onDialogClose} buttonRef={addTickerButtonRef} />
    </>
  );
};
