import React, { useContext, useEffect, useRef, useMemo, useState, useCallback } from "react";
import { Box, Center, Stack, useBreakpointValue, useColorModeValue, Text, Container } from "@chakra-ui/react";
import { DEFAULT_SIDEBAR_WIDTH, SidebarNavigation } from "./components";
import { HeaderInteractionBar } from "screens/common/components";
import { useSettingContext } from "../panels/settings/SettingsProvider";
import { Outlet, useLocation } from "react-router-dom";
import { TypingIndicator } from "screens/thread/components/cells/components";
import {
  useIsMainAppLoading,
  useProjectConfigEntitlements,
  useSidebarNavigation,
  useUserPreference,
  useGetViewConfig,
  useConfigMap,
  useProjectParams,
  DeleteCollectionModal,
  useDeleteCollection,
  useCollectionKey,
  useEntitlementKey,
} from "hooks";
import { ConversationContext } from "screens/thread/ConversationContext";
import { v4 as uuid } from "uuid";
import { useAddToCharliContext } from "screens/panels/addToCharli/AddToCharliWizard/AddToCharliProvider";
import { ProjectDetailDefaultViewHeader } from "screens/collection/views/ProjectDetailDefaultViewHeader";
import { EquityFeedbackModal } from "screens/thread/components/useEquityFeedback";
import { useCopyValue } from "hooks/useCopies";
import { InfoOutlineIcon } from "@chakra-ui/icons";
import { AIUpdatedModal } from "./components/popoverComponent/AIUpdatedModal";
import { useCustomScrollbar } from "hooks/useCustomScrollbar";
import { SharePortfolioModal } from "screens/collection/views/ProjectActions/SharePortfolioModal";
import { ConversationDialogInput } from "screens/thread/ConversationDialogInput";

export const PROJECT_DETAIL_SECTION_SELECTOR = "project-detail-section";
export const HEADER_HEIGHT = 56;
export const HEADER_ICON_HEIGHT = 28;
export const APP_CONTAINER_WIDTH = "1130px";

export const Landing: React.FC = () => {
  const { parentRoute, projectFilter, projectId, contentId } = useProjectParams();
  const { pathname } = useLocation();
  const { isSideNavigationOpen, setIsSideNavigationOpen } = useSettingContext();
  const bgColor = useColorModeValue("#fbfbfb", "gray.800");
  const sidebarBgColor = useColorModeValue("transparent", "gray.900");
  const sidebarBorderColor = useColorModeValue("rgba(61, 61, 61, 0.25)", "rgba(255, 255, 255, 0.25)");
  const isMobile = useBreakpointValue({ base: true, md: false, lg: false }, { fallback: "md", ssr: false });
  const isTablet = useBreakpointValue({ base: false, md: true, lg: false }, { fallback: "md", ssr: false });
  const isMainAppLoading = useIsMainAppLoading();
  const appCountainerRef = useRef<HTMLDivElement>(null);
  const { scrollbarStyle } = useCustomScrollbar(appCountainerRef, { width: "2px", overflowY: "auto" });

  const conversationContext = useContext(ConversationContext);
  const {
    onConversationOpen,
    setConversationId,
    conversationId,
    isAnotherInputFocused,
    isEditing,
    isConversationOpen,
    setShowCommands,
    initialQuestionText,
    onAIUpdatedModalOpen,
    isSharePortfolioModalOpen,
    onSharePortfolioModalClose,
    isPanelOpen,
  } = conversationContext;

  const { currentSidebarType } = useSidebarNavigation();
  const sidebarWidth = (useUserPreference("ui_sidebar_width") as number) || (DEFAULT_SIDEBAR_WIDTH as number);

  const { isAdminPanelOpen, isAddNewProjectPanelOpen, isAddToCollectionModalOpen } = useAddToCharliContext();
  const { due_diligence_project: hasDueDiligence } = useProjectConfigEntitlements();
  const configMap = useConfigMap();

  const maybePortfolioProjectCollectionType = useCollectionKey(projectFilter, "collectionType");
  const maybePortfolioProjectConversationId = useCollectionKey(projectFilter, "conversationId");
  const portfolioCollectionType = useGetViewConfig("portfolioProjectType", maybePortfolioProjectCollectionType, configMap);
  const copyTickerPlaceholder = useCopyValue("copy_ticker_placeholder_text");
  const shareDetails = useCollectionKey(projectId, "shareDetails");
  const isSharedRead = shareDetails && shareDetails.accessMode === "read";

  const collectionType = useMemo(() => {
    if (portfolioCollectionType && portfolioCollectionType.length > 0) return portfolioCollectionType;
    if (parentRoute === "home" && hasDueDiligence) return "due_diligence";
    return projectFilter;
  }, [portfolioCollectionType, parentRoute, hasDueDiligence, projectFilter]);

  const isDueDiligence = useMemo(
    () => portfolioCollectionType === "due_diligence" || collectionType === "due_diligence",
    [portfolioCollectionType, collectionType]
  );

  const showQAinputProjectDetail = useGetViewConfig("projectDetailQAInput", collectionType, configMap);
  const { onDeleteCollection } = useDeleteCollection();
  const isEmbeddingModelVersionOutdated = useCollectionKey(projectId, "isEmbeddingModelVersionOutdated");
  const copyMaintenanceBanner = useCopyValue("copy_maintenance_banner");
  const copyBgColor = useCopyValue("copy_maintenance_banner_bg_color");
  const copyTextColor = useCopyValue("copy_maintenance_banner_text_color");
  const [currentPathname, setCurrentPathname] = useState(pathname);
  const hasCommandLineEntitlement = useEntitlementKey("ui_enable_command_suggestions");

  // TODO look at this scroll to top logic
  const scrollToTop = useCallback(() => {
    const container = appCountainerRef.current;
    if (
      container &&
      !isConversationOpen &&
      !pathname.includes("/panel") &&
      (initialQuestionText || projectId || currentPathname !== pathname)
    ) {
      setCurrentPathname(pathname);
      container.scroll({ top: 0, behavior: "smooth" });
    }
  }, [projectId, initialQuestionText, isConversationOpen, pathname, currentPathname]);

  useEffect(() => {
    scrollToTop();
  }, [scrollToTop]);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (
        event.key === "/" &&
        !isConversationOpen &&
        hasCommandLineEntitlement &&
        !isAdminPanelOpen &&
        !isAddNewProjectPanelOpen &&
        !isAddToCollectionModalOpen &&
        !isAnotherInputFocused &&
        !isEditing &&
        !isPanelOpen
      ) {
        if (!conversationId) {
          setConversationId(maybePortfolioProjectConversationId || contentId || uuid());
        }
        setShowCommands(true);
        onConversationOpen();
      }
    },
    [
      isConversationOpen,
      hasCommandLineEntitlement,
      isAdminPanelOpen,
      isAddNewProjectPanelOpen,
      isAddToCollectionModalOpen,
      isAnotherInputFocused,
      isEditing,
      isPanelOpen,
      conversationId,
      setShowCommands,
      onConversationOpen,
      setConversationId,
      maybePortfolioProjectConversationId,
      contentId,
    ]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [handleKeyDown]);

  const projectNewInputHeight = useMemo(() => {
    if (parentRoute === "home") return undefined;
    if (!showQAinputProjectDetail?.showInput && projectId) return "0";
    if (isMobile || isTablet) return projectId && !pathname.includes("/panel") ? "6rem" : "13rem";
    return projectId && !pathname.includes("/panel") ? "9.3rem" : "16rem";
  }, [isMobile, isTablet, parentRoute, pathname, projectId, showQAinputProjectDetail?.showInput]);

  const renderMaintenanceBanner = useMemo(() => {
    if (!copyMaintenanceBanner.length) return null;
    return (
      <Center width="100%" bgColor={copyBgColor}>
        <Stack px="2rem" py="1rem" direction="row" alignItems="center" justifyContent="center" spacing="1rem">
          <InfoOutlineIcon color={copyTextColor} height="1.5em" />
          <Text fontSize="md" fontWeight="semibold" color={copyTextColor}>
            {copyMaintenanceBanner}
          </Text>
        </Stack>
      </Center>
    );
  }, [copyMaintenanceBanner, copyBgColor, copyTextColor]);

  const renderAIUpdateBanner = useMemo(() => {
    if (!(projectId && collectionType === "due_diligence" && isEmbeddingModelVersionOutdated)) return null;
    return (
      <Center width="100%" bgColor="charli.primaryGreen" cursor="pointer" onClick={onAIUpdatedModalOpen}>
        <Text px="2rem" py="1rem" textAlign="center" fontSize="md" fontWeight="normal" color="white">
          Notice: The AI Models have been updated since this project and ticker symbol was analyzed. If you ask questions, Charli will
          re-analyze the source material.
        </Text>
      </Center>
    );
  }, [projectId, collectionType, isEmbeddingModelVersionOutdated, onAIUpdatedModalOpen]);

  const renderProjectHeader = useMemo(() => {
    const excludedPaths = ["/panel", "/search", "/pricing"];
    if (!(projectId && projectId.length > 0 && !excludedPaths.some((path) => pathname.includes(path)))) return null;
    return (
      <Box pt="1rem" pl={isMobile || isTablet ? ".5rem" : "1rem"} pr={isMobile || isTablet ? ".5rem" : "1rem"}>
        <Box className={PROJECT_DETAIL_SECTION_SELECTOR} height="4rem">
          <ProjectDetailDefaultViewHeader collectionId={projectId} />
        </Box>
      </Box>
    );
  }, [projectId, isMobile, isTablet, pathname]);

  const renderProjectInput = useMemo(() => {
    const includePaths = ["/projects"];
    if (isDueDiligence || !includePaths.some((path) => pathname.includes(path))) return null;
    return (
      <Center width="100%" height={projectNewInputHeight} px="0">
        <Box width="100%">
          <ConversationDialogInput
            disableComponent={isSharedRead}
            tooltip={isSharedRead ? "Questions can not be asked in shared projects" : undefined}
            minWidth="unset"
            inputId={"view-input"}
            className="ch-question-input ch-autocomplete-input-project-detail ch-question-input-conversation-dialog"
            initialText={projectId && !pathname.includes("/panel") ? "Ask me a question about this project" : copyTickerPlaceholder}
            renderRole="embedded"
          />
        </Box>
      </Center>
    );
  }, [isDueDiligence, projectNewInputHeight, isSharedRead, projectId, pathname, copyTickerPlaceholder]);

  return (
    <Box width="100vw" height="100vh" px={0} position="relative">
      <Box position="sticky" top="0" zIndex="10">
        <HeaderInteractionBar showTickerInput={(isDueDiligence && !projectId) || parentRoute === "deal-finder"} />
      </Box>
      <Stack
        direction="row"
        spacing="0"
        height="100%"
        width="100%"
        justifyContent="space-between"
        maxHeight={`calc(100vh - ${HEADER_HEIGHT}px)`}>
        {currentSidebarType === "hidden" && isSideNavigationOpen && (
          <Box
            position="fixed"
            top={`${HEADER_HEIGHT}px`}
            left="0"
            right="0"
            bottom="0"
            backgroundColor="transparent"
            zIndex="9999"
            onClick={() => setIsSideNavigationOpen(false)}
          />
        )}
        <Box height="100%">
          {currentSidebarType === "hidden" ? (
            <Box
              position="absolute"
              top={`${HEADER_HEIGHT}px`}
              left="0"
              height={`calc(100vh - ${HEADER_HEIGHT - 1}px)`}
              width={isSideNavigationOpen ? ["100vw", `${sidebarWidth}px`, `${sidebarWidth}px`] : "0"}
              zIndex="10000"
              overflow="hidden"
              backgroundColor={sidebarBgColor}
              borderRight={isSideNavigationOpen ? `1px solid ${sidebarBorderColor}` : "none"}
              onClick={(e) => e.stopPropagation()}>
              {isSideNavigationOpen && (
                <Box height="100%" width="100%" position="relative">
                  <Box position="absolute" top="0" right="0" bottom="0" width="100%" height="100%" overflow="hidden">
                    <SidebarNavigation />
                  </Box>
                </Box>
              )}
            </Box>
          ) : (
            <SidebarNavigation />
          )}
        </Box>
        <Box width="100%" position="relative" css={scrollbarStyle} overflowX="hidden">
          <Container
            width={!isTablet && !isMobile ? APP_CONTAINER_WIDTH : "100%"}
            maxWidth={!isTablet && !isMobile ? APP_CONTAINER_WIDTH : "100vw"}
            px={0}
            position="relative">
            <Stack spacing="0" width="100%" height="100%" mt="0!important">
              {renderMaintenanceBanner}
              {renderAIUpdateBanner}
              <Box
                className="ch-landing"
                ref={appCountainerRef}
                mt="0!important"
                backgroundColor={bgColor}
                paddingRight="0px"
                position="relative">
                {isMainAppLoading ? (
                  <Center backgroundColor={bgColor} zIndex={100} width="100%" height={`calc(100vh - ${HEADER_HEIGHT - 1}px)`}>
                    <TypingIndicator />
                  </Center>
                ) : (
                  <>
                    {renderProjectHeader}
                    {renderProjectInput}
                    <Outlet />
                  </>
                )}
              </Box>
            </Stack>
          </Container>
        </Box>
      </Stack>
      <DeleteCollectionModal onDeleteCollection={onDeleteCollection} />
      <EquityFeedbackModal />
      <AIUpdatedModal />
      <SharePortfolioModal isOpen={isSharePortfolioModalOpen} onClose={onSharePortfolioModalClose} conversationId={uuid()} />
    </Box>
  );
};
