import { Box, Image, Stack, Flex, useColorModeValue, useTheme, Center } from "@chakra-ui/react";
import React, { useCallback } from "react";
import type { DealTileProps } from "../../../types/dealfinder";
import { TextOverflowTooltip } from "screens/landing/components/TextOverflowTooltip";
import { InvestmentDetailModal } from "./modals/InvestmentDetailModal";
import { ShowMoreContainer } from "screens/common/components/ShowMoreContainer";
import { DealActionButtons } from "./DealActionButtons";
import { useInvestmentDetailModal } from "../../../hooks/useInvestmentDetailModal";
import { useTileProps, useUserPreference } from "hooks";
import { MarkdownViewer } from "screens/markdown/MarkdownViewer";

export const DealTile: React.FC<DealTileProps> = ({ deal, displayOption = "tile" }) => {
  const borderColor = useColorModeValue("gray.300", "gray.600");
  const bgColor = useColorModeValue("white", "gray.700");
  const hoverBgColor = useColorModeValue("gray.50", "gray.700");
  const { currentDealId, openModal, closeModal } = useInvestmentDetailModal();
  const isOpen = currentDealId === deal.id;
  const injectDebugEntityPreference = useUserPreference("ui_inject_debug_entity");
  const textColor = useColorModeValue("primary.darkGray", "gray.400");
  const theme = useTheme();
  const commonTileProps = useTileProps();
  const fontColor = useColorModeValue("gray.700", "gray.400");

  const handleTileClick = useCallback(() => {
    if (injectDebugEntityPreference) {
      console.log(`Deal Data:`, deal);
    }
    openModal(deal);
  }, [injectDebugEntityPreference, openModal, deal]);

  // Render a more compact list view
  if (displayOption === "list") {
    return (
      <>
        <Box
          {...commonTileProps}
          border={"none"}
          borderRadius={"none"}
          color={fontColor}
          className={`ch-dealfinder-list-item ch-dealfinder-deal-${deal.id}`}
          overflow="hidden"
          cursor="pointer"
          onClick={handleTileClick}
          py="1rem"
          px="1rem"
          _hover={{
            bg: hoverBgColor,
          }}>
          <Stack direction="row" justifyContent="space-between">
            <Flex alignItems="left" flex="1">
              <Box width="12rem" alignContent={"center"}>
                {deal.companyLogoUrl ? (
                  <Image src={deal.companyLogoUrl} alt="Company logo" height="30px" mr={3} objectFit="contain" />
                ) : (
                  <Center>
                    <TextOverflowTooltip
                      label={deal.name || ""}
                      style={{ fontWeight: "bold", fontFamily: theme.fonts.logo }}
                      fontSize="1.4rem"
                    />
                  </Center>
                )}
              </Box>
              <Box>
                <TextOverflowTooltip style={{ fontWeight: "bold" }} fontSize="sm" breakWord={false} label={deal.name} />
                <TextOverflowTooltip fontSize="xs" className="ch-project-company-name" breakWord={false} label={deal.exchange} />
              </Box>
            </Flex>
            <Box alignContent={"center"}>
              <DealActionButtons deal={deal} iconSize="1rem" showBorder={false} />
            </Box>
          </Stack>
        </Box>

        {/* Investment Detail Modal */}
        <InvestmentDetailModal isOpen={isOpen} onClose={closeModal} deal={deal} />
      </>
    );
  }

  return (
    <>
      <Box
        className={`ch-dealfinder-tile ch-dealfinder-deal-${deal.id}`}
        borderWidth="1px"
        borderColor={borderColor}
        borderRadius="xl"
        overflow="hidden"
        bg={bgColor}
        transition="all 0.3s"
        height="100%"
        display="flex"
        flexDirection="column"
        cursor="pointer"
        onClick={handleTileClick}
        _hover={{
          transform: "translateY(-4px)",
          shadow: "lg",
        }}>
        <Stack spacing={0} flex={1}>
          {/* Logo Section */}
          <Box p={4} minH="2.5rem" display="flex" alignItems="center">
            {deal.companyLogoUrl && deal.companyLogoUrl.startsWith("https://storage.googleapis.com") ? (
              <Image src={deal.companyLogoUrl} alt="Company logo" height="40px" maxW="100%" objectFit="contain" />
            ) : (
              <TextOverflowTooltip
                className="ch-dealfinder-tile-title"
                label={deal.name || ""}
                breakWord={false}
                style={{ fontSize: "1.2rem", fontWeight: "bold" }}
              />
            )}
          </Box>

          <Box position="relative" overflow="hidden">
            <Image
              src={deal.companyBannerUrl || `https://picsum.photos/seed/${deal.id || deal.name}/800/400`}
              alt={deal.name}
              objectFit="cover"
              objectPosition="right center"
              width="100%"
              height="8rem"
              transition="transform 0.3s"
              _groupHover={{ transform: "scale(1.05)" }}
              fallback={
                <Image
                  src={`https://picsum.photos/seed/${deal.id || deal.name}/800/400`}
                  alt={deal.name || "Deal image"}
                  objectFit="cover"
                  objectPosition="right center"
                  width="100%"
                  height="8rem"
                  minH="8rem"
                />
              }
            />
          </Box>

          <Stack spacing={2} flex={1} alignItems="flex-start" height={"100%"} justifyContent={"space-between"}>
            <Box px="1rem" pt="1rem" alignContent={"top"} fontSize={"xs"} overflow={"hidden"} minH={"7.5rem"}>
              <ShowMoreContainer height="5rem">
                <MarkdownViewer
                  backgroundColor={bgColor}
                  fontColor={textColor}
                  content={
                    deal.companyOverview ||
                    `A company overview for ${deal.name} will be generated by Charli and displayed here when complete.`
                  }
                  fontSize="xs"
                />
              </ShowMoreContainer>
            </Box>
            <DealActionButtons deal={deal} />
          </Stack>
        </Stack>
      </Box>

      {/* Investment Detail Modal */}
      <InvestmentDetailModal isOpen={isOpen} onClose={closeModal} deal={deal} />
    </>
  );
};
