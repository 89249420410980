import React, { useCallback, useMemo, useState } from "react";
import { AdminTiles } from "screens/common/components";
import { Box, useToast } from "@chakra-ui/react";
import { useAppDispatch, useEntitlements, useOrganizationIsLoading, useOrganizations } from "hooks";
import { ConfirmDeleteModal } from "screens/common/components/ConfirmDeleteModal";
import { useOrganizationsPreferences, useOrganizationsPreferencesIsLoading } from "hooks/useOrganizationsPreferences";
import type { LocalOrganizationPreference } from "hooks/useOrganizationsPreferences";
import { UpsertOrganizationPreferencePanel } from "screens/landing/tabs/admin/organizationsPreferences/components/UpsertOrganizationPreference";
import {
  downloadOrganizationsPreferences,
  removeOrganizationPreference,
  upsertOrganizationPreference,
} from "state/organizationPreference/operations";

const VIEW_TITLE = "Organizations Preferences";

export const OrganizationsPreferences = () => {
  const { manage_organizations_read: hasRead, manage_organizations_write: hasWrite } = useEntitlements();

  const organizationsPreferences = useOrganizationsPreferences();
  const organizations = useOrganizations();
  const [isOpen, setIsOpen] = useState<"upsert" | "delete" | null>();
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const dispatch = useAppDispatch();
  const toast = useToast();
  const isLoading = useOrganizationsPreferencesIsLoading();
  const isLoadingOrg = useOrganizationIsLoading();

  const onClose = useCallback(() => {
    setIsOpen(null);
    setSelectedId(null);
  }, []);

  const onOpenUpsert = useCallback((id?: string) => {
    if (id) {
      setSelectedId(id);
    }

    setIsOpen("upsert");
  }, []);

  const onOpenDelete = useCallback((id: string) => {
    setSelectedId(id);
    setIsOpen("delete");
  }, []);

  const onSubmit = useCallback(
    async ({ value, organization, key }: any) => {
      const response = await dispatch(
        upsertOrganizationPreference({
          organizationId: organization,
          key: key,
          value: (() => {
            try {
              return JSON.parse(value);
            } catch (e) {
              return value;
            }
          })(),
        })
      );

      if (response.type === upsertOrganizationPreference.rejected.type) {
        toast({
          title: VIEW_TITLE,
          description: `Error when ${selectedId ? "updating" : "creating"} ${VIEW_TITLE}`,
          status: "error",
          duration: 15000,
          isClosable: true,
        });

        return;
      }

      toast({
        title: VIEW_TITLE,
        description: `Successfully ${selectedId ? "updated" : "created"} ${VIEW_TITLE}`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      dispatch(downloadOrganizationsPreferences());

      onClose();
    },
    [onClose, dispatch, selectedId, toast]
  );

  const onDelete = useCallback(async () => {
    const organizationPreference = (() => {
      if (!selectedId) {
        return undefined;
      }

      const maybeOrgPreference = organizationsPreferences.find((org) => org.key === selectedId);

      return maybeOrgPreference;
    })();

    if (!organizationPreference) {
      return;
    }

    const deleteResponse = await dispatch(
      removeOrganizationPreference({ organizationId: organizationPreference.organizationId, key: organizationPreference.key })
    );

    if (deleteResponse.type === removeOrganizationPreference.rejected.type) {
      toast({
        title: VIEW_TITLE,
        description: "Error when deleting.",
        status: "error",
        duration: 15000,
        isClosable: true,
      });

      return;
    } else {
      toast({
        title: VIEW_TITLE,
        description: "Deleted successfully.",
        status: "success",
        duration: 15000,
        isClosable: true,
      });
    }

    onClose();
  }, [selectedId, organizationsPreferences, toast, dispatch, onClose]);

  const customFields = useMemo(() => {
    return [
      {
        field: "Organization",
        value: (row: LocalOrganizationPreference) => {
          if (isLoadingOrg) {
            return "Loading organizations...";
          }

          const organization = organizations.find((org) => org.id === row.organizationId);

          return organization?.name ?? "n/a";
        },
      },
    ];
  }, [organizations, isLoadingOrg]);

  return (
    <Box>
      <AdminTiles<LocalOrganizationPreference>
        items={organizationsPreferences}
        fieldsToRender={["value"]}
        filterByFields={["key"]}
        customFields={customFields}
        sortByFields={["lastUpdatedDate"]}
        inputFilterPlaceholder="Filter by key"
        defaultSortByKey="lastUpdatedDate"
        onClickCreate={() => onOpenUpsert()}
        onClickDelete={(id) => onOpenDelete(id)}
        onClickEdit={(id) => onOpenUpsert(id)}
        hasWrite={hasWrite}
        hasRead={hasRead}
        tileTitle={VIEW_TITLE}
        tileTitleKey="key"
        keyName="key"
      />

      {isOpen === "upsert" && (
        <UpsertOrganizationPreferencePanel {...(selectedId && { id: selectedId })} isOpen onClose={onClose} onSubmit={onSubmit} />
      )}

      {isOpen === "delete" && (
        <ConfirmDeleteModal
          isOpen
          isLoading={isLoading}
          title={`Delete ${VIEW_TITLE}`}
          body="Are you sure? You can't undo this action afterwards."
          onConfirm={onDelete}
          onClose={onClose}
        />
      )}
    </Box>
  );
};
