import React, { useContext, useState, useCallback } from "react";
import { Box, Stack, useColorModeValue, Text, Flex, useBreakpointValue, Grid, GridItem } from "@chakra-ui/react";
import { useTileProps, useEntitlementKey, useUserPreference, useSidebarNavigation } from "hooks";
import { useCopyValue } from "hooks/useCopies";
import { SimpleMetric } from "../dashboard/SimpleMetric";
import { ComingSoonOverlay } from "screens/common/components/ComingSoonOverlay";
import QuadrantChart from "./chartComponents/QuadrantChart/QuadrantChart";
import { CollectionsFilterContext } from "./CollectionsFilterContext";
import { useCollectionsStocksContext } from "./CollectionsStocksProvider";
import { StockMarketChart } from "./chartComponents/StockMarketChart";
import { BuySellHoldChart } from "./chartComponents/BuySellHoldChart/BuySellHoldChart";
import { useSwipeable } from "react-swipeable";
import { generateQuadrantData } from "./chartComponents/data/quadrantData";
import { generateTradingValueData } from "./chartComponents/data/tradingValueData";
import { generateBuySellData } from "./chartComponents/data/buySellData";
import { DEFAULT_SIDEBAR_WIDTH } from "screens/landing/components";

// Adjust slide count based on layout
const MOBILE_SLIDES_COUNT = 3;

export const ProjectMetrics = () => {
  const { groupedCollections } = useContext(CollectionsFilterContext);
  const commonTileProps = useTileProps();
  const { getStockData, fetchStockData } = useCollectionsStocksContext();
  const [currentIndex, setCurrentIndex] = useState(0);
  const quadrantHelpInfo = useCopyValue("copy_chart_quadrant_help");
  const [quadrantHelpText, quadrantHelpUrl] = quadrantHelpInfo || [];
  const buySellHoldHelpInfo = useCopyValue("copy_chart_buysellhold_help");
  const stockMarketHelpInfo = useCopyValue("copy_chart_stockmarket_help");
  const [buySellHoldHelpText, buySellHoldHelpUrl] = buySellHoldHelpInfo || [];
  const [stockMarketHelpText, stockMarketHelpUrl] = stockMarketHelpInfo || [];

  // Memoize breakpoint values

  const isMobile = useBreakpointValue({ base: true, md: false, lg: false }, { fallback: "md", ssr: false });
  const isTablet = useBreakpointValue({ base: false, md: true, lg: false }, { fallback: "md", ssr: false });
  const isWideScreen = useBreakpointValue({ base: false, md: false, lg: false, xl: true }, { fallback: "md", ssr: false });
  const TILEWIDTH = isMobile ? "100vw" : "100%";
  const SECONDARY_SLIDES_COUNT = 2; // Only BuySellHoldChart and StockMarketChart for desktop/tablet second column
  const showProjectMetrics = useEntitlementKey("ui_project_metrics");
  const injectDebugEntityPreference = useUserPreference("ui_inject_debug_entity");
  const sidebarWidth = (useUserPreference("ui_sidebar_width") as number) || (DEFAULT_SIDEBAR_WIDTH as number);
  const { currentSidebarType } = useSidebarNavigation();
  const tileBgColor = useColorModeValue("white", "#161B25");

  // Memoize slide handling
  const handleSlide = useCallback(
    (direction: "prev" | "next") => {
      setCurrentIndex((prev) => {
        if (direction === "prev") return Math.max(prev - 1, 0);
        const maxSlides = isMobile ? MOBILE_SLIDES_COUNT - 1 : SECONDARY_SLIDES_COUNT - 1;
        return Math.min(prev + 1, maxSlides);
      });
    },
    [isMobile]
  );

  const handlers = useSwipeable({
    onSwipedLeft: () => handleSlide("next"),
    onSwipedRight: () => handleSlide("prev"),
    trackMouse: true,
  });

  // Debug logging effect
  React.useEffect(() => {
    if (!injectDebugEntityPreference) return;
  }, [groupedCollections, injectDebugEntityPreference]);

  // Fetch stock data for all tickers in grouped collections
  React.useEffect(() => {
    if (!groupedCollections || groupedCollections.length === 0) return;

    groupedCollections.forEach((collection) => {
      const { ticker, stockExchange } = collection || {};
      if (ticker && stockExchange) {
        fetchStockData(ticker, stockExchange);
      }
    });
  }, [groupedCollections, fetchStockData]);

  // Memoize data calculations
  const quadrantData = React.useMemo(() => generateQuadrantData(groupedCollections), [groupedCollections]);
  const tradingValueData = React.useMemo(
    () => generateTradingValueData(groupedCollections, getStockData),
    [getStockData, groupedCollections]
  );
  const buySellData = React.useMemo(() => generateBuySellData(groupedCollections, getStockData), [getStockData, groupedCollections]);

  // Memoize styles
  const bgColor = useColorModeValue("#fbfbfb", "gray.800");
  const arrowColor = useColorModeValue("gray.400", "gray.200");
  const arrowBgColor = useColorModeValue("white", "gray.500");
  const arrowBgColorHover = useColorModeValue("gray.100", "gray.500");

  const arrowStyles = React.useMemo(
    () =>
      ({
        cursor: "pointer",
        pos: "absolute",
        top: "50%",
        w: "auto",
        mt: "-22px",
        mx: "-12px",
        p: "1px 5px",
        bg: arrowBgColor,
        color: arrowColor,
        fontWeight: "bold",
        fontSize: "22px",
        transition: "0.6s ease",
        borderRadius: "5px",
        userSelect: "none",
        _hover: {
          bg: arrowBgColorHover,
        },
      } as const),
    [arrowBgColor, arrowBgColorHover, arrowColor]
  );

  const containerStyles = React.useMemo(
    () => ({
      position: "relative" as const,
      height: "100%",
      pt: isMobile ? "0" : "1rem",
      pb: isMobile ? "0" : "1rem",
      px: isMobile ? "0" : "1rem",
      bgColor,
      mb: "1rem",
      mx: "auto",
    }),
    [isMobile, bgColor]
  );

  // Render the QuadrantChart component
  const renderQuadrantChart = () =>
    quadrantData && (
      <Box
        className={"ch-metric-chart-quadrant"}
        {...commonTileProps}
        px={isMobile ? "1rem" : "0"}
        py="0"
        bgColor={tileBgColor}
        borderRadius={isMobile ? "none" : "lg"}
        width={TILEWIDTH}
        flexShrink={0}
        height="100%">
        <SimpleMetric
          className="ch-chart-help-quadrant"
          title="Portfolio Investment (Pi) Quadrant"
          helpText={quadrantHelpText}
          helpUrl={quadrantHelpUrl}>
          <QuadrantChart data={quadrantData} xAxisLabel="Strength of Fundamentals" yAxisLabel="Strength of Market Perception" />
        </SimpleMetric>
      </Box>
    );

  // Render the secondary charts (BuySellHoldChart and StockMarketChart)
  const renderSecondaryCharts = () => (
    <Stack
      direction={isMobile ? "column" : "row"}
      width={TILEWIDTH}
      flexShrink={0}
      height="100%"
      style={{
        transform: `translateX(calc(-${currentIndex} * (${TILEWIDTH} + 0.5rem)))`,
        transition: "transform 0.3s ease-in-out",
        margin: "0 auto",
      }}>
      <Box {...commonTileProps} p="0" bgColor={tileBgColor} borderRadius={isMobile ? "none" : "lg"} width={TILEWIDTH} flexShrink={0}>
        <SimpleMetric title="Trading Analysis" helpText={buySellHoldHelpText} helpUrl={buySellHoldHelpUrl}>
          <BuySellHoldChart data={buySellData} />
        </SimpleMetric>
      </Box>
      {tradingValueData && (
        <Box {...commonTileProps} p="0" bgColor={tileBgColor} borderRadius={isMobile ? "none" : "lg"} width={TILEWIDTH} flexShrink={0}>
          <SimpleMetric title="Fair Market Value vs Trading Price" helpText={stockMarketHelpText} helpUrl={stockMarketHelpUrl}>
            <StockMarketChart data={tradingValueData} />
          </SimpleMetric>
        </Box>
      )}
    </Stack>
  );

  // Desktop/Tablet layout with 2-column grid
  const renderDesktopLayout = () => (
    <Grid templateColumns="1fr 1fr" gap={isMobile || isTablet ? "1rem" : "2rem"} w="full">
      <GridItem>{renderQuadrantChart()}</GridItem>
      <GridItem position="relative" overflow="hidden">
        <Flex w="full" overflow="hidden" {...handlers}>
          {renderSecondaryCharts()}
          <Box position="absolute" top="12.6rem" right="calc(100% - 20px)">
            {currentIndex > 0 && showProjectMetrics && (
              <Text className="ch-project-metrics-previous" {...arrowStyles} left="0" onClick={() => handleSlide("prev")}>
                &#10094;
              </Text>
            )}
          </Box>
          <Box position="absolute" top="12.6rem" right="20px">
            {currentIndex < SECONDARY_SLIDES_COUNT - 1 && !isWideScreen && showProjectMetrics && (
              <Text className="ch-project-metrics-next" {...arrowStyles} right="0" onClick={() => handleSlide("next")}>
                &#10095;
              </Text>
            )}
          </Box>
        </Flex>
      </GridItem>
    </Grid>
  );

  // Mobile layout with single column and sliding behavior for all charts
  const renderMobileLayout = () => (
    <Flex
      w="full"
      alignItems="center"
      justifyContent="center"
      position="relative"
      maxWidth={`calc(100vw - ${currentSidebarType === "hidden" ? 0 : sidebarWidth + 30}px)`}>
      <Flex w="full" overflow="hidden" {...handlers}>
        <Stack
          direction="row"
          spacing="1rem"
          justify="center"
          align="center"
          minW="max-content"
          style={{
            transform: `translateX(calc(-${currentIndex} * (${TILEWIDTH} + 1rem)))`,
            transition: "transform 0.3s ease-in-out",
            margin: "0 auto",
          }}>
          {!showProjectMetrics && (
            <ComingSoonOverlay
              message="Upgrade to the Business Plan for access to the Portfolio Investment Ratings."
              height="100%"
              width="100%"
            />
          )}
          {renderQuadrantChart()}
          <Box {...commonTileProps} bgColor={tileBgColor} borderRadius={isMobile ? "none" : "lg"} width={TILEWIDTH} flexShrink={0}>
            <SimpleMetric title="Trading Analysis" helpText={buySellHoldHelpText} helpUrl={buySellHoldHelpUrl}>
              <BuySellHoldChart data={buySellData} />
            </SimpleMetric>
          </Box>
          {tradingValueData && (
            <Box {...commonTileProps} bgColor={tileBgColor} borderRadius={isMobile ? "none" : "lg"} width={TILEWIDTH} flexShrink={0}>
              <SimpleMetric title="Fair Market Value vs Trading Price" helpText={stockMarketHelpText} helpUrl={stockMarketHelpUrl}>
                <StockMarketChart data={tradingValueData} />
              </SimpleMetric>
            </Box>
          )}
        </Stack>
        <Box position="absolute" top="9.6rem" left="12px">
          {currentIndex > 0 && showProjectMetrics && (
            <Text className="ch-project-metrics-previous" {...arrowStyles} left="0" onClick={() => handleSlide("prev")}>
              &#10094;
            </Text>
          )}
        </Box>
        <Box position="absolute" top="9.6rem" right="12px">
          {currentIndex < MOBILE_SLIDES_COUNT - 1 && showProjectMetrics && (
            <Text className="ch-project-metrics-next" {...arrowStyles} right="0" onClick={() => handleSlide("next")}>
              &#10095;
            </Text>
          )}
        </Box>
      </Flex>
    </Flex>
  );

  return (
    <Box
      {...containerStyles}
      pl={isMobile || isTablet ? ".5rem" : currentSidebarType === "hidden" ? "0" : "1rem"}
      pr={isMobile || isTablet ? ".5rem" : currentSidebarType === "hidden" ? "0" : "1rem"}
      pt={isMobile ? "0" : "1rem"}
      mb="0">
      {!showProjectMetrics && (
        <ComingSoonOverlay
          message="Upgrade to the Business Plan for access to the Portfolio Investment Ratings."
          height="100%"
          width="100%"
        />
      )}
      {isMobile ? renderMobileLayout() : renderDesktopLayout()}
    </Box>
  );
};
