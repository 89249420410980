import React, { useEffect, useState } from "react";
import { Modal, ModalContent, ModalOverlay, useBreakpointValue } from "@chakra-ui/react";
import { ConversationDialogInput } from "screens/thread/ConversationDialogInput";
import { useProjectParams } from "hooks";

interface FloatingDialogModalProps {
  isOpen: boolean;
  onClose: () => void;
  buttonRef: React.RefObject<HTMLElement>;
}

export const FloatingDialogModal: React.FC<FloatingDialogModalProps> = ({ isOpen, onClose, buttonRef }) => {
  const [position, setPosition] = useState({ top: 0, right: 0 });
  const { isPortfolios, projectId } = useProjectParams();
  const isMobile = useBreakpointValue({ base: true, md: true }, { fallback: "md", ssr: true });

  useEffect(() => {
    if (buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      setPosition({
        top: rect.top,
        right: window.innerWidth - rect.left,
      });
    }
  }, [buttonRef, isOpen, isMobile]);

  const modalContentStyles = isMobile
    ? {
        position: "fixed" as const,
        top: `.5rem`,
        left: "0",
        paddingLeft: "5px",
        paddingRight: "2px",
        transform: "translateX(-50%)",
        margin: 0,
        width: "100%",
        maxW: "100%",
      }
    : {
        position: "fixed" as const,
        top: `${position.top}px`,
        right: `${position.right}px`,
        transform: "translateX(-1rem)",
        mt: "-0.3rem",
        mb: "0",
        mr: "0.4rem",
        maxW: "30rem",
        w: "100%",
      };

  return (
    <Modal isOpen={isOpen} onClose={onClose} blockScrollOnMount={false}>
      <ModalOverlay bg={`rgba(0, 0, 0, ${isMobile ? "0.7" : "0.1"})`} />
      <ModalContent
        bg="transparent"
        boxShadow={`0 0 40px rgba(0, 0, 0, 0.5)`}
        borderRadius={"30px 30px 30px 30px!important"}
        border="none"
        {...modalContentStyles}>
        <ConversationDialogInput
          defaultIsOpen
          inputId="view-input"
          minWidth="unset"
          initialText={
            projectId
              ? `Ask a question about this ${isPortfolios ? "portfolio" : "project"}`
              : `Add a new ticker to this ${isPortfolios ? "portfolio" : "project"}`
          }
          afterSubmit={onClose}
        />
      </ModalContent>
    </Modal>
  );
};
