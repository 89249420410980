import React, { useMemo } from "react";
import { Box, Button } from "@chakra-ui/react";
import { Wizard } from "react-use-wizard";
import { PanelStep } from "screens/panels/components/PanelStep";
import { PanelView } from "screens/panels/components/PanelView";
import { useButtonProps } from "hooks";
import type { IField } from "screens/common/components";
import { DynamicForm } from "screens/common/components";
import { useTickerTypeOptions } from "../utils/tickerTypesOptions";
import { useExchangeOptions } from "../utils/exchangeOptions";
export interface CreateTickerForm {
  symbol: string;
  name: string;
  dataProviderId: string | "null";
  exchange: string;
  type: string;
  notes: string;
  companyLogoUrl: string;
  companyBannerUrl: string;
  companyUrl: string;
  companyTaxId: string;
  companyLegalName: string;
}

interface IProps {
  onSubmit: (values: CreateTickerForm) => void;
  isLoading?: boolean;
}

interface IPanelProps extends IProps {
  onClose: () => void;
  isOpen: boolean;
}

const CreateTicker = ({ onSubmit, isLoading }: IProps) => {
  const commonButtonProps = useButtonProps("sm", "primary");

  const tickerTypeOptions = useTickerTypeOptions();
  const exchangeOptions = useExchangeOptions();

  const fields: IField<CreateTickerForm>[] = useMemo(
    () => [
      {
        type: "stack",
        isInline: true,
        spacing: "2rem",
        fields: [
          {
            type: "text",
            entity: "symbol",
            label: "Symbol",
            isRequired: true,
            defaultValue: "",
          },
          {
            type: "text",
            entity: "name",
            label: "Name",
            isRequired: true,
            defaultValue: "",
          },
        ],
      },
      {
        type: "stack",
        isInline: true,
        spacing: "2rem",
        fields: [
          {
            type: "select",
            entity: "exchange",
            label: "Exchange",
            isRequired: true,
            options: exchangeOptions,
            defaultValue: "",
          },
          {
            type: "select",
            entity: "type",
            label: "Type",
            isRequired: true,
            options: [{ label: "Not added", value: "null" }, ...tickerTypeOptions],
            defaultValue: "",
          },
          {
            type: "text",
            entity: "dataProviderId",
            label: "Data Provider Id",
            isRequired: false,
            defaultValue: "",
          },
        ],
      },
      {
        type: "stack",
        isInline: true,
        spacing: "2rem",
        fields: [
          {
            type: "text",
            entity: "companyLogoUrl",
            label: "Company Logo URL",
            isRequired: false,
            defaultValue: "",
          },
          {
            type: "text",
            entity: "companyBannerUrl",
            label: "Company Banner URL",
            isRequired: false,
            defaultValue: "",
          },
          {
            type: "text",
            entity: "companyUrl",
            label: "Company URL",
            isRequired: false,
            defaultValue: "",
          },
        ],
      },
      {
        type: "stack",
        isInline: true,
        spacing: "2rem",
        fields: [
          {
            type: "text",
            entity: "companyTaxId",
            label: "Company Tax ID",
            isRequired: false,
            defaultValue: "",
          },
          {
            type: "text",
            entity: "companyLegalName",
            label: "Company Legal Name",
            isRequired: false,
            defaultValue: "",
          },
        ],
      },
      {
        type: "textarea",
        entity: "notes",
        label: "Notes",
        isRequired: false,
        minHeight: "10rem",
        defaultValue: "",
      },
    ],
    [tickerTypeOptions, exchangeOptions]
  );

  const defaultValues: CreateTickerForm = useMemo(() => {
    return {
      symbol: "",
      exchange: "",
      dataProviderId: "",
      name: "",
      type: "",
      notes: "",
      companyLogoUrl: "",
      companyBannerUrl: "",
      companyUrl: "",
      companyTaxId: "",
      companyLegalName: "",
    };
  }, []);

  return (
    <Box pt="2rem">
      <DynamicForm<CreateTickerForm>
        formId="create-ticker-form"
        defaultValues={defaultValues}
        fields={fields}
        onSubmit={(values) => onSubmit(values)}
        title="Create ticker"
        isDisabled={isLoading}
      />

      <Box display={"flex"} justifyContent={"flex-end"} width="100%" py="1rem">
        <Button isLoading={isLoading} {...commonButtonProps} type="submit" form="create-ticker-form">
          Save Ticker
        </Button>
      </Box>
    </Box>
  );
};

const CreateTickerPanel = (props: IPanelProps) => {
  const { onClose, isOpen, ...rest } = props;

  return (
    <PanelView isOpen={isOpen} onClose={onClose} panelTitle={"Create ticker"}>
      <Wizard>
        <PanelStep>
          <Box pt="1rem">{isOpen && <CreateTicker {...rest} />}</Box>
        </PanelStep>
      </Wizard>
    </PanelView>
  );
};

export { CreateTickerPanel };
