import { AttachmentIcon, WarningIcon } from "@chakra-ui/icons";
import { Badge, IconButton, Box, Tooltip, Stack, useColorModeValue, Spinner } from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import React, { useContext } from "react";
import { InputBarDropzoneContext } from "./InputBarDropzoneContext";

type Size = "md" | "lg";

interface Props {
  size: Size;
}

export const InputBarAttachmentButton: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({ size }) => {
  return <FilesAttachmentButton size={size} />;
};

const FilesAttachmentButton = ({ size }: { size: Size }) => {
  const { files, openFilesModal, isUploadInProgress, didUploadFail } = useContext(InputBarDropzoneContext);
  const badgeBgColor = useColorModeValue("gray.500", "gray.600");
  const textColor = useColorModeValue("gray.500", "gray.400");
  const spinnerColor = useColorModeValue("primary.default", "gray.800");

  return (
    <React.Fragment>
      <Tooltip
        label={didUploadFail ? "Upload failed" : isUploadInProgress ? "Uploading..." : ""}
        aria-label="file"
        visibility={files.length > 0 && (didUploadFail || isUploadInProgress) ? "unset" : "hidden"}>
        <Stack direction="row" spacing={0}>
          <IconButton
            height="100%"
            borderRadius={"full"}
            aria-label="Attach file"
            size={size}
            icon={
              didUploadFail ? (
                <WarningIcon width="1rem" height="1rem" color={textColor} />
              ) : (
                <AttachmentIcon width="1rem" height="1rem" color={textColor} />
              )
            }
            _hover={{}}
            _active={{}}
            opacity={isUploadInProgress ? 0 : 1}
            backgroundColor={"transparent"}
            onClick={() => {
              openFilesModal();
            }}
          />
          {!isUploadInProgress && !didUploadFail && files.length > 0 && (
            <Badge
              position="absolute"
              borderRadius={"full"}
              variant="solid"
              backgroundColor={badgeBgColor}
              bottom={"14px"}
              marginLeft="3px"
              boxSize="15px"
              textAlign="center"
              fontSize="10px"
              zIndex={1}
              pointerEvents="none">
              {files.length}
            </Badge>
          )}
          {isUploadInProgress && (
            <Box position="absolute" bottom={"3px"} marginLeft="11px">
              <Spinner color={spinnerColor} size="sm" />
            </Box>
          )}
        </Stack>
      </Tooltip>
    </React.Fragment>
  );
};
