import { Stack, Text, Image, Box, Skeleton, Icon, useColorModeValue, Flex, useBreakpointValue } from "@chakra-ui/react";
import type { ExternalLink } from "api/newsData/models/ExternalLink";
import { useSkeletonProps } from "hooks";
import { useNewsLinks } from "hooks/useNewsInformation";
import type { FunctionComponent } from "react";
import React, { useMemo } from "react";
import { BsNewspaper } from "react-icons/bs";
import { BiLike, BiDislike, BiTrash } from "react-icons/bi";
import { LinkLogo } from "screens/common/components";
import { TextOverflowTooltip } from "screens/landing/components/TextOverflowTooltip";

const NewsTile: FunctionComponent<{ newsArticle: ExternalLink }> = React.memo(({ newsArticle }) => {
  const linkDomain = new URL(newsArticle.link).hostname;
  const fontColor = useColorModeValue("gray.700", "gray.300");
  const cardBgColor = useColorModeValue("white", "gray.900");
  const borderColor = useColorModeValue("gray.200", "gray.700");
  const headingColor = useColorModeValue("primary.darkGray", "gray.400");
  const iconColor = useColorModeValue("gray.500", "gray.500");
  const linkColor = useColorModeValue("blue.500", "blue.300");
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });

  const handleTileClick = () => {
    window.open(newsArticle.link, "_blank");
  };

  const fallbackImage = useMemo(() => {
    return (
      <Box
        width="8rem"
        minWidth="8rem"
        height="6rem"
        borderRadius="md"
        bgColor="gray.200"
        display="flex"
        justifyContent="center"
        alignItems="center">
        <Icon as={BsNewspaper} boxSize="3rem" color="gray.400" />
      </Box>
    );
  }, []);

  const displayDomain = linkDomain.replace(/^www\./, "");

  // Create a placeholder content summary based on the title
  const contentSummary = `${displayDomain} has emphasized its commitment to leading by example in addressing climate change, aiming to obtain 100% of its electricity from certified renewable sources by 2025.`;

  return (
    <Box className="ch-news-article" borderWidth="1px" borderRadius="lg" borderColor={borderColor} bgColor={cardBgColor} boxShadow="sm">
      <Box px="1rem" pt="1rem">
        <Text fontWeight="600" fontSize="md" lineHeight="1.4" color={headingColor} mb={2}>
          {newsArticle.alias}
        </Text>
        <Stack pt=".5rem" spacing="1rem" direction={"row"} cursor="pointer" onClick={handleTileClick}>
          <Stack spacing="5px" width="100%">
            <Image
              src={newsArticle.thumbnail}
              fallback={fallbackImage}
              alt={newsArticle.alias}
              borderRadius="md"
              width="8rem"
              height="6rem"
              aria-label={newsArticle.alias}
              objectFit="cover"
            />
            <Stack direction="row" width="8rem" spacing={1} alignContent={"center"} justifyContent={"flex-start"}>
              <Box>
                <LinkLogo url={linkDomain} maxHeight={"1rem"} />
              </Box>
              <Text fontSize="xs" color={fontColor}>
                {displayDomain}
              </Text>
            </Stack>
          </Stack>
          <Stack direction="column" spacing={2} height={"100%"} justifyContent={"space-between"}>
            <TextOverflowTooltip noOfLines={5} label={contentSummary} fontSize="sm" color={fontColor} />
            <Text
              textAlign={"right"}
              fontSize="xs"
              color={linkColor}
              aria-label="View more content"
              cursor="pointer"
              onClick={handleTileClick}>
              View Article
            </Text>
          </Stack>
        </Stack>
      </Box>
      <Stack
        mt="1rem"
        direction="row"
        spacing={isMobile ? ".5rem" : "1rem"}
        width="100%"
        px={isMobile ? ".5rem" : "1rem"}
        py={isMobile ? ".2rem" : ".5rem"}
        borderTop="1px solid"
        borderColor={borderColor}
        align="center">
        <Icon as={BiLike} boxSize="1rem" color={iconColor} cursor="pointer" aria-label="Like" />
        <Icon as={BiDislike} boxSize="1rem" color={iconColor} cursor="pointer" aria-label="Dislike" />
        <Icon as={BiTrash} boxSize="1rem" color={iconColor} cursor="pointer" aria-label="Delete" />
        <Flex flex={1} />
      </Stack>
    </Box>
  );
});

export const NewsTilesSection: FunctionComponent = () => {
  const newsArticles = useNewsLinks({ limit: 5 });
  const skeletonStyle = useSkeletonProps();
  const borderColor = useColorModeValue("gray.200", "gray.800");
  const cardBgColor = useColorModeValue("white", "gray.800");

  const newsSkeletons = useMemo(() => {
    return Array.from({ length: 8 }, (_, index) => (
      <Box
        key={index}
        borderWidth="1px"
        borderRadius="lg"
        borderColor={borderColor}
        bgColor={cardBgColor}
        boxShadow="sm"
        overflow="hidden"
        maxWidth="100%"
        mb={4}
        p={4}>
        <Skeleton {...skeletonStyle} height="1.5rem" width="90%" mb={2} />
        <Skeleton {...skeletonStyle} height="10rem" width="100%" borderRadius="md" mb={3} />
        <Skeleton {...skeletonStyle} height="0.8rem" width="100%" mb={2} />
        <Skeleton {...skeletonStyle} height="0.8rem" width="90%" mb={2} />
        <Skeleton {...skeletonStyle} height="0.8rem" width="40%" mb={3} />
        <Skeleton {...skeletonStyle} height="1px" width="100%" mb={3} />
        <Skeleton {...skeletonStyle} height="1.2rem" width="60%" />
      </Box>
    ));
  }, [borderColor, cardBgColor, skeletonStyle]);

  const newsTiles = useMemo(
    () => newsArticles.map((newsArticle, index) => <NewsTile key={index} newsArticle={newsArticle} />),
    [newsArticles]
  );

  return <>{newsArticles && newsArticles.length === 0 ? newsSkeletons : newsTiles}</>;
};
